import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import unionBy from 'lodash/unionBy';
import omitBy from 'lodash/omitBy';
import { getAllFavouriteTeamIds, getUserId } from 'selectors/user';
import { fail, fetchApi, start, success } from 'middleware/fetch';
import { updateUserFavouriteTeam, updateUserProfile } from './user';
import { parseDate } from 'utils/time';
import defaultConfig from 'constants/defaultConfig';
import { isCompetitionStarted } from 'parser/utils';

export const GET_OS_COMPETITION = `GET_OS_COMPETITION`;
export const GET_USER_COMPETITION_TEMPLATE = `GET_USER_COMPETITION_TEMPLATE`;
export const GET_USER_COMPETITION_ELIGIBILITY = `GET_USER_COMPETITION_ELIGIBILITY`;
export const SUBMIT_OS_COMPETITION_ENTRY = `SUBMIT_OS_COMPETITION_ENTRY`;
export const ERRORS = {
  SERVER_ERROR: 'SERVER_ERROR',
  COMPETITION_ERROR: 'COMPETITION_ERROR',
  NOT_ELIGIBLE: 'NOT_ELIGIBLE',
  NOT_ELIGIBLE_ENTERED: 'NOT_ELIGIBLE_ENTERED',
  NOT_ELIGIBLE_PREMIUM: 'NOT_ELIGIBLE_PREMIUM',
};

export const loadUserCompetition = (isUserLoggedIn, competitionId) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: start(GET_OS_COMPETITION),
        payload: {
          inProgress: true,
        },
      });

      let [response, error] = await getOptusSportCompetition(competitionId);

      if (!isEmpty(error)) {
        dispatch({
          type: fail(GET_OS_COMPETITION),
          payload: {
            error: get(error, 'error', {}),
          },
        });
        return;
      }

      const competitions = get(response, 'data.competitions', []);

      dispatch({
        type: success(GET_OS_COMPETITION),
        payload: {
          competitions,
        },
      });

      if (isEmpty(competitions)) {
        return;
      }

      const isCompStarted = isCompetitionStarted(competitions[0]);

      if (isUserLoggedIn && isCompStarted) {
        const currentState = getState();
        const currentUserId = getUserId(currentState.user);
        await checkUserEligibility(dispatch, currentUserId, competitionId);
      }

      [response, error] = await loadUserCompetitionTemplate(competitionId);

      if (!isEmpty(error)) {
        dispatch({
          type: fail(GET_OS_COMPETITION),
          payload: {
            error: get(error, 'error', {}),
          },
        });
      } else {
        dispatch({
          type: success(GET_USER_COMPETITION_TEMPLATE),
          template: response,
        });
      }
    } finally {
      dispatch({
        type: start(GET_OS_COMPETITION),
        payload: {
          inProgress: false,
        },
      });
    }
  };
};

export const checkUserEligibility = async (dispatch, userId, competitionId) => {
  const [response, error] = await getUserCompetitionEligibility(
    competitionId,
    userId,
  );

  if (!isEmpty(error)) {
    dispatch({
      type: fail(GET_USER_COMPETITION_ELIGIBILITY),
      payload: {
        error: get(error, 'error', {}),
      },
    });
  } else {
    dispatch({
      type: success(GET_USER_COMPETITION_ELIGIBILITY),
      payload: response.data,
    });
  }
};

export const submitUserCompetitionEntry = (
  competitionId,
  userId,
  favouriteTeamIds,
  payload,
  triggerCompetitionAnalytics,
) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const currentUserFavouriteTeamIds = getAllFavouriteTeamIds(state);

      const updatedFavouriteTeamIds = unionBy(
        favouriteTeamIds,
        currentUserFavouriteTeamIds,
        'id',
      );

      await updateUserFavouriteTeam(userId, updatedFavouriteTeamIds, dispatch);

      const currentProfileData = get(state.user, 'profile', {});

      const { firstName, lastName, dateOfBirth, postcode, gender } = payload;
      const dob = parseDate(dateOfBirth)?.toLocaleDateString();
      let updatedProfileData = {
        firstName,
        lastName,
        postcode,
        gender,
        ...(dob && { dateOfBirth: dob }),
      };

      // Remove properties with empty values before updating profile
      updatedProfileData = omitBy(updatedProfileData, (v) => !v);

      // Compare current profile details and new profile details to see if they have changed
      const isDataPristine = some([currentProfileData], updatedProfileData);

      if (!isDataPristine) {
        dispatch(updateUserProfile(updatedProfileData));
      }

      const [response, error] = await submitOSCompetitionEntry(
        competitionId,
        userId,
        payload,
      );

      if (!isEmpty(error)) {
        dispatch({
          type: fail(SUBMIT_OS_COMPETITION_ENTRY),
          payload: {
            error: get(error, 'error', {}),
          },
        });
      } else {
        dispatch({
          type: success(SUBMIT_OS_COMPETITION_ENTRY),
          payload: response,
        });

        dispatch(
          triggerCompetitionAnalytics({
            profileUpdated: !isDataPristine,
          }),
        );
      }
    } catch (error) {
      dispatch({
        type: fail(SUBMIT_OS_COMPETITION_ENTRY),
        payload: { error },
      });
    }
  };
};

const getOptusSportCompetition = (competitionId) => {
  return fetchApi('fe-api-OSCompetitions', {
    method: 'get',
    path: `/metadata/${competitionId}`,
  });
};

const getUserCompetitionEligibility = (competitionId, userId) => {
  return fetchApi('fe-api-userCompetitionEligibility', {
    method: 'get',
    path: `/${competitionId}/${userId}`,
  });
};

const loadUserCompetitionTemplate = (competitionId) => {
  return fetchApi('fe-api-dev-metadataGetSystemConfig', {
    method: 'get',
    path: `/userCompetitions/${competitionId}`,
  });
};

const submitOSCompetitionEntry = (competitionId, userId, payload) => {
  return fetchApi('fe-api-OSCompetitions', {
    method: 'post',
    path: `/${competitionId}/${userId}/`,
    body: {
      ...payload,
    },
    timeout: defaultConfig.defaultApiTimeout,
  });
};
