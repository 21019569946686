import API from '@aws-amplify/api';

import * as types from '../constants/actionTypes';
import getFlags from 'ducks/features';
import contentCardsMapper from 'middleware/contentCardMapping';

export function getAllContentCards() {
  return (dispatch, getState) => {
    const state = getState();
    const { systemConfig } = state;
    const getFeatureFlags = getFlags({
      systemConfig,
    });
    const promotionalPlacementsConfig = getFeatureFlags(
      `PROMOTIONAL_PLACEMENTS_CONFIG`,
    );
    const {
      enabled: isPromotionalPlacementsEnabled,
      contentCard: { actions: contentCardActionsConfig } = {},
    } = promotionalPlacementsConfig || {};
    if (!isPromotionalPlacementsEnabled) {
      return false;
    }

    dispatch(setContentCardsUnfiltered());
    return API.get('fe-api-getAllContentCards', '', {})
      .then((result) => {
        const contentCards = result?.assets;
        const contentCardsMapped = contentCardsMapper({
          contentCards,
          contentCardActionsConfig,
        });
        dispatch(setContentCardsUnfilteredSuccess(contentCardsMapped));
      })
      .catch((error) => {
        dispatch(setContentCardsUnfilteredFailure());
        throw error;
      });
  };
}

export function getContentCards(userId) {
  return (dispatch, getState) => {
    const state = getState();
    const { systemConfig } = state;
    const getFeatureFlags = getFlags({
      systemConfig,
    });
    const promotionalPlacementsConfig = getFeatureFlags(
      `PROMOTIONAL_PLACEMENTS_CONFIG`,
    );
    const {
      enabled: isPromotionalPlacementsEnabled,
      contentCard: { actions: contentCardActionsConfig } = {},
    } = promotionalPlacementsConfig || {};
    if (!isPromotionalPlacementsEnabled) return false;

    const path = userId && userId !== '-1' ? `/users/${userId}/web` : `/web`;
    dispatch(setContentCards());
    return API.get('fe-api-getContentCards', path, {})
      .then((result) => {
        const contentCards = result?.data?.assets;

        const contentCardsMapped = contentCardsMapper({
          contentCards,
          contentCardActionsConfig,
        });
        dispatch(setContentCardsSuccess(contentCardsMapped));
      })
      .catch((error) => {
        dispatch(setContentCardsFailure());
        throw error;
      });
  };
}

export function setContentCards() {
  return {
    type: types.GET_CONTENT_CARDS,
  };
}

export function setContentCardsSuccess(contentCards) {
  return {
    type: types.GET_CONTENT_CARDS_SUCCESS,
    contentCards,
  };
}

export function setContentCardsFailure() {
  return {
    type: types.GET_CONTENT_CARDS_FAILURE,
  };
}

export function setContentCardsUnfiltered() {
  return {
    type: types.GET_CONTENT_CARDS_UNFILTERED,
  };
}

export function setContentCardsUnfilteredSuccess(contentCardsUnfiltered) {
  return {
    type: types.GET_CONTENT_CARDS_UNFILTERED_SUCCESS,
    contentCardsUnfiltered,
  };
}
export function setContentCardsUnfilteredFailure() {
  return {
    type: types.GET_CONTENT_CARDS_UNFILTERED_FAILURE,
  };
}

export function setDynamicContentCards(dynamicContentCards) {
  return {
    type: types.SET_DYNAMIC_CONTENT_CARDS,
    dynamicContentCards,
  };
}
