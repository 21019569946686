import styled, { css } from 'styled-components';
import { asRem } from '../Theme/theme';

const Label = styled.label`
  ${(props) => {
    const {
      textSize: size,
      fontVariant,
      verticalPush,
      verticalPull,
      asBlock,
      isError,
      color,
      theme: { font, textSize, palette },
    } = props;
    return css`
      font-family: ${font[fontVariant] || font['secondary']};
      font-weight: bold;
      font-size: ${size[asRem(textSize)] || '.875rem'};
      color: ${color || palette['os-white']};
      transition-timing-function: ease-in-out;
      margin-top: ${verticalPush && asRem(verticalPush)};
      margin-bottom: ${verticalPull && asRem(verticalPull)};
      display: ${asBlock ? 'block' : 'inline'};
      color: ${isError && palette['error-red']};
    `;
  }}
`;

export { Label };
