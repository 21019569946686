import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function teamColors(state = initialState.teamcolors, action) {
  let currentTeamColors;

  switch (action.type) {

    case types.GET_TEAMCOLORS_INPROGRESS:
      currentTeamColors = {
        ...state,
        inProgress: true,
        isSuccess: false
      };

      return currentTeamColors;

    case types.GET_TEAMCOLORS_SUCCESS:
      return {
        ...state,
        inProgress: false,
        isSuccess: true,
        allTeamColors: action.teamcolors.rows.TeamID ? [ ...state.allTeamColors, action.teamcolors.rows ] : state.allTeamColors
      }


    case types.GET_TEAMCOLORS_FAILURE:
      currentTeamColors = {
        // for failure case, use whatever has been loaded before
        ...state,
        inProgress: false,
        isSuccess: false
      };

      return currentTeamColors;

    default:
      return state;
  }
}

