import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from '../reducers/rootReducer';
import thunk from 'redux-thunk';
import { loadingBarMiddleware } from 'react-redux-loading-bar';
import defaultConfig from '../constants/defaultConfig';
import fetch from '../middleware/fetch';
import asyncDispatchMiddleware from '../middleware/asyncDispatchMiddleware';
import * as selectors from '../selectors';
import { useSelector } from '../utils/hooks';

const createPortalStore = (initialState) => {
  const env = defaultConfig.env || 'prod';
  const composeEnhancers =
    env !== 'prod'
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
      : compose;

  const middleware = [
    thunk,
    loadingBarMiddleware({
      promiseTypeSuffixes: ['INPROGRESS', 'SUCCESS', 'FAILURE'],
    }),
    asyncDispatchMiddleware,
    fetch,
  ];

  return createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middleware)),
  );
};

export { selectors, useSelector };

export default createPortalStore;
