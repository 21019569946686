import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function livescore(state = initialState.liveScores, action) {
  let currentScore;

  switch (action.type) {

    case types.GET_LIVESCORE_INPROGRESS:
      currentScore = {
        ...state.find(scoreData => scoreData.competitionId === action.results.competitionId || scoreData.season === action.results.season),
        ...action.results,
        inProgress: true,
        isSuccess: false
      };

      return [
        ...state.filter(scoreData => scoreData.competitionId !== action.results.competitionId || scoreData.season !== action.results.season),
        currentScore
      ];

    case types.GET_LIVESCORE_SUCCESS:

      return [
        ...state.filter(scoreData => scoreData.competitionId !== action.results.competitionId || scoreData.season !== action.results.season),
        {
          ...action.results,
          inProgress: false,
          isSuccess: true
        }
      ];

    case types.GET_LIVESCORE_FAILURE:
      currentScore = {
        // for failure case, use whatever has been loaded before
        ...state.find(scoreData => scoreData.competitionId === action.results.competitionId || scoreData.season === action.results.season),
        ...action.results,
        inProgress: false,
        isSuccess: false
      };

      return [
        ...state.filter(scoreData => scoreData.competitionId !== action.results.competitionId || scoreData.season !== action.results.season),
        currentScore
      ];

    default:
      return state;
  }
}
