import get from 'lodash/get';
import {
  ERRORS,
  GET_OS_COMPETITION,
  GET_USER_COMPETITION_ELIGIBILITY,
  GET_USER_COMPETITION_TEMPLATE,
  SUBMIT_OS_COMPETITION_ENTRY,
} from 'actions/userCompetition';
import { fail, start, success } from 'middleware/fetch';

export default function userCompetitions(
  state = {
    error: false,
    inProgress: false,
    isSuccess: false,
    isFormSubmitSuccess: null,
  },
  action,
) {
  const { type, payload } = action;
  let newState = {};

  switch (type) {
    case start(GET_OS_COMPETITION): {
      newState = Object.assign(newState, state, payload);
      return newState;
    }
    case success(GET_OS_COMPETITION): {
      newState = Object.assign(newState, state, {
        ...payload,
        isSuccess: true,
      });
      return newState;
    }
    case fail(GET_OS_COMPETITION): {
      const { error = {} } = payload;
      newState = Object.assign(newState, state, {
        isSuccess: false,
        error: {
          ...error,
          code: ERRORS.COMPETITION_ERROR,
        },
      });
      return newState;
    }
    case start(GET_USER_COMPETITION_ELIGIBILITY): {
      newState = Object.assign(newState, state, payload);
      return newState;
    }
    case success(GET_USER_COMPETITION_ELIGIBILITY): {
      newState = Object.assign(newState, state, payload);
      return newState;
    }
    case fail(GET_USER_COMPETITION_ELIGIBILITY): {
      const { error = {} } = payload;
      let errorCode = get(error, 'code', '');
      errorCode = Object.keys(ERRORS).includes(errorCode)
        ? errorCode
        : ERRORS.SERVER_ERROR;

      newState = Object.assign(newState, state, {
        error: {
          ...error,
          code: errorCode,
        },
      });
      return newState;
    }
    case start(GET_USER_COMPETITION_TEMPLATE): {
      newState = Object.assign(newState, state, { inProgress: true });
      return newState;
    }
    case success(GET_USER_COMPETITION_TEMPLATE): {
      newState = Object.assign(newState, state, {
        template: action.template,
        isSuccess: true,
      });
      return newState;
    }
    case fail(GET_USER_COMPETITION_TEMPLATE): {
      const { error = {} } = payload;
      newState = Object.assign(newState, state, {
        isSuccess: false,
        error: {
          ...error,
          code: ERRORS.COMPETITION_ERROR,
        },
      });
      return newState;
    }
    case start(SUBMIT_OS_COMPETITION_ENTRY): {
      newState = Object.assign(newState, state, {
        isFormSubmitSuccess: null,
        inProgress: true,
      });
      return newState;
    }
    case success(SUBMIT_OS_COMPETITION_ENTRY): {
      newState = Object.assign(newState, state, {
        isFormSubmitSuccess: true,
        inProgress: false,
      });
      return newState;
    }
    case fail(SUBMIT_OS_COMPETITION_ENTRY): {
      const { error = {} } = payload;
      let errorCode = get(error, 'code', '');
      errorCode = Object.keys(ERRORS).includes(errorCode)
        ? errorCode
        : ERRORS.SERVER_ERROR;

      newState = Object.assign(newState, state, {
        error: {
          ...error,
          code: errorCode,
        },
      });
      newState.inProgress = false;
      newState.isFormSubmitSuccess = false;
      return newState;
    }
    default:
      return state;
  }
}
