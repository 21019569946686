import ReactGA from 'react-ga4';
import defaultConfig from '../constants/defaultConfig';

export function initializeGoogleAnalytics() {
  const gaTrackingId = defaultConfig.gaTrackingId;
  ReactGA.initialize(gaTrackingId);
}

export function triggerGoogleAnalyticsEvent(category, action, label) {
  const ga = window.GoogleAnalyticsObject;
  if (ga !== 'ga') {
    initializeGoogleAnalytics();
  }
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
}
