import React, { Component } from "react";
import PropTypes from "prop-types";

class TableHeader extends Component {
  static propTypes = {
    /** Classes to apply to the root element of this component. Classes added here will override any default classes or props that accept classes. For example width - use with caution. */
    className: PropTypes.string,
    /** Content for the table **/
    headers: PropTypes.array
  };

  static defaultProps = {
     headers: ['POS', 'P', 'W', 'D', 'L', 'GF', 'GA', 'GD', 'PTS', 'Form', 'Next']
  }


  renderTableHeaderCell(headers) {
    if (headers) {
      return headers.map((header, index) => {
        return (
          <div className={`${header === 'Next' ? 'hide-on-tiny-screens w-30 sm:w-auto' : 'w-30 sm:w-auto'} ${header !== 'POS' ? 'text-center' : 'text-left'} ${header === 'GF' || header === 'GA' ? 'hidden md:table-header-cell md:table-cell table-header-cell table-cell px-10 font-MarkProHeavy text-2xs lg:text-sm' : header === 'Form' ? 'hidden md:table-header-cell md:table-cell px-10 text-center font-MarkProHeavy text-2xs lg:text-sm md:w-150' : header === 'POS' ? 'table-header-cell table-cell px-10 font-MarkProHeavy text-2xs lg:text-sm' : 'table-header-cell table-cell px-0 sm:px-10 font-MarkProHeavy text-2xs lg:text-sm'} lg:px-14 pt-16 pb-7 md:pt-15 md:pb-8 leading-1.6 md:leading-loose`} key={index}>{header}</div>
        )
      })
    }
    return null;
  }

  render() {
    return (
      <header className="bg-widget-grey table-header text-white font-MarkProBold h-40 pt-16 pb-7">
        {this.renderTableHeaderCell(this.props.headers)}
      </header>
    )
  }
}

export default TableHeader;