import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import HelpSupportModal from './ui-kit/HelpSupportModal';
import Button from './ui-kit/Button';
import LinkButton from './ui-kit/LinkButton';
import { withFeatureFlags } from '../context/withFeatureFlags';
import { dispatchAnalytic } from 'ducks/analytic';

class HelpSupportUI extends Component {
  componentDidUpdate(prevProps) {
    const { showModal: prevShowModal } = prevProps;
    const { showModal: currentShowModal } = this.props;
    if (prevShowModal !== currentShowModal && currentShowModal) {
      // when the help support modal open
      dispatchAnalytic({ name: 'Support' });
    }
  }

  render() {
    const isLoggedIn = this.props.user.loggedIn;
    const linkCss = `font-MarkPro w-fit-content leading-1.29 md:leading-1.13 text-teal hover:text-teal-dark active:text-teal-light active:no-underline os-transition os-transitionproperty-all text-sm md:text-base`;
    const fontCss = `text-sm md:text-base text-white font-MarkPro leading-1.29 md:leading-normal`;

    const faqLinks = get(
      this.props.getFeatureFlags('HELP_SUPPORT_CONFIG'),
      'faq_links',
      [],
    );

    const SupportHubLink =
      'https://www.optus.com.au/for-you/support/entertainment/optus-sport-hub?utm_source=OSWeb-Help';

    return (
      <HelpSupportModal
        open={this.props.showModal}
        onClose={() => this.props.closeModal()}
        type='helpsupport'
        newHome={this.props.newHome}
      >
        <div className='flex flex-col pt-48 xl:pt-32'>
          <h1 className='font-MarkProHeavy text-2xl xl:text-4xl text-white leading-none'>
            Get Help
          </h1>
          <div className='h-48 mt-32 mt-16'>
            <span className={fontCss}>
              Need a hand? Visit the{' '}
              <a
                className={linkCss}
                href={SupportHubLink}
                target='_blank'
                rel='noopener noreferrer'
              >
                Optus Sport Support Hub
              </a>{' '}
              or check the list below for answers to some common questions and
              troubleshooting:
            </span>
          </div>
          <div>
            <div className='my-24 flex flex-col'>
              {faqLinks.map(
                ({ url, text, showWhenLoggedIn, showWhenLoggedOut }, index) => {
                  return (isLoggedIn && showWhenLoggedIn) ||
                    (!isLoggedIn && showWhenLoggedOut) ? (
                    <a
                      className={`${linkCss} pb-20`}
                      href={url}
                      target='_blank'
                      rel='noopener noreferrer'
                      key={`faq_link_${index}`}
                    >
                      {text}
                    </a>
                  ) : null;
                },
              )}
              <b>
                <a
                  className={linkCss}
                  href={SupportHubLink}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Visit Support Hub {`>`}
                </a>
              </b>
            </div>
          </div>

          <div className='mb-14 flex mt-16 mb-24 flex-col items-start justify-between'>
            <div
              className={`text-base md:text-md text-white font-MarkProHeavy leading-double md:leading-1.78`}
            >
              Can’t solve it? Chat with us 24/7:
            </div>
            <Button
              className={`w-full mt-8 sm:mb-0 sm:mr-30`}
              text='Chat with an expert'
              type='primary'
              handler={() => {
                this.props.closeModal();
                window.open(SupportHubLink, '_blank');
              }}
              width='w-full sm:w-279'
            />
          </div>

          <div className='flex flex-col items-start justify-between'>
            <div
              className={`text-base md:text-md text-white font-MarkProHeavy leading-double md:leading-1.78`}
            >
              Urgent streaming issues?{' '}
              {isLoggedIn
                ? 'Let us know and our team will investigate:'
                : 'Please log in or create an account to report an issue:'}
            </div>
            {isLoggedIn ? (
              <>
                <Button
                  type='secondary'
                  className={`w-full`}
                  buttonClassName={`min-w-128 w-full sm:w-279 my-16`}
                  text='Report a streaming issue'
                  textClassName={'font-MarkProHeavy'}
                  handler={() => {
                    this.props.closeModal();
                    this.props.history.push('/help');
                  }}
                />
                <LinkButton
                  text='View active issues'
                  className={`${linkCss} underline block`}
                  textClassName={'font-MarkPro'}
                  handler={() => {
                    this.props.closeModal();
                    // Trigger bell icon hover
                    this.props.showNotification();
                    return false;
                  }}
                />
              </>
            ) : (
              <Button
                className={`w-full my-16`}
                text='Log in or sign up'
                type='secondary'
                handler={() => {
                  this.props.closeModal();
                  this.props.history.push('/login');
                }}
                width='w-full sm:w-279'
              />
            )}
          </div>
        </div>
      </HelpSupportModal>
    );
  }
}

export default withRouter(withFeatureFlags(HelpSupportUI));
