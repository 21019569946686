import { analyticAction } from '../../../ducks/analytic';
import { reGenerateSearchSessionId } from './utils';

const searchSessionStarted = (dispatch) => {
  dispatch(
    analyticAction({
      eventName: 'Search Session Started',
      search_session_id: reGenerateSearchSessionId(),
    }),
  );
};

export default searchSessionStarted;
