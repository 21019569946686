const STEPPER_INIT = `stepper/INIT`;
const STEPPER_SET_SEQUENCE = `stepper/SET_SEQUENCE`;
const STEPPER_COMPLETE_CURRENT_STAGE = `stepper/COMPLETE_CURRENT_STAGE`;
const STEPPER_RESET_STAGE = `stepper/RESET_STAGE`;

export function completed() {
  return `complete`;
}

export function incomplete() {
  return `incomplete`;
}

export function current() {
  return `current`;
}

const initialState = {
  currentStage: undefined,
  stages: {},
  sequence: {},
  data: {},
  done: false,
};

export function isCurrentStage(stepper, stage) {
  const { currentStage } = stepper;
  return currentStage === stage;
}

export function isLastStage(stepper, stage) {
  const { currentStage } = stepper;
  const fromStage = stage || currentStage;
  return getNextStage(stepper, fromStage) === null;
}

export function getSequence(stepper) {
  const { sequence, currentSequence } = stepper;

  const [firstOfSequence] = Object.values(sequence);
  const voucherSequence = currentSequence
    ? sequence[currentSequence]
    : firstOfSequence;
  return (voucherSequence || []).filter(Boolean);
}

export function getStageLabel(stepper, stage) {
  const sequence = getSequence(stepper);
  const index = sequence.indexOf(stage) + 1; // Stage labels starts with 1
  return index.toString();
}

export function getStageStatus(stepper, stage) {
  const { stages } = stepper;
  return stages[stage];
}

export function getFirstStage(stepper) {
  const [firstStage] = getSequence(stepper);
  return firstStage;
}

export function getLastStage(stepper) {
  const sequence = getSequence(stepper);
  return sequence.slice(-1)[0];
}

export function isCurrentStageRemoved(stepper) {
  const { currentStage } = stepper;
  const sequence = getSequence(stepper);
  const currentIndex = sequence.indexOf(currentStage);
  return currentIndex === -1;
}

export function getNextStage(stepper) {
  const { currentStage } = stepper;
  const sequence = getSequence(stepper);
  const currentIndex = sequence.indexOf(currentStage);
  if (currentIndex === -1) {
    return null;
  }
  const nextIndex = currentIndex + 1;
  const hasNoNextStage = sequence[nextIndex] === undefined;
  if (hasNoNextStage) {
    return null;
  }
  return sequence[nextIndex];
}

export function getNextStagesFromStage(stepper, fromStage) {
  const sequence = getSequence(stepper);
  const fromStageIndex = sequence.indexOf(fromStage);
  const slice = sequence.slice(fromStageIndex + 1);
  if (slice && slice.length === 0) {
    return null;
  }
  return slice;
}

export function applyStatusToStages(stepper, stageKeys, status) {
  const { stages } = stepper;
  return stageKeys.reduce((allStages, stage) => {
    return {
      ...allStages,
      [stage]: status,
    };
  }, stages);
}

// This is just for the <FormSection /> component use
export function getFormSectionProps(stepper, stage) {
  const isCurrent = isCurrentStage(stepper, stage);
  const label = getStageLabel(stepper, stage);
  const status = getStageStatus(stepper, stage);
  return {
    open: isCurrent,
    disable: !isCurrent,
    step: label,
    status,
  };
}

function getCurrentSequence(stepper, newCurrentSequence) {
  const { currentSequence } = stepper;
  return {
    currentSequence: newCurrentSequence || currentSequence,
  };
}

export default function stepperReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case STEPPER_INIT: {
      const { stages, sequence, currentSequence } = payload;
      const firstStage = getFirstStage({
        sequence,
        currentSequence,
      });
      return {
        ...state,
        currentStage: firstStage,
        currentSequence,
        sequence,
        stages: {
          ...stages,
          [firstStage]: current(),
        },
        data: {},
      };
    }
    case STEPPER_SET_SEQUENCE: {
      const { currentSequence } = payload;
      return {
        ...state,
        currentSequence,
      };
    }
    case STEPPER_COMPLETE_CURRENT_STAGE: {
      const { currentStage } = state;
      const { currentSequence, data } = payload;
      const nextStage = getNextStage(state);
      const isDone = nextStage === null;
      const nextStageStatus = !isDone && {
        [nextStage]: current(),
      };
      const isRemovedAndLast =
        isCurrentStageRemoved(state) && nextStage === null;
      const stage = isRemovedAndLast ? getLastStage(state) : currentStage;
      return {
        ...state,
        error: null,
        done: isDone,
        currentStage: isDone ? stage : nextStage,
        ...getCurrentSequence(state, currentSequence),
        stages: {
          ...state.stages,
          ...nextStageStatus,
          [currentStage]: completed(),
        },
        data: {
          ...state.data,
          ...data,
        },
      };
    }
    case STEPPER_RESET_STAGE: {
      const { stage, currentSequence } = payload;
      const nextStages = getNextStagesFromStage(state, stage);
      const resetNextStages = applyStatusToStages(
        state,
        nextStages,
        incomplete(),
      );
      return {
        ...state,
        currentStage: stage,
        ...getCurrentSequence(state, currentSequence),
        error: null,
        done: false,
        stages: {
          ...state.stages,
          ...resetNextStages,
          [stage]: current(),
        },
      };
    }
    default:
      return state;
  }
}

export function setCurrentSequence({ currentSequence }) {
  return {
    type: STEPPER_SET_SEQUENCE,
    payload: {
      currentSequence,
    },
  };
}

export function formStart({ stages, sequence, currentSequence }) {
  return {
    type: STEPPER_INIT,
    payload: {
      currentSequence,
      stages,
      sequence,
    },
  };
}

export function completeCurrentStage({ currentSequence, data } = {}) {
  return (dispatch) =>
    dispatch({
      type: STEPPER_COMPLETE_CURRENT_STAGE,
      payload: {
        currentSequence,
        data,
      },
    });
}

export function resetStage({ stage, currentSequence }) {
  return (dispatch) =>
    dispatch({
      type: STEPPER_RESET_STAGE,
      payload: {
        stage,
        currentSequence,
      },
    });
}
