import React from 'react';
import PropTypes from 'prop-types';

const MDTabLink = ({label, active, link, subMenu, handler, className, callback, ...props}) => (
  <li
    className={`h-48 mr-4 cursor-pointer flex-none ${className ? ` ${className}` : '' }`}
    onClick={callback}
    >
    <span
      className={`${active ? 'border-teal' : 'border-transparent'} align-bottom inline-block os-transition no-underline
       hover:border-teal text-white border-b-4 hover:text-teal ${ subMenu ? 'font-MarkPro' : 'font-MarkProBold'} text-sm leading-1.14 text-center pb-12 pt-16 px-16`}
      {...props}
      id={label}>
      {label}
      </span>
  </li>
);

MDTabLink.propTypes = {
  /** Label for the link*/
  label: PropTypes.string.isRequired,
  /** Whether this link is active or not*/
  active: PropTypes.bool,
  /** Link attached.*/
  link: PropTypes.string,
  /** Whether this is a sub menu or not*/
  subMenu: PropTypes.bool,
  /** Handler function for this link. This function will be invoked with the click event received on the link item */
  handler: PropTypes.func,
  /** Classes to apply to the root element of this component. Classes added here will override any default classes or props that accept classes. For example width - use with caution. */
  className: PropTypes.string,
};

MDTabLink.defaultProps = {
  active: false,
  link: null,
  subMenu: false,
  handler: null,
  className: null,
  callback: () => {}
};

export default MDTabLink;

