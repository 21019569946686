import styled, { css } from 'styled-components';

const InnerContainer = styled.div``;

const SideContainer = styled.div`
  position: fixed;
  top: 50%;
  width: 100%;
  max-width: 400px;
  padding-top: 0px;
  padding-bottom: 24px;
  z-index: 999;
  background: white;
  &.left {
    left: -400px;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    transform: translate(0%, -50%);
  }
  &.right {
    right: -400px;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    transform: translate(0%, -50%);
  }
  &.bottom {
    right: 0px;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 0px;
    bottom: 0;
    top: unset;
    z-index: 998;
  }
  ${(props) =>
    props.isActive &&
    css`
      &.left {
        left: 0;
      }
      &.right {
        right: 0;
      }
      &.bottom {
        right: 0;
      }
    `}
`;

const SideGroup = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 24px;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: auto;
  max-height: 100%;
  transition: all 0.3s ease-in-out;
  height: ${(props) => props.height - 200 + 'px' || '100%'};
  max-height: 800px;
`;

const SideClose = styled.button`
  height: 32px;
  width: 32px;
  outline: none;
  background: white;
  border: none;
  margin: 0;
  &:focus {
    outline: none;
    border: none;
  }
`;

const Arrow = styled.div.attrs({ className: 'arrow right' })`
  ${(props) =>
    props.rotate &&
    css`
      transform: rotate(${props.rotate}deg);
    `}
  &.arrow {
    position: absolute;
    color: #333;
    width: 16px;
    height: 16px;
    top: 10px;
    padding: 0;
    left: 15px;
  }

  &.arrow:before,
  &.arrow:after {
    content: '';
    background: #333;
    border-radius: 4px;
    display: block;
    height: 4px;
    position: absolute;
    right: 0;
    width: 16px;
  }

  &.arrow.right:before {
    transform: rotate(45deg);
  }

  &.arrow.right:before {
    top: -4px;
  }

  &.arrow.right:after {
    bottom: 09px;
    transform: rotate(-45deg);
  }
`;

const SideControl = styled.div`
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 48px;
  background: white;
  padding: 16px;
  margin: 0;
  &::active,
  &::hover,
  &:focus {
    outline: none;
  }
  ${(props) =>
    props.position === 'right' &&
    css`
      left: -45px;
      top: 50%;
      transform: translate(0, -50%);
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    `}
  ${(props) =>
    props.position === 'bottom' &&
    css`
      left: -45px;
      top: 50%;
      transform: translate(0, -50%);
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    `}
  ${(props) =>
    props.position === 'left' &&
    css`
      right: -45px;
      top: 50%;
      transform: translate(0, -50%);
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    `}
`;

export {
  SideGroup,
  InnerContainer,
  SideContainer,
  Arrow,
  SideControl,
  SideClose,
};
