import React from "react";
import PropTypes from "prop-types";
import SectionTab from './SectionTab'
import UpcomingFixtureRow from './UpcomingFixtureRow';

const UpcomingFixtures = ({ fixtures, tabs }) => (
  <div className="w-full h-auto bg-widget-grey flex flex-col items-center justify-center px-16 pb-0 xl:pb-8">
    <span className="text-base text-white py-16 font-MarkProHeavy" >Upcoming Fixtures</span>
    {tabs && <div className=" w-full md:w-2/5 mb-16 relative h-40 max-w-472">
    {tabs}
    </div> }

    {fixtures}
  </div>
);

UpcomingFixtures.propTypes = {
  /** Details of upcoming matches */
  fixtures: PropTypes.arrayOf(UpcomingFixtureRow),

  /** Tab header to be shown */
  tabs: PropTypes.objectOf(SectionTab)

};
export default UpcomingFixtures;
