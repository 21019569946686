import colorLuminance from './colorLuminance';

const asRem = (fontSize) => `${parseFloat(fontSize) / 16}rem`;

const theme = {
  palette: {
    'os-navy': '#172659',
    'os-blue': '#172659', // repeated remove
    'os-ui-pink': '#ff126d',
    'os-teal': '#05cedd',
    'os-teal-light': '#028A94',
    'os-teal-dark': '#0F676E',
    'os-ui-green': '#00ff91',
    'os-light-grey': '#acafb2',
    'os-medium-grey': '#575859',
    'os-med-dark-grey': '#353535',
    'os-dark-grey': '#191919',
    'os-black': '#000000',
    'os-white': '#ffffff',
    transparent: 'transparent',
    white: '#FFFFFF',
    white10: 'rgba(255, 255, 255, 0.1)',
    'white-20': 'rgba(255, 255, 255, 0.2)',
    black: '#000000',
    black50: 'rgba(0, 0, 0, 0.5)',
    black60: 'rgba(0, 0, 0, 0.6)',
    black80: 'rgba(0, 0, 0, 0.8)',
    teal: '#05CEDD',
    navy: '#172659',
    purple: '#444292',
    berry: '#80276C',
    yellow: '#FDCC08',
    pink: '#FF126D',
    green: '#00FF91',
    'error-red': '#D93A1B',
    'live-red': '#FF0000',
    'lightest-grey': '#F1F4F6',
    'lighter-grey': '#D1D7DA',
    'light-grey': '#ACAFB2',
    'medium-grey': '#575859',
    'medium-dark-grey': '#353535',
    'widget-grey': '#1F1F1F',
    'dark-grey': '#191919',
    'darkest-grey': '#111111',
  },
  font: {
    primary: 'MarkPro, sans-serif',
    secondary: 'MarkPro-Bold, sans-serif',
    tertiary: 'MarkPro-Heavy, sans-serif',
  },
  textSize: {
    '3xs': '.5rem', // 8px
    '2xs': '.625rem', // 10px
    xs: '.75rem', // 12px
    sm: '.875rem', // 14px
    base: '1rem', // 16px
    md: '1.125rem', // 18px
    lg: '1.25rem', // 20px
    xl: '1.5rem', // 24px
    '2xl': '2rem', // 32px
    '3xl': '2.5rem', // 40px
    '4xl': '3rem', // 48px
    '5xl': '3.5rem', // 56px
    '6xl': '4rem', // 64px
    '7xl': '5rem', // 80px
    '8xl': '6rem', // 96px
    '10xl': '8rem', // 128px
    '100': '100%',
  },
  spacing: {
    'mt-4': 4, // re-map config styles to pixels (pixel to rem is handled on component)
  },
  shadows: {
    default: '0 2px 4px 0 rgba(0,0,0,0.10)',
    '2xs': '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    xs: ' 0 5px 6px 0 rgba(0, 0, 0, 0.5)',
    md: '0 4px 8px 0 rgba(0,0,0,0.12), 0 2px 4px 0 rgba(0,0,0,0.08)',
    lg: '0 15px 30px 0 rgba(0,0,0,0.11), 0 5px 15px 0 rgba(0,0,0,0.08)',
    xl: '0 26px 25px 0 rgba(0, 0, 0, 0.5)',
    'nav-drawer': '5px -5px 18px 0 rgba(0, 0, 0, 0.5)',
    inner: 'inset 0 2px 4px 0 rgba(0,0,0,0.06)',
    outline: '0 0 0 3px rgba(52,144,220,0.5)',
    none: 'none',
    'fixture-score': '0 9px 13px 0 rgba(0, 0, 0, 0.5)',
  },
};

export { asRem, colorLuminance };
export default theme;
