import get from 'lodash/get';
import axios from 'axios';
import * as types from '../constants/actionTypes';
import defaultConfig from 'constants/defaultConfig';

export function getSummary(seasonId, competitionId, teamId) {
  return (dispatch) => {
    const results = { seasonId, competitionId, teamId };
    const {
      vcmsBaseUrl,
      VCMSConfig: { teamSummaryEndpoint },
    } = defaultConfig;
    if (!competitionId || !teamId || !seasonId) {
      return;
    }
    const url = `${vcmsBaseUrl}${teamSummaryEndpoint}?season=${seasonId}&competition=${competitionId}&team=${teamId}`;
    const headers = {};

    dispatch({ type: types.GET_TEAMSUMMARY_INPROGRESS, results });

    axios
      .get(url, { headers })
      .then((res) => {
        const isSuccess = get(res, 'data.result[0].statuscode', 'N') === 'Y';

        if (isSuccess) {
          results.rows = get(res, 'data.APIinfo[0]', []); // results return HighlightsInfo as well
          dispatch({ type: types.GET_TEAMSUMMARY_SUCCESS, results });
        } else {
          dispatch({ type: types.GET_TEAMSUMMARY_FAILURE, results });
        }
      })
      .catch((error) => {
        dispatch({ type: types.GET_TEAMSUMMARY_FAILURE, results });
      });
  };
}
