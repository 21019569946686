import { isArray, isObject, find, keys } from 'lodash';

import { isMobile } from '../../../utils/userAgent';
import {
  ValidBannerTypes,
  ValidTargetUserSegments,
  ValidTargetPlatforms,
  InvalidFlag,
  ContentCardTypeName,
  actionComponents,
} from './constants';
import { dispatchAnalytic } from 'ducks/analytic';
import { getPageRailEventData } from 'analytic/util';
import defaultConfig from 'constants/defaultConfig';
import { replaceImageSize } from 'utils';
import { brazeState } from 'utils/braze';

export const log = (msg = '', obj = '') => {
  const enableDebug = brazeState.getData('enableDebug');
  if (enableDebug) {
    console.log(msg, obj);
  }
};

const { srcSetWidths, sizesAspectRatio } = defaultConfig?.image || {};

const AspectRatiosSupported = keys(sizesAspectRatio) || [];

export const getValidAspectRatio = (aspectRatio) => {
  return (AspectRatiosSupported.includes(aspectRatio) && aspectRatio) || '16:9';
};

function getSourceSetUrl(asset, aspectRatio) {
  const validAspectRatio = getValidAspectRatio(aspectRatio);
  const sourceSetUrl = asset[`imageUrl_${validAspectRatio}`] || '';
  return sourceSetUrl;
}

export function getAspectRatio(aspectRatio) {
  const validAspectRatio = getValidAspectRatio(aspectRatio);
  return validAspectRatio.split(':');
}

function getSrcSet(asset, aspectRatio) {
  const validAspectRatio = getValidAspectRatio(aspectRatio);
  const sourceSetUrl = getSourceSetUrl(asset, validAspectRatio);
  const [width, height] = getAspectRatio(validAspectRatio);
  return srcSetWidths.map((mw) => {
    const dimensions = `${mw}x${Math.round((mw * height) / width)}`;
    const parsedImageUrl = sourceSetUrl.replace(
      `\${width x height}`,
      dimensions,
    );
    return `${parsedImageUrl} ${mw}w`;
  });
}

export function getImageProps(asset, aspectRatio) {
  if (!asset) {
    return { imgSrc: '', srcSet: [] };
  }
  const validAspectRatio = getValidAspectRatio(aspectRatio);
  const imageSourceUrl = asset[`imageUrl_${validAspectRatio}`];

  const imgSrc =
    imageSourceUrl &&
    replaceImageSize(imageSourceUrl, sizesAspectRatio[validAspectRatio]?.px);

  const srcSet = getSrcSet(asset, validAspectRatio);

  return { imgSrc, srcSet };
}

export function isValidUrl(url) {
  try {
    new URL(url);
    return true;
  } catch (err) {
    return false;
  }
}

export function getDynamicAction(action, dynamicActionConfig) {
  if (!action) return false;

  const parseDynamicActionObj = (obj) => {
    const { regExp, path } = obj || {};

    const newRegExp = regExp && new RegExp(regExp);

    return path && newRegExp
      ? {
          regExp: newRegExp,
          path,
        }
      : false;
  };

  const foundAction = find(dynamicActionConfig, (actionObj) => {
    const parsedActionObj = parseDynamicActionObj(actionObj);

    const { regExp, path } = parsedActionObj || {};

    if (path && regExp) {
      return regExp.test(action);
    }

    return false;
  });

  if (foundAction) {
    const parsedActionObj = parseDynamicActionObj(foundAction);

    const { regExp, path } = parsedActionObj || {};

    if (path && regExp) {
      const matches = action.match(regExp);

      if (isArray(path) && matches && matches.length) {
        // Now construct the final remapped path
        // Note: (index) is reserved for picking out from regexp matches i.e. (1) --> matches[1]
        const matchesRegExpPattern = new RegExp(/^\(\d+\)$/);
        const pathRemapped = path.map((val) => {
          // Check if it needs to be replaced with regexp matches
          if (val && matchesRegExpPattern.test(val)) {
            const newVal = val.replace('(', '').replace(')', '');
            return matches[newVal];
          }

          return val;
        });

        const parsedPathRemapped = pathRemapped.filter((pathPart) => pathPart);

        return parsedPathRemapped.length && parsedPathRemapped.join('/');
      }
    }
  }

  return false;
}

export function isBannerDataValid(contentCard) {
  const { bannerType, actions } = contentCard;
  const selectedBannerType = ValidBannerTypes[bannerType];

  if (!selectedBannerType) return false;
  const checkValues = Object.keys(selectedBannerType).map((key) => {
    const property = selectedBannerType[key];

    const value = contentCard[key];
    const action = actions && actions[key];
    if (property.type === 'string') {
      if (!value || value === '') return InvalidFlag;
    } else {
      if (property.optional && !value) return true; // if the property is optional and value is not defined in CMS
      if (!action) return InvalidFlag;
    }
    return true;
  });
  const foundInvalidFlag = checkValues.find((val) => val === InvalidFlag);
  return !foundInvalidFlag;
}

export function isDataValid(validData, data) {
  if (!data) return false;

  const dataArray = isArray(data)
    ? data
    : data?.split(' ').filter((item) => item !== '');

  const checkValues = dataArray.map((dataKey) => {
    if (!validData.includes(dataKey)) return InvalidFlag;
    return true;
  });

  return !checkValues.find((val) => val === InvalidFlag);
}

// to check if the key is part of ValidBannerTypes to render
export function checkRenderingElement(contentCard, key) {
  const { bannerType } = contentCard;
  const selectedBannerType = ValidBannerTypes[bannerType];
  if (!selectedBannerType) return false;
  return Object.keys(selectedBannerType).includes(key);
}

export function isNullish(val) {
  return !val || (isArray(val) && val.length === 0);
}

export function isTargetPlatformValid(targetPlatform) {
  return (
    isNullish(targetPlatform) ||
    isDataValid(ValidTargetPlatforms, targetPlatform)
  );
}

export function isTargetUserSegmentsValid(targetUserSegments) {
  return (
    isNullish(targetUserSegments) ||
    isDataValid(ValidTargetUserSegments, targetUserSegments)
  );
}

export function isContentCardDataValid(contentCard) {
  return (
    contentCard &&
    isBannerDataValid(contentCard) &&
    isTargetPlatformValid(contentCard?.targetPlatform) &&
    isTargetUserSegmentsValid(contentCard?.targetUserSegment)
  );
}

export function isTargetedPlatform(targetPlatform) {
  if (isNullish(targetPlatform)) return true;
  if (!isTargetPlatformValid(targetPlatform)) return false;

  const targetPlatformArray = isArray(targetPlatform)
    ? targetPlatform
    : targetPlatform?.split(' ').filter((item) => item !== '');

  if (
    targetPlatformArray.includes('WEB') ||
    (isMobile() && targetPlatformArray.includes('MOBILE_WEB')) ||
    (!isMobile() && targetPlatformArray.includes('DESKTOP_WEB'))
  )
    return true;
  return false;
}

export function isTargetedUserSegment(targetUserSegment, userTags) {
  if (!userTags) return false;

  if (isNullish(targetUserSegment)) return true;

  if (!isTargetUserSegmentsValid(targetUserSegment) || !userTags) return false;

  const targetUserSegmentArray = isArray(targetUserSegment)
    ? targetUserSegment
    : targetUserSegment?.split(' ').filter((item) => item !== '');

  const checkResultArray = targetUserSegmentArray.map((segment) => {
    return !!userTags.find((tag) => tag === segment);
  });

  const result = checkResultArray.find((result) => !!result); // pass if any of result is true
  return result;
}

export function isTargeted(targetUserSegment, targetPlatform, userTags) {
  return (
    isTargetedUserSegment(targetUserSegment, userTags) &&
    isTargetedPlatform(targetPlatform)
  );
}

export function isValidContentCardByBE(asset, contentCardsInObj) {
  return isObject(contentCardsInObj) && contentCardsInObj[asset?.id];
}

/**
 *
 * @param {*} asset
 * @param {*} userTags
 * @param {*} contentCardIds array of content card ids (that is returned from BE API) to match its id with asset.id | falsy if BE validation is not feasible
 * @returns
 */
export function isValidContentCard(asset, userTags, contentCardsInObj) {
  const { contentCard, imageUrl } = contentCardsInObj[asset?.id] || {};

  if (contentCard && imageUrl) {
    // invalid if there is no default image
    const { targetUserSegment, targetPlatform } = contentCard;

    if (contentCardsInObj) {
      return (
        isBannerDataValid(contentCard) && // validate value of type and action
        isValidContentCardByBE(asset, contentCardsInObj)
      );
    }
    if (
      isContentCardDataValid(contentCard) && // validate value of type, action and target(segment, platform)
      isTargeted(targetUserSegment, targetPlatform, userTags)
    ) {
      return true;
    }
  }
  return false;
}

export function assetFilter(assets, userTags, contentCardsInObj) {
  return isArray(assets)
    ? assets.filter(
        (asset) =>
          asset?.assetTypeName !== ContentCardTypeName ||
          isValidContentCard(asset, userTags, contentCardsInObj),
      )
    : [];
}

export function getActionHandlers({
  dispatch,
  pages,
  editorial,
  asset,
  navigations,
  type,
}) {
  const getEventData = () =>
    getPageRailEventData({ pages, editorial, asset, navigations, type });
  const { cardClickAction, primaryButtonAction, secondaryButtonAction } =
    asset?.contentCard?.actions || {};
  return {
    handleCardClick: (history) => {
      dispatch({ type: 'cardClicked' });
      clickEventDispatcher({
        clickElement: 'card',
        asset,
        eventData: getEventData(),
      });
      cardClickAction?.handler && cardClickAction.handler(history);
    },
    handlePrimaryButtonClickedClick: (history) => {
      dispatch({ type: 'primaryButtonClicked' });
      clickEventDispatcher({
        clickElement: 'primary',
        asset,
        eventData: getEventData(),
      });
      primaryButtonAction?.handler && primaryButtonAction.handler(history);
    },
    handleSecondaryButtonClickedClick: (history) => {
      dispatch({ type: 'secondaryButtonClicked' });
      clickEventDispatcher({
        clickElement: 'secondary',
        asset,
        eventData: getEventData(),
      });
      secondaryButtonAction?.handler && secondaryButtonAction.handler(history);
    },
  };
}

export function getCarouselCustomWidgetKeys(customWidgetConfig) {
  if (!isObject(customWidgetConfig)) return [];
  const customWidgetConfigKeys = Object.keys(customWidgetConfig);
  const carouselCustomWidgetKeys = customWidgetConfigKeys.filter(
    (key) => customWidgetConfig[key]?.isCarousel,
  );
  return carouselCustomWidgetKeys;
}

export function isCarouselTypeCustomWidget(customWidgetConfig, carouselType) {
  if (!carouselType) return false;
  const carouselCustomWidgetKeys = getCarouselCustomWidgetKeys(
    customWidgetConfig,
  );
  return carouselCustomWidgetKeys.find((key) => key === carouselType);
}

const clickEventDispatcher = ({ clickElement, asset, eventData }) => {
  const {
    id: assetId,
    title,
    description,
    contentCard: {
      id: contentCardId,
      bannerType,
      cardClickAction,
      primaryButtonLabel,
      primaryButtonAction,
      secondaryButtonLabel,
      secondaryButtonAction,
      noTextOverlay,
      badgeLabel,
      targetUserSegment,
      targetPlatform,
      personalisedOffersOptInsOnly,
      personalisedAdsOptInsOnly,
      previewUsersOnly,
    } = {},
  } = asset;

  const clicked = {
    type: '',
    label: '',
    action: '',
  };

  switch (clickElement) {
    case 'card':
      clicked.type = 'CARD';
      clicked.action = cardClickAction;
      break;
    case 'primary':
      clicked.type = 'PRIMARY_BUTTON';
      clicked.label = primaryButtonLabel;
      clicked.action = primaryButtonAction;
      break;
    case 'secondary':
      clicked.type = 'SECONDARY_BUTTON';
      clicked.label = secondaryButtonLabel;
      clicked.action = secondaryButtonAction;
      break;
    default:
  }

  dispatchAnalytic({
    name: 'Content Card Clicked',
    option: {
      contentCard: {
        assetId,
        contentCardId,
        title,
        description,
        bannerType,
        cardClickAction,
        primaryButtonLabel,
        primaryButtonAction,
        secondaryButtonLabel,
        secondaryButtonAction,
        noTextOverlay,
        badgeLabel,
        targetUserSegment,
        targetPlatform,
        personalisedOffersOptInsOnly,
        personalisedAdsOptInsOnly,
        previewUsersOnly,
      },
      clicked,
      ...eventData,
    },
  });
};

function getAction(action, configAction) {
  const getActionInternal = () => {
    // Internal type action
    const internalActionFound = configAction.internal.find(
      (internalActionItem) => {
        const internalActionItemKey = Object.keys(internalActionItem)[0];
        return internalActionItemKey === action;
      },
    );
    return internalActionFound && Object.values(internalActionFound)[0];
  };

  const getAssetTypeAction = () => {
    // Asset type action
    const foundAction = configAction.asset.find((key) => action.includes(key));
    return foundAction;
  };

  const getIsActionComponent = () => {
    // Component type action
    const foundAction = configAction.component.find((key) => key === action);
    return foundAction && actionComponents[foundAction];
  };

  if (isValidUrl(action)) {
    // check if URL format
    return {
      type: 'external',
      handler: () => {
        const newWindow = window.open(action, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
      },
      href: action,
    };
  }

  const actionInternal = getActionInternal();
  if (actionInternal) {
    // check if string format
    return {
      type: 'internal',
      handler: (history) => {
        history && history.push(actionInternal);
      },
      href: actionInternal,
    };
  }

  const dynamicActionConfig = configAction['internal-dynamic'];
  const actionDynamicInternal = getDynamicAction(action, dynamicActionConfig);

  if (actionDynamicInternal) {
    // check if string format with dynamic parameters
    return {
      type: 'internal-dynamic',
      handler: (history) => {
        history && history.push(actionDynamicInternal);
      },
      href: actionDynamicInternal,
    };
  }

  const isActionAsset = getAssetTypeAction();
  if (isActionAsset) {
    // check if internal link with asset(/video/,/news/) format
    return {
      type: 'asset',
      handler: (history) => {
        history && history.push(action);
      },
      href: action,
    };
  }

  const isActionComponent = getIsActionComponent();
  if (isActionComponent) {
    return {
      type: 'component',
      handler: () => {},
      component: isActionComponent,
    };
  }

  return false;
}

export function getAssetActions({ configAction, contentCard }) {
  if (!configAction || !contentCard) return false;
  const {
    cardClickAction,
    primaryButtonAction,
    secondaryButtonAction,
  } = contentCard;

  const contentCardActions = [
    cardClickAction ? 'cardClickAction' : null,
    primaryButtonAction ? 'primaryButtonAction' : null,
    secondaryButtonAction ? 'secondaryButtonAction' : null,
  ].filter(Boolean);

  const actions = Object.fromEntries(
    contentCardActions.map((action) => {
      const contentCardAction = contentCard[action];
      const actionObject = getAction(contentCardAction, configAction);
      return [action, actionObject];
    }),
  );

  return actions;
}
