import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { selectors, useSelector } from '../../../store/createPortalStore';
import { assetMapping } from '../../../middleware/assetMapping';
import { getItemFilter } from './utils';

import { useSearchContext } from '../SearchProvider';
import { handleRequest } from '../SearchService';
import Item from './Item';
import Filters from './Filters';
import LoadingIcon from '../../ui/ui-kit/Icons/LoadingIcon';
import LazyLoad from '../../ui/LazyLoad';
import {
  analyticSearchRequested,
  analyticSearchSessionStarted,
} from '../__analytics__';
import { chunkArray } from '../../../utils';


const ResultsLoading = ({ className = '' }) => (
  <div
    className={`w-full mx-auto text-dark-grey text-md text-center mb-16 font-MarkProBold px-16 pt-24 pb-8 ${className}`}
  >
    <LoadingIcon />
  </div>
);

export default function SearchResults({ isInSearchPage = false }) {
  const dispatch = useDispatch();

  const {
    state: {
      config,
      suggestions: { data: suggestionsData = [] } = {},
      loading,
      search: { data: searchData = [] },
      searchText: text,
    } = {},
    dispatch: searchDispatch,
  } = useSearchContext();

  const [state, setState] = useState({
    searchResult: searchData,
    filters: [],
    selectedFilters: [],
  });

  const isFetched = useRef();
  useEffect(() => {
    if (loading) isFetched.current = true;
  }, [loading]);

  const isNoResult =
    !state.searchResult.length &&
    (isInSearchPage || !suggestionsData.length) &&
    !loading;

  useEffect(() => {
    if (isInSearchPage && text && !searchData.length && !isFetched.current) {
      analyticSearchSessionStarted(dispatch);
      analyticSearchRequested(dispatch, { query: text });

      handleRequest({
        value: text,
        config,
        dispatch,
        searchDispatch,
      });
    }
  }, [searchData, config, searchDispatch, dispatch, isInSearchPage, text]);

  const setFilteredResult = useCallback((data) => {
    setState((prevState) => ({
      ...prevState,
      searchResult: data,
    }));
  }, []);
  const setFilters = useCallback((filters) => {
    setState((prevState) => ({
      ...prevState,
      filters,
    }));
  }, []);
  const setSelectedFilters = useCallback((selectedFilters) => {
    setState((prevState) => ({
      ...prevState,
      selectedFilters,
    }));
  }, []);

  const userStrictType = useSelector(selectors.user.userStrictType);
  const isLoggedIn = useSelector(selectors.user.isLoggedIn);

  const { searchResult = [] } = state;
  return (
    <div className={'pt-24 pb-8 flex flex-col'}>
      <Filters
        filters={state.filters}
        setSelectedFilters={setSelectedFilters}
        setFilters={setFilters}
        setFilteredResult={setFilteredResult}
        className={'mb-16'}
      />
      {!isFetched.current ? null : isNoResult ? (
        <p
          className={
            'mx-auto p-24 md:p-40 text-dark-grey text-center text-lg mb-16 font-MarkProBold'
          }
        >
          No Results Found
        </p>
      ) : (
        <>
          <p className={'mt-12 mb-16 text-xl leading-base font-MarkProHeavy'}>
            Search results
          </p>

          {loading && (
            <ResultsLoading className={isInSearchPage && 'p-24 md:p-40'} />
          )}

          {chunkArray(searchResult, 10).map((results, index) => {
            return (
              <LazyLoad key={index}>
                {results.map((item, i) => {
                  const assetItem = assetMapping(item);
                  return (
                    <Item
                      key={assetItem.id}
                      {...assetItem}
                      assetFilter={getItemFilter(item)}
                      isArticle={assetItem.assetTypeName === 'article'}
                      isPremiumUser={userStrictType === 'premium'}
                      assetPosition={i}
                      filters={state.filters}
                      selectedFilters={state.selectedFilters}
                      isLoggedIn={isLoggedIn}
                    />
                  );
                })}
              </LazyLoad>
            );
          })}
        </>
      )}
    </div>
  );
}
