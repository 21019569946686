import React from 'react';
import isFunction from 'lodash/isFunction';
import withSystemConfig from './withSystemConfig';
import { Redirect } from 'react-router-dom';
import getFlags from '../../ducks/features';
import defaultConfig from '../../constants/defaultConfig';

import {
  getVendorLocation,
  getVendorDetail,
} from '../../utils/vendor';

export const DefaultUnsupported = () => <Redirect to="/404" />;

/**
 * withFeatureFlags
 * Provides the Component with the convenience function `getFeatureFlags`.
 * To get the specific flag value, use this.props.getFeatureFlags('THE_FLAG').
 * To get all flags, use this.props.getFeatureFlags().
 *
 * This component can be anywhere in the render tree
 * under `SystemConfigContextProvider` as the root.

 * @param {*} Component
 */
export const withFeatureFlags = (Component) =>
  withSystemConfig((props) => {
    const { systemConfig } = props;
    // TODO: For local dev, you can mock out the feature flags here...
    // systemConfig.features.FEATURE_PAYMENT_PAGE = false;
    return (
      <Component {...props} getFeatureFlags={getFlags({ systemConfig })} />
    );
  });

export const withVendorLocation = (Component) => (props) => {
  const location = props.ctv;
  const getMatchedVendorLocation = ({ vendor, localConfig }) => {
    const providedConfig = localConfig
      ? defaultConfig?.features?.['CTV_CONFIG']
      : props.getFeatureFlags('CTV_CONFIG');

    if (!vendor || vendor === 'undefined') {
      // Custom CTV_CONFIG
      return getVendorLocation({ config: providedConfig, location });
    }

    return getVendorLocation({
      config: providedConfig,
      location: vendor,
    });
  };

  const getMatchedVendorDetail = ({ vendor, localConfig }) => {
    const providedConfig = localConfig
      ? defaultConfig?.features?.['CTV_CONFIG']
      : props.getFeatureFlags('CTV_CONFIG');

    if (!vendor || vendor === 'undefined') {
      // Custom CTV_CONFIG
      return getVendorDetail({ config: providedConfig, location });
    }

    return getVendorDetail({
      config: providedConfig,
      location: vendor,
    });
  };

  return (
    <Component
      getVendorLocation={getMatchedVendorLocation}
      getVendorDetail={getMatchedVendorDetail}
      {...props}
    />
  );
};

/**
 * CheckFeatureFlag
 * Checks for feature flags and renders accordingly.
 * For missing feature flags, this function falls back to
 * `defaultConfig.features` for default values.
 *
 * Example:
 * ```
 * export default compose(
 *   withFeatureFlagCheck(featureFlagsProps),
 *   connect(mapStateToProps, mapDispatchToProps),
 * )(PaymentPage);
 * ```
 *
 * Refer to CheckFeatureFlag for more details.
 *
 * @typedef {object} CheckFeatureFlag
 * @property {string} featureFlag The key to check. Used for simple true/false flag check.
 * @property {function} isSupported The function to determine if the feature is supported.
 * @property {React.Component} render The component to render before rendering children.
 * @property {React.Component} renderUnsupported The component to render if feature is unsupported.
 * @property {boolean} forceFlag The true/false flag to force feature support/
 * @property {object} getFeatureFlags Provided by `withFeatureFlags`.
 *
 */
export const CheckFeatureFlag = withFeatureFlags((props) => {
  const {
    getFeatureFlags,
    featureFlag,
    isSupported,
    render,
    renderUnsupported,
    forceFlag,
  } = props;
  const flag =
    forceFlag === undefined ? getFeatureFlags(featureFlag) : forceFlag;
  const isFeatureSupported = isFunction(isSupported)
    ? isSupported(getFeatureFlags)
    : flag;
  const renderUnsupportedComponent = renderUnsupported || DefaultUnsupported;
  if (!isFeatureSupported) {
    return renderUnsupportedComponent();
  }
  const renderComponent = isFunction(render) ? render(props) : null;
  return (
    <>
      {renderComponent}
      {props.children}
    </>
  );
});

/**
 * withFeatureFlagCheck
 * Checks for feature flags and renders accordingly.
 * Use with other composed functions such as Redux connect.
 *
 * Examples:
 *
 * Usage with `compose`
 * ```
 * export default compose(
 *   withFeatureFlagCheck(featureFlagsProps),
 *   connect(mapStateToProps, mapDispatchToProps),
 * )(PaymentPage);
 * ```
 *
 * Simple use case to check a single true/false flag
 * ```
 * withFeatureFlagCheck({ featureFlag: "THE_FLAG" })
 * ```
 *
 * Complex use case to check multiple flags and custom render if unsupported.
 * Display banner message if feature is supported.
 * ```
 * withFeatureFlagCheck({
 *   isSupported: (getFeatureFlags) => {
 *     return getFeatureFlags(`THE_FLAG`);
 *   },
 *   renderUnsupported: <Redirect to="/" />
 *   render: () => <Message open message="This is a new feature blah blah" />
 * )
 * ```
 *
 * Refer to CheckFeatureFlag for more details.
 *
 * @param {*} featureFlagsProps
 */
export const withFeatureFlagCheck = (featureFlagsProps) => (Component) => (
  props,
) => (
  <CheckFeatureFlag {...featureFlagsProps}>
    <Component {...props} />
  </CheckFeatureFlag>
);
