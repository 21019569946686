import _ from 'lodash';
import * as types from '../constants/actionTypes';
import API from '@aws-amplify/api';
import defaultConfig from '../constants/defaultConfig';
import { assetMapping, contentCardFilter } from '../middleware/assetMapping';
import { editorialTitleMapping } from '../middleware/editorialTitleMapping';
import { isLoggedIn, user } from '../selectors/user';
import { getUserId } from 'selectors/user';

const editorialsFlag = _.get(defaultConfig.apiFlags, 'editorials', false);
const searchFlag = _.get(defaultConfig.apiFlags, 'search', false);

function getCustomConfigUrl(panel, userId) {
  const panelConfig = panel?.config ? JSON.parse(panel.config) : null;
  if (Array.isArray(panelConfig) && panelConfig[0]) {
    let url = panelConfig[0].url;
    url = url.replace('{platform}', 'web');
    url = url.replace('{userId}', userId);
    return url;
  }
  return null;
}

function callExploreRail(id, panelConfig, dispatch) {
  const assets = [];
  panelConfig.forEach((obj) => {
    assets.push(obj);
  });

  dispatch({ type: types.GET_EDITORIAL_INPROGRESS, editorial: { id: id } });
  const parsedEditorial = {
    assets,
    displayName: '',
    id,
  };
  dispatch({
    type: types.GET_EDITORIAL_SUCCESS,
    editorial: parsedEditorial,
  });
}

function callEditorialAPI(
  apiKey,
  path,
  id,
  limit,
  getState,
  dispatch,
  customWidgetConfig,
  isPromotionalPlacementsEnable,
  panel,
) {
  if (!apiKey || !path) {
    return null;
  }

  dispatch({ type: types.GET_EDITORIAL_INPROGRESS, editorial: { id: id } });

  const queryStringParameters = {
    ...(limit && { limit }),
  };

  return API.get(apiKey, path, { queryStringParameters })
    .then((editorial) => {
      let parsedEditorial = editorial;
      let assetStructure = customWidgetConfig
        ? customWidgetConfig.assetStructure
        : 'assets';
      if (parsedEditorial && (parsedEditorial.id || customWidgetConfig)) {
        let assets = _.get(parsedEditorial, assetStructure, []);
        if (editorialsFlag && !_.isEmpty(assets)) {
          // If limit has been specified then we should truncate the list of assets
          if (limit) {
            assets = assets.slice(0, limit);
          }

          const currentState = getState();
          const navigations = _.get(currentState, 'navigation.navigations', []);
          assets = contentCardFilter(assets, isPromotionalPlacementsEnable);
          assets = assets.map((asset) => assetMapping(asset, navigations));

          parsedEditorial.assets = assets;
          const title = _.get(parsedEditorial, 'title', null);
          if (_.isEmpty(title)) {
            parsedEditorial.title = editorialTitleMapping(parsedEditorial);
          }
        }
        if (!parsedEditorial.id) {
          parsedEditorial.id = id;
        }
        if (panel) {
          parsedEditorial.panel = {
            type: panel.type,
            title: panel.title,
            assetId: panel.assetId,
          };
        }

        dispatch({
          type: types.GET_EDITORIAL_SUCCESS,
          editorial: { ...parsedEditorial },
        });
      } else {
        dispatch({
          type: types.GET_EDITORIAL_FAILURE,
          editorial: { id: id },
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: types.GET_EDITORIAL_FAILURE,
        editorial: { id: id },
      });
    });
}
export function userStatusHandler(id, panel, getFeatureFlags) {
  return function(dispatch, getState) {
    if (!isLoggedIn(getState())) {
      const CUSTOM_WIDGET = getFeatureFlags
        ? getFeatureFlags('CUSTOM_WIDGET')
        : defaultConfig.features?.CUSTOM_WIDGET;
      if (
        panel?.config &&
        CUSTOM_WIDGET &&
        CUSTOM_WIDGET.hasOwnProperty(panel.panelType)
      ) {
        const customWidgetConfig = CUSTOM_WIDGET[panel.panelType];
        if (customWidgetConfig.loginRequired) {
          dispatch({ type: types.GET_EDITORIAL_PURGE, editorial: { id: id } });
        }
      }
    }
  };
}

export function loadFavouriteEditorial(id, limit, optusTeamCode, panel) {
  return function(dispatch, getState) {
    if (_.isEmpty(id) || _.isEmpty(optusTeamCode)) return false;

    dispatch({ type: types.GET_EDITORIAL_INPROGRESS, editorial: { id } });

    const currentState = getState();
    const navigations = _.get(currentState, 'navigation.navigations', []);
    const params = {
      queryStringParameters: {
        optusTeamCode,
        limit,
      },
    };

    const path = `/search/v2/web/assets`;
    return API.get('fe-api-dev-metadataGetAsset', path, params)
      .then((data) => {
        const assets = data?.asset;
        if (_.isArray(assets)) {
          const parsedAssets = assets.map((asset) =>
            assetMapping(asset, navigations),
          );
          const { type: panelType, assetId, title: panelTitle } = panel || {};

          dispatch({
            type: types.GET_EDITORIAL_SUCCESS,
            editorial: {
              assets: parsedAssets,
              id, // set the id manually as the API will not return it.
              title: 'Favourite Team', // need to set default title even it is not used in UI actually
              panel: {
                assetId,
                title: panelTitle,
                type: panelType,
              },
            },
          });
        } else {
          dispatch({
            type: types.GET_EDITORIAL_FAILURE,
            editorial: { id },
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: types.GET_EDITORIAL_FAILURE,
          editorial: { id },
        });
        throw error;
      });
  };
}

export function loadEditorial(id, limit, panel, getFeatureFlags) {
  return function(dispatch, getState) {
    let path = editorialsFlag
      ? `/editorials/v2/${id}/web`
      : `/editorials/${id}`;

    const CUSTOM_WIDGET = getFeatureFlags
      ? getFeatureFlags('CUSTOM_WIDGET')
      : defaultConfig.features?.CUSTOM_WIDGET;

    const promotionalPlacementsConfig = getFeatureFlags
      ? getFeatureFlags('PROMOTIONAL_PLACEMENTS_CONFIG')
      : defaultConfig.features?.PROMOTIONAL_PLACEMENTS_CONFIG;

    const isPromotionalPlacementsEnable = promotionalPlacementsConfig?.enabled;

    if (
      panel?.config &&
      CUSTOM_WIDGET &&
      CUSTOM_WIDGET.hasOwnProperty(panel.panelType)
    ) {
      switch (panel?.panelType) {
        case 'explore':
          if (getFeatureFlags('FEATURE_EXPLORE_RAIL')) {
            const panelConfig = panel?.config ? JSON.parse(panel.config) : null;
            return callExploreRail(id, panelConfig, dispatch);
          }
          break;
        default:
          const loggedInUser = user(getState());

          const userId = getUserId(loggedInUser);
          const customWidgetConfig = CUSTOM_WIDGET[panel.panelType];

          if (
            !customWidgetConfig.loginRequired ||
            (customWidgetConfig.loginRequired && isLoggedIn(getState()))
          ) {
            path = getCustomConfigUrl(panel, userId);
            return callEditorialAPI(
              'fe-api-dev-customGetEditorial',
              path,
              id,
              limit,
              getState,
              dispatch,
              customWidgetConfig,
              isPromotionalPlacementsEnable,
              panel,
            );
          }
      }
    } else {
      return callEditorialAPI(
        'fe-api-dev-metadataGetEditorial',
        path,
        id,
        limit,
        getState,
        dispatch,
        '',
        isPromotionalPlacementsEnable,
        panel,
      );
    }
  };
}

export function loadSearchSimilarClasses(
  id,
  tagType,
  tagName,
  assetType,
  limit,
  currentAssetId,
) {
  return function(dispatch, getState) {
    return loadSearchAsset(
      id,
      tagType,
      tagName,
      assetType,
      limit,
      dispatch,
      getState,
      currentAssetId,
    );
  };
}

export async function getAssetByType(typeParam, value, getState) {
  const currentState = getState();
  const path = searchFlag ? `/search/v2/web/assets` : `/assets`;
  const navigations = _.get(currentState, 'navigation.navigations', []);
  let params = {
    queryStringParameters: {
      [typeParam]: value,
    },
  };

  const assets = await API.get('fe-api-dev-metadataGetAsset', path, params);
  let parsedAssets = _.get(assets, 'asset', []);
  if (!_.isEmpty(parsedAssets)) {
    parsedAssets = parsedAssets.map((asset) =>
      assetMapping(asset, navigations),
    );
  }

  return parsedAssets;
}

export function loadArticleReadMoreRails(
  id,
  tagType,
  tagName,
  limit,
  articleId,
  type,
) {
  return async function(dispatch, getState) {
    dispatch({ type: types.GET_EDITORIAL_INPROGRESS, editorial: { id: id } });
    const asset = await getAssetByType('relatedAssetId', articleId, getState);
    let editorial = {
      id,
      title: editorialTitleMapping({ id }),
    };
    if (asset && asset.length > 0) {
      editorial.assets = asset.filter(
        (obj) => obj.assetTypeName?.toLowerCase() === type?.toLowerCase(),
      );
      if (limit) {
        editorial.assets = editorial.assets.slice(0, limit);
      }
      dispatch({ type: types.GET_EDITORIAL_SUCCESS, editorial });
    } else {
      loadSearchAsset(
        id,
        tagType,
        tagName,
        type,
        limit,
        dispatch,
        getState,
        articleId,
      );
    }
  };
}

export function loadSearchAsset(
  id,
  tagType,
  tagName,
  assetType,
  limit,
  dispatch,
  getState,
  currentAssetId,
) {
  const currentState = getState();

  const prefix = isLoggedIn(currentState) ? '' : '/public';
  let path = `${prefix}/assets/web?tagType=${tagType}&tagName=${tagName}`;
  if (assetType) {
    path += `&assetType=${assetType?.toUpperCase()}`;
  }

  dispatch({ type: types.GET_EDITORIAL_INPROGRESS, editorial: { id: id } });

  return API.get('fe-api-search', path, {})
    .then((editorial) => {
      let parsedEditorial = editorial;
      if (parsedEditorial) {
        let assets = _.get(parsedEditorial, 'result', []);

        if (assets && currentAssetId) {
          assets = assets.filter((obj) => obj.id !== currentAssetId);
        }

        if (editorialsFlag && !_.isEmpty(assets)) {
          // If limit has been specified then we should truncate the list of assets
          if (limit) {
            assets = assets.slice(0, limit);
          }

          const navigations = _.get(currentState, 'navigation.navigations', []);

          assets = assets.map((asset) => assetMapping(asset, navigations));
          parsedEditorial.assets = assets;
        }
        parsedEditorial.id = id;
        const title = _.get(parsedEditorial, 'title', null);
        if (_.isEmpty(title)) {
          parsedEditorial.title = editorialTitleMapping(parsedEditorial);
        }
        dispatch({
          type: types.GET_EDITORIAL_SUCCESS,
          editorial: { ...parsedEditorial },
        });
      } else {
        dispatch({
          type: types.GET_EDITORIAL_FAILURE,
          editorial: { id: id },
        });
      }
    })
    .catch((error) => {
      dispatch({ type: types.GET_EDITORIAL_FAILURE, editorial: { id: id } });
    });
}
