import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './styles';

const getMappedTitle = (title) => {
  switch (title && title.toLowerCase()) {
    case 'replays':
      return 'On Demand';
    default:
      return title;
  }
};
const Title = ({ title, theme = 'dark' }) => (
  <header
    className={`
                text-left
                font-MarkProHeavy
                leading-double
                xl:leading-none
                text-xl
                xl:text-2xl
                pt-24
                pb-8
                md:py-24
                lg:py-32
                ${styles[theme]?.title}
                `}
  >
    {title}
  </header>
);

const ViewAllLink = ({ to, theme = 'dark' }) => {
  return (
    <Link
      to={to}
      className={`
      font-MarkPro
      text-teal
      text-sm
      hover:text-teal-dark
      active:text-teal-light
      os-transition-btn
      ${styles[theme]?.viewAll}
    `}
    >
      View All
    </Link>
  );
};

const Header = ({
  categoryPath,
  title,
  viewAllUrl,
  showViewAllLink = true,
  theme = 'dark',
}) => (
  <div
    className='
    flex
    justify-between
    items-center
    w-full
  '
  >
    <Title title={getMappedTitle(title)} theme={theme} />
    {showViewAllLink && (
      <ViewAllLink to={viewAllUrl || categoryPath} theme={theme} />
    )}
  </div>
);

Header.propTypes = {
  categoryPath: PropTypes.string.isRequired,
};

export default Header;
