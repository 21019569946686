import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getPageTitleFromRootPath } from '../../utils/getPageTitle';
import { setOGTitle } from 'utils/openGraph';

class UpdateDocumentTitle extends Component {
  updateDocumentTitle(title) {
    document.title = title;
  }

  handleTitleUpdate() {
    let title = this.props.documentTitle;
    const useOwnMetadata = this.props.useOwnMetadata;
    const path = window.location.pathname;

    const pathParts = path.split('/');
    pathParts && pathParts.shift();
    const isNewsArticlePath = pathParts.length === 4 && pathParts[0] === 'news';

    if (!isNewsArticlePath && !useOwnMetadata) {
      const mappedTitle = getPageTitleFromRootPath(path, '');
      title = mappedTitle || title;
      this.updateDocumentTitle(title);
    }
  }

  render() {
    this.handleTitleUpdate();
    return (
      <>
        {setOGTitle(document.title)}
        {this.props.children}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    documentTitle: state.documentTitle,
  };
};

export default withRouter(connect(mapStateToProps)(UpdateDocumentTitle));
