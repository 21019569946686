import _ from 'lodash';
import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function theme(state = initialState.themeConfig, action) {
  switch ( action.type ) {
    case types.TOGGLE_THE_THEME: {
      const { theme } = action;
      return _.merge(
        {},
        state,
        {
          'currentTheme': theme
        }
      );
    }
    case types.SET_THEME_ALERT: {
      const themeAlert = _.get(action, 'themeAlert', null);

      return _.merge(
        {},
        state,
        {
          themeAlert
        }
      );
    }
    default: {
      return state;
    }
  }
}
