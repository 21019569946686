import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function squad(state = initialState.squad, action) {
  let currentSquad;

  switch (action.type) {

    case types.GET_SQUAD_INPROGRESS:
      currentSquad = {
        ...state.find(squad => squad.teamID === action.results.teamId),
        ...action.results,
        inProgress: true,
        isSuccess: false
      };

      return [
        ...state.filter(squad => squad.teamID === action.results.teamId),
        currentSquad
      ];

    case types.GET_SQUAD_SUCCESS:

      return [
        ...state.filter(squad => squad.teamId !== action.results.teamId),
        {
          ...action.results,
          inProgress: false,
          isSuccess: true
        }
      ];

    case types.GET_SQUAD_FAILURE:
      currentSquad = {
        // for failure case, use whatever has been loaded before
        ...state.find(squad => squad.teamID === action.results.teamId),
        ...action.results,
        inProgress: false,
        isSuccess: false
      };

      return [
        ...state.filter(squad => squad.teamID === action.results.teamId),
        currentSquad
      ];

    default:
      return state;
  }
}
