import _ from 'lodash';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import legacyUrlMapping from '../../constants/legacyUrlMapping';
import defaultConfig from '../../constants/defaultConfig';

class RedirectLegacyURL extends Component {

  isLegacyUrl(path) {
    const lastChar = path.substr(-1);
    if (lastChar === '/') {
      path = path.slice(0, -1);
    }
    return legacyUrlMapping[legacyUrlMapping.findIndex(p => p.oldPath === path)];
  }

  /*
    Check if the following three query parameters have been provided:
      season = *&
      competition = *&
      match =*
    If they are all provided then DO NOT redirect.
   */
  redirectPlayURL(location) {
    const {
      pathname: path,
      search: searchQuery,
    } = location;
    if (path) {
      const secondDirectory = path.split('/')[2];
      if (secondDirectory && secondDirectory === 'play') {
        if (searchQuery && searchQuery.charAt(0) === '?') {
          const searchParams = searchQuery.substring(1).split('&');
          if (searchParams) {
            const searchCriterionExists = {
              season: false,
              competition: false,
              match: false
            };
            searchParams.forEach(param => {
              Object.keys(searchCriterionExists).forEach(criteria => {
                if (param.includes(criteria)) {
                  searchCriterionExists[criteria] = true;
                }
              });
            });
            if (Object.values(searchCriterionExists).every(criteria => criteria === true)) {
              return false;
            }
          }
        }
      }
    }
    return true;
  }

  componentDidMount() {
    // Check for uefa-nations-league in path and replace with 'internationals'
    const {
      location
    } = this.props;
    const pathName = _.get(location, 'pathname', '');
    const search = _.get(location, 'search', '');

    if (_.includes(pathName, '/uefa-nations-league/')) {
      this.props.history.replace(`${pathName.replace('/uefa-nations-league/', '/internationals/')}${search}`);
    }

    const {
      redirectFitnessOnOptusSport = false
    } = defaultConfig;
    if (pathName === '/fitnessonoptussport' && redirectFitnessOnOptusSport) {
      this.props.history.replace(`/fitness${search}`);
    }

    if (!this.redirectPlayURL(this.props.location)) {
      return;
    }
    const legacyUrl = this.props.location && this.isLegacyUrl(this.props.location.pathname);
    if (legacyUrl) {
      this.props.history.replace(legacyUrl.newPath);
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(RedirectLegacyURL);
