import React from 'react';

const IconPath = (props) => {
  const { fill = '#ff0000', ...rest } = props;
  return (
    <svg
      width={rest.width}
      height={rest.height}
      viewBox={`0 0 32 32`}
      {...props}
    >
      <g fill="none" fillRule="evenodd" transform="translate(8 8)">
        <circle cx="8" cy="8" r="8" fill={fill} />
        <path
          fill="#000"
          d="M7.564 9.55L7 4.24V4h2.136v.24l-.564 5.31H7.564zm-.467 2.86v-1.923H9.04v1.923H7.097z"
        />
      </g>
    </svg>
  );
};

export default IconPath;
