import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
  input {
    border: 1px solid #a9aeb9;
  }
`;

const Item = styled.li`
  box-sizing: border-box;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  min-width: 0;
  border: 1px solid #a9aeb9;
  border-radius: 6px;
  margin-top: 16px;
  flex-basis: 100px;
  padding: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: white;
`;

const Group = styled.ul`
  display: flex;
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
  &::after {
    content: '';
    flex: auto;
  }
  ${Item} {
    &:last-child {
      margin-left: 24px;
    }
  }
`;

const Title = styled.h1`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 24px;
  font-size: 13px;
  color: #a9aeb9;
  font-weight: 400;
  width: 100%;
`;

export { Container, Title, Group, Item };
