import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import getFlags from 'ducks/features';

const useChartBeatConfig = () => {
  const systemConfig = useSelector((state) => state.systemConfig) || {};

  const chartBeatConfig = useMemo(() => {
    const getFeatureFlags = getFlags({
      systemConfig,
    });

    return getFeatureFlags(`CHARTBEAT_CONFIG`);
  }, [systemConfig]);

  return chartBeatConfig;
};

export default useChartBeatConfig;
