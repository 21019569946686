
import React from 'react';
import PropTypes from 'prop-types';

const LiveTvIcon = ({ width, height, color, ...props }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" {...props}>
        <path fill={color} fillRule="evenodd" stroke={color} strokeWidth=".5" d="M19.597 8H27v16H5V8h7.404L10 2.845 11.813 2l2.797 6h2.78l2.798-6 1.813.845L19.597 8zM7 10v12h18V10H7zm12 6l-5 3v-6l5 3z" />
        <g fill="none" fillRule="evenodd">
            <use fill="#FFF" xlink="#a" />
            <circle cx="27" cy="9" r="4" fill="#F00" />
        </g>
    </svg>

);

LiveTvIcon.propTypes = {
    color: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

LiveTvIcon.defaultProps = {
    color: 'currentColor',
    width: 32,
    height: 32
};

export default LiveTvIcon;
