export default {
  similar_classes: {
    category: 'Fitness-VOD',
    action: 'Click',
    label: 'Related Video Clicks',
  },
  top_picks: {
    category: 'Fitness-VOD',
    action: 'Click',
    label: 'Top Picks Video Clicks',
  },
};
