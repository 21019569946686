import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function allteams(state = initialState.allteams, action) {
  let currentAllTeams;

  switch (action.type) {

    case types.GET_ALLTEAMS_INPROGRESS:
      currentAllTeams = {
        ...state,
        inProgress: true,
        isSuccess: false
      };

      return currentAllTeams;

    case types.GET_ALLTEAMS_SUCCESS:    
      return {
        ...state,
        inProgress: false,
        isSuccess: true,
        allteams: [...action.allteams.rows]
      }


    case types.GET_ALLTEAMS_FAILURE:
      currentAllTeams = {
        // for failure case, use whatever has been loaded before
        ...state,
        inProgress: false,
        isSuccess: false
      };

      return currentAllTeams;

    default:
      return state;
  }
}

