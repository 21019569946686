import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isLoggedIn } from 'selectors/user';
import { usePrevious } from 'utils/hooks';

const withLogin = (Component) => (props) => {
  const [didUserLogin, setDidUserLogin] = useState(null);
  const [didUserLogout, setDidUserLogout] = useState(null);
  const userLoggedIn = useSelector(isLoggedIn);
  const previousLoggedIn = usePrevious(userLoggedIn);

  useEffect(() => {
    if (previousLoggedIn && !userLoggedIn) {
      setDidUserLogout(true);
      setDidUserLogin(false);
    }

    if (previousLoggedIn === false && userLoggedIn) {
      setDidUserLogin(true);
      setDidUserLogout(false);
    }
    // eslint-disable-next-line
  }, [userLoggedIn, previousLoggedIn]);

  return (
    <Component
      {...props}
      userLoggedIn={userLoggedIn}
      didUserLogin={didUserLogin}
      didUserLogout={didUserLogout}
    />
  );
};

export default withLogin;
