import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function matchhighlights(state = initialState.matchhighlights, action) {
  let currentMatchHighlights;

  switch (action.type) {

    case types.GET_MATCHHIGHLIGHTS_INPROGRESS:
      currentMatchHighlights = {
        ...state,
        inProgress: true,
        isSuccess: false
      };

      return currentMatchHighlights;

    case types.GET_MATCHHIGHLIGHTS_SUCCESS:    
      return {
        ...state,
        inProgress: false,
        isSuccess: true,
        matchhighlights: [...action.matchhighlights.rows]
      }


    case types.GET_MATCHHIGHLIGHTS_FAILURE:
      currentMatchHighlights = {
        // for failure case, use whatever has been loaded before
        ...state,
        inProgress: false,
        isSuccess: false
      };

      return currentMatchHighlights;

    default:
      return state;
  }
}

