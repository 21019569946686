import {
  getBrowser,
  getOS,
  getDeviceDetail,
  getAppInfo,
  getDevice,
} from '../../utils/userAgent';

export const getSegmentBaseProps = () => {
  const deviceDetail = getDeviceDetail();
  const { deviceId, model: deviceModel = '' } = getDevice();
  const { name: osName, version: osVersion } = getOS();
  const { name: browserName, version: browserVersion } = getBrowser();
  const {
    appName,
    appVersion,
    platform,
    advertConsent,
    environment,
  } = getAppInfo();

  return {
    application: {
      environment,
      appName,
      appVersion,
      platform,
    },
    device: {
      osName,
      osVersion,
      advertConsent: !!advertConsent,
      advertId: '',
      advertIdType: '',
      browserName,
      browserVersion,
      deviceId,
      deviceModel,
      deviceDetail,
      deviceTime: new Date(),
    },
  };
};

const baseProps = getSegmentBaseProps();
const getSegmentProps = (props = {}) => {
  const {
    userId,
    userType,
    userTypeStatus,
    userTags = [],
    userOfferIds,
    ...payload
  } = props;
  const userProps = {
    user: {
      userId,
      userTags,
      userType,
      userTypeStatus,
      userOfferIds,
    },
  };

  return {
    ...baseProps,
    ...userProps,
    ...payload,
  };
};

export { getSegmentProps };
