import MobileDetect from 'mobile-detect';

export default function isTouch() {
  let isTouch = false;
  let md = new MobileDetect(window.navigator.userAgent);

  isTouch = !!(md.mobile() || md.phone() || md.tablet());

  return isTouch;
}
