import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function comparison(state = initialState.comparison, action) {
  let currentComparison;

  switch (action.type) {

    case types.GET_COMPARISON_INPROGRESS:
      currentComparison = {
        ...state.find(comparison => comparison.competitionId === action.results.competitionId || comparison.season === action.results.season),
        ...action.results,
        inProgress: true,
        isSuccess: false
      };

      return [
        ...state.filter(comparison => comparison.competitionId !== action.results.competitionId || comparison.season !== action.results.season),
        currentComparison
      ];

    case types.GET_COMPARISON_SUCCESS:

      return [
        ...state.filter(comparison => comparison.competitionId !== action.results.competitionId || comparison.season !== action.results.season),
        {
          ...action.results,
          inProgress: false,
          isSuccess: true
        }
      ];

    case types.GET_COMPARISON_FAILURE:
      currentComparison = {
        // for failure case, use whatever has been loaded before
        ...state.find(comparison => comparison.competitionId === action.results.competitionId || comparison.season === action.results.season),
        ...action.results,
        inProgress: false,
        isSuccess: false
      };

      return [
        ...state.filter(comparison => comparison.competitionId !== action.results.competitionId || comparison.season !== action.results.season),
        currentComparison
      ];

    default:
      return state;
  }
}
