import React from 'react';
import { LoaderItem, LoaderContainer } from './styles';

const Loader = ({
  variant = 'regular',
  width = 32,
  height = 32,
  stroke = '#05cedd',
  viewBox = '0 0 90 90',
  strokeWidth = 4,
  ...props
}) => {
  return (
    <LoaderContainer>
      <LoaderItem
        stroke={stroke}
        strokeWidth={strokeWidth}
        variant={variant}
        height={height}
        center={props.center}
      >
        <svg width={width} height={height} viewBox={viewBox} {...props}>
          <circle cx="45" cy="45" r="38" />
        </svg>
      </LoaderItem>
    </LoaderContainer>
  );
};

export default Loader;
