import React, { Component } from 'react';
import LoadingIcon from './ui-kit/Icons/LoadingIcon';

class PageLoading extends Component {
  render() {
    if (this.props.pastDelay) {
      return (
        <div className='min-h-page flex items-center justify-center'>
          <LoadingIcon className='h-64 w-64' />
        </div>
      )
    } else {
      return null;
    }
  }
}

export default PageLoading;
