import React, { Component } from 'react';
import Footer from '../ui/ui-kit/Footer';
import FooterLink from '../ui/ui-kit/FooterLink';

class PageFooterSection extends Component {
  render() {
    let links = [];
    links.push(
      <FooterLink
        key='Privacy'
        label='Privacy'
        link='http://www.optus.com.au/about/legal/privacy'
      />,
    );
    links.push(<FooterLink key='Terms' label='Terms' link='/terms' />);
    links.push(
      <FooterLink
        key='Copyright'
        label='Copyright'
        link='http://www.optus.com.au/about/legal/copyright'
      />,
    );

    return (
      <Footer
        links={links}
        className={this.props.className}
        hideMegaFooter={this.props.hideMegaFooter}
        hideDownloadApps={this.props.hideDownloadApps}
        newHome={this.props.newHome}
      />
    );
  }
}

export default PageFooterSection;
