import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function metaDescription(state = initialState.metaDescription, action) {

  switch (action.type) {
    case types.SET_META_DESCRIPTION: {
      return action.metaDescription
    }

    default:
      return state;
  }
}

