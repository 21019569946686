export default function getThemes() {
  return {
    nightTheme: {
      name: 'nightTheme',
      bgColor: 'black',
      titleColor: 'white',
      textColor: 'white',
      descriptionColor: 'light-grey',
      authorTextColor: 'white',
      themeClass: '',
      themeButtonClass: '',
    },
    dayTheme: {
      name: 'dayTheme',
      bgColor: 'white',
      titleColor: 'black',
      textColor: 'black',
      descriptionColor: 'medium-grey',
      authorTextColor: 'black',
      themeClass: 'light-theme',
      themeButtonClass: 'day',
    }
  };
}

export const themeNames = {
  nightTheme: 'nightTheme',
  dayTheme: 'dayTheme',
}
