import { analyticAction } from '../../../ducks/analytic';
import { getClonedSearchSessionId } from './utils';
import { showAllId } from '../SearchResults/utils'

function searchFilterClicked(dispatch, { assets = [], filters = [] }) {
  const assetIds = assets
    .slice(0, 10)
    .map((asset) => asset.id)
    .join(', ');
  const asset_count = assets.length;
  const asset_filter_combination = filters.includes(showAllId) ? showAllId : filters.join(', ');

  dispatch(
    analyticAction({
      eventName: 'Search Filter Clicked',
      search_session_id: getClonedSearchSessionId(),
      assets: assetIds,
      asset_filter_combination,
      asset_count,
    }),
  );
}

export default searchFilterClicked;
