import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function editorials(state = initialState.editorials, action) {
  let currentEditorial;
  switch (action.type) {
    case types.GET_EDITORIAL_INPROGRESS:
      currentEditorial = {
        ...state.find((editorial) => editorial.id === action.editorial.id),
        id: action.editorial.id,
        inProgress: true,
        isSuccess: false,
      };

      return [
        ...state.filter((editorial) => editorial.id !== action.editorial.id),
        currentEditorial,
      ];

    case types.GET_EDITORIAL_SUCCESS:
      return [
        ...state.filter((editorial) => editorial.id !== action.editorial.id),
        {
          ...action.editorial,
          inProgress: false,
          isSuccess: true,
        },
      ];

    case types.GET_EDITORIAL_FAILURE:
      currentEditorial = {
        ...state.find((editorial) => editorial.id === action.editorial.id),
        id: action.editorial.id,
        inProgress: false,
        isSuccess: false,
      };

      return [
        ...state.filter((editorial) => editorial.id !== action.editorial.id),
        currentEditorial,
      ];

    case types.GET_EDITORIAL_PURGE:
      return [
        ...state.filter((editorial) => editorial.id !== action.editorial.id),
      ];

    default:
      return state;
  }
}
