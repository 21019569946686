import React from 'react';

const IconPath = (props) => {
  const { fill = '#FFF', ...rest } = props;
  return (
    <svg
      width={rest.width}
      height={rest.height}
      viewBox={rest.viewBox || `0 0 24 24`}
      {...rest}
    >
      <path
        fill={fill}
        fillRule="evenodd"
        d="M15.327 9.799a5.623 5.623 0 0 1 1.54 1.858c.383.735.575 1.535.575 2.401 0 .967-.237 1.858-.71 2.674a5.237 5.237 0 0 1-1.934 1.933 5.233 5.233 0 0 1-2.673.71 5.233 5.233 0 0 1-2.673-.71 5.237 5.237 0 0 1-1.934-1.933 5.233 5.233 0 0 1-.71-2.674c0-.886.202-1.706.605-2.462a5.017 5.017 0 0 1 1.63-1.827V8.017c0-.544.142-1.058.424-1.541a3.257 3.257 0 0 1 1.148-1.163 2.957 2.957 0 0 1 1.555-.438c.554 0 1.073.146 1.556.438.483.292.871.68 1.163 1.163.292.483.438.997.438 1.54V9.8zM10.01 8.017v1.148c.625-.282 1.33-.423 2.115-.423.725 0 1.47.16 2.235.483V8.017c0-.584-.221-1.093-.664-1.526-.443-.433-.952-.65-1.526-.65-.574 0-1.077.217-1.51.65-.433.433-.65.942-.65 1.526zm2.115 10.391c.785 0 1.51-.196 2.175-.589a4.433 4.433 0 0 0 1.586-1.586c.393-.664.589-1.39.589-2.175 0-.785-.196-1.51-.59-2.175a4.433 4.433 0 0 0-1.585-1.586 4.199 4.199 0 0 0-2.175-.589c-.785 0-1.51.197-2.175.59a4.433 4.433 0 0 0-1.586 1.585 4.199 4.199 0 0 0-.589 2.175c0 .786.196 1.51.59 2.175A4.433 4.433 0 0 0 9.95 17.82c.665.393 1.39.59 2.175.59zm-1.269-4.259c0-.342.126-.634.378-.876s.549-.363.891-.363c.342 0 .64.121.891.363.252.242.378.534.378.876 0 .342-.126.64-.378.891a1.219 1.219 0 0 1-.891.378c-.342 0-.64-.126-.891-.378a1.219 1.219 0 0 1-.378-.891z"
      />
    </svg>
  );
};

export default IconPath;
