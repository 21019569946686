import PopupImageOverlay from './components/PopupImageOverlay';

export const ContentCardTypeName = 'content-card';

export const ValidBannerTypes = {
  NO_BUTTON: { cardClickAction: { type: 'action', optional: true } },
  ONE_BUTTON: {
    cardClickAction: { type: 'action', optional: true },
    primaryButtonAction: { type: 'action' },
    primaryButtonLabel: { type: 'string' },
  },
  TWO_BUTTONS: {
    cardClickAction: { type: 'action', optional: true },
    primaryButtonAction: { type: 'action' },
    primaryButtonLabel: { type: 'string' },
    secondaryButtonAction: { type: 'action' },
    secondaryButtonLabel: { type: 'string' },
  },
};

export const actionComponents = {
  popupImageOverlay: PopupImageOverlay,
};

export const ValidTargetUserSegments = [
  '',
  'NOT_LOGGED_IN',
  'BASIC',
  'PREMIUM',
  'HYPERION_ACTIVE',
  'HYPERION_CANCELLED',
  'HYPERION_TERMINATED',
  'APPSTORE_ACTIVE',
  'APPSTORE_CANCELLED',
  'APPSTORE_TERMINATED',
  'PLAYSTORE_ACTIVE',
  'PLAYSTORE_CANCELLED',
  'PLAYSTORE_TERMINATED',
  'CREDITCARD_ACTIVE',
  'CREDITCARD_CANCELLED',
  'CREDITCARD_TERMINATED',
  'ARS_ENGLAND_CLUB',
  'AUS_AUSTRALIA_INTERNATIONAL',
  'MISSING_USER_PROFILE_GENDER',
  'MISSING_USER_PROFILE_POSTCODE',
  'MISSING_USER_PROFILE_DATE_OF_BIRTH',
  'MISSING_FAVOURITE_TEAM',
];

export const ValidTargetPlatforms = [
  '',
  'WEB',
  'MOBILE_WEB',
  'DESKTOP_WEB',
  'MOBILE',
  'ANDROID',
  'IOS',
  'CTV',
  'XBOX',
  'FIRETV',
  'ATV',
  'PLAYSTATION',
  'SAMSUNGTV',
  'FOXTEL',
  'LGTV',
  'ANDROIDTV',
];

export const InvalidFlag = '{{invalid}}';
