import moment from 'moment/moment';
import PropTypes from 'prop-types';
import React from 'react';

/**
 *
 * @param timeString
 * @returns {string}
 */
const getFormattedTimeStringFromNow = ({timeString, thresholdValue, thresholdUnit}) => {
  const timeStringMoment = moment(timeString);
  const timeStringFromNow = timeStringMoment.fromNow();
  const [timeValue, timeUnit, pastOrFuture] = timeStringFromNow.split(' ');
  const timeUnitLower = timeUnit.toLowerCase();

  if (thresholdValue && thresholdUnit) {
    const diffThresholdValue = moment().diff(timeStringMoment, thresholdUnit);

    // Display timestamp if threshold has been exceeded/met
    if (diffThresholdValue > thresholdValue) {
      const sameYear = timeStringMoment.isSame(moment(), 'year');

      return timeStringMoment.format(`ddd D MMM ${!sameYear ? 'YYYY' : ''}`);
    }
  }

  if (timeUnitLower === 'days' && timeValue >= 7) {
    const noOfWeeks = Math.floor(timeValue / 7);
    const timeUnit = noOfWeeks > 1 ? 'weeks' : 'week';
    return `${noOfWeeks}  ${timeUnit} ${pastOrFuture}`;
  }

  return timeStringFromNow;
};

export const TimeString = ({
  timeString,
  className,
  linearChannel,
  thresholdValue,
  thresholdUnit
}) => {
  return <div
    className={`time-string text-2xs font-MarkPro ${className}`}
  >
    {linearChannel ?
      ''
      :
      getFormattedTimeStringFromNow({
        timeString,
        thresholdValue,
        thresholdUnit,
      })
    }
  </div>;
};

TimeString.propTypes = {
  timeString: PropTypes.string,
  className: PropTypes.string,
  linearChannel: PropTypes.bool,
};

TimeString.defaultProps = {
  timeString: '',
  className: '',
  linearChannel: false,
};
