import React from "react";
import PropTypes from "prop-types";
import MatchLineupSubsRow from './MatchLineupSubsRow';

const showPlayers = (title, playersList) => (
  <div className="w-full text-center py-8">
  <div className="w-full mb-8">
    <span className="text-white text-xs leading-loose">{title}</span>
    </div>
    {playersList}
  </div>
);

const PreviewLineup = ({
  teamTabs,
  goalKeepers,
  defenders,
  midFielders,
  forwards
}) => (
  <div className="bg-widget-grey w-full flex flex-col items-center justify-center py-16 pb-8 px-8 xl:px-16">
    <div className="w-full text-center mb-8">
    <span className="text-white text-base font-MarkProHeavy">Full Squad</span>
    </div>
    {teamTabs && <div className=" w-full md:w-2/5 my-16 relative h-40 max-w-472">{teamTabs}</div>}
    {showPlayers("Goal keepers", goalKeepers)}
    {showPlayers("Defenders", defenders)}
    {showPlayers("MidFielders", midFielders)}
    {showPlayers("Forwards", forwards)}
  </div>
);

PreviewLineup.propTypes = {
  /** The team name to be appear in the tabs */
  teamTabs: PropTypes.node,
  /** The list of goalkeepers in the team */
  goalKeepers: PropTypes.arrayOf(MatchLineupSubsRow),
  /** The list of defenders in the team */
  defenders: PropTypes.arrayOf(MatchLineupSubsRow),
  /** The list of midfielders in the team */
  midFielders: PropTypes.arrayOf(MatchLineupSubsRow),
  /** The list of forwards in the team */
  forwards: PropTypes.arrayOf(MatchLineupSubsRow)

}

export default PreviewLineup;
