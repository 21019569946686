const coupon = (state) => state.coupon;
const couponData = (state) => state.coupon?.couponData;
const orderData = (state) => state?.coupon?.orderData;
const couponValue = (state) => state?.coupon?.couponValue;
const error = (state) => state?.coupon?.error;
const loading = (state) => state?.coupon?.loading;
const eligibility = (state) => state?.coupon?.eligibility;

export {
  error,
  coupon,
  couponData,
  orderData,
  loading,
  couponValue,
  eligibility,
};
