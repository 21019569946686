import moment from 'moment';
import _ from "lodash";

export function getTeamList(fixturesResults) {
  let teamList = [];

  fixturesResults.forEach(item => {
    let tmp = teamList.find(team => team.value === item.HomeTeamID);
    if (!tmp) {
      // not found
      teamList.push({ value: item.HomeTeamID, label: item.HomeTeamName });
    }

    tmp = teamList.find(team => team.value === item.AwayTeamID);
    if (!tmp) {
      // not found
      teamList.push({ value: item.AwayTeamID, label: item.AwayTeamName });
    }
  });

  teamList.sort((team1, team2) => team1.label < team2.label ? -1 : 1);
  teamList.unshift({ value: null, label: 'All Teams' }); // TODO: can be better to have a cross to remove selected for SelectBox

  return teamList;
}

export function getMonthList() {
  const monthList = [
    { value: null, label: 'All Months' }, // TODO: can be better to have a cross to remove selected for SelectBox
    { value: 'January', label: 'January' },
    { value: 'February', label: 'February' },
    { value: 'March', label: 'March' },
    { value: 'April', label: 'April' },
    { value: 'May', label: 'May' },
    { value: 'June', label: 'June' },
    { value: 'July', label: 'July' },
    { value: 'August', label: 'August' },
    { value: 'September', label: 'September' },
    { value: 'October', label: 'October' },
    { value: 'November', label: 'November' },
    { value: 'December', label: 'December' },
  ];

  return monthList;
}

export function getCompetitionList() {
  return [
    {
      value: 'all',
      label: 'All'
    },
    {
      value: '941',
      label: 'UEFA Nations League'
    },
    {
      value: '235',
      label: 'EURO 2024 Qualifiers'
    },
    // TODO removed temporarily as requested by Tom Townley
    // {
    //   value: '88',
    //   label: 'International Friendlies'
    // },
  ];
}

export function filterFixtureResultList(fixturesResults, selectedTeam, selectedMonth, selectedYear, selectedCompetitionID, competitions) {
  let filteredList = fixturesResults;
  if (selectedCompetitionID) {
    if (selectedCompetitionID === 'all') {
      fixturesResults = competitions
        .map(secFix => _.get(secFix, 'rows', []))
        .reduce((a, b) => [
          ...a,
          ...b], []);
      // allFixtures.sort((a, b) => moment(a.MatchDateTime).isAfter(b.MatchDateTime) ? 1 : -1);
    } else {
      const filteredCompetition = competitions.find(competition => competition.competitionId === parseInt(selectedCompetitionID, 10));
      filteredList = _.get(filteredCompetition, 'rows', []);
    }
  }

  if (selectedTeam) {
    filteredList = fixturesResults.filter(item => selectedTeam === item.HomeTeamID || selectedTeam === item.AwayTeamID);
  }

  if (selectedMonth) {
    filteredList = filteredList.filter(item => {
      const matchDate = moment.utc(_.get(item, 'MatchDateTime', '')).local();

      return matchDate.format('MMMM') === selectedMonth;
    });
  }

  if (selectedYear) {
    const seasonStart = selectedYear.split('/')[0];
    const seasonEnd = selectedYear.split('/')[1]
    filteredList = filteredList.filter(item => {
      const matchDate = moment.utc(_.get(item, 'MatchDateTime', '')).local();
      return matchDate.format('YYYY') === seasonEnd || seasonStart;
    });
  }
  return filteredList;
}


export function getYearList(fixturesResults) {
  let years = [];
  let yearList = [];
  let count = 5
  fixturesResults.map(fixtureItem => {
    const year = Number(fixtureItem.MatchDateTime.split('-')[0]);
    const formattedYear = `${year - 1}/${year}`
    if (!years.includes(formattedYear) && count > 0) {
      years.push(formattedYear)
    }
    return null
  })

  years.map(year => {
    yearList.push({ label: year, value: year })
    return null
  })

  return yearList
}

export function getSeasonsList(currentYear, currentMonth, filteredResults) {
  // get the list of unique years to render in years select box in results page
  const uniqueYears = Array.from(new Set(filteredResults.map(result => result.MatchDateTime.substring(0, 4))));
  let years = [];
  let yearList = [];
  for (let i = 0; i <= uniqueYears.length; i++) {
    let year;
    if (currentMonth > 6) {
      year = (Number(currentYear) + 1) - i;
    }
    else {
      year = Number(currentYear) - i;
    }
    const formattedYear = `${year - 1}/${year}`;
    years.push(formattedYear);
  }

  years.map(year => {
    yearList.push({ label: year, value: year })
    return null
  })
  return yearList
}
