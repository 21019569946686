import React from 'react';
import PropTypes from 'prop-types';

const padding = value => value > 9 ? ' px-4' : '';

const Counter = ({value, className, ...props}) => (
  <div className={`table shadow-2xs rounded-full bg-black h-16 min-w-16 border-2 border-pink${padding(value)}${className ? ` ${className}` : ''}`} {...props}>
    <p className="os-fv-tb-nums text-xs font-MarkProHeavy text-pink table-cell align-middle text-center">{value}</p>
  </div>
);

Counter.propTypes = {
  /** value as string/number to be shown in counter */
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  /** tailwind css classes*/
  className: PropTypes.string,
};

Counter.defaultProps = {
  className: null,
};

export default Counter;
