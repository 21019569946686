import styled, { css } from 'styled-components';

const Heading = styled.h1`
  color: #323439;
  line-height: 32px;
  font-weight: 700;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  font-size: 24px;
  margin: 0;
  width: 100%;
  ${(props) =>
    props.left &&
    css`
      text-align: left;
    `}
`;

const Controls = styled.div`
  padding: 16px 24px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  border-bottom: 1px solid black;
`;

const Button = styled.button`
  margin: 0 0 0 16px;
  ${(props) =>
    props.right &&
    css`
      margin-left: auto;
      margin-top: 0;
      margin-right: 0;
      margin-bottom: 0;
    `}
  ${(props) =>
    props.reset &&
    css`
      margin: 0;
      margin-top: 16px;
      &:first-child {
        margin-top: 0;
      }
    `}
  padding: 10px 10px;
  display: flex;
  align-items: center;
  background: black;
  text-align: center;
  color: white;
  border: none;
  font-size: 20px;
  border-radius: 4px;
  max-height: 40px;
  cursor: pointer;
  &:first-child {
    margin-left: 0;
  }
  &::active,
  &::hover,
  &:focus {
    outline: none;
  }
`;

const Expand = styled(Button)`
  background: none;
  margin-right: 8px;
`;

const Status = styled.div`
  padding: 10px;
  color: black;
`;

const BottomOverlay = styled.div.attrs({ className: 'test-bottom' })`
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
  height: 20px;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
`;

export { Status, Heading, Controls, Expand, Button, BottomOverlay };
