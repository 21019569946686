import React from 'react';
const IconPath = ({ width, height, viewBox, className, ...props }) => {
  return (
    <svg width={width} height={height} viewBox={viewBox} {...props}>
      <circle cx="45" cy="45" r="38" />
    </svg>
  );
};

export default IconPath;
