import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { placeholderImg } from './ui-kit/Articles/utils';
import defaultConfig from 'constants/defaultConfig';

const { srcSetWidths } = defaultConfig?.image || {};

class Picture extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      placeholderFallback: false,
    };
  }

  componentWillUnmount() {
    this.imgNode.src = '';
  }

  getSourceSet = (src, minWidths) => {
    if (src && minWidths) {
      return minWidths.map((mw) => {
        const dimensions = `${mw}x${Math.round((mw * 9) / 16)}`;
        const parsedImageUrl = src.replace(`\${width x height}`, dimensions);
        return `${parsedImageUrl} ${mw}w`;
      });
    }
    return [];
  };

  render() {
    let {
      className,
      src,
      srcSet,
      minWidths,
      alt,
      defaultImage,
      imageUrl_16_9,
      imgAttrs,
    } = this.props;

    const resizedSrcSets =
      srcSet || this.getSourceSet(imageUrl_16_9, minWidths);
    return (
      <picture>
        {this.state.placeholderFallback ? (
          <img
            className={className || 'block'}
            src={placeholderImg}
            alt={alt}
            ref={(node) => (this.imgNode = node)}
            {...imgAttrs}
          />
        ) : (
          <img
            className={className || 'block'}
            src={src || defaultImage || placeholderImg}
            alt={alt}
            srcSet={resizedSrcSets}
            onError={(e) => this.setState({ placeholderFallback: true })}
            ref={(node) => (this.imgNode = node)}
            {...imgAttrs}
          />
        )}
      </picture>
    );
  }
}

export default Picture;

Picture.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
  srcSet: PropTypes.string,
  defaultImage: PropTypes.string,
  imageUrl_16_9: PropTypes.string,
  minWidths: PropTypes.array,
};

Picture.defaultProps = {
  className: 'block',
  alt: '',
  minWidths: srcSetWidths || [],
};
