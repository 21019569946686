import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function allfixtures(state = initialState.allfixtures, action) {
  let currentAllFixtures;

  switch (action.type) {

    case types.GET_ALLFIXTURES_INPROGRESS:
      currentAllFixtures = {
        ...state,
        inProgress: true,
        isSuccess: false
      };

      return currentAllFixtures;

    case types.GET_ALLFIXTURES_SUCCESS:    
      return {
        ...state,
        inProgress: false,
        isSuccess: true,
        allfixtures: [...action.allfixtures.rows]
      }


    case types.GET_ALLFIXTURES_FAILURE:
      currentAllFixtures = {
        // for failure case, use whatever has been loaded before
        ...state,
        inProgress: false,
        isSuccess: false
      };

      return currentAllFixtures;

    default:
      return state;
  }
}

