import UAParser from 'ua-parser-js';
import _, { isArray, isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import jsCookie from 'js-cookie';
import defaultConfig from '../constants/defaultConfig';

let parser = new UAParser(window.navigator.userAgent);
let parsedUA = parser.getResult();

const crawlers = [
  'Applebot',
  'bingbot',
  'BingPreview',
  'Bytespider',
  'Baiduspider',
  'facebookexternalhit/1.1',
  'Facebot',
  'Googlebot',
  'LinkedInBot/1.0',
  'OptusCloudSearchCrawler',
  'prerendercloud',
  'Pinterest',
  'SkypeUriPreview',
  'Slack',
  'Twitterbot',
  'WhatsApp/2',
  'Yandex',
  'Yeti',
];
const botRegex = new RegExp(crawlers.join('|'), 'i');

export function getBrowser() {
  return parser.getBrowser();
}

export function getOS() {
  return parser.getOS();
}

export function getDevice() {
  return {
    ...parser.getDevice(),
    ...generateDeviceId(),
  };
}

export const isBot = () => {
  const userAgent = window?.navigator?.userAgent || '';
  return botRegex.test(userAgent);
};

export function isMobile() {
  const deviceData = getDevice();
  const deviceType = _.get(deviceData, 'type');

  return _.includes(['mobile', 'tablet'], deviceType);
}

export function getDeviceDetail() {
  let deviceDetail = null;
  const vendor = _.get(parsedUA, ['details', 'device', 'vendor'], null);
  const model = _.get(parsedUA, ['details', 'device', 'model'], null);
  let deviceType = _.get(parsedUA, ['device', 'type'], null);
  deviceType =
    deviceType === 'mobile' || deviceType === 'tablet' ? 'tablet' : 'PC';

  if (vendor) {
    deviceDetail = vendor + '|';
  }

  if (model) {
    deviceDetail += model;
  }

  if (!deviceDetail) {
    // no detail device type information in user agent, use the device type metadata
    deviceDetail = deviceType;
  }

  return deviceDetail;
}

export function getParsedUA() {
  return parsedUA;
}

export function generateDeviceId() {
  if (window && window.localStorage) {
    const key = 'deviceId';
    let deviceId = window.localStorage.getItem(key);
    if (!deviceId) {
      deviceId = uuidv4();
      window.localStorage.setItem(key, deviceId);
    }
    return { deviceId };
  }

  // Fallback to new uuid if window or window.localStorage not available
  return { deviceId: uuidv4() };
}

export async function getTvid() {
  const TVID_KEY = 'tvid';
  const isLocalstorageEnabled = window?.localStorage;
  const isCookieEnabled = navigator?.cookieEnabled;
  const expires = 365;

  const fetchTvid = async () => {
    try {
      const getTvidUrl = 'https://wjxst.publishers.tremorhub.com/getTVID';
      const res = await axios.get(getTvidUrl, {
        withCredentials: true,
        mode: 'no-cors',
      });

      const tvid = res?.data;

      if (tvid) {
        isLocalstorageEnabled && window.localStorage.setItem(TVID_KEY, tvid);
        isCookieEnabled && jsCookie.set(TVID_KEY, tvid, { expires });
      }
      return tvid || '';
    } catch (error) {
      // TODO: to replace below to the segment app event
      console.log('tvid error:', error);
      return '';
    }
  };

  const tvid = await ((isLocalstorageEnabled &&
    window.localStorage.getItem(TVID_KEY)) ||
    (isCookieEnabled && jsCookie.get(TVID_KEY)) ||
    fetchTvid());

  return {
    tvid,
  };
}

export function getAppInfo() {
  const {
    advertConsent,
    name: appName = 'optus-sport-portal',
    env: environment = '',
    version: appVersion = '',
    platform,
  } = defaultConfig;

  return {
    appName,
    platform,
    environment,
    appVersion,
    advertConsent,
  };
}

export const isMobileWebView = () => {
  return window?.location?.search?.includes('setDevice=mobile');
};

export const isSNSWebView = (filterBrowser) => {
  const { inAppBrowserKeys } = defaultConfig || {};
  const filterBrowserKeys =
    (isArray(filterBrowser) ? filterBrowser : inAppBrowserKeys) || [];
  const browserData = getBrowser();
  const { name: browserName } = browserData || {};
  return (
    !isEmpty(filterBrowserKeys) &&
    browserName &&
    filterBrowserKeys.includes(browserName.toLowerCase())
  );
};

const isSSRUserAgentDefaultProps = { skipMobileWebView: false };

export const isSSRUserAgent = ({
  skipMobileWebView,
} = isSSRUserAgentDefaultProps) => {
  const uaString =
    'Mozilla/5.0 (Macintosh; Intel Mac OS X 12_0_1) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/96.0.4664.45 Safari/537.36 AWSHeadlessRenderer';

  // Temporary; to be refactored later to proper blacklist config
  const disableRoutes = ['/samsungtv'];
  const routeDisabled = disableRoutes.includes(window?.location?.pathname);

  return (
    window.navigator.userAgent === uaString ||
    isBot() ||
    (!skipMobileWebView && isMobileWebView()) ||
    routeDisabled
  );
};
