import get from 'lodash/get';
import axios from 'axios';
import * as types from '../constants/actionTypes';
import defaultConfig from 'constants/defaultConfig';

export function getSquad(competitionId, season, teamId) {
  return (dispatch) => {
    const results = { teamId };
    const {
      vcmsBaseUrl,
      VCMSConfig: { teamSquadEndpoint },
    } = defaultConfig;
    if (!competitionId || !teamId || !season) {
      return;
    }
    const url = `${vcmsBaseUrl}${teamSquadEndpoint}?season=${season}&competition=${competitionId}&team=${teamId}`;

    dispatch({ type: types.GET_SQUAD_INPROGRESS, results });

    axios
      .get(url)
      .then((res) => {
        const isSuccess = get(res, 'data.result[0].statuscode', 'N') === 'Y';

        if (isSuccess) {
          results.rows = get(res, 'data.APIinfo[0].SquadInfo', []); // results return HighlightsInfo as well
          dispatch({ type: types.GET_SQUAD_SUCCESS, results });
        } else {
          dispatch({ type: types.GET_SQUAD_FAILURE, results });
        }
      })
      .catch((error) => {
        dispatch({ type: types.GET_SQUAD_FAILURE, results });
      });
  };
}
