import { analyticAction } from '../../../ducks/analytic';
import { getClonedSearchSessionId } from './utils';

const searchAssetClicked = (
  dispatch,
  { asset = {}, filters = [], pathName = '', assetPosition = '0' },
) => {
  const asset_filter_combination = filters && filters.join(', ');
  const asset_type = asset.type;
  // const bucket_name = pathName; //'Highlights: Aston Villa v Manchester United'; //pathName
  const asset_position = assetPosition;
  dispatch(
    analyticAction({
      eventName: 'Asset Clicked',
      search_session_id: getClonedSearchSessionId(),
      asset,
      bucket_name: 'search',
      screen: 'search',
      asset_filter_combination,
      asset_type,
      asset_position,
    }),
  );
};

export default searchAssetClicked;
