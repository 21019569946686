import React from 'react';
import PropTypes from 'prop-types';

const GoalSavedIcon = ({ width, height, viewBox, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox} {...props}>
    <path fill="#FFF" fillRule="evenodd" d="M8.5 0c0 .488.454.918 1.362 1.29.7.273 1.628.487 2.782.644.89.117 1.675.175 2.356.175a25.717 25.717 0 0 1-.596 3.282c-.227.937-.511 1.845-.852 2.724a19.985 19.985 0 0 1-1.078 2.227c-.34.625-.71 1.21-1.107 1.758-.34.468-.7.908-1.079 1.318-.303.332-.605.635-.908.908a6.395 6.395 0 0 1-.653.498L8.5 15l-.227-.176a6.395 6.395 0 0 1-.653-.498 13.273 13.273 0 0 1-.908-.908c-.379-.41-.738-.85-1.079-1.318a16.056 16.056 0 0 1-1.107-1.758 19.985 19.985 0 0 1-1.078-2.227 21.606 21.606 0 0 1-.852-2.724A25.717 25.717 0 0 1 2 2.109c.662 0 1.448-.068 2.356-.205 1.154-.156 2.081-.37 2.782-.644C8.046.908 8.5.488 8.5 0zM2.993 2.988c.398 2.578 1.183 4.893 2.356 6.944a16.78 16.78 0 0 0 2.697 3.515V1.875c-1.003.586-2.687.957-5.053 1.113zm5.961 10.46a16.78 16.78 0 0 0 2.697-3.516c1.154-2.051 1.92-4.346 2.299-6.885a19.82 19.82 0 0 1-2.952-.41c-.7-.176-1.382-.41-2.044-.703v11.513z"/>
  </svg>
);

GoalSavedIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  viewBox: PropTypes.string
};

GoalSavedIcon.defaultProps = {
  width: 32,
  height: 32,
  viewBox: '0 0 32 32'
};

export default GoalSavedIcon;



