import React, { Component } from 'react';
import { withFeatureFlags } from '../../context/withFeatureFlags';
import FooterWidget from './FooterWidget';

class FooterMegaFooter extends Component {
  render() {
    const { hideDownloadApps, newHome, getFeatureFlags } = this.props;

    const footerLinks = getFeatureFlags('FOOTER_LINKS');

    return (
      <div
        className={`
        flex
        flex-wrap
        xl:flex-no-wrap
        xl:flex-row-reverse
        ${newHome ? '' : 'px-14'}
        px-16
        sm:px-32
        lg:px-80
        py-0
        py-24
        sm:py-32
        lg:py-40
        pb-16
        xl:w-full
        justify-start
        lg:justify-center
        bg-core-dark-grey
      `}
      >
        <div
          className={`
            w-full
            xl:flex-1
            xl:ml-16
            xl:w-2/5
            ${hideDownloadApps ? 'hidden md:block' : ''}
          `}
        >
          <FooterWidget title='' widgettype='appstore-links' hideDownloadApps />
        </div>
        <div
          className={`
            md:flex
            md:flex-no-wrap
            w-full
            xl:mt-0
            xl:w-4/5
          `}
        >
          <div className='w-full sm:w-1/2 md:w-2/6'>
            <FooterWidget title='Optus Sport' widgettype='os-links' />
          </div>
          <div className='w-full sm:w-1/2 md:w-2/6'>
            <FooterWidget title='Help & Support' widgettype='help-links' footerLinks={footerLinks} />
          </div>
          <div className='w-full sm:w-1/2 md:w-2/6'>
            <FooterWidget title='Advertise' widgettype='advertise-links' />
          </div>
        </div>
      </div>
    );
  }
}

export default withFeatureFlags(FooterMegaFooter);
