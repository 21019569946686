import React from 'react';
import PropTypes from 'prop-types';

const YellowCardIcon = ({ width, height, viewBox, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox} {...props}>
    <rect width="8" height="12" x="4" y="2" fill="#FDCC08" fillRule="evenodd" rx="2"/>
</svg>
);

YellowCardIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  viewBox: PropTypes.string
};

YellowCardIcon.defaultProps = {
  width: 32,
  height: 32,
  viewBox: '0 0 32 32'
};

export default YellowCardIcon;



