import React, { Component } from 'react';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import compact from 'lodash/compact';
import find from 'lodash/find';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as squadActions from '../../actions/squad';
import * as fixturesActions from '../../actions/fixtures';
import * as resultsActions from '../../actions/results';
import * as tableActions from '../../actions/table';
import * as teamStatsActions from '../../actions/teamstats';
import * as teamSummaryActions from '../../actions/teamSummary';
import { default as TeamProfileUI } from '../ui/TeamProfileUI';
import PageLoading from '../ui/PageLoading';
import * as optaUtils from '../../utils/opta';
import * as assetActions from '../../actions/asset';
import defaultConfig from '../../constants/defaultConfig';
import { getSaagCompetition } from '../../utils/teamListings';
import { isPremiumUser } from 'selectors/user';

class TeamProfile extends Component {
  constructor(props, context) {
    super(props, context);
    const navigations = get(this.props.navigation, 'navigations', []);
    const opta = optaUtils.getOptaConfig(
      this.props.location.pathname,
      navigations,
    );
    const teamId = this.props.match.params.teamId;
    this.getTeamSummary = this.getTeamSummary.bind(this);
    this.loadAsset = this.loadAsset.bind(this);

    const teams = get(props, ['teams', 'allTeams'], get(props, 'teams', []));
    const teamName = this.getTeamName(teams, teamId);
    this.loadAsset(teamName);
    if (opta && teamId) {
      if (!this.props.teamSummary) {
        this.getTeamSummary(opta.season, opta.competitionId, teamId);
      }
      if (!this.props.squad) {
        this.getSquad(opta.competitionId, opta.season, teamId);
      }
    }
    this.state = { teamId };
  }

  componentWillReceiveProps(nextProps) {
    let path = get(nextProps, 'location.pathname', '').toLowerCase();
    if (path === '') {
      // no new path, use the current path
      path = get(this.props, 'location.pathname', '').toLowerCase();
    }

    let navigations = get(nextProps, 'navigation.navigations', []);

    if (navigations.length === 0) {
      navigations = get(this.props.navigation, 'navigation.navigations', []);
    }

    // path or navigation updated, need to update fixture
    const opta = optaUtils.getOptaConfig(path, navigations);

    if (opta) {
      const currentCompetitionId = get(
        this.props.teamSummary,
        'competitionId',
        null,
      );
      const currentSeason = get(this.props.teamSummary, 'seasonId', null);
      const currentTeams = get(
        this.props,
        ['teams', 'allTeams'],
        get(this.props, 'teams', []),
      );
      const nextTeams = get(
        nextProps,
        ['teams', 'allTeams'],
        get(nextProps, 'teams', []),
      );
      const inProgress =
        (nextProps.results?.length &&
          nextProps.results.some((r) => r.inProgress)) ||
        (nextProps.table?.length &&
          nextProps.table.some((t) => t.inProgress)) ||
        (nextProps.fixtures?.length &&
          nextProps.fixtures.some((t) => t.inProgress));
      if (
        !inProgress &&
        nextTeams.length &&
        (currentCompetitionId !== opta.competitionId ||
          currentSeason !== opta.season ||
          currentTeams.length !== nextTeams.length)
      ) {
        this.getFixtures(opta.competitionId, opta.season);
        this.getResults(opta.competitionId, opta.season);
        this.getTables(opta.competitionId, opta.season);

        this.getTeamSummary(opta.season, opta.competitionId, this.state.teamId);
        this.getSquad(opta.competitionId, opta.season, this.state.teamId);
        const teamName = this.getTeamName(nextTeams, this.state.teamId);
        this.loadAsset(teamName);

        if (nextTeams.length) {
          const competitions = nextTeams.find(
            (team) => team.optaId === this.state.teamId,
          );
          if (competitions) {
            const optaItems = get(opta, 'optaItems');

            const filteredCompetitions = competitions.competitions.map(
              (team) => {
                const compTeamOptaId = get(team, 'teamOptaId');

                return compTeamOptaId && compTeamOptaId === this.state.teamId
                  ? team
                  : null;
              },
            );
            const deDupeUniqCompetitions = uniqBy(
              compact(filteredCompetitions),
              (team) => team && team.optaId,
            );

            deDupeUniqCompetitions.map((team) => {
              const teamCompetitionId = get(team, 'optaId');
              // If we have optaItems then use that to retrieve the correct season id for the team.CompetitionID
              let optaSeasonId = get(opta, 'season', new Date().getFullYear());
              let competitionCode = get(opta, 'competitionCode', null);
              if (optaItems) {
                const teamCompetitionInfo = find(
                  optaItems,
                  (optaData) =>
                    Number(get(optaData, 'competitionId')) ===
                    Number(teamCompetitionId),
                );

                if (teamCompetitionInfo) {
                  optaSeasonId = get(
                    teamCompetitionInfo,
                    'season',
                    optaSeasonId,
                  );
                  competitionCode = get(teamCompetitionInfo, 'competitionCode');
                }
              }

              this.getTeamStats(
                optaSeasonId,
                Number(teamCompetitionId),
                this.state.teamId,
                competitionCode,
              );
              return null;
            });
          }
        }
      }
    }
  }

  getTeamName(teams, teamId) {
    if (!teams.length) {
      return '';
    }
    const teamDetails = teams.find((team) => team.optaId === teamId);
    return teamDetails ? teamDetails.title : '';
  }

  getSquad(competitionId, seasonId, teamId) {
    this.props.squadActions.getSquad(competitionId, seasonId, teamId);
  }

  getTeamStats(seasonId, competitionId, teamId, competitionCode) {
    this.props.teamStatsActions.getTeamStats(
      seasonId,
      competitionId,
      teamId,
      competitionCode,
    );
  }

  getTeamSummary(seasonId, competitionId, teamId) {
    this.props.teamSummaryActions.getSummary(seasonId, competitionId, teamId);
  }
  getResults(competitionId, season) {
    this.props.resultsActions.getResults(competitionId, season);
  }
  getTables(competitionId, season) {
    this.props.tableActions.getTables(competitionId, season);
  }

  getFixtures(competitionId, season) {
    this.props.fixturesActions.getFixtures(competitionId, season);
  }

  loadAsset(teamName) {
    if (teamName) {
      this.props.assetActions.getAssetByType('team', teamName);
    }
  }

  render() {
    const {
      assetCategory,
      squad,
      teamSummary,
      teams,
      teamStats,
      table,
      fixtures,
      results,
      match,
      opta,
      competitions,
      imageBaseUrl,
      panelCoverSize,
      categoryMapping,
      livematchData,
      isPremiumUser,
    } = this.props;
    const inProgress =
      get(squad, 'inProgress', false) ||
      get(teamSummary, 'inProgress', false) ||
      get(teamStats, 'inProgress', false);
    const squadResults = get(squad, 'rows', []);
    const teamSummaryResults = get(teamSummary, 'rows', []);
    const filteredLiveData = get(livematchData, 'info', []);
    if (inProgress) {
      return <PageLoading />;
    }
    if (table.length === 0 || fixtures.length === 0 || results.length === 0) {
      return null;
    }

    return (
      <TeamProfileUI
        onSelectChange={this.getTeamStats}
        teams={teams}
        teamID={match.params.teamId || this.state.teamId}
        squad={squadResults}
        teamStats={teamStats}
        teamSummary={teamSummaryResults}
        tableSummary={table}
        fixtures={fixtures}
        results={results}
        opta={opta}
        competitions={competitions}
        assetCategory={assetCategory}
        isPremiumUser={isPremiumUser}
        imageBaseUrl={imageBaseUrl}
        panelCoverSize={panelCoverSize}
        categoryMapping={categoryMapping}
        liveMatchData={filteredLiveData}
      />
    );
  }
}

function mapStateToProps(state, ownProps) {
  const path = ownProps.location.pathname.toLowerCase();
  const navigations = get(state.navigation, 'navigations', []);
  const opta = optaUtils.getOptaConfig(path, navigations);
  const teamId = ownProps.match.params.teamId;
  let squad = [];
  let teamSummaryResults = [];
  let livematchData;
  const { teams } = state;
  if (opta) {
    teamSummaryResults = state.teamSummary.find(
      (summary) =>
        summary.competitionId === opta.competitionId &&
        summary.seasonId === opta.season &&
        summary.teamId === teamId,
    );
    const competition = getSaagCompetition(opta.competitionId);
    livematchData = state.saagdata.find(
      (saageItem) => saageItem.sport === competition,
    );
  }
  squad = state.squad.find((squad) => squad.teamId === teamId);
  const allTeams = get(state, 'teams.allTeams', []);
  const teamCompetitions = allTeams.find((team) => team.optaId === teamId);
  const filteredCompetitions = teamCompetitions
    ? teamCompetitions.competitions.map((team) => {
        const compTeamOptaId = get(team, 'teamOptaId');

        return compTeamOptaId && compTeamOptaId === teamId ? team : null;
      })
    : [];
  const deDupeUniqCompetitions = uniqBy(
    compact(filteredCompetitions),
    (team) => team && team.optaId,
  );

  const imageBaseUrl =
    get(state, 'systemConfig.image.baseUrl', null) ||
    defaultConfig.image.baseUrl;
  const panelCoverSize =
    get(state, 'systemConfig.image.sizes.panelCoverSize', null) ||
    defaultConfig.image.sizes.panelCoverSize;
  const categoryMapping = get(state, 'systemConfig.categoryMapping', null);
  const isUserPremium = isPremiumUser(state);

  return {
    systemConfig: state.systemConfig,
    navigation: state.navigation,
    opta: opta,
    squad,
    teamStats: state.teamStats,
    teamSummary: teamSummaryResults,
    table: state.tables,
    fixtures: state.fixtures,
    results: state.results,
    teams,
    competitions: deDupeUniqCompetitions,
    assetCategory: state.assetCategory,
    isPremiumUser: isUserPremium,
    imageBaseUrl: imageBaseUrl,
    panelCoverSize: panelCoverSize,
    categoryMapping: categoryMapping,
    livematchData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    squadActions: bindActionCreators(squadActions, dispatch),
    teamStatsActions: bindActionCreators(teamStatsActions, dispatch),
    teamSummaryActions: bindActionCreators(teamSummaryActions, dispatch),
    assetActions: bindActionCreators(assetActions, dispatch),
    fixturesActions: bindActionCreators(fixturesActions, dispatch),
    resultsActions: bindActionCreators(resultsActions, dispatch),
    tableActions: bindActionCreators(tableActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamProfile);
