import _ from 'lodash';
import defaultConfig from '../constants/defaultConfig';
import isNumeric from '../utils/isNumeric';

const assetsFlag = _.get(defaultConfig.apiFlags, 'assets', false);

function checkIncludes(categoryId, str) {
  return _.includes(_.toLower(categoryId), str);
}

export function replaceCategoryId(categoryId) {
  if (checkIncludes(categoryId, 'premier-league')) {
    return 'epl';
  } else if (checkIncludes(categoryId, 'womens-world-cup')) {
    return 'womens-world-cup-2023';
  } else if (
    checkIncludes(categoryId, 'international-') ||
    checkIncludes(categoryId, 'uefa-nations-league')
  ) {
    return 'internationals';
  } else if (checkIncludes(categoryId, 'europa-league')) {
    // BLOCKED AS VALUE NOT RETURNED BY NAVIGATION API
    // return 'uefa-europa-league';
  } else if (
    checkIncludes(categoryId, 'champions-league') ||
    checkIncludes(categoryId, 'uefa-super-cup') ||
    checkIncludes(categoryId, 'uefa-champions-league')
  ) {
    // BLOCKED AS VALUE NOT RETURNED BY NAVIGATION API
    // return 'uefa-champions-league';
  } else if (checkIncludes(categoryId, 'fa-wsl')) {
    return 'womens';
  } else if (checkIncludes(categoryId, 'fifa-club-world-cup')) {
    // BLOCKED AS VALUE NOT RETURNED BY NAVIGATION API
    // return 'fifa-club-world-cup';
  } else if (
    checkIncludes(categoryId, 'j-league') ||
    checkIncludes(categoryId, 'jleague')
  ) {
    return 'j-league';
  } else if (checkIncludes(categoryId, 'uefa-euro-2020')) {
    // BLOCKED AS VALUE NOT RETURNED BY NAVIGATION API
    // return 'uefa-euro-2020';
  } else if (checkIncludes(categoryId, 'k-league')) {
    return 'k-league';
  } else if (
    checkIncludes(categoryId, 'optus-sport-fit') ||
    checkIncludes(categoryId, 'os-fitness') ||
    checkIncludes(categoryId, 'fitness')
  ) {
    return 'fitness';
    // } else if (checkIncludes(categoryId, 'copa-america')) {
    //   return 'copa-america-2024';
  } else if (
    checkIncludes(categoryId, 'dfb') ||
    checkIncludes(categoryId, 'dfb-pokal')
  ) {
    return 'dfb-pokal';
    // } else if (
    //   checkIncludes(categoryId, 'euc') ||
    //   checkIncludes(categoryId, 'uefa-euro-2024')
    // ) {
    //   return 'uefa-euro-2024';
  } else if (checkIncludes(categoryId, 'national-womens-soccer-league')) {
    return 'nwsl';
  } else if (checkIncludes(categoryId, 'fa-cup')) {
    return 'fa-cup';
  }
  return null;
}

export const categoryUrlMapping = (categoryId) => {
  if (
    checkIncludes(categoryId, 'premier-league') ||
    checkIncludes(categoryId, 'epl')
  ) {
    return 'premier-league';
  } else if (
    checkIncludes(categoryId, 'jleague') ||
    checkIncludes(categoryId, 'j-league')
  ) {
    return 'j-league';
  } else if (checkIncludes(categoryId, 'fa-wsl')) {
    return 'womens';
  } else if (
    checkIncludes(categoryId, 'optus-sport-fit') ||
    checkIncludes(categoryId, 'os-fitness') ||
    checkIncludes(categoryId, 'fitness')
  ) {
    return 'fitness';
  } else if (
    checkIncludes(categoryId, 'dfb') ||
    checkIncludes(categoryId, 'dfb-pokal')
  ) {
    return 'dfb-pokal';
  } else if (
    checkIncludes(categoryId, 'womens-world-cup') ||
    checkIncludes(categoryId, 'wwc') ||
    checkIncludes(categoryId, 'fwwc')
  ) {
    return 'womens-world-cup-2023';
  } else if (checkIncludes(categoryId, 'k-league')) {
    return 'k-league';
    // } else if (checkIncludes(categoryId, 'copa-america')) {
    //   return 'copa-america-2024';
  } else if (checkIncludes(categoryId, 'national-womens-soccer-league')) {
    return 'nwsl';
  } else if (checkIncludes(categoryId, 'fa-cup')) {
    return 'fa-cup';
  }
  return categoryId;
};

export function getPlaybackPath(asset, categoryMapping) {
  if (typeof asset !== 'object' || !Array.isArray(categoryMapping)) {
    return null;
  }

  const assetId = _.get(asset, 'id', null);
  const assetSlug = _.get(asset, 'slug');

  let categoryId = _.get(asset, 'categoryId', null);
  const mapping = categoryMapping.find((item) => item.id === categoryId);
  let sectionPath = _.get(mapping, 'path', 'epl');

  if (assetsFlag) {
    if (_.isEmpty(categoryId) || !isNumeric(categoryId)) {
      sectionPath = _.get(asset, 'categorySectionPath');
      sectionPath =
        sectionPath === null ? replaceCategoryId(categoryId) : sectionPath;
    }
  }

  return assetSlug
    ? `/${sectionPath ? `${sectionPath}/` : ''}videos/${assetId}/${assetSlug}`
    : `/${sectionPath}/play/${assetId}`;
}

export const getSearchPlaybackPath = ({
  assetId,
  assetSlug,
  categorySectionPath,
  categoryId,
}) => {
  const sectionPath = categorySectionPath || replaceCategoryId(categoryId);
  if (assetId) {
    const searchPlaybackPath = assetSlug
      ? `/videos/${assetId}/${assetSlug}`
      : `/play/${assetId}`;

    return sectionPath
      ? `/${sectionPath}${searchPlaybackPath}`
      : searchPlaybackPath;
  }
  return sectionPath ? sectionPath : `/`;
};

export function getSectionPath(currentPath) {
  const paths = currentPath.split('/');

  // current path is in format as /epl/fixture/ ...
  if (!paths || paths.length < 1 || !paths[1]) {
    //wrong currentPath or in home page which is '/' no opta metadata
    return null;
  }

  return paths[1].toLowerCase();
}

export const getBaseUrl = () => {
  return _.get(defaultConfig, 'currentBaseUrl', 'https://sport.optus.com.au');
};

export const getBaseHostname = () => {
  try {
    const baseUrl = getBaseUrl();
    const parsedURL = new URL(baseUrl);
    return parsedURL.hostname;
  } catch {
    return 'sport.optus.com.au';
  }
};

export function getCurrentUrl(pathname) {
  const windowLocation = window.location;
  return (
    getBaseUrl() +
    (pathname || _.get(windowLocation, 'pathname')) +
    _.get(windowLocation, 'search')
  );
}

export const getPathFromUrl = (urlStr) => {
  try {
    var url = new URL(urlStr);
    return url.pathname;
  } catch {
    return '';
  }
};

export const getAbsoluteStaticUrl = (url) =>
  (url && `${process.env.PUBLIC_URL}${url}`) || '';

export const getAbsoluteStaticPrimaryPPUrl = (url) => {
  const currentUrl = getAbsoluteStaticUrl(url);
  return currentUrl.includes('sport-ott') || !process.env.PUBLIC_URL
    ? `https://static.sport-pp.optus.com.au${url}`
    : currentUrl;
};

export const getMobileDeviceQueryString = (assetPath) =>
  window?.location?.search?.includes('setDevice=mobile')
    ? assetPath.match(/[?]/g)
      ? '&setDevice=mobile'
      : '?setDevice=mobile'
    : '';

export const getPlaceholderTeamUrl = () =>
  getAbsoluteStaticUrl('/images/default-team-logo.png');
