import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function teamStats(state = initialState.teamStats, action) {
  let currentTeamStats;

  switch (action.type) {

    case types.GET_TEAMSTATS_INPROGRESS:
      currentTeamStats = {
        ...state.find(teamStats => teamStats.teamId === action.results.teamId  ||  teamStats.competitionId === action.results.competitionId || teamStats.season === action.results.season),
        ...action.results,
        inProgress: true,
        isSuccess: false
      };

      return [
        ...state.filter(teamStats => teamStats.teamId === action.results.teamId  || teamStats.competitionId === action.results.competitionId || teamStats.season === action.results.season),
        currentTeamStats
      ];

    case types.GET_TEAMSTATS_SUCCESS:

    
      return [
        ...state.filter(teamStats =>  teamStats.teamId !== action.results.teamId  || action.results.competitionId !== teamStats.competitionId  || teamStats.season !== action.results.season),
        {
          ...action.results,
          inProgress: false,
          isSuccess: true
        }
      ];

    case types.GET_TEAMSTATS_FAILURE:
      currentTeamStats = {
        // for failure case, use whatever has been loaded before
        ...state.find(teamStats => teamStats.teamId === action.results.teamId  || teamStats.competitionId === action.results.competitionId || teamStats.season === action.results.season),
        ...action.results,
        inProgress: false,
        isSuccess: false
      };

      return [
        ...state.filter(teamStats => teamStats.teamId === action.results.teamId  || teamStats.competitionId === action.results.competitionId || teamStats.season === action.results.season),
        currentTeamStats
      ];

    default:
      return state;
  }
}
