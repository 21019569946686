import React from 'react';
import PropTypes from 'prop-types';

import { selectors, useSelector } from 'store/createPortalStore';

const defaultAbsolutePosition = 'os-right-8 os-bottom-10';

function FreemiumBadge({
  compact = true,
  absolute = true,
  style,
  className,
  defaultPosition = true,
}) {
  const userStrictType = useSelector(selectors.user.userStrictType);
  if (userStrictType === 'premium') return null;
  return (
    <div
      style={style}
      className={`h-20 inline-flex items-center text-black bg-green rounded-xl shadow-sm ${
        absolute ? 'absolute' : ''
      } ${compact ? 'px-14' : 'px-8'} ${
        defaultPosition ? defaultAbsolutePosition : ''
      } ${className}`}
    >
      <span className='leading-base text-xs font-MarkProBold uppercase'>
        {compact ? 'Free' : 'Free to watch'}
      </span>
    </div>
  );
}

FreemiumBadge.propTypes = {
  compact: PropTypes.bool,
  absolute: PropTypes.bool,
  stye: PropTypes.object,
  className: PropTypes.string,
};

FreemiumBadge.defaultProps = {
  compact: true,
  absolute: true,
  style: {},
  className: '',
};

export default FreemiumBadge;
