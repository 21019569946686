import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { getPlaceholderTeamUrl } from 'utils/path';

const renderTeam = (team,teamShortCode, reverse) => (
  <div>
    <span className={`leading-loose hidden xl:block break-words text-xs text-white max-w-32 xl:max-w-full truncate ${reverse ? 'mr-10 md:mr-7 text-right' : 'ml-10 md:ml-7'}`}>{_.trim(team)}</span>
    <span className={`leading-loose xl:hidden break-words text-xs text-white max-w-32 xl:max-w-full truncate ${reverse ? 'mr-10 md:mr-7 text-right' : 'ml-10 md:ml-7'}`}>{teamShortCode}</span>
  </div>
);

const renderLogo = imgUrl => <img alt='' className='w-24 h-24 object-contain' src={imgUrl || getPlaceholderTeamUrl()} />;

const FixtureTeam = ({imgUrl = '', team,teamShortCode, className, reverse}) => (
  <div className={className}>
    <div className={`flex flex-row items-center ${reverse ? 'justify-end' : ''}`}>
      {
        !reverse ? renderLogo(imgUrl) : renderTeam(team,teamShortCode, reverse)
      }
      {
        !reverse ? renderTeam(team,teamShortCode, reverse) : renderLogo(imgUrl)
      }
    </div>
  </div>
);

FixtureTeam.propTypes = {
  /** URL to the team icon to be used. */
  imgUrl: PropTypes.string.isRequired,
  /** Team Name. */
  team: PropTypes.string.isRequired,
  /** Classes to apply to the root element of this component. Classes added here will override any default classes or props that accept classes. For example width - use with caution. */
  className: PropTypes.string,
  /** Whether the logo & team name is displayed in reverse. */
  reverse: PropTypes.bool,
  /** Team shortCode. */
  teamShortCode: PropTypes.string.isRequired,
};

FixtureTeam.defaultProps = {
  className: null,
  reverse: false
};

export default FixtureTeam;
