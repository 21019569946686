import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { InputItem } from './styles';

const InputField = (props) => {
  const {
    maxWidth,
    fullWidth,
    variant,
    styles = {},
    onChange: onChangeProp,
    align = '',
    maxLength,
    placeholder = '',
    verticalPush = 0,
    verticalPull = 0,
    isError = false,
    disabled = false,
    defaultValue = '',
    ...rest
  } = props;
  const [value, setValue] = useState('');

  const onChange = (e) => {
    const { value } = e.target;
    setValue(value);
    onChangeProp(value);
  };

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
      onChangeProp(defaultValue);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <InputItem
      isError={isError}
      maxLength={maxLength}
      value={value}
      variant={variant}
      fullWidth={fullWidth}
      onChange={onChange}
      verticalPull={verticalPull}
      verticalPush={verticalPush}
      placeholder={placeholder}
      disabled={disabled}
      style={styles}
      {...{ maxWidth, align }}
      {...rest}
    />
  );
};

InputField.propTypes = {
  /**
   * Justifies the content of the button
   */
  align: PropTypes.oneOf(['left', 'center', 'right']),
  /**
   * Max width to display label content for. The exciding content will be truncated with ellipsis.
   */
  maxWidth: PropTypes.string,
  /**
   * On Click of tail button
   */
  onClick: PropTypes.func,
  /**
   * Stretch `Block` to fill the parent size
   */
  fullWidth: PropTypes.bool,
  /**
   * Placeholder `Block` to fill the parent size
   */
  placeholder: PropTypes.string,
  /**
   * Style object on input field
   */
  styles: PropTypes.object,
  /**
   * Margin bottom
   */
  verticalPull: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Margin bottom
   */
  verticalPush: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Style variant
   */
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
};

InputField.defaultProps = {
  fullWidth: true,
  align: 'left',
  trail: 'Apply',
  maxLength: 10,
  styles: {},
  label: '',
  verticalPush: 0,
  variant: 'primary',
  placeholder: '',
};

export default InputField;
