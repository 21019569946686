import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function highlights(state = initialState.highlights, action) {
  let currentHighlights;

  switch (action.type) {

    case types.GET_HIGHLIGHTS_INPROGRESS:
      currentHighlights = {
        ...state.find(highlights => highlights.competitionId === action.results.competitionId && highlights.season === action.results.season && highlights.matchId === action.results.matchId),
        ...action.results,
        inProgress: true,
        isSuccess: false
      };

      return [
        ...state.filter(highlights => highlights.competitionId !== action.results.competitionId || highlights.season !== action.results.season),
        currentHighlights
      ];

    case types.GET_HIGHLIGHTS_SUCCESS:

      return [
        ...state.filter(highlights => highlights.competitionId !== action.results.competitionId || highlights.season !== action.results.season),
        {
          ...action.results,
          inProgress: false,
          isSuccess: true
        }
      ];

    case types.GET_HIGHLIGHTS_FAILURE:
      currentHighlights = {
        // for failure case, use whatever has been loaded before
        ...state.find(highlights => highlights.competitionId === action.results.competitionId && highlights.season === action.results.season && highlights.matchId === action.results.matchId),
        ...action.results,
        inProgress: false,
        isSuccess: false
      };

      return [
        ...state.filter(highlights => highlights.competitionId !== action.results.competitionId || highlights.season !== action.results.season),
        currentHighlights
      ];

    default:
      return state;
  }
}
