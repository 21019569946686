import { ANALYTIC_NEEDLES } from '../analytic/config';

// Make lowercase on needles array
const LowerCaseNeedles = ANALYTIC_NEEDLES.map((needle) => {
  return needle.toLocaleLowerCase();
});

const replaceNeedlesInHaystack = (needles, replaceData) => (haystack) => {
  function replaceNeedle(haystackObject) {
    for (const key of Object.keys(haystackObject)) {
      const value = haystackObject[key];
      const keyLowercase = key.toLocaleLowerCase();
      if (LowerCaseNeedles.find((e) => e === keyLowercase)) {
        haystackObject[key] = replaceData;
      } else if (value && typeof value === 'object') {
        replaceNeedle(value);
      }
    }
    return haystackObject;
  }
  return replaceNeedle(haystack);
};

export { replaceNeedlesInHaystack };
