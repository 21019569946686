import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import FooterMegaFooter from './FooterMegaFooter';

const getYear = () => {
  const year = moment().format('YYYY');
  return year;
};

const renderLinks = (links) => {
  return links.map((link, key) => {
    const props = {
      target: `_blank`,
      className: `text-white leading-1.14 text-dark-grey text-sm no-underline mr-8 lg:mr-24`,
      key,
    };
    return React.cloneElement(link, props);
  });
};

const Footer = ({
  websiteName,
  copyRight,
  maxWidth,
  links,
  children,
  className,
  hideMegaFooter,
  hideDownloadApps,
  newHome,
  ...props
}) => {
  let footerBottomLinks = (
    <div
      className={`min-h-64 bg-black flex flex-no-wrap flex-col px-16 sm:px-32 lg:px-80 sm:flex-row items-center sm:justify-between w-full ${className ? ` ${className}` : ''}`}
      {...props}
    >
      <div
        className={`
      flex
      flex-col
      sm:flex-row
      flex-no-wrap
      justify-center
      items-center
      sm:justify-between
    `}
      >
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://optus.com.au/"
          className="text-white leading-1.14 font-MarkProHeavy text-sm ml-8 mt-16 sm:mt-0 sm:ml-0 no-underline os-transition-btn hover:text-teal-dark active:text-teal-light"
        >
          {websiteName}
        </a>
        <div className="flex flex-no-wrap sm:ml-32 mr-8 lg:mr-14 mt-16 sm:mt-0">
          {renderLinks(links)}
        </div>
      </div>
      <p
        className={`text-sm leading-1.14 text-dark-grey text-center no-underline mt-24 sm:mt-0 sm:text-left mt-16 mb-48 sm:mb-0 lg:inline-block'
          }`}
      >{`© ${getYear()} ${copyRight}`}</p>
    </div>
  );

  // TODO: delete this later once signup changes are fully stable
  // if (newHome) {
  //   footerBottomLinks = (
  //     <div
  //       className={`min-h-64 bg-black flex flex-no-wrap flex-col sm:flex-row items-center sm:justify-between w-full`}>
  //       {footerBottomLinks}
  //     </div>
  //   );
  // }
  return (
    <footer
      className={`w-full bg-black block xl:flex xl:flex-wrap xl:flex-column justify-center ${className}`}
    >
      {!hideMegaFooter && (
        <FooterMegaFooter
          hideDownloadApps={false}
          newHome={newHome}
        />
      )}
      {footerBottomLinks}
    </footer>
  );
};

Footer.propTypes = {
  /** Copyright text to show in the footer.**/
  copyRight: PropTypes.string,
  /** Text to show at the location of website.**/
  websiteName: PropTypes.string,
  /** maxWidth supports a Tailwind width class passed on as string.**/
  maxWidth: PropTypes.string,
  /** Links to add on to the footer links area.**/
  links: PropTypes.node,
  /** Hide/Show the FooterMegaFooter **/
  hideMegaFooter: PropTypes.bool,
  /** Hide/Show the Download the Apps section on tablet/mobile devices **/
  hideDownloadApps: PropTypes.bool,
};

Footer.defaultProps = {
  websiteName: 'optus.com.au',
  copyRight: 'Singtel Optus Pty Limited',
  maxWidth: 'max-w-lg',
  links: [],
  hideMegaFooter: false,
  hideDownloadApps: true,
  className: '',
};

export default Footer;
