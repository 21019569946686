import { useSelector } from 'react-redux';

const useContentCard = (assetId, isDynamic) => {
  const { isReady, data: contentCardsData } = useSelector((state) =>
    isDynamic ? state?.contentCardsUnfiltered : state?.contentCards,
  );
  if (!isReady || !assetId) {
    return null;
  }

  const contentCard = contentCardsData?.find(
    (contentCard) => contentCard?.id === assetId,
  );

  return contentCard;
};

export default useContentCard;
