import React from 'react';
import PropTypes from 'prop-types';

const NotificationIcon = ({ width, height, color, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={`0 0 32 32`} {...props}>
    <g fill={color} fillRule="evenodd">
        <path fillOpacity="0" d="M0 0h32v32H0z"/>
        <path d="M16 27a3 3 0 0 1-3-3h6a3 3 0 0 1-3 3zm6.97-12.481c0 2.78 1.084 5.456 3.03 7.481H6a10.793 10.793 0 0 0 3.03-7.481v-1.695C9.03 9.07 12.167 6 16 6c3.833 0 6.97 3.07 6.97 6.824v1.695z"/>
    </g>
</svg>
);

NotificationIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

NotificationIcon.defaultProps = {
  color: 'currentColor',
	width: 32,
	height: 32
};

export default NotificationIcon;
