import _ from 'lodash';
import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function assetCategory(state, action) {
  state = typeof state === 'undefined' ? initialState.assetCategory : state;
  let stateChangedObj = {};

  switch (action.type) {
    case types.GET_ASSET_CATEGORY_PENDING: {
      return _.assign(
        {},
        state,
        initialState.assetCategory,
        {
          loading: true
        }
      );
    }
    case types.GET_ASSET_CATEGORY_SUCCESS: {
      let assetData = action.assetData || {};

      if (!_.isEmpty(assetData)) {
        stateChangedObj.data = assetData;
        stateChangedObj.loading = false;
      }

      return _.merge(
        {},
        state,
        stateChangedObj,
        {
          'readyData': true
        }
      );
    }
    case types.GET_ASSET_CATEGORY_FAILURE: {
      return _.assign(
        {},
        state,
        initialState.assetCategory.data,
        {
          'readyData': true,
          loading: false
        }
      );
    }
    case types.GET_ASSET_PLAY_SUCCESS: {
      let assetPlayData = action.assetPlayData || {};

      if (!_.isEmpty(assetPlayData)) {
        stateChangedObj.play = assetPlayData;
      }

      return _.merge(
        {},
        state,
        stateChangedObj,
        {
          'loadPlayInProgress': false,
          'readyPlay': true
        }
      );
    }
    case types.GET_ASSET_PLAY_FAILURE: {
      let playFailureData = action.playFailureData || {};

      if (!_.isEmpty(playFailureData)) {
        stateChangedObj.play = playFailureData;
      }

      return _.merge(
        {},
        state,
        stateChangedObj,
        {
          'loadPlayInProgress': false,
          'readyPlay': true
        }
      );
    }
    case types.GET_ASSET_PLAY_HISTORY_SUCCESS: {
      let assetPlayHistoryData = action.assetPlayHistoryData || {};

      if (!_.isEmpty(assetPlayHistoryData)) {
        stateChangedObj.playHistory = assetPlayHistoryData;
      }

      return _.assign(
        {},
        state,
        stateChangedObj,
        {
          'readyPlayHistory': true
        }
      );
    }
    case types.GET_ASSET_PLAY_HISTORY_FAILURE: {
      stateChangedObj.playHistory = initialState.asset.playHistory;

      return _.assign(
        {},
        state,
        stateChangedObj,
        {
          'readyPlayHistory': true
        }
      );
    }
    case types.GET_ASSET_EPG_SUCCESS: {
      let assetEpgData = action.assetEpgData || {};

      if (!_.isEmpty(assetEpgData)) {
        stateChangedObj.epg = assetEpgData;
      }

      return _.assign(
        {},
        state,
        stateChangedObj,
        {
          'readyEpg': true
        }
      );
    }
    case types.GET_ASSET_EPG_FAILURE: {
      // Don't override if epg already exists
      const existingEpg = _.get(state, ['epg', 'DVB-EPG']);
      if (!_.isEmpty(existingEpg)) {
        return state;
      }

      stateChangedObj.epg = initialState.assetCategory.epg;

      return _.assign(
        {},
        state,
        stateChangedObj,
        {
          'readyEpg': true
        }
      );
    }
    case types.UNSET_ASSET_PLAY: {
      stateChangedObj.play = initialState.assetCategory.play;
      stateChangedObj.readyPlay = initialState.assetCategory.readyPlay;

      return _.assign(
        {},
        state,
        stateChangedObj
      );
    }
    case types.SET_ASSET_PLAY_INPROGRESS: {
      return _.merge(
        {},
        state,
        stateChangedObj,
        {
          'loadPlayInProgress': true,
        }
      );
    }
    case types.UNSET_ASSET_PLAY_INPROGRESS: {
      return _.merge(
        {},
        state,
        stateChangedObj,
        {
          'loadPlayInProgress': false,
        }
      );
    }
    case types.UNSET_ASSET: {
      return initialState.assetCategory;
    }
    default: {
      return state;
    }
  }
}

