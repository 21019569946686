import React from 'react';
import { connect } from 'react-redux';
import LoadingIcon from '../ui/ui-kit/Icons/LoadingIcon';

const defaultConfig = {}
const SystemConfigContext = React.createContext(defaultConfig);

const Loading = () => (
  <div className="h-screen">
    <div className="h-full flex flex-col justify-center items-center">
      <LoadingIcon />
    </div>
  </div>
);

class ContextProvider extends React.Component {
  render() {
    const { systemConfig } = this.props;
    const { inProgress } = systemConfig;
    if (inProgress) {
      return <Loading />;
    }
    return (
      <SystemConfigContext.Provider value={systemConfig}>
        {this.props.children}
      </SystemConfigContext.Provider>
    )
  }
}

/**
 * withSystemConfig
 * Provides the Component with the `systemConfig`.
 * This component can be anywhere in the render tree
 * under `SystemConfigContextProvider` as the root.
 *
 * @param {*} Component
 */
const withSystemConfig = Component => props => (
  <SystemConfigContext.Consumer>
    {(systemConfig) => <Component {...props} systemConfig={systemConfig} />}
  </SystemConfigContext.Consumer>
);

const mapStateToProps = ({ systemConfig }) => ({
  systemConfig,
})

export const SystemConfigContextProvider = connect(mapStateToProps)(ContextProvider);

export default withSystemConfig;
