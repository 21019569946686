import React from 'react';
import PropTypes from 'prop-types';

const LossIcon = ({ width, height, viewBox, ...props }) => (
<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox} {...props}>
    <g fill="none" fillRule="evenodd">
        <circle cx="10" cy="10" r="10" fill="#FF126D"/>
        <path fill="#172659" d="M13 12.509V14H7V6h1.643v6.509z"/>
    </g>
</svg>
);

LossIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  viewBox: PropTypes.string
};

LossIcon.defaultProps = {
    width: 16,
    height: 16,
    viewBox: '0 0 20 20'
};

export default LossIcon;



