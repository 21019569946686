import React from 'react';
import PropTypes from 'prop-types';

const PenaltyIcon = ({ width, height, viewBox, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox} {...props}>
    <g fill="none" fillRule="evenodd">
        <g fill="#FFF">
            <path d="M4.94 12.632a5.733 5.733 0 0 1-.865-.708 6.06 6.06 0 0 1-.441-.496l1.216.5.232.095.162.191.85 1.001a5.46 5.46 0 0 1-1.154-.583zM2.491 8.684L3.623 6.85l1.989.646.015.046.73 2.245.015.045-1.228 1.69-1.995-.82a5.5 5.5 0 0 1-.658-2.018zm.154-2.152a5.473 5.473 0 0 1 .753-1.637l-.102 1.336-.019.25-.132.214-.692 1.123c.014-.44.079-.87.192-1.286zm1.293-2.314a5.561 5.561 0 0 1 1.715-1.251l2.092.508v2.093l-.039.028-1.91 1.388-.056.041-1.967-.639.165-2.168zm3.807-1.762a5.424 5.424 0 0 1 .487 0c.442.017.874.086 1.291.204l-1.291.314-.243.06-.244-.06-1.278-.31a5.565 5.565 0 0 1 1.278-.208zm2.593.508a5.56 5.56 0 0 1 1.586 1.111l.139.144.163 2.153-1.988.646-.047-.034-1.909-1.388-.05-.035V3.475l2.106-.51zm2.863 3.091a5.56 5.56 0 0 1 .345 1.748l-.692-1.122-.132-.214-.019-.25-.1-1.32c.246.363.446.751.598 1.158zm-4.153 7.396a5.606 5.606 0 0 1-2.117-.004l-1.392-1.639 1.227-1.689h2.282v-.5H6.822l-.725-2.234 1.898-1.379 1.899 1.38-.232.714h.524l.186-.572.015-.045 1.99-.647.889 1.442c.259.088.487.213.695.36.015-.135.029-.27.034-.408a5.982 5.982 0 0 0-1.753-4.473 5.995 5.995 0 0 0-2.183-1.394 6.016 6.016 0 0 0-4.628.213 5.98 5.98 0 0 0-1.674 1.181c-.048.048-.094.097-.14.146a5.988 5.988 0 0 0-1.568 4.868 5.973 5.973 0 0 0 1.708 3.471 5.963 5.963 0 0 0 3.125 1.653c.369.07.744.105 1.118.105.351 0 .701-.038 1.048-.099v-.45z"/>
            <path d="M4.94 12.632a5.733 5.733 0 0 1-.865-.708 6.06 6.06 0 0 1-.441-.496l1.216.5.232.095.162.191.85 1.001a5.46 5.46 0 0 1-1.154-.583zM2.491 8.684L3.623 6.85l1.989.646.015.046.73 2.245.015.045-1.228 1.69-1.995-.82a5.5 5.5 0 0 1-.658-2.018zm.154-2.152a5.473 5.473 0 0 1 .753-1.637l-.102 1.336-.019.25-.132.214-.692 1.123c.014-.44.079-.87.192-1.286zm1.293-2.314a5.561 5.561 0 0 1 1.715-1.251l2.092.508v2.093l-.039.028-1.91 1.388-.056.041-1.967-.639.165-2.168zm3.807-1.762a5.424 5.424 0 0 1 .487 0c.442.017.874.086 1.291.204l-1.291.314-.243.06-.244-.06-1.278-.31a5.565 5.565 0 0 1 1.278-.208zm2.593.508a5.56 5.56 0 0 1 1.586 1.111l.139.144.163 2.153-1.988.646-.047-.034-1.909-1.388-.05-.035V3.475l2.106-.51zm2.863 3.091a5.56 5.56 0 0 1 .345 1.748l-.692-1.122-.132-.214-.019-.25-.1-1.32c.246.363.446.751.598 1.158zm-4.153 7.396a5.606 5.606 0 0 1-2.117-.004l-1.392-1.639 1.227-1.689h2.282v-.5H6.822l-.725-2.234 1.898-1.379 1.899 1.38-.232.714h.524l.186-.572.015-.045 1.99-.647.889 1.442c.259.088.487.213.695.36.015-.135.029-.27.034-.408a5.982 5.982 0 0 0-1.753-4.473 5.995 5.995 0 0 0-2.183-1.394 6.016 6.016 0 0 0-4.628.213 5.98 5.98 0 0 0-1.674 1.181c-.048.048-.094.097-.14.146a5.988 5.988 0 0 0-1.568 4.868 5.973 5.973 0 0 0 1.708 3.471 5.963 5.963 0 0 0 3.125 1.653c.369.07.744.105 1.118.105.351 0 .701-.038 1.048-.099v-.45z"/>
        </g>
        <text fill="#00FF91" fontFamily="MarkPro-Heavy, Mark Pro" fontSize="7" fontWeight="600" letterSpacing=".56">
            <tspan x="10" y="15">P</tspan>
        </text>
    </g>
</svg>
);

PenaltyIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  viewBox: PropTypes.string
};

PenaltyIcon.defaultProps = {
  width: 32,
  height: 32,
  viewBox: '0 0 32 32'
};

export default PenaltyIcon;



