import { toUpper } from 'lodash';

const getErrorObj = (errorCode = '', statusCode, playbackStatus = '') => {
  let errorObj = {};

  switch (toUpper(errorCode)) {
    case 'PLAYBACK_UAV':
      errorObj = {
        message: 'Playback API error | 400 unsupported app version error',
        errorRefCode: 'server_err',
      };
      break;
    case 'ASSET_NOT_FOUND':
      errorObj = {
        message: 'Playback API error | 404 content not available error',
        errorRefCode: 'server_err',
      };
      break;
    case 'UNAUTHORIZED':
      errorObj = {
        message: 'Playback API error | 401 unauthorized',
        errorRefCode: 'server_err',
      };
      break;
    case 'DEVICE_LIMIT_EXCEEDED':
      errorObj = {
        message: 'Playback API error | 401 device limit exceed error',
        errorRefCode: 'exceed_device_allowance_err',
      };
      break;
    case 'PLAYBACK_DIM':
      errorObj = {
        message: 'Playback API error | 401 device is missing error',
        errorRefCode: 'server_err',
      };
      break;
    case '':
    case null:
    case undefined:
      if (playbackStatus) {
        errorObj = getErrorObjForPlaybackStatus(playbackStatus);
      } else {
        errorObj = getErrorObjFromStatus(statusCode, errorCode);
      }
      break;
    default:
      errorObj = {
        message: `Playback API error | ${statusCode} ${errorCode}`,
        errorRefCode: 'server_err',
      };
  }

  return errorObj;
};

const getErrorObjFromStatus = (statusCode, errorCode) => {
  let errorObj = {};

  switch (`${statusCode}`) {
    case '403':
      errorObj = {
        message: 'Playback API error | 403 geo filter error',
        errorRefCode: 'server_err',
      };
      break;
    default:
      errorObj = {
        message: `Playback API error | ${statusCode} ${errorCode}`,
        errorRefCode: 'server_err',
      };
  }

  return errorObj;
};

const getErrorObjForPlaybackStatus = (playbackStatus) => {
  let errorObj = {};

  switch (playbackStatus) {
    case 'NOT_STARTED':
      errorObj = {
        message: 'Playback API error | 200 live stream not ready',
        errorRefCode: 'playback_error_LiveNotStartedError',
      };
      break;
    default:
      errorObj = {
        message: `Playback API error | 200 ${playbackStatus}`,
        errorRefCode: 'server_err',
      };
  }

  return errorObj;
};

export default getErrorObj;
