import React from 'react';
import PropTypes from 'prop-types';

const UpwardsIcon = ({ width, height, viewBox, ...props }) => (
  <svg className="upwards-icon" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox} {...props}>
    <g fill="none" fillRule="evenodd">
        <path d="M0 0h10v8H0z"/>
        <path fill="#00FF91" d="M5 1l5 6H0z"/>
    </g>
  </svg>
);

UpwardsIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  viewBox: PropTypes.string
};

UpwardsIcon.defaultProps = {
  width: 10,
  height: 6,
  viewBox: '0 0 10 6'
};

export default UpwardsIcon;



