import { useEffect, useState } from 'react';
import { selectors, useSelector } from '../../../store/createPortalStore';
// import defaultConfig from '../../../constants/defaultConfig';
import { getDevice } from '../../../utils/userAgent';

export default function useConnectWithSearch() {
  // const { awsSportsDataBaseUrl, platform } = defaultConfig;
  const { deviceId } = getDevice();

  const { user: userSelector } = selectors;
  const { loggedIn } = useSelector(userSelector.user);

  const [state, setState] = useState({
    // platform,
    deviceId,
    loggedIn,
    // awsSportsDataBaseUrl,
  });

  useEffect( () => {
    setState((prevState) => ({
      ...prevState,
      loggedIn,
    }));
  }, [loggedIn]);

  return state;
}
