import React from 'react';
import PropTypes from 'prop-types';

const CloseIcon = ({ width, height, color, viewBox, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox} {...props}>
  <path fill={color} fillRule="evenodd" d="M7 6.13L12.222.908l.87.87L7.87 7l5.222 5.222-.87.87L7 7.87l-5.222 5.222-.87-.87L6.13 7 .908 1.778l.87-.87L7 6.13z"/>
  </svg>
  );

CloseIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  viewBox: PropTypes.string
};

CloseIcon.defaultProps = {
	width: 16,
	height: 16,
  color: '#FFF',
  viewBox: '0 0 16 16'
};

export default CloseIcon;
