import React from 'react';
import InputLabel from '../InputLabel';
import InputField from '../InputField';
import PropTypes from 'prop-types';
import {
  Container,
  InputContainer,
  InputFooter,
  InputTail,
  InputLoader,
} from './styles';
import LoadingIcon from '../../common/Loader';
const InputGroup = (props) => {
  const {
    label = '',
    tail = '',
    verticalPush = 0,
    message = null,
    direction = '',
    disabled,
    isError,
    onSubmit = () => {},
    onChange = () => {},
    isLoading = false,
    ...rest
  } = props;

  return (
    <Container direction={direction}>
      {label && <InputLabel>{label}</InputLabel>}
      <InputContainer verticalPush={verticalPush}>
        <InputField
          isError={isError}
          disabled={disabled}
          onChange={onChange}
          {...rest}
        />
        {tail && (
          <InputTail onClick={onSubmit} verticalPush={verticalPush}>
            {tail}
          </InputTail>
        )}
        {isLoading && (
          <InputLoader>
            <LoadingIcon />
          </InputLoader>
        )}
      </InputContainer>
      {message && <InputFooter>{message}</InputFooter>}
    </Container>
  );
};

InputGroup.propTypes = {
  /**
   * Label above the input field
   */
  label: PropTypes.string,
  /**
   * Trailing content at the end of input
   */
  tail: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * Direction the content will flow
   */
  direction: PropTypes.oneOf(['row', 'column']),
  /**
   * Margin Top
   */
  verticalPush: PropTypes.number,
  /**
   * Message dispayed under input field
   */
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * Method to submit an action
   */
  onSubmit: PropTypes.func,
  /**
   * Boolean to determine if field is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Boolean to determine if field should show an error state
   */
  isError: PropTypes.bool,
};

InputGroup.defaultProps = {
  fullWidth: true,
  align: 'left',
  tail: '',
  maxLength: 10,
  label: '',
  direction: 'column',
  verticalPush: 16,
  disabled: false,
  isError: false,
  message: '',
};

export default InputGroup;
