import React from 'react';
const IconPath = (props) => {
  const { fill = '#fff', ...rest } = props;
  return (
    <svg
      width={rest.width}
      height={rest.height}
      viewBox={`0 0 32 32`}
      {...rest}
    >
      <g fill={fill} fillRule="evenodd">
        <path fill="none" d="M0 0h32v32H0z" />
        <path d="M16 22.75c-1.719 0-3.5-.516-5.344-1.547-1.312-.781-2.578-1.781-3.797-3-.687-.687-1.218-1.265-1.593-1.734L4.89 16l.375-.469c.375-.469.906-1.047 1.593-1.734 1.22-1.219 2.485-2.219 3.797-3 1.844-1.031 3.617-1.547 5.32-1.547 1.704 0 3.477.516 5.32 1.547 1.313.781 2.579 1.781 3.798 3 .687.687 1.218 1.265 1.593 1.734l.375.469-.375.469c-.375.469-.906 1.047-1.593 1.734-1.219 1.219-2.485 2.219-3.797 3-1.844 1.031-3.61 1.547-5.297 1.547zM6.812 16a21.6 21.6 0 0 0 3.47 3.14c2 1.407 3.898 2.11 5.695 2.11 1.796 0 3.695-.703 5.695-2.11A21.6 21.6 0 0 0 25.14 16a21.6 21.6 0 0 0-3.47-3.14c-2-1.407-3.898-2.11-5.694-2.11-1.797 0-3.696.703-5.696 2.11A21.6 21.6 0 0 0 6.812 16zm6.516.094c0-.75.258-1.375.774-1.875.515-.5 1.14-.75 1.875-.75.734 0 1.359.25 1.875.75.515.5.765 1.117.75 1.851a2.681 2.681 0 0 1-.774 1.875c-.5.516-1.117.774-1.851.774-.735 0-1.36-.258-1.875-.774-.516-.515-.758-1.132-.727-1.851h-.047z" />
      </g>
    </svg>
  );
};

export default IconPath;
