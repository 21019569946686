import React, { useState, useEffect } from 'react';
import Icon from '../index';
import Input from '../../InputField';
import Toggle from '../../Toggle';

import { Container, Group, Item, Title } from './styles';

const listItems = [
  {
    name: 'TRASH',
    size: 32,
    viewBox: `0 0 32 32`,
  },
  {
    name: 'CLOSE',
    size: 32,
    viewBox: `0 0 32 32`,
  },
  {
    name: 'INFO_ICON',
    size: 32,
    viewBox: `0 0 32 32`,
  },
  {
    name: 'LOCKED',
    size: 32,
    fill: 'black',
    viewBox: `0 0 32 32`,
  },
  {
    name: 'VISIBILITY',
    size: 32,
    fill: 'black',
    viewBox: `0 0 32 32`,
  },
  {
    name: 'SEARCH',
    size: 32,
    fill: 'black',
    viewBox: `0 0 32 32`,
  },
  {
    name: 'BACK',
    size: 32,
    fill: 'black',
    stroke: 'black',
    viewBox: `0 0 32 32`,
  },
  {
    name: 'SETTINGS',
    size: 32,
    fill: 'black',
    viewBox: `0 0 32 32`,
  },
  {
    name: 'SUCCESS',
    size: 32,
    fill: 'black',
    viewBox: `0 0 32 32`,
  },
  {
    name: 'SEND',
    size: 32,
    stroke: 'black',
    fill: 'black',
    viewBox: `0 0 32 32`,
  },
  {
    name: 'EXPAND',
    size: 32,
    stroke: 'black',
    fill: 'black',
    viewBox: `0 0 32 32`,
  },
];

const generateSize = ({ size }) =>
  ({
    small: 24,
    medium: 32,
    large: 40,
  }[size]);

const IconHelper = () => {
  const [searchValue, setSearchValue] = useState('');
  const [filtered, setFiltered] = useState([]);
  const [size, setSize] = useState('small');

  const list = listItems;

  useEffect(() => {
    if (!list || list.length <= 0) {
      return;
    }
    if (!searchValue || searchValue.length <= 0) {
      setFiltered(list);
      return;
    }
    if (list && searchValue && searchValue.length > 0) {
      const filteredArray = list.filter((str) => {
        const foundKey = str?.name?.toLowerCase() || str?.toLowerCase();
        return foundKey?.indexOf(searchValue.toLowerCase()) >= 0;
      });
      setFiltered(filteredArray);
    }

    // eslint-disable-next-line
  }, [searchValue]);

  const onChange = (value) => {
    setSearchValue(value);
  };

  if (!list || list.length <= 0) {
    return null;
  }

  return (
    <Container>
      <Toggle
        callback={setSize}
        label="Size"
        max={3}
        items={['small', 'medium', 'large']}
      />
      <br />
      <Input
        icon={<Icon name="SEARCH" size={20} />}
        placeholder="search"
        variant="tertiary"
        height={36}
        value={searchValue}
        onChange={onChange}
      />
      <Group>
        {filtered.map((item, index) => (
          <Item key={index}>
            <Title alt={index} title={item}>
              {item?.name || item}
            </Title>
            <Icon
              key={index}
              name={item?.name || item}
              fill={item?.fill || 'black'}
              stroke={item?.stroke || 'none'}
              viewBox={item?.viewBox || `0 0 32 32`}
              size={generateSize({ size })}
            />
          </Item>
        ))}
      </Group>
    </Container>
  );
};

export default IconHelper;
