import { incomplete } from '../ducks/stepper';

const SIGNUP_STAGE = {
  ENTER_EMAIL: `signup/STAGE_ENTER_EMAIL`,
  VERIFY_MOBILE: `signup/STAGE_VERIFY_MOBILE`,
  CREATE_ACCOUNT: `signup/STAGE_CREATE_ACCOUNT`,
};

const getSignupSequence = (getFeatureFlags) => {
  const FEATURE_PHONE_CODE_VERIFICATION = getFeatureFlags(
    'FEATURE_PHONE_CODE_VERIFICATION',
  );
  return {
    LOGGED_OUT: [
      SIGNUP_STAGE.ENTER_EMAIL,
      FEATURE_PHONE_CODE_VERIFICATION ? SIGNUP_STAGE.VERIFY_MOBILE : undefined,
      SIGNUP_STAGE.CREATE_ACCOUNT,
    ].filter(Boolean),
  };
};

const getSignupStages = (getFeatureFlags) => {
  const FEATURE_PHONE_CODE_VERIFICATION = getFeatureFlags(
    'FEATURE_PHONE_CODE_VERIFICATION',
  );
  return {
    [SIGNUP_STAGE.ENTER_EMAIL]: incomplete(),
    ...(FEATURE_PHONE_CODE_VERIFICATION && {
      [SIGNUP_STAGE.VERIFY_MOBILE]: incomplete(),
    }),
    [SIGNUP_STAGE.CREATE_ACCOUNT]: incomplete(),
  };
};

const initialState = {};

const signupReducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    default:
      return state;
  }
};

export { SIGNUP_STAGE, getSignupSequence, getSignupStages };

export default signupReducer;
