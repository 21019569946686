import React, { useState } from 'react';

import _ from 'lodash';
import { TimeString } from '../../../ui/new-home/layouts/asset-card/TimeString';
import moment from 'moment';
import Countdown from 'react-countdown-now';

export default function DetailSection(props) {
  const {
    name,
    title,
    categoryTitle,
    startDate,
    liveBroadcastTime,
    match,
    assetTypeName,
    isArticle,
    live: assetLive,
    assetFilter,
  } = props;
  const matchTime =
    assetTypeName !== 'linear-channel' &&
    !isArticle &&
    _.get(match, 'kickOffTime', liveBroadcastTime);
  const timeString =
    assetTypeName !== 'linear-channel' && (matchTime || startDate);

  const eventDate = moment.utc(liveBroadcastTime).local();
  const localTime = moment();
  const dateMomentFormat = 'ddd D MMM';
  const [isLive, setIsLive] = useState(
    !isArticle && assetLive && !localTime.isBefore(eventDate),
  );

  return (
    <div className='w-full inline-block flex flex-col self-center'>
      <div className='inline-block mb-4 md:mb-12'>
        {/* category display for non mobile */}
        <div className='hidden md:inline-block text-light-grey font-MarkProBold text-xs leading-loose md:text-sm md:leading-1.14 align-text-bottom max-w-70%'>
          <span className='text-ellipsis-1'>{categoryTitle}</span>
        </div>
        {/* filter display for mobile */}
        <div className='inline-block md:hidden text-light-grey font-MarkProBold text-xs leading-loose md:text-sm md:leading-1.14 align-text-bottom max-w-50%'>
          <span className='text-ellipsis-1'>{assetFilter}</span>
        </div>
        {isLive ? null : assetLive && localTime.isBefore(eventDate) ? (
          <>
            <span className='font-MarkPro inline-block text-light-grey text-2xs leading-1.6 md:text-xs md:leading-loose'>
              &nbsp;•&nbsp;
            </span>
            <span className='countdown-wrap font-MarkPro inline-block text-light-grey text-2xs leading-1.6 md:text-xs md:leading-loose'>
              <Countdown
                date={eventDate.format('llll')}
                onComplete={() => setIsLive(true)}
                renderer={({ days, hours, minutes, seconds, completed }) => {
                  if (!completed && days < 1 && hours < 12) {
                    return (
                      <>
                        <span className='countdown-label-text'>
                          Coverage in{' '}
                        </span>
                        <span className='os-tabular-nums'>
                          {hours}:{minutes}:{seconds}
                        </span>
                      </>
                    );
                  }

                  return (
                    <>
                      {moment
                        .utc(matchTime)
                        .local()
                        .format(
                          `${dateMomentFormat} ${
                            moment(matchTime).isSame(localTime, 'year')
                              ? ''
                              : 'YYYY'
                          } h:mm A`,
                        )}
                    </>
                  );
                }}
              />
            </span>
          </>
        ) : matchTime ? (
          <>
            <span className='font-MarkPro inline-block text-light-grey text-2xs leading-1.6 md:text-xs md:leading-loose'>
              &nbsp;•&nbsp;
            </span>
            <span className='font-MarkPro inline-block text-light-grey text-2xs leading-1.6 md:text-xs md:leading-loose'>
              {moment
                .utc(matchTime)
                .local()
                .format(
                  `${dateMomentFormat} ${
                    moment(matchTime).isSame(localTime, 'year') ? '' : 'YYYY'
                  } h:mm A`,
                )}
            </span>
          </>
        ) : (
          timeString && (
            <>
              <span className='font-MarkPro inline-block text-light-grey text-2xs leading-1.6 md:text-xs md:leading-loose'>
                &nbsp;•&nbsp;
              </span>
              <TimeString
                timeString={timeString}
                thresholdValue={7}
                thresholdUnit='days'
                linearChannel={name === 'linear-channel'}
                className='font-MarkPro inline-block text-light-grey text-2xs leading-1.6 md:text-xs md:leading-loose'
              />
            </>
          )
        )}
      </div>
      <div className='font-MarkProBold leading-24px text-ellipsis-2'>
        {title}
      </div>
    </div>
  );
}
