import * as types from '../constants/actionTypes';
import initialState from './initialState';

export const bannerAssetInitialState = {
  data: {},
  inProgress: true,
  isSuccess: false,
};

export default function bannerAsset(state, action) {
  state = typeof state === 'undefined' ? initialState.bannerAsset : state;

  switch (action.type) {
    case types.GET_BANNER_ASSET: {
      const { assetId } = action;
      return {
        ...state,
        ...{
          [assetId]: bannerAssetInitialState,
        },
      };
    }
    case types.GET_BANNER_ASSET_SUCCESS: {
      const { asset } = action;
      const { id: assetId } = asset;
      return {
        ...state,
        ...{
          [assetId]: {
            data: asset,
            inProgress: false,
            isSuccess: true,
          },
        },
      };
    }
    case types.GET_BANNER_ASSET_FAILURE: {
      const { assetId } = action;
      return {
        ...state,
        ...{
          [assetId]: {
            data: {},
            inProgress: false,
            isSuccess: false,
          },
        },
      };
    }
    default: {
      return state;
    }
  }
}
