import React from 'react';
import { useDispatch } from 'react-redux';

import { useSearchContext } from '../SearchProvider';
import { removeStoreSearchHistory } from '../SearchService';
import { analyticSearchRequested, SEARCH_SOURCES } from '../__analytics__';
import { SearchHistoryBtnClear } from './styled';

import CloseIcon from '../../ui/ui-kit/Icons/CloseIcon';

export default function SearchHistory({
  handleSearch = () => {},
  className = '',
}) {
  const dispatch = useDispatch();
  const {
    state: { history: { data } = {} } = {},
    dispatch: dispatchSearch,
  } = useSearchContext();

  const handleRemoveHistory = (index) => {
    dispatchSearch({
      type: 'SET_HISTORY_DATA',
      data: {
        data: removeStoreSearchHistory(index),
      },
    });
  };

  const onClickHistoryItem = (value) => {
    analyticSearchRequested(dispatch, {
      query: value,
      searchSource: SEARCH_SOURCES.RECENT,
    });
    handleSearch(value);
  };

  const hasHistory = !!data && data.length;

  if (!hasHistory) {
    return (
      <div
        className={`p-40 mx-auto text-dark-grey text-md text-center bg-widget-grey ${className}`}
      >
        <p className="text-lg mb-16 font-MarkProBold">No recent searches</p>
        <p>Your recent searches will appear here</p>
      </div>
    );
  }

  return (
    <div className={`px-16 pt-24 pb-8 bg-widget-grey ${className}`}>
      <div className="p-0 justify-between flex mb-8 text-white font-MarkProBold">
        Recent results
      </div>
      <div className="p-0 overflow-hidden">
        {data.map((value, i) => (
          <div
            key={`${value}-${i}`}
            className={
              'relative border-medium-dark-grey ' +
              (i === 0 ? ' border-t' : 'border-t')
            }
          >
            <button
              type="button"
              onClick={() => onClickHistoryItem(value)}
              className="text-sm text-left py-12 px-16 w-full text-white hover:bg-medium-dark-grey"
            >
              {value}
            </button>
            <SearchHistoryBtnClear
              type="button"
              onClick={() => handleRemoveHistory(i)}
              className="p-0 absolute"
            >
              <CloseIcon />
            </SearchHistoryBtnClear>
          </div>
        ))}
      </div>
    </div>
  );
}
