import React from "react";
import TeamTitle from "./TeamTitle";
import StatsBadge from "./StatsBadge";
import TeamBG from '../TeamsListings/TeamBG'


const TeamBanner = ({ logo, title, teamColor }) => (
  
  <div className="h-176 md:h-304 xl:h-352 w-full flex flex-col items-center justify-center">
  <TeamBG teamColor={`#${teamColor}`}/>
    <div className="w-full absolute">
      <div className="flex flex-col items-center justify-center">
      <StatsBadge className="mb-12 md:mb-8" logoUrl={logo} />
      <TeamTitle text={title} />
      </div>
    </div>
  </div>
);

TeamBanner.defaultProps = {
  logo: "https://2.bp.blogspot.com/-z2MwRDijAqI/V6_eNsu3f-I/AAAAAAAAKQE/pOodVn4xLvIsUw2lNkDF1smWgxwUJ_QPgCLcB/s1600/Arsenal-icon.png",
  title: "Manchester United",
  teamColor: "#EF0107"
};


export default TeamBanner;
