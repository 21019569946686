const getVendorLocation = ({ config = {}, location = '' }) => {
  if (!config || !location) {
    return null;
  }

  const getLocation = () => {
    const paths = Object.keys(config);
    if (paths?.includes(location)) {
      return location;
    }
    return null;
  };
  return getLocation();
};

const getVendorDetail = ({ config = {}, location = '' }) => {
  const getDetail = () => {
    if (!config || !location) {
      return null;
    }
    const paths = Object.keys(config);
    if (paths?.includes(location)) {
      return config[location];
    }
    return null;
  };
  return getDetail();
};

export { getVendorLocation, getVendorDetail };
