import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function navigation(state = initialState.navigation, action) {
  let newState = {};

  switch (action.type) {
    case types.GET_NAVIGATION_INPROGRESS:
      newState = {
        ...state,
        inProgress: true,
        isSuccess: false
      };
      return newState;

    case types.GET_NAVIGATION_SUCCESS:
      newState = {
        ...action.navigation,
        inProgress: false,
        isSuccess: false
      };
      return newState;

    case types.GET_NAVIGATION_FAILURE:
      newState = {
        ...action.navigation,
        inProgress: false,
        isSuccess: false
      };
      return newState;

    default:
      return state;
  }
}
