import React from 'react';

const NewHomeContainer = (props) => {
  const { panelMarginTop, footerPadding, footerwidth, ...rest } = props;
  return (
    <div
      className={`
      flex
      justify-center
      w-full
      ${panelMarginTop}
      ${footerPadding ? footerPadding : 'px-16 sm:px-32'}
    `}
    >
      <div
        className={`
          new-home
          flex
          flex-col
          w-full
          pb-16
          ${footerwidth}
      `}
        {...rest}
      >
        {rest.children}
      </div>
    </div>
  );
};

export default NewHomeContainer;
