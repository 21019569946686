import React from 'react';
import styled from 'styled-components';

import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const StyledLink = styled.a`
  color: inherit;
`;

function Link({ action, onClick, children }) {
  const history = useHistory();

  const linkAttrs = {
    onClick: () => {},
  };

  linkAttrs.onClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    onClick && onClick(history);
  };

  if (action?.href) {
    linkAttrs.href = action.href; // provide href to link so the user can open in new tab or copy url
  }

  if (action) {
    return (
      <StyledLink {...linkAttrs} className='cursor-pointer no-underline'>
        {children}
      </StyledLink>
    );
  }
  return <>{children}</>;
}

export default Link;
