import React from 'react';
import Modal from 'components/ui/ui-kit/Modal';
import { getImageProps } from '../utils';
import Picture from 'components/ui/Picture';

function PopupImageOverlay({ dispatch, contentCardContext }) {
  const { asset, cardClicked, primaryButtonClicked, secondaryButtonClicked } =
    contentCardContext || {};

  const closeHandler = () => {
    cardClicked && dispatch({ type: 'undoCardClicked' });
    primaryButtonClicked && dispatch({ type: 'undoPrimaryButtonClicked' });
    secondaryButtonClicked && dispatch({ type: 'undoSecondaryButtonClicked' });
  };

  const { images: { overlay } = {}, title = '' } = asset || {};

  const { imgSrc, srcSet } = getImageProps(overlay || asset); // using asset images as fallback

  return (
    <Modal open={true} onClose={closeHandler}>
      <div>
        <Picture
          src={imgSrc}
          srcSet={srcSet}
          className='w-full max-w-full h-full object-contain'
          alt={title}
        />
        {/* <picture>
          <img
            src={image}
            alt={title}
            className='w-full max-w-full h-full object-contain'
          />
        </picture> */}
      </div>
    </Modal>
  );
}

export default PopupImageOverlay;
