import { dispatchAnalytic } from 'ducks/analytic';
import keyToID from 'utils/keyToID';

export const pathnameToEventMapper = ({ navigations, pathname }) => {
  let returnEvent = {
    name: '',
    option: {},
  };

  if (!pathname) {
    return returnEvent;
  }

  const firstPath = pathname.split('/')[1];
  const secondPath = pathname.split('/')[2] || '';

  switch (pathname) {
    case '/':
      returnEvent = {
        name: 'Home',
        option: {
          type: 'football',
        },
      };
      break;
    case '/fitness':
      returnEvent = {
        name: 'Home',
        option: {
          type: 'fitness',
        },
      };
      break;
    case '/category/home_linear_channels':
      returnEvent = {
        name: 'Live Channel',
      };
      break;
    case '/helpsupport':
      returnEvent = {
        name: 'Support',
      };
      break;
    case '/help':
      returnEvent = {
        name: 'Support New Issue',
      };
      break;
    case '/usersettings':
      returnEvent = {
        name: 'Manage Subscription',
      };
      break;
    case '/usersettings/devices':
      returnEvent = {
        name: 'Device Management',
      };
      break;
    default:
  }
  if (returnEvent.name === '') {
    const navCompetition = navigations.find(
      (navItem) => navItem.opta && navItem.path === firstPath,
    );

    if (navCompetition && secondPath === '')
      returnEvent = {
        name: 'Competition',
        option: {
          comp_name: navCompetition.title,
        },
      };
  }
  if (returnEvent.name === '' && firstPath === 'fitness') {
    const navFitness = navigations.find((navItem) => navItem.path === firstPath)
      ?.navigations;
    const foundFitnessNavItem =
      navFitness && navFitness.find((navItem) => navItem.path === secondPath);

    if (foundFitnessNavItem) {
      returnEvent = {
        name: 'Competition',
        option: {
          comp_name: foundFitnessNavItem.title,
        },
      };
    }
  }
  if (returnEvent.name === '' && firstPath === 'news') {
    const navNews = navigations.find((navItem) => navItem.path === firstPath)
      ?.navigations;
    if (secondPath === '') {
      returnEvent = {
        name: 'Competition',
        option: {
          comp_name: 'News & Articles',
        },
      };
    } else {
      const foundNewsNavItem =
        navNews && navNews.find((navItem) => navItem.path === secondPath);

      if (foundNewsNavItem) {
        returnEvent = {
          name: 'Competition',
          option: {
            comp_name: `${foundNewsNavItem.title} News`,
          },
        };
      }
    }
  }
  if (returnEvent.name === '' && firstPath === 'search') {
    returnEvent = {
      name: 'Search',
    };
  }
  return returnEvent;
};

function getAppMangerKey(navigations) {
  let [, firstPath, secondPath] = window?.location?.pathname?.split('/');
  if (firstPath === '' && !secondPath) {
    firstPath = 'home';
  } else if (firstPath === CategoryKey || secondPath === CategoryKey) {
    return CategoryKey;
  }
  let appManagerKey = '';
  /* eslint-disable-next-line no-unused-expressions */
  navigations?.forEach((firstNav) => {
    if (firstNav.path === firstPath) {
      if (!secondPath) {
        appManagerKey = firstNav.appManagerKey;
      } else {
        /* eslint-disable-next-line no-unused-expressions */
        firstNav.navigations?.forEach((secondNav) => {
          if (secondNav.path === secondPath)
            appManagerKey = secondNav.appManagerKey;
        });
      }
    }
  });

  return appManagerKey;
}

export const assetClickedEventDispatcher = ({
  pages,
  navigations,
  asset,
  editorialId,
  editorials,
}) => {
  const editorial = getEditorialById(editorials, editorialId);

  const pageRailEventData = getPageRailEventData({
    pages,
    editorial,
    asset,
    navigations,
    type: 'card',
  });

  const assetEventData = getAssetEventData({ asset, editorial });

  dispatchAnalytic({
    name: 'Asset Clicked',
    option: { ...assetEventData, ...pageRailEventData },
  });
};

export const getAssetEventData = ({ asset, editorial }) => {
  const {
    broadcastEndTime,
    startDate,
    updatedAt,
    source,
    title,
    live,
    isInTransition,
    id,
    duration,
    liveBroadcastTime,
    type,
    categoryTitle,
  } = asset;

  const { panel: { title: bucket_name } = {}, assets } = editorial || {};
  const asset_position = assets?.findIndex((asset) => asset.id === id) ?? -1;

  const assetEventData = {
    asset: {
      broadcastEndTime,
      broadcastStartTime: liveBroadcastTime,
      duration,
      id,
      isInTransition,
      isLive: live,
      publishStartDate: startDate,
      source,
      title,
      type,
      updatedAt,
    },
    asset_category: categoryTitle,
    asset_id: id,
    asset_name: title,
    asset_position,
    asset_type: type,
    bucket_name,
  };

  return assetEventData;
};

export const CategoryKey = 'category';

export const getPageRailEventData = ({
  pages,
  editorial,
  asset,
  navigations,
  type,
}) => {
  const appManagerKey = getAppMangerKey(navigations);
  if (appManagerKey === CategoryKey) {
    return {
      rail: {
        title: editorial?.title,
        type: 'category',
      },
      page: {
        name: editorial?.title,
      },
    };
  }
  const pageId = keyToID(appManagerKey);
  const currentPage = pages?.find((page) => keyToID(page.id) === pageId);

  if (currentPage === undefined || currentPage === -1) return {};

  const eventRailData = {
    title: '',
    index: -1,
    type: '',
  };

  if (type === 'banner') {
    // banner
    eventRailData.type = type;
    eventRailData.title = asset?.title;
    eventRailData.index = currentPage?.panels?.findIndex(
      (panel) => panel?.assetId === asset.id,
    );
  } else {
    // asset/contentCard in rails
    const {
      id: editorialId,
      panel: { type: panelType, title: panelTitle, assetId: panelAssetId } = {},
    } = editorial || {};

    eventRailData.type = panelType;
    eventRailData.title = panelTitle || asset?.title;
    eventRailData.index = currentPage?.panels?.findIndex((panel) =>
      panelType === 'dynamic_rail'
        ? panel.assetId === panelAssetId
        : keyToID(panel.bucketKey) === editorialId,
    );
  }

  return {
    rail: eventRailData,
    page: {
      name: currentPage.id,
    },
  };
};

export const getEditorialById = (editorials, editorialId) => {
  return (
    editorialId && editorials?.find((editorial) => editorial.id === editorialId)
  );
};
