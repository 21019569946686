import _ from 'lodash';
import { routesList as getRoutesList } from '../constants/routesList';

const addToRouteList = (navigation = {}, routeList = [], routeItem) => {
  const newPaths = _.get(navigation, 'newPaths', []);
  const routeName = _.get(routeItem, 'name', null);
  if (routeName.toLowerCase() === 'videos' && newPaths) {
    // only allow abbreviated competition code for Videos route
    newPaths.forEach((path) => {
      let newRouteItem = { ...routeItem };
      newRouteItem.path = `/${path}${routeItem.path}`;
      routeList.push(newRouteItem);
    });
  }
  let newRouteItem = { ...routeItem };
  newRouteItem.path = `/${navigation.path}${routeItem.path}`;
  routeList.push(newRouteItem);
};

export function getRouteList(navigations, props) {
  let retList = [];

  getRoutesList(props).forEach((item) => {
    if (!item.inSection) {
      // path is not in section
      retList.push(item);
    } else {
      // this path is within a section, add section path prefix based on backend navigation
      if (Array.isArray(navigations)) {
        navigations.forEach((nav) => {
          if (!nav.landingPage) {
            let newItem = { ...item };
            addToRouteList(nav, retList, newItem);
          }
        });
      }
    }
  });

  return retList;
}
