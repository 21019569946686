import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from './Icons/CloseIcon';
import BodyEnd from './BodyEnd';

class Modal extends React.Component {
  closeModal(e, onClose) {
    if (typeof onClose === 'function') onClose(e);
    e.stopPropagation();
  }

  render() {
    const {
      open,
      onClose,
      children,
      dismissOnOutsideClick,
      type,
      className,
      ...props
    } = this.props;
    return !this.props.open ? null : (
      <BodyEnd>
        <div
          className={`fixed w-full h-full max-h-screen pin-l pin-t ${
            type === 'helpsupport' ? 'mx-7 my-8 z--1' : 'z-99999'
          } flex bg-black60 backdrop-blur items-center`}
          onClick={(e) => {
            dismissOnOutsideClick && this.closeModal(e, onClose);
          }}
        >
          <div
            className={`w-full  max-w-1206 ${
              type === 'helpsupport'
                ? 'h-full px-24 md:px-32 flex items-start justify-center '
                : 'p-8'
            } sm:m-auto  bg-darkest-grey shadow-xl ${
              className ? ` ${className}` : ''
            }`}
            {...props}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div
              className={`flex justify-end ${
                type === 'helpsupport' ? 'mr-0 md:mr-0' : ''
              }`}
            >
              <button
                className={`w-32 h-32 flex justify-center items-center text-white focus:outline-none`}
                onClick={(e) => {
                  this.closeModal(e, onClose);
                }}
              >
                <CloseIcon />
              </button>
            </div>
            <div
              style={{ maxHeight: 'calc(100vh - 48px)' }}
              className={` ${
                type === 'helpsupport'
                  ? 'md:h-full w-9/10 md:w-full'
                  : 'p-8 w-full'
              } overflow-auto`}
            >
              {' '}
              {children}
            </div>
          </div>
        </div>
      </BodyEnd>
    );
  }
}

Modal.propTypes = {
  /** Current state of this modal */
  open: PropTypes.bool,
  /** Handler function for the modal close button. This function will be invoked with the click event received on the close button */
  onClose: PropTypes.func,
  /** Whether to close this modal when the user clicks outside of the modal. */
  dismissOnOutsideClick: PropTypes.bool,
  /** Type of the Modal. */
  type: PropTypes.string,
};

Modal.defaultProps = {
  open: false,
  onClose: null,
  dismissOnOutsideClick: true,
  type: 'default',
};

export default Modal;
