import React from 'react';
const IconPath = (props) => {
  const { fill = '#9592A8', stroke = 'none', ...rest } = props;
  return (
    <svg
      width={rest.width}
      height={rest.height}
      viewBox={rest.viewBox || `0 0 32 32`}
    >
      <g fill="none" fillRule="evenodd">
        <path fill={fill} fillOpacity="0" d="M0 0h32v32H0z" />
        <path
          stroke={stroke}
          strokeWidth="1.5"
          d="M18.03 21.275L13 15.638 18.03 10"
        />
      </g>
    </svg>
  );
};

export default IconPath;
