import React, { useMemo } from 'react';

import { useBreakpoint } from '../../../../utils/hooks';
import { chunkArray } from '../../../../utils';
import FilterItem from './FilterItem';

const filterCharacterLimit = 10;

export default function FilterList({ filters, onFilterButtonClick }) {
  const { breakpoint } = useBreakpoint();

  const requiredItems = {
    xxs: 4,
    xs: 6,
    sm: 8,
    md: 8,
  };

  // Check if any of the filters exceeds our character limit,
  // if true we will need to apply larger widths to each breakpoint
  let characterLimitExceeded = false;
  if (filters?.length && Array.isArray(filters)) {
    filters.forEach(filter => {
      const text = filter.text || '';
      if (text?.length >= filterCharacterLimit) {
        characterLimitExceeded = true;

        // Update require items for each breakpoint
        requiredItems.xs = 4;
        requiredItems.sm = 4;
        requiredItems.md = 6;
      }
    })
  }

  const chunckedFilters = useMemo(() => {
    // Create placeholderItems to fill the empty cells
    const placeholderItems = [];
    const placeholderItemsNumber =
      (requiredItems[breakpoint] || 8) - (filters?.length || 0);

    for (let i = 0; i < placeholderItemsNumber; i++) {
      placeholderItems.push({});
    }

    // Make 'filter buttons' group by columns
    return chunkArray([...filters, ...placeholderItems], 2);
  }, [filters, breakpoint, requiredItems]);

  if (!filters?.length || !chunckedFilters?.length) return null;
  return chunckedFilters.map((filterChunk, i) => {
    return (
      <div
        key={i}
        className={`
          inline-block
          flex-none
          py-2
          pr-8
          ${characterLimitExceeded ?
            'w-3/4 xs:w-3/5 sm:w-1/2 md:w-1/3 lg:w-1/4' :
            'w-1/2 xs:w-1/3 sm:w-1/4'
          }
        `}
      >
        {filterChunk.map((item, index) => (
          <FilterItem
            key={`${item.text}-${index}`}
            item={item}
            firstItem={index === 0}
            onFilterButtonClick={onFilterButtonClick}
          />
        ))}
      </div>
    );
  });
}
