import * as types from '../constants/actionTypes';

const setDocumentTitle = (title) => {
  return {
    type: types.SET_DOCUMENT_TITLE,
    title,
  };
};

export { setDocumentTitle };
