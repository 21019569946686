export const statsObject = {
  generalPlay: [
    'Aerial Duels Won (%)',
    'Corners',
    'Duels Won (%)',
    'Interceptions',
    'Offsides',
    'Possessions (%)'
  ],
  defense: [
    'Clearances',
    'Fouls Conceded',
    'Red Cards',
    'Tackles',
    'Tackles Won (%)',
    'Yellow Cards'
  ],
  attack: [
    'Blocked Shots',
    'Goals',
    'Shot Accuracy (Excluding Blocked Shots)',
    'Shots From Inside The Box',
    'Shots From Outside The Box',
    'Shots On Target',
    'Total Shots (Including Blocked Shots)'
  ],
  distribution: [
    '% Of Passes Long',
    'Passing Accuracy (%)',
    'Passing Accuracy Opp. Half (%)',
    'Successful Crosses (%)',
    'Total Crosses',
    'Total Passes'
  ]
};

export const statsObjectV2 = {
  generalPlay: [
    'Possession (%)',
    'Total Shots',
    'Total Passes',
    'Interception',
    'Corners',
    'Offsides',
  ],
  attack: [
    'Shot Accuracy (%)',
    'Total Shots',
    'Shots From Inside The Box',
    'Shots From Outside The Box',
    'Shots On Target',
    'Blocked Shots'
  ],
  defense: [
    'Clearances',
    'Tackles',
    'Tackles Won (%)',
    'Red Cards',
    'Yellow Cards',
    'Fouls Conceded',
  ],
  distribution: [
    'Total Passes',
    'Passing Accuracy (%)',
    'Passing Accuracy Opp. Half (%)',
    'Long Passes(%)',
    'Total Crosses',
    'Successful Crosses (%)',
  ]
};

export const statsTypes = [
  'General Play',
  'Attack',
  'Defence & Discipline',
  'Distribution'
];

export const getStatsObjectKey = statsType => {
  switch (statsType) {
    case 'General Play':
      return 'generalPlay';

    case 'Defence & Discipline':
      return 'defense';

    case 'Distribution':
      return 'distribution';

    case 'Attack':
      return 'attack';

    default:
      return 'generalPlay';
  }
};

export const excludedKeys = key => {
  const excludedValues = [
    'Successful Crosses (%)',
    'Passing Accuracy Opp. Half (%)',
    'Passing Accuracy (%)',
    '% Of Passes Long',
    'Tackles Won (%)'
  ];
  const value = excludedValues.find(item => item === key);
  return value ? true : false;
};

export function showMatch(period) {
  switch (period) {
    case 'PreMatch':
      return true;

    case 'First Half':
      return true;

    case 'Half Time':
      return true;

    case 'Second Half':
      return true;

    case 'Extra Time':
      return true;

    case 'Extra First Half':
      return true;

    case 'Extra Half Time':
      return true;

    case 'Extra Second Half':
      return true;

    case 'Full Time':
      return false;

    case 'FullTime':
      return false;

    case 'Penalty Shootout':
      return true;

    case 'PostMatch':
      return false;

    case 'Abandoned':
      return false;

    case 'Postponed':
      return true;

    default:
      return true;
  }
}

export function showLiveForUpcomingMatch(period) {
  switch ( period ) {
    case 'PreMatch':
      return false;

    case 'First Half':
      return true;

    case 'Half Time':
      return true;

    case 'Second Half':
      return true;

    case 'Extra Time':
      return true;

    case 'Extra First Half':
      return true;

    case 'Extra Half Time':
      return true;

    case 'Extra Second Half':
      return true;

    case 'Full Time':
      return false;

    case 'FullTime':
      return false;

    case 'Penalty Shootout':
      return true;

    case 'PostMatch':
      return false;

    case 'Abandoned':
      return false;

    case 'Postponed':
      return false;

    default:
      return false;
  }
}

export const isLiveMatch = match => {
  return match.IsLive.toLowerCase() === 'y'
    || (![
        'full time',
        'fulltime',
        'postponed',
        'abandoned'
      ].includes(match.Period.toLowerCase())
      && match.Period !== 'PreMatch');
}
