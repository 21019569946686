import React from 'react';
const IconPath = (props) => {
  const { fill = '#00FF91', ...rest } = props;
  return (
    <svg
      width={rest?.width || 32}
      height={rest?.height || 32}
      viewBox={rest?.viewBox || `0 0 32 32`}
      {...props}
    >
      <g fill={fill} fillRule="evenodd">
        <path
          fillRule="nonzero"
          d="M72 139c37.003 0 67-29.997 67-67S109.003 5 72 5 5 34.997 5 72s29.997 67 67 67zm0 5c-39.765 0-72-32.235-72-72S32.235 0 72 0s72 32.235 72 72-32.235 72-72 72z"
        />
        <path d="M62.393 91.163l40.305-40.305 3.536 3.535-40.305 40.305-3.536 3.536-22.627-22.627 3.536-3.536 19.091 19.092z" />
      </g>
    </svg>
  );
};

export default IconPath;
