const legacyUrlMapping = [
  {
    oldPath: '/featured/18068',
    newPath: '/category/home_featured'
  },
  {
    oldPath: '/featured/20136',
    newPath: '/category/home_replays'
  },
  {
    oldPath: '/featured/20342',
    newPath: '/category/home_minimatches'
  },
  {
    oldPath: '/featured/16813',
    newPath: '/category/home_highlights'
  },
  {
    oldPath: '/featured/18066',
    newPath: '/category/home_live_matches'
  },
  {
    oldPath: '/epl/featured/1717',
    newPath: '/epl/category/epl_replays'
  },
  {
    oldPath: '/epl/featured/19675',
    newPath: '/epl/category/epl_minimatches'
  },
  {
    oldPath: '/epl/featured/1715',
    newPath: '/epl/category/epl_highlights'
  },
  {
    oldPath: '/epl/featured/9226',
    newPath: '/epl/category/epl_shorts'
  },
  {
    oldPath: '/epl/featured/1716',
    newPath: '/epl/category/epl_shows'
  },
  {
    oldPath: '/epl/featured/2061',
    newPath: '/epl/category/epl_interviews'
  },
  {
    oldPath: '/epl/featured/2097',
    newPath: '/epl/category/epl_pressconferences'
  },
  {
    oldPath: '/epl/featured/1714',
    newPath: '/epl/category/epl_news'
  },
  {
    oldPath: '/epl/featured/1718',
    newPath: '/epl/category/epl_classics'
  },
  {
    oldPath: '/epl/featured/2118',
    newPath: '/epl/category/epl_legends'
  },
  {
    oldPath: '/epl/featured/1713',
    newPath: '/epl/category/epl_live_matches'
  },
  {
    oldPath: '/uefa-champions-league/featured/19735',
    newPath: '/uefa-champions-league/category/ucl_replays'
  },
  {
    oldPath: '/uefa-champions-league/featured/19733',
    newPath: '/uefa-champions-league/category/ucl_minimatches'
  },
  {
    oldPath: '/uefa-champions-league/featured/19731',
    newPath: '/uefa-champions-league/category/ucl_highlights'
  },
  {
    oldPath: '/uefa-champions-league/featured/19727',
    newPath: '/uefa-champions-league/category/ucl_shorts'
  },
  {
    oldPath: '/uefa-champions-league/featured/19729',
    newPath: '/uefa-champions-league/category/ucl_shows'
  },
  {
    oldPath: '/uefa-champions-league/featured/19714',
    newPath: '/uefa-champions-league/category/ucl_live_matches'
  },
  {
    oldPath: '/uefa-champions-league/featured/19720',
    newPath: '/uefa-champions-league/category/ucl_news'
  },
  {
    oldPath: '/uefa-champions-league/featured/19724',
    newPath: '/uefa-champions-league/category/ucl_interviews'
  },
  {
    oldPath: '/uefa-europa-league/featured/21068',
    newPath: '/uefa-europa-league/category/uel_replays'
  },
  {
    oldPath: '/uefa-europa-league/featured/21138',
    newPath: '/uefa-europa-league/category/uel_live_matches'
  },
  {
    oldPath: '/uefa-europa-league/featured/21069',
    newPath: '/uefa-europa-league/category/uel_minimatches'
  },
  {
    oldPath: '/uefa-europa-league/featured/21071',
    newPath: '/uefa-europa-league/category/uel_highlights'
  },
  {
    oldPath: '/uefa-europa-league/featured/21075',
    newPath: '/uefa-europa-league/category/uel_shorts'
  },
  {
    oldPath: '/uefa-europa-league/featured/21073',
    newPath: '/uefa-europa-league/category/uel_shows'
  },
  {
    oldPath: '/uefa-europa-league/featured/21080',
    newPath: '/uefa-europa-league/category/uel_news'
  },
  {
    oldPath: '/uefa-nations-league/featured/19797',
    newPath: '/internationals/category/unl_replays'
  },
  {
    oldPath: '/uefa-nations-league/featured/19793',
    newPath: '/internationals/category/unl_highlights'
  },
  {
    oldPath: '/uefa-nations-league/featured/19795',
    newPath: '/internationals/category/unl_minimatches'
  },
  {
    oldPath: '/uefa-nations-league/featured/19791',
    newPath: '/internationals/category/unl_shows'
  },
  {
    oldPath: '/uefa-nations-league/featured/19789',
    newPath: '/internationals/category/unl_shorts'
  },
  {
    oldPath: '/uefa-nations-league/featured/19783',
    newPath: '/internationals/category/unl_news'
  },
  {
    oldPath: '/uefa-nations-league/featured/19787',
    newPath: '/internationals/category/unl_interviews'
  },
  {
    oldPath: '/uefa-nations-league',
    newPath: '/internationals'
  },
  {
    oldPath: '/category',
    newPath: '/'
  },
  {
    oldPath: '/epl/play',
    newPath: '/'
  },
  {
    oldPath: '/uefa-champions-league/play',
    newPath: '/'
  },
  {
    oldPath: '/uefa-europa-league/play',
    newPath: '/'
  },
  {
    oldPath: '/uefa-nations-league/play',
    newPath: '/'
  },
  {
    oldPath: '/internationals/play',
    newPath: '/'
  },
  {
    oldPath: '/epl/teamprofile',
    newPath: '/'
  },
  {
    oldPath: '/uefa-champions-league/teamprofile',
    newPath: '/'
  },
  {
    oldPath: '/uefa-europa-league/teamprofile',
    newPath: '/'
  },
  {
    oldPath: '/uefa-nations-league/teamprofile',
    newPath: '/'
  },
  {
    oldPath: '/internationals/teamprofile',
    newPath: '/'
  },
  {
    oldPath: '/epl/category',
    newPath: '/'
  },
  {
    oldPath: '/uefa-champions-league/category',
    newPath: '/'
  },
  {
    oldPath: '/uefa-europa-league/category',
    newPath: '/'
  },
  {
    oldPath: '/uefa-nations-league/category',
    newPath: '/'
  },
  {
    oldPath: '/internationals/category',
    newPath: '/'
  },
];

export default legacyUrlMapping;
