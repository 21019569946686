import React from "react";
import PropTypes from "prop-types";
import BackIcon from "./Icons/BackIcon";
import SubIcon from "./Icons/SubIcon";
import GenericPlayer from "./GenericPlayer";

class MatchLineupSubsRow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {showGenericPlayer: false }
    this.setGenericPlayer = this.setGenericPlayer.bind(this)
  }

  setGenericPlayer() {
    this.setState({ showGenericPlayer: true})
  }
  render() {
    const { playerNumber,
    playerImage,
    playerName,
    backIcon,
    subbed,
    subPlayer,
    subTime,
    className,
    type } = this.props
    return (
      <div
        className={`flex bg-transparent h-48 w-full justify-between items-center ${className}`}
      >
        <div className="h-16 w-20 text-center">
          <span className="text-white text-sm font-MarkProHeavy leading-1.14">
            {playerNumber}
          </span>
        </div>
        <div className={`h-38 w-38 mt-10 min-w-38 ${type === "subs" ? '' : 'ml-3'}`}>
        {this.state.showGenericPlayer ? <GenericPlayer height="38" width="38" /> 
        :
          <img
            onError={this.setGenericPlayer}
            className="h-full"
            src={playerImage}
            alt=""
          />
        }
        </div>
        <div className="w-3/5 flex h-full ml-7 flex-grow flex-col items-start justify-center">
          <span
            className={`text-white text-sm leading-1.14 ${
              subbed ? "pt-8" : ""
            }`}
          >
            {playerName}
          </span>
          {subbed && (
            <div className="flex items-start justify-start">
              <div className="h-16 w-16 mr-4">
                <SubIcon />
              </div>
              <span className="text-white text-sm leading-1.14">
                {`${subTime} ${subPlayer}`}{" "}
              </span>
            </div>
          )}
        </div>
        <div className="invisible">
        {backIcon}
        </div>
      </div>
    );
  }
}

MatchLineupSubsRow.propTypes = {
  /** Kit number of the player */
  playerNumber: PropTypes.string,
  /** Image url of the player */
  playerImage: PropTypes.string,
  /** Display name of the player */
  playerName: PropTypes.string,
  /** click handler icon  */
  backIcon: PropTypes.objectOf(BackIcon),
  /** Whether the player is subbed in */
  subbed: PropTypes.bool,
  /** Icon to be shown to indicate that the player is subbed */
  subIcon: PropTypes.objectOf(SubIcon),
  /** Name of the player to whom the player is subbed in */
  subPlayer: PropTypes.string,
  /** Time at which the player is subbed */
  subTime: PropTypes.string,
  /** Type of the component */
  type: PropTypes.string
};

MatchLineupSubsRow.defaultProps = {
  playerNumber: "",
  playerImage: "",
  playerName: "",
  backIcon: <BackIcon className="text-white rotate-right" />,
  subbed: false,
  subIcon: <SubIcon />,
  subPlayer: "",
  subTime: "",
  className: "",
  type: ""
};

export default MatchLineupSubsRow;
