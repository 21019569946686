import React, { useState, useEffect } from 'react';
import { ToggleContainer, ToggleItem, Label } from './styles';

const Toggle = ({ items = [], label = '', max = 2, callback = () => {} }) => {
  const [isActive, setIsActive] = useState('');

  useEffect(() => {
    if (!items || items.length <= 0) return;
    setIsActive(items?.[0]);
    callback(items?.[0]);
    // eslint-disable-next-line
  }, []);

  const onPress = (item) => {
    setIsActive(item);
    callback(item);
  };

  const getMaxItem = () => {
    return items.reduce((acc, item) => {
      const reachedMax = items.indexOf(item) >= max;
      if (!reachedMax) {
        return [...acc, item];
      } else {
        return acc;
      }
    }, []);
  };

  if (!items || items.length <= 0) return null;
  return (
    <>
      {label && <Label>{label}</Label>}
      <ToggleContainer>
        {getMaxItem().map((item) => (
          <ToggleItem
            key={item}
            isActive={item === isActive}
            onClick={() => onPress(item)}
          >
            {item}
          </ToggleItem>
        ))}
      </ToggleContainer>
    </>
  );
};

export default Toggle;
