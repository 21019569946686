import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import { decodeURIComponentSafe } from '../sanitiseString';

function getCurrentValues(queryString, key) {
  let currentValues = qs.parse(queryString)[key] || [];
  if (!Array.isArray(currentValues)) {
    currentValues = [currentValues];
  }

  return currentValues;
}
function toggleValue(currentValues, selectedKey) {
  const isExistInQueryParams = currentValues.find((key) => key === selectedKey);

  let newValues = currentValues;
  if (isExistInQueryParams) {
    newValues = newValues.filter((key) => key !== selectedKey);
  } else {
    newValues.push(selectedKey);
  }

  return newValues;
}

export default function useQueryStringListState(key, deps = []) {
  if (!key)
    throw Error("[useQueryStringListState] 'key' prop must be provided");

  const history = useHistory();

  const keys = useMemo(() => {
    let values = qs.parse(history.location.search)[key] || [];
    if (!Array.isArray(values) && values) {
      values = [values];
    }

    return values || [];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.search, key, ...deps]);

  const toggleKey = useCallback(
    (selected) => {
      const currentValues = getCurrentValues(history.location.search, key);
      const newValues = toggleValue(currentValues, selected);

      history.replace(
        qs.stringifyUrl({
          url: encodeURI(encodeURI(decodeURIComponentSafe(history.location.pathname))),
          query: {
            ...qs.parse(history.location.search),
            [key]: newValues,
          },
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, key, ...deps],
  );

  const setKeys = useCallback(
    (selectedList) => {
      history.replace(
        qs.stringifyUrl({
          url: encodeURI(encodeURI(decodeURIComponentSafe(history.location.pathname))),
          query: {
            ...qs.parse(history.location.search),
            [key]: selectedList,
          },
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, key, ...deps],
  );

  return [keys, toggleKey, setKeys];
}