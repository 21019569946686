export default {
  sign_up_button: {
    category: 'Fitness-VOD',
    action: 'Click',
    label: 'Sign Up Button',
  },
  log_in_button: {
    category: 'Fitness-VOD',
    action: 'Click',
    label: 'Log in Button',
  },
};
