import React from 'react';

import Link from '../components/Link';
import ActionComponent from '../components/ActionComponent';
import Picture from 'components/ui/Picture';

function ContentCardAUI({ asset, actionHandlers, imgSrc, srcSet }) {
  const { title, description, contentCard } = asset;
  const { badgeLabel, actions: { cardClickAction } = {} } = contentCard || {};

  const { handleCardClick } = actionHandlers;

  return (
    <>
      <ActionComponent />
      <Link action={cardClickAction} onClick={handleCardClick}>
        <div className='font-white text-white no-underline h-full flex flex-col rounded-t-xl'>
          <div
            className={`rounded-xl h-full video-info-box relative bg-dark-grey text-white pt-56-25%`}
          >
            <div
              className={`sub-content md:flex xs:flex-col pt-16 px-24 pb-24`}
            >
              <div
                className={`card-title text-sm md:text-base leading-16px md:leading-20px text-center font-MarkProHeavy mb-8 `}
              >
                {title}
              </div>
              <div className='font-MarkPro text-xs leading-loose text-center mb-16'>
                {description}
              </div>

              <div className='absolute w-full leading-zero os-top-0 os-left-0 h-0 overflow-hidden block rounded-t-xl pb-56-25%'>
                <Picture
                  alt={title}
                  src={imgSrc}
                  srcSet={srcSet}
                  className={'w-full h-auto'}
                />
                {badgeLabel && (
                  <div className='h-20 inline-flex items-center text-black bg-yellow rounded-xl shadow-sm absolute px-14 os-right-8 os-bottom-10 '>
                    <span className='leading-base text-xs font-MarkProBold'>
                      {badgeLabel}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
}

export default ContentCardAUI;
