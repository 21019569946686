
export default function randomNumber(number, start) {
  if (number) {
    const defaultStart = typeof start === 'undefined' ? 0 : start;

    return parseInt(
      (Math.random() * (number - defaultStart) + start),
      10
    );
  }

  return null;
}
