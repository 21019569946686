import React from 'react';
import { Log, Group } from './styles';

const LogList = ({ items = [] }) => {
  const renderTemp = () => {
    const temp = [{ eventName: 'Events' }];
    const renderItems = temp.map((item, index) => (
      <Log className="dashed" key={index}>
        {item?.eventName}
      </Log>
    ));
    return <Group>{renderItems}</Group>;
  };

  const renderLogs = () => {
    if (!items || items.length <= 0) {
      return renderTemp();
    }
    return items.map((item, index) => <Log key={index}>{item}</Log>);
  };
  return <Group>{renderLogs()}</Group>;
};

export default LogList;
