import get from 'lodash/get';
import axios from 'axios';
import defaultConfig from '../constants/defaultConfig';
import * as types from '../constants/actionTypes';

export function getFixtures(competitionId, season) {
  return (dispatch) => {
    const fixtures = { competitionId, season };
    if (!competitionId || !season) {
      dispatch({ type: types.GET_FIXTURES_FAILURE, fixtures });
      return;
    }
    const {
      vcmsBaseUrl,
      VCMSConfig: { fixturesEndpoint },
    } = defaultConfig;
    const url = `${vcmsBaseUrl}${fixturesEndpoint}?season=${season}&competition=${competitionId}`;

    dispatch({ type: types.GET_FIXTURES_INPROGRESS, fixtures });

    axios
      .get(url)
      .then((res) => {
        const isSuccess = get(res, 'data.result[0].statuscode', 'N') === 'Y';

        if (isSuccess) {
          fixtures.rows = get(res, 'data.APIinfo[0].FixtureInfo', []);
          dispatch({ type: types.GET_FIXTURES_SUCCESS, fixtures });
        } else {
          dispatch({ type: types.GET_FIXTURES_FAILURE, fixtures });
        }
      })
      .catch((error) => {
        dispatch({ type: types.GET_FIXTURES_FAILURE, fixtures });
      });
  };
}
