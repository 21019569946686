import React from 'react';
import PropTypes from 'prop-types';

const SubIcon = ({ width, height, viewBox, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox} {...props}>
    <g fill="none" fillRule="evenodd">
        <path fill="#FF126D" d="M11 3.921H3.614l1.221-1.395L3.5 1 0 5l3.5 4 1.335-1.526L3.614 6.08H11z"/>
        <path fill="#00FF91" d="M5 9.921h7.386l-1.221-1.395L12.5 7l3.5 4-3.5 4-1.335-1.526 1.221-1.395H5z"/>
    </g>
  </svg>
);

SubIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  viewBox: PropTypes.string
};

SubIcon.defaultProps = {
  width: 32,
  height: 32,
  viewBox: '0 0 32 32'
};

export default SubIcon;



