import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function stats(state = initialState.stats, action) {
  let currentStats;

  switch (action.type) {

    case types.GET_STATS_INPROGRESS:
      currentStats = {
        ...state.find(stats => stats.competitionId === action.results.competitionId &&  stats.season === action.results.season && stats.matchId === action.results.matchId),
        ...action.results,
        inProgress: true,
        isSuccess: false
      };

      return [
        ...state.filter(stats => stats.competitionId !== action.results.competitionId || stats.season !== action.results.season),
        currentStats
      ];

    case types.GET_STATS_SUCCESS:

      return [
        ...state.filter(stats => stats.competitionId !== action.results.competitionId || stats.season !== action.results.season),
        {
          ...action.results,
          inProgress: false,
          isSuccess: true
        }
      ];

    case types.GET_STATS_FAILURE:
      currentStats = {
        // for failure case, use whatever has been loaded before
        ...state.find(stats => stats.competitionId === action.results.competitionId &&  stats.season === action.results.season && stats.matchId === action.results.matchId),
        ...action.results,
        inProgress: false,
        isSuccess: false
      };

      return [
        ...state.filter(stats => stats.competitionId !== action.results.competitionId || stats.season !== action.results.season),
        currentStats
      ];

    default:
      return state;
  }
}
