import React, { useEffect, useMemo, useCallback } from 'react';

import { useSearchContext } from '../../SearchProvider';
import {
  getFiltersByData,
  getResultByFilter,
  updateSelectedFilters,
} from '../utils';
import { Carousel } from '../../../../common';
import { useQueryStringListState } from '../../../../utils/hooks';
import FilterList from './FilterList';
import { analyticSearchFilterClicked } from '../../__analytics__';
import { useDispatch } from 'react-redux';
import { showAllId } from '../../SearchResults/utils';

export default function Filters({
  setFilteredResult,
  filters,
  setFilters,
  setSelectedFilters,
  className,
}) {
  const dispatch = useDispatch();
  const { state: { search: { data } = {} } = {} } = useSearchContext();

  const allFilters = useMemo(() => getFiltersByData(data) || [], [data]);
  const [qsFilters, toggleQsFilter, setQsFilters] = useQueryStringListState(
    'filter',
  );
  useEffect(() => {
    // If user filtered at least one item, isSelectedAllFilters ? 'All' will be select : null
    if (allFilters?.length > 0 && qsFilters?.length > 0) {
      const isSelectedAllFilters = allFilters.length - 1 <= qsFilters.length;
      if (isSelectedAllFilters) {
        setQsFilters([]);
      }
    }
  }, [allFilters, qsFilters, setQsFilters]);

  useEffect(() => {
    // Update filter 'inner component state' according to 'qsFilters'
    const { newData, filteredData } = updateSelectedFilters(
      allFilters,
      qsFilters,
    );

    setFilters(newData);
    const filteredResult = getResultByFilter(data, filteredData);
    setFilteredResult(filteredResult);
    setSelectedFilters(filteredData);
  }, [data, allFilters, qsFilters, setFilteredResult, setFilters, setSelectedFilters, dispatch]);

  const onFilterButtonClick = useCallback(
    (item) => {
      const { text, id } = item;
      const isPlaceholder = !text;
      if (!isPlaceholder) {
        const filters =
          qsFilters.indexOf(id) !== -1
            ? qsFilters.filter((filter) => filter !== id)
            : qsFilters.concat(id);
        // Update filter 'inner component state' according to 'qsFilters'
        const { filteredData } = updateSelectedFilters(allFilters, filters);
        const filteredResult = getResultByFilter(data, filteredData);

        analyticSearchFilterClicked(dispatch, {
          assets: filteredResult,
          filters,
        });

        if (id === showAllId) {
          setQsFilters([]);
        } else {
          toggleQsFilter(id);
        }
      }
    },
    [toggleQsFilter, setQsFilters, allFilters, data, dispatch, qsFilters],
  );

  if (!Array.isArray(filters) || !filters.length) {
    return null;
  }

  return (
    <Carousel className={className}>
      <FilterList filters={filters} onFilterButtonClick={onFilterButtonClick} />
    </Carousel>
  );
}
