
export function isPlaybackLive(playbackData) {
  return playbackData && playbackData.live;
}

// Deprecated way of determining isLive from asset metadata
// export function isLive({ isInTransition, duration, live }) {
//   return ((isInTransition && duration === 0) || live || duration === 0);
// }

export function getSourceStreamUrl(playerConfig) {
  const { file } = getPlaylistSource(playerConfig);
  return file;
}

export function getPlaylistSource(playerConfig) {
  const { playlist: [playlistItem = {}] = [{}] } = playerConfig;
  const { sources: [sourceItem = {}] = [{}] } = playlistItem;
  return sourceItem;
}

export function setPlaylistSource(url, playerConfig) {
  const { playlist: [playlistItem = {}] = [{}] } = playerConfig;
  const { sources: [sourceItem = {}] = [{}] } = playlistItem;
  sourceItem.file = url;
}
