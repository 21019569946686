import React from "react";
import PropTypes from "prop-types";

const PreviousMeetings = ({ title, items }) => (
  <div className="w-full bg-widget-grey flex flex-col items-center justify-center px-16 md:px-31 xl:px-38 pb-8 pt-16 xl:pb-16">
    <span className="text-white text-base font-MarkProHeavy pb-8">
      {title}
    </span>
    {items}
  </div>
);

PreviousMeetings.propTypes = {
  /** The display title for the previous meetings component */
  title: PropTypes.string
};

export default PreviousMeetings;
