import React from 'react';
import { useDispatch } from 'react-redux';
import { selectors, useSelector } from '../../../store/createPortalStore';
import LogList from '../../../common/LogList';
import { analyticAction } from '../../../ducks/analytic';

import { Heading, Button } from '../../../components/helpers/Helper/styles';

const AnalyticHelper = () => {
  // Hooks
  const dispatch = useDispatch();
  // Selectors
  const { analytic: analyticSelector } = selectors;
  const items = useSelector(analyticSelector.items);
  const showAction = false;
  return (
    <>
      <Heading>{'Analytic Logs'}</Heading>
      {showAction && (
        <Button
          reset
          onClick={() =>
            dispatch(
              analyticAction({
                eventName: 'Login Button Clicked',
                level: 'info',
              }),
            )
          }
        >
          Event
        </Button>
      )}
      <LogList items={items} />
    </>
  );
};

export { AnalyticHelper };
