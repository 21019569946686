import { isArray } from 'lodash';
import { getAbsoluteStaticUrl } from 'utils/path';

const widths = [320, 480, 640, 820, 1280, 1920, 2560];

export const placeholderImg = getAbsoluteStaticUrl(
  '/images/OS_LOGO_640_360_LR.jpg',
);

const getImage = (images = [], width) =>
  images?.find((image) => image.width === width)?.url;

export const getImageUrls = ({ defaultImage, images }) => {
  if (!images) {
    return defaultImage || placeholderImg;
  }
  return widths
    .map((width) => {
      const imageURL = getImage(images, width);
      return imageURL ? `${imageURL} ${width}w` : undefined;
    })
    .join(', ');
};

export const getImageUrl = (defaultImage, width, images) =>
  getImage(images, width) || defaultImage || placeholderImg;

export function getMetaFields(metaFields) {
  const fields = {};
  isArray(metaFields) &&
    metaFields.forEach((field) => {
      const { displayName, value } = field;
      if (displayName && value) fields[displayName] = value;
    });

  return fields;
}
