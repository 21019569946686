import React from 'react';
import moment from 'moment';

import AssetImage, {
  DurationStatus,
} from 'components/ui/new-home/layouts/asset-card/AssetImage';

export default function ImageSection(props) {
  const {
    live: isLive,
    duration,
    liveBroadcastTime,
    className = '',
    assetTypeName,
  } = props;

  const eventDate = moment.utc(liveBroadcastTime).local();
  const localTime = moment();
  return (
    <div className={`inline-block mr-16 w-96 md:w-128 ${className}`}>
      <AssetImage
        {...props}
        asset={props}
        lazyLoaded={true}
        dontShowLabels={true}
        localTime={localTime}
        eventDate={eventDate}
        isLive={isLive}
        heightClassName={'h-64 md:h-76'}
        className={'rounded-lg relative'}
      >
        <DurationStatus
          isLive={isLive}
          duration={duration}
          eventDate={eventDate}
          localTime={localTime}
          disableCountdown={true}
          assetTypeName={assetTypeName}
          style={{
            bottom: 4,
            right: 4,
          }}
          className={'absolute rounded-lg h-24 bg-black80'}
        />
      </AssetImage>
    </div>
  );
}
