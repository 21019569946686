import _ from 'lodash';
import {
  SUBSCRIPTION_VOUCHER_SUCCESS,
  SUBSCRIPTION_VOUCHER_FAILURE,
  SUBSCRIPTION_VOUCHER_INPROGRESS,
  SUBSCRIPTION_USER_CREATE_INPROGRESS,
  SUBSCRIPTION_USER_CREATE_SUCCESS,
  SUBSCRIPTION_USER_CREATE_FAILURE,
  CREATE_USER_BY_VOUCHER_SUCCESS,
  CREATE_USER_BY_VOUCHER_INPROGRESS,
  UPDATE_VOUCHER_SUBMIT_BUTTON,
  CREATE_USER_BY_VOUCHER_FAILURE,
  VALIDATE_COUPON_INPROGRESS,
  VALIDATE_COUPON_FAILURE,
  VALIDATE_COUPON_SUCCESS,
} from '../constants/actionTypes';
import { incomplete } from '../ducks/stepper';
import { getUserDetails } from '../ducks/subscription';

const SAVE_VOUCHER_CODE = `SAVE_VOUCHER_CODE`;

export const VOUCHER_STAGE = {
  ENTER_CODE: `voucher/STAGE_ENTER_CODE`,
  ACCEPT_TERMS: `voucher/STAGE_ACCEPT_TERMS`,
  ENTER_EMAIL: `voucher/STAGE_ENTER_EMAIL`,
  VERIFY_MOBILE: `voucher/STAGE_VERIFY_MOBILE`,
  CREATE_ACCOUNT: `voucher/STAGE_CREATE_ACCOUNT`,
};

export const getVoucherSequence = (getFeatureFlags) => {
  const FEATURE_PHONE_CODE_VERIFICATION = getFeatureFlags(
    'FEATURE_PHONE_CODE_VERIFICATION',
  );
  return {
    LOGGED_OUT: [
      VOUCHER_STAGE.ENTER_CODE,
      VOUCHER_STAGE.ACCEPT_TERMS,
      VOUCHER_STAGE.ENTER_EMAIL,
      FEATURE_PHONE_CODE_VERIFICATION ? VOUCHER_STAGE.VERIFY_MOBILE : undefined,
      VOUCHER_STAGE.CREATE_ACCOUNT,
    ].filter(Boolean),
    LOGGED_IN: [
      VOUCHER_STAGE.ENTER_CODE,
      VOUCHER_STAGE.ACCEPT_TERMS,
      FEATURE_PHONE_CODE_VERIFICATION ? VOUCHER_STAGE.VERIFY_MOBILE : undefined,
    ].filter(Boolean),
  };
};

export const getVoucherStages = (getFeatureFlags) => {
  const FEATURE_PHONE_CODE_VERIFICATION = getFeatureFlags(
    'FEATURE_PHONE_CODE_VERIFICATION',
  );
  return {
    [VOUCHER_STAGE.ENTER_CODE]: incomplete(),
    [VOUCHER_STAGE.ACCEPT_TERMS]: incomplete(),
    [VOUCHER_STAGE.ENTER_EMAIL]: incomplete(),
    ...(FEATURE_PHONE_CODE_VERIFICATION && {
      [VOUCHER_STAGE.VERIFY_MOBILE]: incomplete(),
    }),
    [VOUCHER_STAGE.CREATE_ACCOUNT]: incomplete(),
  };
};

const initialState = {
  voucherData: null,
  // This flag is just a special case due to the current
  // implementation of logging in then immediately applying
  // the voucher.
  createUserByVoucher: false,
};

function isValidVoucher(voucherData) {
  return _.get(voucherData, `eligibility`);
}

export default function voucherReducer(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case VALIDATE_COUPON_INPROGRESS: {
      return {
        ...state,
        error: null,
        voucherData: null,
      };
    }
    case VALIDATE_COUPON_SUCCESS: {
      const { voucherData } = action;
      return {
        ...state,
        error: isValidVoucher(voucherData) ? null : voucherData,
        voucherData,
      };
    }
    case VALIDATE_COUPON_FAILURE: {
      const { voucherData } = action;
      return {
        ...state,
        error: voucherData || { description: type },
        voucherData: null,
      };
    }
    case SUBSCRIPTION_VOUCHER_INPROGRESS: {
      return {
        ...state,
        error: null,
        voucherData: null,
      };
    }
    case SUBSCRIPTION_VOUCHER_SUCCESS: {
      const { voucherData } = action;
      return {
        ...state,
        error: isValidVoucher(voucherData) ? null : voucherData,
        voucherData,
      };
    }
    case SUBSCRIPTION_VOUCHER_FAILURE: {
      const { voucherData } = action;
      return {
        ...state,
        error: voucherData || { description: type },
        voucherData: null,
      };
    }
    case SUBSCRIPTION_USER_CREATE_INPROGRESS: {
      return {
        ...state,
        error: null,
        subscriptionData: null,
        applied: null,
      };
    }
    case SUBSCRIPTION_USER_CREATE_SUCCESS: {
      const { subscriptionData } = action;

      // Perform a user force cache refresh
      action.asyncDispatch(getUserDetails({ forceRefreshCache: true }));

      return {
        ...state,
        error: null,
        subscriptionData,
        applied: true,
      };
    }
    case SUBSCRIPTION_USER_CREATE_FAILURE: {
      return {
        ...state,
        error: { description: type },
        subscriptionData: null,
        applied: false,
      };
    }
    case CREATE_USER_BY_VOUCHER_INPROGRESS: {
      return {
        ...state,
        error: null,
        // This flag is just a special case due to the current
        // implementation of logging in then immediately applying
        // the voucher.
        createUserByVoucher: null,
        // Set `applied` to null for progress purposes
        applied: null,
      };
    }
    case CREATE_USER_BY_VOUCHER_SUCCESS: {
      const { applied: appliedBySubscriptionUserCreate } = state;
      const inProgress = appliedBySubscriptionUserCreate === null;
      const applied = inProgress ? appliedBySubscriptionUserCreate : true;
      return {
        ...state,
        error: null,
        // This flag is just a special case due to the current
        // implementation of logging in then immediately applying
        // the voucher.
        createUserByVoucher: true,
        applied,
      };
    }
    case CREATE_USER_BY_VOUCHER_FAILURE:
    case UPDATE_VOUCHER_SUBMIT_BUTTON: {
      return {
        ...state,
        error: { description: type },
        // This flag is just a special case due to the current
        // implementation of logging in then immediately applying
        // the voucher.
        createUserByVoucher: false,
        applied: false,
      };
    }
    case SAVE_VOUCHER_CODE: {
      const { voucherCode } = action;
      return {
        ...state,
        voucherCode,
        error: null,
      };
    }
    default:
      return state;
  }
}

export function saveVoucherCode(voucherCode) {
  return {
    type: SAVE_VOUCHER_CODE,
    voucherCode,
  };
}
