import React from 'react';
import Link from './ui-kit/Link';
import PageFooter from '../features/PageFooter';
import HeaderNav from './ui-kit/HeaderNav/';
import { getAbsoluteStaticUrl } from 'utils/path';

class UnsupportedBrowser extends React.Component {
  render() {
    const { browserName, browserVersion } = this.props;

    return (
      <div className='h-full'>
        <div className='flex'>
          <HeaderNav simpleHeader={true} />
        </div>
        <div
          className='w-full flex md:justify-center bg-black'
          style={{ minHeight: 'calc(100vh - 6rem)' }}
        >
          <div className='h-full w-full md:w-369 xl:w-793 flex flex-col md:items-center px-14 md:px-0'>
            <div className='h-48 md:h-64 xl:h-96 w-full xl:w-589 text-center mt-32 md:mt-40 xl:mt-144'>
              <span className='text-white text-xl md:text-2xl xl:text-4xl font-MarkProHeavy'>
                For a better experience, update your browser.
              </span>
            </div>

            <div className='text-white text-center text-base font-MarkProBold mt-32 leading-double'>
              {`${browserName} ${browserVersion}`} detected
            </div>

            <div className='w-full xl:w-589 text-center mt-16 md:mt-32'>
              <span className='text-white text-base leading-1.6'>
                It looks like the browser you are using is not supported or is
                out of date. Update your browser or download a supported one
                below.
              </span>
            </div>

            <div className='w-full mt-32'>
              <div className='w-full flex flex-wrap xl:flex-no-wrap'>
                <div className='h-196 w-50pminusHalf xl:w-176'>
                  <div className='w-full h-full flex flex-col items-center'>
                    <div className='w-112 h-112 mt-32'>
                      <img
                        className='h-full w-full'
                        src={getAbsoluteStaticUrl('/images/apple-safari.png')}
                        alt=''
                      />
                    </div>
                    <div className='h-20 w-full text-center mt-32'>
                      <Link
                        type='helpSupport'
                        textSize='text-base'
                        link='https://www.apple.com/in/safari/'
                        target='blank'
                      >
                        Safari
                      </Link>
                    </div>
                  </div>
                </div>
                <div className='h-196 w-50pminusHalf xl:w-176 ml-7 xl:ml-30'>
                  <div className='w-full h-full flex flex-col items-center'>
                    <div className='w-112 h-112 mt-32'>
                      <img
                        className='h-full w-full'
                        src={getAbsoluteStaticUrl(
                          '/images/mozilla-firefox.png',
                        )}
                        alt=''
                      />
                    </div>
                    <div className='h-20 w-full text-center mt-32'>
                      <Link
                        type='helpSupport'
                        textSize='text-base'
                        link='https://www.mozilla.org/en-US/firefox/new/'
                        target='blank'
                      >
                        Mozilla Firefox
                      </Link>
                    </div>
                  </div>
                </div>
                <div className='h-196 w-50pminusHalf xl:w-176 mt-30 xl:mt-0 xl:ml-30'>
                  <div className='w-full h-full flex flex-col items-center'>
                    <div className='w-112 h-112 mt-32'>
                      <img
                        className='h-full w-full'
                        src={getAbsoluteStaticUrl('/images/google-chrome.png')}
                        alt=''
                      />
                    </div>
                    <div className='h-20 w-full text-center mt-32'>
                      <Link
                        type='helpSupport'
                        textSize='text-base'
                        link='https://www.google.com/chrome/'
                        target='blank'
                      >
                        Google Chrome
                      </Link>
                    </div>
                  </div>
                </div>
                <div className='h-196 w-50pminusHalf ml-7 mt-30 xl:mt-0 xl:ml-30 xl:w-176'>
                  <div className='w-full h-full flex flex-col items-center'>
                    <div className='w-112 h-112 mt-32'>
                      <img
                        className='h-full w-full'
                        src={getAbsoluteStaticUrl('/images/microsoft-edge.png')}
                        alt=''
                      />
                    </div>
                    <div className='h-20 w-full text-center mt-32'>
                      <Link
                        type='helpSupport'
                        textSize='text-base'
                        link='https://www.microsoft.com/en-us/windows/microsoft-edge'
                        target='blank'
                      >
                        Microsoft Edge
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PageFooter ref='footer' hideMegaFooter={true} />
      </div>
    );
  }
}

export default UnsupportedBrowser;
