import React from 'react';
const IconPath = (props) => {
  const { fill = '#00FF91', ...rest } = props;
  return (
    <svg
      width={rest.width}
      height={rest.height}
      viewBox={`0 0 32 32`}
      {...rest}
    >
      <g fill="none" fillRule="evenodd" transform="translate(8 8)">
        <circle cx="8" cy="8" r="8" fill={fill} />
        <path
          fill="#000"
          d="M5.635 10.83l-2.31-2.31L4.74 7.106l2.31 2.31 4.243-4.243 1.414 1.414-5.657 5.657-1.414-1.414z"
        />
      </g>
    </svg>
  );
};

export default IconPath;
