import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function content(state = initialState.content, action) {
  switch (action.type) {
    case types.SET_CONTENT_SAAG_VISIBILITY:
      return {
        ...state,
        saag: {
          visibility: action.visibility,
        },
      };
    default:
      return state;
  }
}
