import React from 'react';
const IconPath = (props) => {
  const { fill = '#76758f', stroke = 'none', ...rest } = props;
  return (
    <svg
      width={rest.size || 20}
      height={rest.height || 20}
      viewBox={rest.viewBox || `0 0 20 20`}
    >
      <path
        fill={fill}
        stroke={stroke}
        d="M17.652 15.048a.488.488 0 00-.1-.281q-.788-.943-1.266-1.617a6.908 6.908 0 00.45-1.069l2.18-.337a.327.327 0 00.225-.147.459.459 0 00.1-.274v-2.6a.419.419 0 00-.1-.274.406.406 0 00-.239-.147l-2.136-.329a9.62 9.62 0 00-.478-1.153q.211-.31.633-.844t.591-.759a.47.47 0 00.1-.267q0-.38-2.025-2.25a.467.467 0 00-.3-.112.412.412 0 00-.281.1L13.347 3.94a6.19 6.19 0 00-1.055-.436l-.323-2.151a.362.362 0 00-.141-.246.429.429 0 00-.281-.106H8.933a.411.411 0 00-.422.337 16.164 16.164 0 00-.323 2.166 6.84 6.84 0 00-1.083.45L5.488 2.688a.506.506 0 00-.3-.1q-.268 0-1.111.83a11.923 11.923 0 00-1.148 1.255.412.412 0 00-.1.281.535.535 0 00.1.281q.787.942 1.266 1.617a6.935 6.935 0 00-.45 1.069l-2.18.337a.327.327 0 00-.225.147.458.458 0 00-.099.268v2.6a.419.419 0 00.1.274.346.346 0 00.239.134l2.138.337a7.2 7.2 0 00.492 1.153q-.225.31-.661.858t-.577.745a.472.472 0 00-.1.267q0 .38 2.025 2.25a.467.467 0 00.3.112.38.38 0 00.281-.1l1.659-1.251a6.161 6.161 0 001.055.436l.323 2.152a.362.362 0 00.141.246.429.429 0 00.281.105h2.615a.411.411 0 00.422-.337 16.766 16.766 0 00.323-2.18 6.365 6.365 0 001.083-.436l1.617 1.266a.506.506 0 00.3.1q.267 0 1.1-.837a14.7 14.7 0 001.16-1.258.36.36 0 00.095-.261zm-4.866-2.5a3.469 3.469 0 01-2.545 1.052 3.6 3.6 0 010-7.2 3.6 3.6 0 012.545 6.145z"
        data-name="Path 319"
      />
    </svg>
  );
};

export default IconPath;
