import React from 'react';
import Autosuggest from 'react-autosuggest';
import { useDispatch } from 'react-redux';

import { useSearchContext } from '../SearchProvider';
import { reqSearchSuggestions } from '../SearchService';
import {
  analyticSearchAutoSuggest,
  analyticSearchRequested,
  SEARCH_SOURCES,
} from '../__analytics__';

import { autosuggestTheme } from './styled';

export default function SearchAutoSuggest({
  inputProps = {},
  handleSearch = () => {},
  setSuggestionRequestTimer = () => {},
  clearSuggestionRequestTimer = () => {},
  renderInputComponent = () => {},
}) {
  const dispatch = useDispatch();
  const {
    state: { config, suggestions: { data: suggestionsData } = {} } = {},
    dispatch: dispatchSearch,
  } = useSearchContext();

  const getSuggestions = async (value) => {
    const res = await reqSearchSuggestions(value, config);
    const { result = [] } = res || {};
    analyticSearchAutoSuggest(dispatch, {
      query: value,
      results: result,
    });

    if (result) {
      dispatchSearch({
        type: 'SET_SUGGESTIONS_DATA',
        data: {
          data: result.map((item) => ({
            text: item,
          })),
        },
      });
    }
  };

  const onSuggestionsClearRequested = () => {
    dispatchSearch({ type: 'SET_SUGGESTIONS_DATA', data: { data: [] } });
    clearSuggestionRequestTimer();
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    clearSuggestionRequestTimer();
    if (value.length > 2) {
      setSuggestionRequestTimer(() => getSuggestions(value));
    }
  };

  const onSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method },
  ) => {
    analyticSearchRequested(dispatch, {
      searchSource: SEARCH_SOURCES.AUTOCOMPLETE,
      clickedAutoCompleteIndex: `${suggestionIndex}`,
      query: suggestionValue,
    });

    handleSearch(suggestionValue);
  };

  const getSuggestionValue = (suggestion) => suggestion.text;
  const renderSuggestion = (suggestion) => <div>{suggestion.text}</div>;
  const shouldRenderSuggestions = (value) => value.trim().length > 2;

  return (
    <Autosuggest
      inputProps={inputProps}
      renderInputComponent={renderInputComponent}
      theme={autosuggestTheme}
      suggestions={suggestionsData}
      renderSuggestion={renderSuggestion}
      getSuggestionValue={getSuggestionValue}
      shouldRenderSuggestions={shouldRenderSuggestions}
      onSuggestionSelected={onSuggestionSelected}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
    />
  );
}
