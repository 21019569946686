import moment from 'moment';
import {
  ANALYTIC_TIME_FORMAT,
  ANALYTIC_NEEDLES,
  ANALYTIC_NEEDLE_REPLACER,
} from '../analytic/config';
import { replaceNeedlesInHaystack } from '../utils';

export const segmentEvents = {
  videoPlaybackStarted: 'Video Playback Started',
  videoContentStarted: 'Video Content Started',
  videoContentPlaying: 'Video Content Playing',
  videoAdPlaying: 'Video Ad Playing',
  videoAdStarted: 'Video Ad Started',
  videoAdCompleted: 'Video Ad Completed',
  videoContentCompleted: 'Video Content Completed',
  videoPlaybackPaused: 'Video Playback Paused',
  videoPlaybackResumed: 'Video Playback Resumed',
  videoPlaybackCompleted: 'Video Playback Completed',
  videoPlaybackInterrupted: 'Video Playback Interrupted',
  videoPlaybackBufferStarted: 'Video Playback Buffer Started',
  videoPlaybackBufferCompleted: 'Video Playback Buffer Completed',
  videoPlaybackSeekStarted: 'Video Playback Seek Started',
  videoPlaybackSeekCompleted: 'Video Playback Seek Completed',
  videoPlaybackRequested: 'Video Playback Requested',
  yoSpaceInit: 'Yospace Init',
  videoPlaybackError: 'Video Playback Error',
  apiMlPlayback: 'Api ML Playback',
  playbackErrorDisplayed: 'Playback Error Displayed',
  playbackErrorInteraction: 'Playback Error Interaction',
};

export const segmentEventTypes = {
  video: 'video',
  ssai: 'ssai',
  sdk: 'sdk',
  api: 'api',
};

export const segmentEventCategory = {
  videoStartFailure: 'vsf',
  videoPlaybackFailure: 'vpf',
  errorBeforeVideoStart: 'ebvs',
  player: 'player',
  user: 'user',
  api: 'api',
  generalPlayback: 'os_ml_general_playback_api',
  freePlayback: 'os_ml_free_playback_api',
  heartbeat: 'heartbeat',
  trickplay: 'trick-play',
  buffer: 'buffer',
  adStarted: 'ad-started',
  adEnded: 'ad-ended',
  playerInitialised: 'player-initialized',
  contentStarted: 'content-started',
  contentEnded: 'content-ended',
  contentCompleted: 'content-completed',
  playerDestroyed: 'player-destroyed',
  yospace: 'yospace_init',
  videoPlayerKicked: 'vpk',
  videoPlayerZombieKicked: 'vpk',
};

export const getHttpRequestResponsePayloadForApi = (
  apiUrl,
  apiRequestParam,
  apiResponse,
) => {
  const now = moment().format(ANALYTIC_TIME_FORMAT);

  const traversedResponse = apiResponse
    ? replaceNeedlesInHaystack(
        ANALYTIC_NEEDLES,
        ANALYTIC_NEEDLE_REPLACER,
      )(apiResponse)
    : {};

  const traversedRequestParams = apiRequestParam
    ? replaceNeedlesInHaystack(
        ANALYTIC_NEEDLES,
        ANALYTIC_NEEDLE_REPLACER,
      )(apiRequestParam)
    : {};
  const traversedHeaders = apiResponse
    ? replaceNeedlesInHaystack(
        ANALYTIC_NEEDLES,
        ANALYTIC_NEEDLE_REPLACER,
      )(apiResponse.headers)
    : {};
  const httpPayload = apiResponse
    ? {
        success: !apiResponse.errorStatus,
        request: {
          url: apiUrl,
          header: JSON.stringify(traversedRequestParams.headers) || {},
          requestTime: now,
        },
        response: {
          status: apiResponse.status || apiResponse.errorStatus,
          body: JSON.stringify(traversedResponse),
          header: JSON.stringify(traversedHeaders),
        },
      }
    : {};

  return httpPayload;
};

export const getErrorPropsForApi = (errorStatus) => {
  return errorStatus
    ? {
        error: {
          type: SegmentErrorMapping.videoPlaybackApiError.type,
          message: errorStatus,
        },
      }
    : {};
};

export const SegmentErrorMapping = {
  videoPlaybackFailure: { type: 'stream', message: 'user closed player' },
  videoStartFailure: { type: 'stream', message: 'video start failure' },
  videoPlaybackYoSpaceError: {
    type: 'sdk',
    message: 'yospace init error',
  },
  videoPlaybackApiError: {
    type: 'api',
    message: 'api fetch error',
  },
  videoPlaybackPlayerError: {
    type: 'player',
    message: 'player error',
  },
  videoPlayerKill: { type: 'user', message: 'user switched asset' },
  videoPlayerKicked: {
    type: 'chuck-norris-kick',
    message: 'user exceed maximum concurrent streams',
  },
  videoPlayerZombieKicked: {
    type: 'zombie-kick',
    message: 'user exceed maximum stream session time',
  },
};
export const SegmentPropertyExclusionList = {
  LIVE: {
    [segmentEvents.videoAdStarted]: [
      'global_from_position',
      'global_to_position',
    ],
    [segmentEvents.videoAdCompleted]: [
      'global_from_position',
      'global_to_position',
    ],
  },
  VOD: {},
};
export const PlaybackPositionOverrideList = {
  LIVE: [
    segmentEvents.videoPlaybackBufferCompleted,
    segmentEvents.videoPlaybackBufferStarted,
    segmentEvents.videoPlaybackError,
    segmentEvents.videoPlaybackInterrupted,
    segmentEvents.videoPlaybackPaused,
    segmentEvents.videoPlaybackResumed,
    segmentEvents.videoPlaybackSeekStarted,
    segmentEvents.videoPlaybackSeekCompleted,
    segmentEvents.videoPlaybackFailure,
    segmentEvents.videoPlaybackPlayerError,
    segmentEvents.videoPlaybackStarted,
    segmentEvents.videoPlaybackCompleted,
  ],
  VOD: [
    segmentEvents.videoPlaybackBufferCompleted,
    segmentEvents.videoPlaybackBufferStarted,
    segmentEvents.videoPlaybackError,
    segmentEvents.videoPlaybackInterrupted,
    segmentEvents.videoPlaybackPaused,
    segmentEvents.videoPlaybackResumed,
    segmentEvents.videoPlaybackSeekStarted,
    segmentEvents.videoPlaybackSeekCompleted,
    segmentEvents.videoPlaybackFailure,
    segmentEvents.videoPlaybackPlayerError,
    segmentEvents.videoPlaybackStarted,
    segmentEvents.videoPlaybackCompleted,
  ],
};
export const SegmentEventMapping = {
  videoPlaybackApiError: {
    event: {
      type: segmentEventTypes.video,
      name: segmentEvents.videoPlaybackError,
      category: segmentEventCategory.api,
    },
  },
  videoPlaybackYoSpaceError: {
    event: {
      type: segmentEventTypes.video,
      name: segmentEvents.videoPlaybackError,
      category: segmentEventCategory.yospace,
    },
  },
  videoPlaybackPlayerError: {
    event: {
      type: segmentEventTypes.video,
      name: segmentEvents.videoPlaybackError,
      category: segmentEventCategory.player,
    },
  },
  videoPlaybackFailure: {
    event: {
      type: segmentEventTypes.video,
      name: segmentEvents.videoPlaybackInterrupted,
      category: segmentEventCategory.videoPlaybackFailure,
    },
  },
  yoSpaceInit: {
    event: {
      type: segmentEventTypes.sdk,
      category: segmentEventCategory.yospace,
      name: segmentEvents.yoSpaceInit,
    },
  },
  apiMlFreePlayback: {
    event: {
      type: segmentEventTypes.api,
      category: segmentEventCategory.freePlayback,
      name: segmentEvents.apiMlPlayback,
    },
  },
  apiMlGeneralPlayback: {
    event: {
      type: segmentEventTypes.api,
      category: segmentEventCategory.generalPlayback,
      name: segmentEvents.apiMlPlayback,
    },
  },
  videoPlaybackRequested: {
    event: {
      type: segmentEventTypes.video,
      category: segmentEventCategory.user,
      name: segmentEvents.videoPlaybackRequested,
    },
  },
  videoPlaybackStarted: {
    event: {
      type: segmentEventTypes.video,
      category: segmentEventCategory.playerInitialised,
      name: segmentEvents.videoPlaybackStarted,
    },
  },
  videoPlaybackCompleted: {
    event: {
      type: segmentEventTypes.video,
      category: segmentEventCategory.playerDestroyed,
      name: segmentEvents.videoPlaybackCompleted,
    },
  },
  videoContentStarted: {
    event: {
      type: segmentEventTypes.video,
      category: segmentEventCategory.contentStarted,
      name: segmentEvents.videoContentStarted,
    },
  },
  videoContentCompleted: {
    event: {
      type: segmentEventTypes.video,
      category: segmentEventCategory.contentEnded,
      name: segmentEvents.videoContentCompleted,
    },
  },
  videoContentPlaying: {
    event: {
      type: segmentEventTypes.video,
      category: segmentEventCategory.heartbeat,
      name: segmentEvents.videoContentPlaying,
    },
  },
  videoPlaybackBufferStarted: {
    event: {
      type: segmentEventTypes.video,
      category: segmentEventCategory.buffer,
      name: segmentEvents.videoPlaybackBufferStarted,
    },
  },
  videoPlaybackBufferCompleted: {
    event: {
      type: segmentEventTypes.video,
      category: segmentEventCategory.buffer,
      name: segmentEvents.videoPlaybackBufferCompleted,
    },
  },
  videoPlaybackPaused: {
    event: {
      type: segmentEventTypes.video,
      category: segmentEventCategory.trickplay,
      name: segmentEvents.videoPlaybackPaused,
    },
  },
  videoPlaybackResumed: {
    event: {
      type: segmentEventTypes.video,
      category: segmentEventCategory.trickplay,
      name: segmentEvents.videoPlaybackResumed,
    },
  },
  videoPlaybackSeekStarted: {
    event: {
      type: segmentEventTypes.video,
      category: segmentEventCategory.trickplay,
      name: segmentEvents.videoPlaybackSeekStarted,
    },
  },
  videoPlaybackSeekCompleted: {
    event: {
      type: segmentEventTypes.video,
      category: segmentEventCategory.trickplay,
      name: segmentEvents.videoPlaybackSeekCompleted,
    },
  },
  videoAdStarted: {
    event: {
      type: segmentEventTypes.ssai,
      category: segmentEventCategory.adStarted,
      name: segmentEvents.videoAdStarted,
    },
  },
  videoAdCompleted: {
    event: {
      type: segmentEventTypes.ssai,
      category: segmentEventCategory.adEnded,
      name: segmentEvents.videoAdCompleted,
    },
  },
  videoAdPlaying: {
    event: {
      type: segmentEventTypes.ssai,
      category: segmentEventCategory.heartbeat,
      name: segmentEvents.videoAdPlaying,
    },
  },
  videoStartFailure: {
    event: {
      type: segmentEventTypes.video,
      name: segmentEvents.videoPlaybackInterrupted,
      category: segmentEventCategory.videoStartFailure,
    },
  },
  videoPlayerKicked: {
    event: {
      type: segmentEventTypes.video,
      name: segmentEvents.videoPlaybackInterrupted,
      category: segmentEventCategory.videoPlayerKicked,
    },
  },
  videoPlayerZombieKicked: {
    event: {
      type: segmentEventTypes.video,
      name: segmentEvents.videoPlaybackInterrupted,
      category: segmentEventCategory.videoPlayerZombieKicked,
    },
  },
  videoPlayerKill: {
    event: {
      type: segmentEventTypes.video,
      name: segmentEvents.videoPlaybackInterrupted,
      category: segmentEventCategory.errorBeforeVideoStart,
    },
  },
};

export const getEventTrackingProps = (key) => {
  const commonEventProps = SegmentEventMapping[key]
    ? SegmentEventMapping[key].event
    : {};
  return {
    ...commonEventProps,
  };
};
