import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function results(state = initialState.results, action) {
  let currentResults;

  switch (action.type) {

    case types.GET_RESULTS_INPROGRESS:

    currentResults = {
      ...state.find(results => results.competitionId === action.results.competitionId && results.season === action.results.season),
      ...action.results,
      inProgress: true,
      isSuccess: false
    };
    return [
      ...state.filter(results => results.competitionId !== action.results.competitionId || results.season !== action.results.season),
      currentResults
    ];

    case types.GET_RESULTS_SUCCESS:
      return [
        ...state.filter(results => results.season !== action.results.season || results.competitionId !== action.results.competitionId),
        {
          ...action.results,
          inProgress: false,
          isSuccess: true
        }
      ];

    case types.GET_RESULTS_FAILURE:
      currentResults = {
        // for failure case, use whatever has been loaded before
        ...state.find(results => results.competitionId === action.results.competitionId && results.season === action.results.season),
        ...action.results,
        inProgress: false,
        isSuccess: false
      };

      return [
        ...state.filter(results => results.competitionId !== action.results.competitionId || results.season !== action.results.season),
        currentResults
      ];

    default:
      return state;
  }
}

