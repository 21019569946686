import { getAssetActions } from 'components/ui/PromotionalPlacements/utils';
import { isArray } from 'lodash';

function contentCardsMapper({ contentCards, contentCardActionsConfig }) {
  if (!contentCards || !isArray(contentCards)) return [];
  return contentCards.map((asset) => {
    const { contentCard } = asset || {};

    const actions = getAssetActions({
      configAction: contentCardActionsConfig,
      contentCard,
    });

    return {
      ...asset,
      contentCard: { ...contentCard, actions },
    };
  });
}

export default contentCardsMapper;
