import React from 'react';

export default function FilterItem({
  item = {},
  firstItem = false,
  onFilterButtonClick = () => {},
}) {
  const { count, text, isSelected } = item;
  const isPlaceholder = !text;
  const displayText = !isPlaceholder
    ? `${text} ${count > 0 ? `(${count})` : ``}`
    : ' ';

  const filterButtonClassName = `w-full py-8 px-8 md:py-12 rounded-full outline-none capitalize text-sm h-full font-MarkProBold ${
    isSelected ? 'bg-teal text-black' : 'bg-core-dark-grey'
  } ${
    isPlaceholder ? ' text-0 cursor-default pointer-events-none' : ' text-white'
  }`;

  return (
    <li
      className={`w-full block flex-none ${firstItem ? 'mb-8' : ''} ${
        isPlaceholder ? 'opacity-50' : ''
      }`}
    >
      <button
        type='button'
        onClick={() => onFilterButtonClick(item)}
        className={filterButtonClassName}
      >
        {displayText}
      </button>
    </li>
  );
}
