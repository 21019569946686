import React, { Component } from "react";
import PropTypes from "prop-types";
import TeamColor from "./TeamColor";
import RadialGradient from "./RadialGradient";
import LinearGradient from "./LinearGradient";

class TeamBG extends Component {
  render() {
    return (
        <div className="teamlisting-team-stacked-bg w-full h-full relative">
            <div className="absolute pin-l pin-t w-full h-full">
                <TeamColor teamColor={this.props.teamColor} />
            </div>
            <div className="absolute pin-l pin-t w-full h-full">
                <RadialGradient />
            </div>
            <div className="absolute pin-l pin-t w-full h-full">
                <LinearGradient />
            </div>
        </div>
    )
  }
}

TeamBG.propTypes = {
    /** Team Color */
    teamColor: PropTypes.string.isRequired
};

TeamBG.defaultProps = {
    teamColor: '#ff0'
};

export default TeamBG;