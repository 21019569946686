import styled, { css, keyframes } from 'styled-components';

const loaderSpinAnimation = () => {
  return keyframes`
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(720deg);
    }
`;
};

const loaderDashAnimation = () => {
  return keyframes`
    0% {
        stroke-dashoffset: 120;
        color: white;
    }
    50% {
      stroke-dashoffset: 282;
      color: #05cedd;
      }
    100% {
      stroke-dashoffset: 120;
      color: white;
    }
  `;
};

const LoaderContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoaderItem = styled.div`


  ${(props) =>
    props.width &&
    css`
      width: ${props.width || '32px'};
      height: ${props.height || ' 32p'}x;
    `}


  ${(props) =>
    props.center &&
    css`
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 999;
    `}
    ${(props) =>
      props.stroke &&
      css`
        circle {
          stroke: ${props.stroke};
          stroke-width: ${props.strokeWidth};
        }
      `}
  ${(props) =>
    props.variant === 'regular' &&
    css`
      margin: 0;
      padding: 0;
      animation: ${loaderSpinAnimation} 1.5s ease-out infinite;
      border-radius: 100%;
      transform-origin: center;
      circle {
        stroke-width: ${(props) => props.strokeWidth || 14};
        stroke-linecap: round;
        stroke-dasharray: 282;
        stroke: currentColor;
        fill: transparent;
        animation: ${loaderDashAnimation} 1.5s linear infinite;
        width: 100%;
        height: 100%;
      }
    `}
    `;

export { LoaderItem, LoaderContainer };
