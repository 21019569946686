import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function saagdata(state = initialState.saagdata, action) {
  let currentSAAGData;

  switch (action.type) {
    case types.GET_SAAGDATA_INPROGRESS:
      return state;

    case types.GET_SAAGDATA_SUCCESS:
      return [...action.saagdata];

    case types.GET_SAAGDATA_FAILURE:
      currentSAAGData = {
        // for failure case, use whatever has been loaded before
        ...state.find(saagdata => saagdata.competitionId === action.saagdata.competitionId && saagdata.season === action.saagdata.season),
        ...action.saagdata,
        inProgress: false,
        isSuccess: false
      };

      return [
        ...state.filter(saagdata => saagdata.competitionId !== action.saagdata.competitionId || saagdata.season !== action.saagdata.season),
        currentSAAGData
      ];

    default:
      return state;
  }
}

