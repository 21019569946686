import React, { useState, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';

const LazyLoad = React.memo(({ children, className }) => {
  const [visible, setVisible] = useState([]);
  const nodeRef = useRef(null);
  const isComponentMounted = useRef(true); // The isComponentMounted ref is used to ensure that the component is still mounted before updating the state. This should prevent the children from re-rendering when you scroll away and back to the component.

  const onScroll = () => {
    const node = nodeRef.current;

    if (node && node.children) {
      let newVisible = Array(node.children.length).fill(false);
      let hasVisibleChild = false;

      for (let i = 0; i < node.children.length; i++) {
        const el = node.children[i];
        const elRect = el.getBoundingClientRect();
        const inside = elRect.top < window.innerHeight;

        if (inside) {
          newVisible[i] = true;
          hasVisibleChild = true;
        }
      }

      if (hasVisibleChild && isComponentMounted.current) {
        setVisible(newVisible);
      }
    }
  };

  useEffect(() => {
    setTimeout(onScroll, 500); // To fix not rendering children when this component is in the middle of screen at initial rendering and onScroll not triggered.
    return () => {
      isComponentMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    window.addEventListener('resize', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
      window.removeEventListener('resize', onScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const lazyLoadedChildren = useMemo(() => {
    if (!children) return null;
    return React.Children.map(children, (child, index) => {
      if (visible[index]) {
        return child;
      }
      return React.cloneElement(child, { lazyLoaded: false });
    });
  }, [children, visible]);

  return (
    <div ref={nodeRef} className={`relative ${className}`}>
      {lazyLoadedChildren}
    </div>
  );
});

LazyLoad.propTypes = {
  className: PropTypes.string,
};

LazyLoad.defaultProps = {
  className: '',
};

export default LazyLoad;
