import React from 'react';
import PropTypes from 'prop-types';

const SearchIcon = ({ width, height, color, viewBox, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
    {...props}
  >
    <path
      fill={color}
      stroke={color}
      strokeWidth=".5"
      fillRule="evenodd"
      d="M14 6c4.418 0 8 3.582 8 8 0 1.849-.627 3.55-1.68 4.906l5.7 5.7-1.413 1.415-5.701-5.701C17.55 21.373 15.849 22 14 22c-4.418 0-8-3.582-8-8s3.582-8 8-8zm0 2c-3.314 0-6 2.686-6 6s2.686 6 6 6 6-2.686 6-6-2.686-6-6-6z"
    />
  </svg>
);

SearchIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  viewBox: PropTypes.string,
};

SearchIcon.defaultProps = {
  color: 'currentColor',
  width: 32,
  height: 32,
  viewBox: '0 0 32 32',
};

export default SearchIcon;
