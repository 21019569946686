import jsCookie from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

export const SEARCH_ANALYTICS_COOKIE_NAMES = {
  COMMING_FROM_SEARCH_RESULTS: 'comming_from_search_results',
  SESSION_NAME: 'search_session_id',
  RESULTS_SESSION_NAME: 'search_result_session_id',
  SEARCH_STRING: 'search_string',
  SEARCH_SOURCE: 'search_source',
  SEARCH_INDEX: 'search_index',
};

export const SEARCH_SOURCES = {
  AUTOCOMPLETE: 'autocomplete',
  RECENT: 'recent',
  MANUAL: 'manual',
};

const sessionCookieName = SEARCH_ANALYTICS_COOKIE_NAMES.SESSION_NAME;
const sessionResultsCookieName = SEARCH_ANALYTICS_COOKIE_NAMES.RESULTS_SESSION_NAME;
const fromSearchResultsCookieName =
  SEARCH_ANALYTICS_COOKIE_NAMES.COMMING_FROM_SEARCH_RESULTS;

export const reGenerateSearchSessionId = () => {
  const sessionId = uuidv4();
  jsCookie.set(sessionCookieName, sessionId);
  return sessionId;
};

export const getSearchSessionId = () => {
  return jsCookie.get(sessionCookieName) || reGenerateSearchSessionId();
};

// When the search_session_id leads the user to results,we set
// a copy of search_session_id in search_results_session_id to
// send to event metrics as search_session_id, we've done this
// because the user might over click search box and causes re-
// generating the search_session_id. So becarful with that ;-)
export const cloneSearchSessionId = () => {
  const sessionCookie = getSearchSessionId();

  // Make sure search_session_id has been sat in cookies before
  if (sessionCookie) {
    jsCookie.set(sessionResultsCookieName, sessionCookie);
  }
};
export const getClonedSearchSessionId = () => jsCookie.get(sessionResultsCookieName);

export const saveSearchDetailRequestedAssetInCookie = ({
  search_string,
  search_source,
  search_index,
}) => {
  jsCookie.set(SEARCH_ANALYTICS_COOKIE_NAMES.SEARCH_STRING, search_string);
  jsCookie.set(SEARCH_ANALYTICS_COOKIE_NAMES.SEARCH_SOURCE, search_source);
  jsCookie.set(SEARCH_ANALYTICS_COOKIE_NAMES.SEARCH_INDEX, search_index);
};

export const popSearchDetailRequestedAssetInCookie = ({
  keepTheCookies = false,
} = {}) => {
  const { SEARCH_STRING } = SEARCH_ANALYTICS_COOKIE_NAMES;
  const search_string = jsCookie.get(SEARCH_STRING);
  if (!keepTheCookies) jsCookie.remove(SEARCH_STRING);

  const { SEARCH_SOURCE } = SEARCH_ANALYTICS_COOKIE_NAMES;
  const search_source = jsCookie.get(SEARCH_SOURCE);
  if (!keepTheCookies) jsCookie.remove(SEARCH_SOURCE);

  const { SEARCH_INDEX } = SEARCH_ANALYTICS_COOKIE_NAMES;
  const search_index = jsCookie.get(SEARCH_INDEX) || null;
  if (!keepTheCookies) jsCookie.remove(SEARCH_INDEX);

  return { search_string, search_source, search_index };
};

export const setCommingFromSearchResultsCookie = () => {
  jsCookie.set(SEARCH_ANALYTICS_COOKIE_NAMES.COMMING_FROM_SEARCH_RESULTS, true);
};
export const popCommingFromSearchResultsCookie = () => {
  if (jsCookie.get(fromSearchResultsCookieName)) {
    jsCookie.remove(fromSearchResultsCookieName);
    return true;
  }
  return false;
};
