export function decodeURIComponentSafe(s) {
  if (!s) {
    return s;
  }
  return decodeURIComponent(
    removeNonAscii(s).replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25'),
  );
}

export function removeNonAscii(s) {
  if (!s) {
    return s;
  }
  // eslint-disable-next-line
  return s.replace(/[^\x00-\x7F]/g, '');
}

export function replaceMultipleStrings(s, mapObj) {
  var re = new RegExp(Object.keys(mapObj).join('|'), 'gi');
  return s.replace(re, function(matched) {
    return mapObj[matched];
  });
}
