import React from 'react';
import PropTypes from 'prop-types';

const ArticleIcon = ({ width, height, viewBox, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox} {...props}>
    <g
      fill="#FFF"
      fillRule="evenodd"
    >
      <path
        fillOpacity="0"
        d="M0 0h24v24H0z"
      />
      <path
        d="M6 6.75h12v1.5H6zM6 15.75h9v1.5H6zM6 9.75h10.5v1.5H6zM6 12.75h12v1.5H6z"
      />
    </g>
  </svg>
);

ArticleIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  viewBox: PropTypes.string
};

ArticleIcon.defaultProps = {
  width: 32,
  height: 32,
  viewBox: '0 0 32 32',
};

export default ArticleIcon;
