import useBreakpoint from 'use-breakpoint';

// TODO: We must synce these breakpoints with tailwind breakpoints
const breakpointList = {
  xxs: 300,
  xs: 375,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1300,
  xxxl: 1440,
  xxxxl: 1500,
  xxxxxl: 1600,
  xxxxxxl: 1700,
  xxxxxxxl: 1800,
};

function getInitialBreakpoint(width = 600) {
  const entries = Object.entries(breakpointList);
  const index = entries.findIndex(
    (breakpointEntry) => width < breakpointEntry[1],
  );

  if (index === -1) return entries[entries.length - 1][0];
  if (index === 0) return entries[index][0];
  return entries[index - 1][0];
}

export default function useBreakpoints() {
  const { breakpoint, ...rest } = useBreakpoint(
    breakpointList,
    getInitialBreakpoint(window.innerWidth),
  );

  return { breakpoint, ...rest };
}
