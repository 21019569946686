import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Title, Content, Header } from './styles';

const Accordion = (props) => {
  const {
    onClick: onClickProp,
    isActive: isActiveProp,
    title,
    maxHeight,
    children,
  } = props;
  const [isActive, setIsActive] = useState(isActiveProp || false);

  const onClick = () => {
    setIsActive(!isActive);
    onClickProp();
  };

  return (
    <Container>
      <Header onClick={onClick}>{title && <Title>{title}</Title>}</Header>
      {children && (
        <Content maxHeight={maxHeight} isActive={isActive}>
          {children}
        </Content>
      )}
    </Container>
  );
};

Accordion.propTypes = {
  /** Handler for expanding the accordion */
  onClick: PropTypes.func,
  /** Heading of accordion optional */
  isActive: PropTypes.bool,
  /** Initial state of accordion defaults to false */
  title: PropTypes.string,
  /** Accordion content. */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Accordion.defaultProps = {
  maxHeight: 1000,
};

export default Accordion;
