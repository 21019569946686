import React, {
  createContext,
  useState,
  useContext,
  useReducer,
  useEffect,
} from 'react';

import useAsync from '../../../hooks/useAsync';
import searchReducer, { searchInitState } from './SearchReducer';
import useConnectWithSearch from './useConnectWithSearch';
import { getStoreSearchHistory } from '../SearchService';

export const SearchContext = createContext();
export const useSearchContext = () => useContext(SearchContext);

export default function SearchProvider({ children }) {
  const config = useConnectWithSearch();
  const [state, dispatch] = useReducer(searchReducer, {
    ...searchInitState,
    config,
  });

  const { loggedIn } = config;
  useEffect(() => {
    dispatch({
      type: 'SET_CONFIG_DATA',
      data: {
        loggedIn,
      },
    });
  }, [loggedIn]);
  useAsync(async () => {
    const data = getStoreSearchHistory();
    dispatch({
      type: 'SET_HISTORY_DATA',
      data: {
        data,
      },
    });
  }, [config]);

  const [contextValue, setContextValue] = useState({
    state,
    dispatch,
  });
  useEffect(() => {
    setContextValue((previousContextValue) => ({
      ...previousContextValue,
      state,
    }));
  }, [state]);

  return (
    <SearchContext.Provider value={contextValue}>
      {children}
    </SearchContext.Provider>
  );
}
