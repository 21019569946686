/**
 * asyncDispatchMiddleware
 * This middleware adds `asyncDispatch` to all actions.
 *
 * The use case for this is to be able to schedule an action right after an action
 * is received in the reducer level (not at component level yet) but letting the
 * original action finish first before the scheduled action kicks in.
 *
 * Example: (inside a reducer)
 * ```
 * case success(CUSTOMER_SUBSCRIPTION_UPDATE): {
 *    action.asyncDispatch(getUserDetails({ forceRefreshCache: true }));
 *    return state;
 * }
 * ```
 *
 * This is for simple use cases like call an action after current
 * action is dispatched. For complex orchestration of actions, we
 * may need to explore redux-sagas or similar solutions.
 *
 * @param {*} store
 */
const asyncDispatchMiddleware = store => next => action => {
  let syncActivityFinished = false;
  let actionQueue = [];

  function flushQueue() {
    actionQueue.forEach(a => store.dispatch(a)); // flush queue
    actionQueue = [];
  }

  function asyncDispatch(asyncAction) {
    actionQueue = actionQueue.concat([asyncAction]);
    if (syncActivityFinished) {
      flushQueue();
    }
  }

  const actionWithAsyncDispatch = Object.assign({}, action, { asyncDispatch });

  next(actionWithAsyncDispatch);

  syncActivityFinished = true;
  
  flushQueue();
};

export default asyncDispatchMiddleware;
