import React from 'react';
const IconPath = (props) => {
  const { fill = '#fff', ...rest } = props;
  return (
    <svg
      width={rest.size || 20}
      height={rest.height || 20}
      viewBox={rest.viewBox || `0 0 20 20`}
    >
      <g fill="none" fillRule="evenodd">
        <path fill={fill} fillOpacity="0" d="M0 0h128v128H0z" />
        <path
          fill={fill}
          d="M108.063 43.875L121.374 57l-13.313 13.125L103.939 66l6-6H97v30H28V36h69v18h12.938l-6-6 4.124-4.125zM91 42H34v12h57V42zM34 84h57V60H34v24z"
        />
      </g>
    </svg>
  );
};

export default IconPath;
