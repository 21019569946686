import React from 'react';

const IconPath = (props) => {
  const { stroke = '#000', ...rest } = props;
  return (
    <svg
      width={rest.width}
      height={rest.height}
      viewBox={rest?.viewBox || `0 0 20 20`}
      fill="none"
    >
      <path
        d="M9.05882 2H4C2.89543 2 2 2.89543 2 4V16C2 17.1046 2.89543 18 4 18H16C17.1046 18 18 17.1046 18 16V10.9412"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 8L17.5294 2.47058"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.8235 2H18V7.17647"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconPath;
