import React from 'react';
import PropTypes from 'prop-types';

const Label = ({children, className, color, textSize, ...props }) => (
  <label className={`${color} ${textSize} ${className}`} {...props}>{children}</label>
);

Label.propTypes = {
  /** Child components*/
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Classname for styling*/
  className: PropTypes.string,
  /** Tailwind color class, default is text-white*/
  color: PropTypes.string,
  /** Tailwind textsize class, default is text-sm*/
  textSize: PropTypes.string,
};

Label.defaultProps = {
  color: 'text-white',
  textSize: 'text-sm',
  className: '',
};

export default Label;

