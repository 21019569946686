import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function livematches(state = initialState.livematches, action) {
  let currentLiveMatches;

  switch (action.type) {

    case types.GET_LIVEMATCHES_INPROGRESS:
      const alreadyExistingData = state.find(livematches => livematches.competitionId === action.livematches.competitionId && livematches.season === action.livematches.season)
      if(alreadyExistingData){
        alreadyExistingData.inProgress = true;
        alreadyExistingData.isSuccess = false;
      }
      return state;

    case types.GET_LIVEMATCHES_SUCCESS:
          return [
        ...state.filter(livematches => livematches.competitionId !== action.livematches.competitionId || livematches.season !== action.livematches.season),
        {
          ...action.livematches,
          inProgress: false,
          isSuccess: true
        }
      ];

    case types.GET_LIVEMATCHES_FAILURE:
      currentLiveMatches = {
        // for failure case, use whatever has been loaded before
        ...state.find(livematches => livematches.competitionId === action.livematches.competitionId && livematches.season === action.livematches.season),
        ...action.livematches,
        inProgress: false,
        isSuccess: false
      };

      return [
        ...state.filter(livematches => livematches.competitionId !== action.livematches.competitionId || livematches.season !== action.livematches.season),
        currentLiveMatches
      ];

    default:
      return state;
  }
}

