import _ from 'lodash';
import React, { Component } from 'react';
import moment from 'moment-timezone';
import TapAndPinchable from 'react-tappable/lib/TapAndPinchable';
import { withRouter } from 'react-router-dom';
import FixtureTeam from './FixtureTeam';
import FixtureStatus from '../FixtureStatus';
import { getPlaceholderTeamUrl } from 'utils/path';

class NextMatch extends Component {
	constructor(props) {
		super(props);

		this.state = {
			nextmatchPopoverShown: false
		}
	}

	findTeamShortCode = (teamID, teamlist) => {
		if (teamlist && teamlist.length !== 0) {
			const team = teamlist.allTeams.find( e => e.TeamID === teamID );

			if (team) {
				const teamShortCode = team.SYMID;
				return teamShortCode;
			}
		}
		return null;
	}

	handleTap = e => {
		this.setState({
			nextmatchPopoverShown: !this.state.nextmatchPopoverShown
		})
	}

	handlePress = e => {
		this.setState({
			nextmatchPopoverShown: false
		}, () => this.props.history.push(this.props.nextAssetURL))
	}

	handleMouseClick = url => {
		this.setState({
			nextmatchPopoverShown: false
		}, () => this.props.history.push(url))
	}

	render() {
    const {
      currentTeamID,
      teams,
      NextTeamImageUrl,
    } = this.props;

		let match = this.props.nextMatchData || {};
		const {
      HomeTeamID,
      AwayTeamID
		} = match;
		let homeTeamShortCode = this.findTeamShortCode(HomeTeamID, teams);
		let awayTeamShortCode = this.findTeamShortCode(AwayTeamID, teams);

    const homeTeamImg = _.get(match, 'HomeTeamImageURL', '');
    const awayTeamImg = _.get(match, 'AwayTeamImageURL', '');

    // Retrieve next match's opponent team image
    let finalNextTeamImg = NextTeamImageUrl;
    if (!finalNextTeamImg && currentTeamID) {
    	finalNextTeamImg = `${currentTeamID}` === `${HomeTeamID}` ? awayTeamImg : homeTeamImg;
		}

		const matchDate = moment.utc(match.MatchDateTime).local();
		const matchDateFormatted = matchDate.format("dddd, DD MMM YYYY");
		const matchTimeFormatted = matchDate.format("HH:mm");

		return (
			<TapAndPinchable onTap={e => this.handleTap(e)} onPress={e => this.handlePress(e)}>
				<div
					onMouseEnter={() => this.setState({ nextmatchPopoverShown: true })}
					onMouseLeave={() => this.setState({ nextmatchPopoverShown: false })}
					onClick={()=> this.handleMouseClick(this.props.nextAssetURL)}
					className="cursor-pointer team-badge inline-block align-middle relative pointer-events-none xl:pointer-events-auto outline-none focus:outline-none active:outline-none"
				>
					<div className={`nextmatch-popover-wrap absolute w-279 h-80 bg-medium-dark-grey os-triangle-after-popup p-16 shadow-fixture-score z-99999 ${this.state.nextmatchPopoverShown ? 'xl:flex xl:flex-wrap xl:justify-center popover-fadein' : 'hidden popover-fadeout'}`}>
						<h2 className="text-center text-white text-xs font-MarkPro mb-10">{matchDateFormatted}</h2>

						<div className="flex flex-no-wrap justify-center items-center">
							<div className="team-one flex-1 text-right">
								<FixtureTeam
									imgUrl={homeTeamImg}
									team={homeTeamShortCode || ''}
									reverse
									teamShortCode={homeTeamShortCode || ''}
								/>
							</div>

							<div className="score-card mx-7 flex-no-grow flex-no-shrink w-46 relative os-top--1">
								<FixtureStatus label={matchTimeFormatted} type="time" mini />
							</div>

							<div className="team-two flex-1">
								<FixtureTeam
									imgUrl={awayTeamImg}
									team={awayTeamShortCode || ''}
									teamShortCode={awayTeamShortCode || ''}
								/>
							</div>
						</div>
					</div>
					<div
						className="w-24 h-24 bg-center bg-no-repeat bg-contain rounded-full inline-block align-middle"
						style={{backgroundImage: `url(${finalNextTeamImg || getPlaceholderTeamUrl()})`}}
					/>
				</div>
			</TapAndPinchable>
		)
	}
}

NextMatch.defaultProps = {
	onNextClick: () => {}
}

export default withRouter(NextMatch);


