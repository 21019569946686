import React, { Component } from 'react';
import PageFooterSection from "../ui/PageFooterSection";

class PageFooter extends Component {
  render() {
    return (
      <PageFooterSection
        className={this.props.className}
        hideMegaFooter={this.props.hideMegaFooter}
        hideDownloadApps={this.props.hideDownloadApps}
        newHome={this.props.newHome}
      />
    );
  }
}

export default PageFooter;
