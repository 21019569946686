import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import Header from './Header/';
import VideoCard from './asset-card/AssetCard';
import defaultConfig from '../../../../constants/defaultConfig';
import selectors from './selectors';
import { selectors as storeSelector } from '../../../../store/createPortalStore';
import ContentCardAUI from '../../PromotionalPlacements/layouts/ContentCardAUI';
import ContentCardAsset from '../../PromotionalPlacements/components/ContentCardAsset';
import { assetFilter } from '../../PromotionalPlacements/utils';
import { ContentCardTypeName } from '../../PromotionalPlacements/constants';
import SectionLoader from '../../../ui/ui-kit/SectionLoader';

const VideoCardList = loadable(() => import('./LayoutDVideoCardList'));

/**
 * Responsive Component to create 2 different types of grids at the moment (v1 OR v2 passed as props)
 *
 * Desktop mode:
 * v1 (default): A combination of 2 Hero cards with 4 Normal cards
 * v2: Row 1 portrays 2 Hero cards
 *     Row 2 portrays 4 Normal cards
 *
 * Tablet mode:
 * v1 (default): Row 1 & 2 both portray a lone Hero card for each
 *               Row 3 & 4 both portray 2 Normal cards
 * v2: Same as v1's layout
 *
 * Mobile mode:
 * v1 (default) and v2: Similar layout with smaller tiles
 */

class LayoutAHeroGrid extends Component {
  render() {
    const {
      carousel,
      editorial,
      categoryMapping,
      categoryPath,
      gridVersion,
      assets,
      isArticle,
      sectionPath,
      theme,
      carouselIndex,
      userTags,
      contentCardsInObj: {
        isReady: isContentCardsReady,
        data: contentCardsInObj,
      },
    } = this.props;
    if (
      (editorial && editorial.inProgress && !editorial.isSuccess) ||
      !userTags ||
      !isContentCardsReady
    ) {
      return <SectionLoader />;
    }

    const assetsFiltered = assetFilter(assets, userTags, contentCardsInObj);
    const assetsLength = assetsFiltered.length;

    // We need to fallback to Layout D if less than 6 assets
    if (assetsLength < 6) {
      return (
        <VideoCardList
          key={`${carousel.id}_${carouselIndex}`}
          carousel={carousel}
          noOfRows={1}
          sectionPath={sectionPath}
          theme={theme}
          themeType='d'
        />
      );
    }

    const showViewAllLink = carousel.showViewAllLink;
    const assetsForGrid = assetsLength ? assetsFiltered.slice(0, 6) : null;
    return assetsForGrid ? (
      <div
        className='
          flex
          flex-col
        '
      >
        {!isArticle && (
          <Header
            title={_.get(carousel, 'title', _.get(editorial, 'title', ''))}
            categoryPath={categoryPath}
            theme={theme}
            showViewAllLink={showViewAllLink}
          />
        )}
        <div
          className={`${
            gridVersion === 'v1'
              ? 'layout-a-card-list-v1'
              : 'layout-a-card-list-v2'
          } pl-0`}
        >
          {assetsForGrid.map((asset, index) => {
            const { assetTypeName } = asset;
            return (
              <div
                key={`${editorial.id}-${asset?.id}`}
                className={`item-${index + 1}`}
              >
                {assetTypeName === ContentCardTypeName ? (
                  <ContentCardAsset
                    key={`${editorial.id}-${asset?.id}`}
                    assetId={asset?.id}
                    uiComponent={ContentCardAUI}
                    editorialId={editorial.id}
                  />
                ) : (
                  <VideoCard
                    asset={asset}
                    isArticle={isArticle}
                    categoryMapping={categoryMapping}
                    theme={theme}
                    themeType='a'
                    className='rounded-t-xl'
                    editorialId={editorial.id}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
    ) : null;
  }
}

function mapStateToProps(state, ownProps) {
  if (ownProps.isArticle) {
    const { articles } = ownProps;

    return {
      assets: articles,
    };
  } else {
    const editorial = state.editorials.find(
      (ed) => ed.id === ownProps.carousel.id,
    );
    const carouselImageSize =
      _.get(state, 'systemConfig.image.sizes.carouselImageSize', null) ||
      defaultConfig.image.sizes.carouselImageSize;
    const assetTypeNames = _.get(
      state,
      'systemConfig.hideCarouselModal.assetTypeNames',
      [],
    );
    const categoryMapping = _.get(state, 'systemConfig.categoryMapping', []);
    const contentCardsInObj = storeSelector.contentCards.getContentCardsInObj(
      state,
    );

    return {
      carouselImageSize,
      assetTypeNames,
      categoryMapping,
      editorial,
      categoryPath: selectors.getCategoryPath(ownProps, editorial),
      assets: _.get(editorial, 'assets', []),
      userTags: storeSelector.user.getUserTags(state),
      contentCardsInObj,
    };
  }
}

LayoutAHeroGrid.propTypes = {
  gridVersion: PropTypes.string,
  isArticle: PropTypes.bool,
  articles: PropTypes.array,
  theme: PropTypes.string,
};

LayoutAHeroGrid.defaultProps = {
  gridVersion: 'v1',
  isArticle: false,
  articles: [],
  theme: 'dark',
};

export default connect(mapStateToProps)(LayoutAHeroGrid);
