import get from 'lodash/get';
import head from 'lodash/head';
import { getUserId } from 'selectors/user';
import { start, success, fail, apiAction } from '../middleware/fetch';

const initialState = {};

export const RELATED_ASSETS_FETCH = 'relatedAssets/FETCH';
export const RELATED_ASSETS_RESET = 'relatedAssets/RESET';

export function fetchRelatedAssets(relatedAssetId) {
  return (dispatch, getState) => {
    const state = getState();
    const { user } = state;
    const currentUserId = getUserId(user);
    dispatch(
      apiAction(RELATED_ASSETS_FETCH, `fe-api-upnext`, {
        path: `/${currentUserId}`,
        queryStringParameters: {
          assetId: relatedAssetId,
        },
      }),
    );
  };
}

export default function relatedAssets(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case RELATED_ASSETS_RESET:
    case start(RELATED_ASSETS_FETCH): {
      return {};
    }
    case success(RELATED_ASSETS_FETCH): {
      const nextAsset = head(get(payload, 'assets', [])) || {};
      return nextAsset;
    }
    case fail(RELATED_ASSETS_FETCH): {
      return {
        error: payload,
      };
    }
    default:
      return state;
  }
}
