
import React from 'react';
import PropTypes from 'prop-types';

const BackIcon = ({ width, height, color, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32" {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill={color} fillOpacity="0" d="M0 0h32v32H0z"/>
      <path stroke={color} strokeWidth="1.5" d="M18.03 21.275L13 15.638 18.03 10"/>
    </g>
  </svg>


);

BackIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

BackIcon.defaultProps = {
  color: 'currentColor',
  width: 32,
  height: 32
};

export default BackIcon;
