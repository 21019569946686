export default {
  light: {
    container: 'text-black bg-white card-shadow rounded-xl',
    message: 'text-white',
    content: 'p-8',
    picture: 'rounded-t-xl block',
    pictureTeamVideo: 'rounded-t-xl block',
  },
  lightv2: {
    container: 'text-black bg-white',
    message: 'text-white',
    content: 'p-8',
    picture: 'rounded-xl block',
    pictureTeamVideo: 'rounded-t-xl block',
  },
  dark: {
    container: 'text-white',
    message: '',
    content: '',
    picture: 'rounded-xl block',
    pictureTeamVideo: 'rounded-t-xl block',
  },
};
