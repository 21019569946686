import React from "react";

const StatsListItem = ({ displayText, value, className }) => (
  <div className={`w-full h-32 bg-widget-grey flex items-center justify-between ${className}`}>
    <div className="h-16">
      <span className="text-sm text-white font-MarkPro leading-1.14">
        {displayText}
      </span>
    </div>
    <div className="h-16">
      <span className="text-sm text-white font-MarkProHeavy leading-1.14">
        {value}
      </span>
    </div>
  </div>
);

StatsListItem.defaultProps = {
  className: ''
}


export default StatsListItem;