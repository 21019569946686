export const searchInitState = {
  config: {},
  search: {
    message: {},
    data: [],
  },
  suggestions: {
    message: {},
    data: [],
  },
  history: {
    message: {},
    data: [],
  },
  loading: false,
  isShowHistory: true,
  isShowSearchSectionInNavbar: false,
  searchText: '',
  sameSearchTextLimit: 0,
};

export default function searchReducer(state, action) {
  const { type, data } = action;
  switch (type) {
    case 'UPDATE_SEARCH_STATE':
      return {
        ...state,
        ...data,
      };
    case 'SET_CONFIG_DATA':
      const { config } = state;
      return {
        ...state,
        config: {
          ...config,
          ...data,
        },
      };
    case 'SET_SEARCH_TEXT':
      const {
        searchText,
        sameSearchTextLimit,
      } = state;

      let localSameSearchTextLimit = sameSearchTextLimit;
      if (data && searchText === data) {
        localSameSearchTextLimit++;
      } else {
        localSameSearchTextLimit = 0;
      }

      return {
        ...state,
        searchText: data,
        sameSearchTextLimit: localSameSearchTextLimit,
      };
    case 'SET_SEARCH_DATA':
      const { search } = state;

      return {
        ...state,
        search: {
          ...search,
          ...data,
        },
      };
    case 'SET_SUGGESTIONS_DATA':
      const { suggestions } = state;
      return {
        ...state,
        suggestions: {
          ...suggestions,
          ...data,
        },
      };
    case 'SET_HISTORY_DATA':
      const { history } = state;
      const newHistoryState = {
        ...history,
        ...data,
      };

      return {
        ...state,
        history: newHistoryState,
      };
    case 'TOGGLE_SHOW_SEARCH_SECTION':
      const { isShowSearchSectionInNavbar } = state;
      let newValue =
        typeof data === 'boolean' ? data : !isShowSearchSectionInNavbar;

      return {
        ...state,
        isShowSearchSectionInNavbar: newValue,
      };
    case 'TOGGLE_SEARCH_LOADING':
      const { loading } = state;

      return {
        ...state,
        loading: !loading,
      };
    default:
      return state;
  }
}
