import React from 'react';
const IconPath = (props) => {
  const { fill = '#9592A8', stroke = 'none', ...rest } = props;
  return (
    <svg
      width={rest.width}
      height={rest.height}
      viewBox={rest.viewBox || `0 0 20 20`}
    >
      <g stroke={stroke} strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M9.9996,1.9997 C14.4186,1.9997 17.9996,5.5817 17.9996,9.9997 C17.9996,14.4177 14.4186,17.9997 9.9996,17.9997 C5.5816,17.9997 1.9996,14.4177 1.9996,9.9997 C1.9996,5.5817 5.5816,1.9997 9.9996,1.9997 Z M9.9996,3.4997 C6.4156,3.4997 3.4996,6.4157 3.4996,9.9997 C3.4996,13.5837 6.4156,16.4997 9.9996,16.4997 C13.5836,16.4997 16.4996,13.5837 16.4996,9.9997 C16.4996,6.4157 13.5836,3.4997 9.9996,3.4997 Z M7.1716,7.1717 C7.4646,6.8787 7.9396,6.8787 8.2326,7.1717 L8.2326,7.1717 L9.9996,8.9387 L11.7676,7.1717 C12.0606,6.8787 12.5356,6.8787 12.8286,7.1717 C13.1216,7.4647 13.1206,7.9387 12.8286,8.2317 L12.8286,8.2317 L11.0606,9.9997 L12.8286,11.7677 C13.1216,12.0607 13.1206,12.5357 12.8286,12.8287 C12.5356,13.1217 12.0606,13.1217 11.7676,12.8287 L11.7676,12.8287 L9.9996,11.0607 L8.2326,12.8287 C7.9396,13.1217 7.4646,13.1217 7.1716,12.8287 C6.8786,12.5357 6.8786,12.0607 7.1716,11.7677 L7.1716,11.7677 L8.9396,9.9997 L7.1716,8.2317 C6.8786,7.9397 6.8786,7.4647 7.1716,7.1717 Z"
          id="Combined-Shape"
          fill={fill}
        ></path>
      </g>
    </svg>
  );
};

export default IconPath;
