import React from 'react';

const IconPath = (props) => {
  const { fill = '#FFF', ...rest } = props;
  return (
    <svg
      width={rest.width}
      height={rest.height}
      viewBox={rest.viewBox}
      {...rest}
    >
      <path
        fill={fill}
        fillRule="evenodd"
        d="M21.685 20.153l2.285 2.267-2.28 2.27-2.433-2.426a7.6 7.6 0 1 1 2.428-2.11zM15.6 11.04a4.545 4.545 0 1 1-.01 9.09 4.545 4.545 0 0 1 .01-9.09z"
      />
    </svg>
  );
};

export default IconPath;
