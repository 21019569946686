import React, { useState } from 'react';
import useWindowsize from '../../utils/hooks/useWindowsize';
import {
  SideClose,
  SideControl,
  InnerContainer,
  SideContainer,
  SideGroup,
} from './styles';
import Icon from '../Icon';
import { withRouter } from 'react-router-dom';

const Menu = ({
  children,
  showClose = false,
  position = 'left',
  renderControls = null,
}) => {
  const { height } = useWindowsize();
  const [isActive, setIsActive] = useState(true);

  return (
    <SideContainer className={position} height={height} isActive={isActive}>
      {showClose && (
        <SideControl position={position}>
          <SideClose onClick={() => setIsActive(!isActive)}>
            <Icon name="CLOSE" size={32} />
          </SideClose>
        </SideControl>
      )}
      <InnerContainer>
        {renderControls && renderControls}
        <SideGroup height={height}>{children}</SideGroup>
      </InnerContainer>
    </SideContainer>
  );
};

export default withRouter(Menu);
