export default {
  lightv2: {
    viewAll: 'text-cyan-blue font-MarkProBold',
    viewAllActive: 'text-cyan-blue',
    viewAllHover: 'text-teal-light',
    title: 'text-black',
  },
  light: {
    viewAll: 'text-cyan-blue',
    viewAllActive: 'text-cyan-blue',
    viewAllHover: 'text-teal-light',
    title: 'text-black',
  },
  dark: {
    viewAll: 'text-teal',
    viewAllActive: 'text-teal-light',
    viewAllHover: 'text-teal-dark',
    title: 'text-white',
  },
};
