import _ from 'lodash';
import * as types from '../constants/actionTypes';
import initialState from './initialState';


export default function ticketManager(state = initialState.ticketManager, action) {
  let newState = {};
  let currentUserId;
  let currentTickets;
  let currentTicketId;
  let currentComments;

  switch (action.type) {
    case types.CREATE_TICKET_INPROGRESS:
      newState = Object.assign(
        newState,
        state,
        {
          createTicket: {
            inProgress: true,
            isSuccess: false
          }
        });

      return newState;

    case types.CREATE_TICKET_SUCCESS:
      newState = Object.assign(
        newState,
        state,
        {
          createTicket: {
            inProgress: false,
            isSuccess: true
          }
        });

      return newState;

    case types.CREATE_TICKET_FAILURE:
      newState = Object.assign(
        newState,
        state,
        {
          createTicket: {
            inProgress: false,
            isSuccess: false
          }
        });

      return newState;

    case types.GET_ALL_TICKETS_INPROGRESS:
      // if request is for the same user id, do not clear the current cached ticket from the store
      currentUserId = _.get(state.fetchTickets, 'userId', false);
      currentTickets = _.get(state.fetchTickets, 'tickets', false);

      newState = Object.assign(
        newState,
        state,
        {
          fetchTickets: {
            userId: action.userId,
            inProgress: true,
            isSuccess: false,
            tickets: currentUserId === action.userId ? currentTickets : []
          }
        });

      return newState;

    case types.GET_ALL_TICKETS_SUCCESS:
      currentUserId = _.get(state.fetchTickets, 'userId', false);

      newState = Object.assign(
        newState,
        state,
        {
          fetchTickets: {
            userId: currentUserId,
            inProgress: false,
            isSuccess: true,
            tickets: action.tickets
          }
        });
      return newState;

    case types.GET_ALL_TICKETS_FAILURE:
    case types.CLEAR_ALL_TICKETS:
      newState = Object.assign(
        newState,
        state,
        {
          fetchTickets: {
            inProgress: false,
            isSuccess: false,
            tickets: []
          }
        });

      return newState;

    case types.GET_ALL_COMMENTS_INPROGRESS:
      // if request is for the same ticket id, do not clear the current cached ticket from the store
      currentTicketId = _.get(state.fetchComments, 'ticketId', false);
      currentComments = _.get(state.fetchComments, 'comments', false);

      newState = Object.assign(
        newState,
        state,
        {
          fetchComments: {
            ticketId: action.ticketId,
            inProgress: true,
            isSuccess: false,
            comments: currentTicketId === action.ticketId ? currentComments : null
          }
        });

      return newState;

    case types.GET_ALL_COMMENTS_SUCCESS:
      currentTicketId = _.get(state.fetchComments, 'ticketId', false);

      newState = Object.assign(
        newState,
        state,
        {
          fetchComments: {
            ticketId: currentTicketId,
            inProgress: false,
            isSuccess: true,
            comments: action.currentTicketComments
          }
        });

      return newState;

    case types.GET_ALL_COMMENTS_FAILURE:
    case types.CLEAR_ALL_COMMENTS:
      newState = Object.assign(
        newState,
        state,
        {
          fetchComments: {
            inProgress: false,
            isSuccess: false,
            comments: {}
          }
        });

      return newState;

    case types.CREATE_COMMENT_INPROGRESS:
      newState = Object.assign(
        newState,
        state,
        {
          createComment: {
            inProgress: true,
            isSuccess: false
          }
        });

      return newState;

    case types.CREATE_COMMENT_SUCCESS:
      newState = Object.assign(
        newState,
        state,
        {
          createComment: {
            inProgress: false,
            isSuccess: true
          }
        });

      return newState;

    case types.CREATE_COMMENT_FAILURE:
      newState = Object.assign(
        newState,
        state,
        {
          createComment: {
            inProgress: false,
            isSuccess: false
          }
        });

      return newState;

    default:
      return state;
  }
}
