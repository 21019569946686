const device = (state) => state.device;
// VALIDATE
const validateLoading = (state) => state?.device?.validateLoading;
const validateData = (state) => state?.device?.validateData;
const validateError = (state) => state?.device?.validateError;
// STATUS
const statusLoading = (state) => state?.device?.statusLoading;
const statusData = (state) => state?.device?.statusData;
const statusError = (state) => state?.device?.statusError;
// TESTING ONLY
const codeLoading = (state) => state?.device?.codeLoading;
const codeData = (state) => state?.device?.codeData;
const codeError = (state) => state?.device?.codeError;
// TESTING ONLY
const pollLoading = (state) => state?.device?.pollLoading;
const pollData = (state) => state?.device?.pollData;
const pollError = (state) => state?.device?.pollError;

export {
  device,
  validateLoading,
  validateData,
  validateError,
  statusLoading,
  statusData,
  statusError,
  codeLoading,
  codeData,
  codeError,
  pollLoading,
  pollData,
  pollError,
};
