export const getCompetition = (competitonID) => {
  switch (competitonID) {
    case 8:
      return 'epl';
    case 5:
      return 'uefa-champions-league';
    case 6:
      return 'uefa-europa-league';
    case 941:
    case 336:
    case 1166:
      return 'internationals';
    case 550:
      return 'womens';
    case 335:
      return 'fifa-club-world-cup';
    case 20:
      return 'j-league';
    // case 3:
    //   return 'uefa-euro-2024';
    case 235:
      return 'internationals';
    case 205:
      return 'k-league';
    // case 128:
    //   return 'copa-america-2024';
    case 408:
    case 642:
      return 'womens-world-cup-2023';
    case 231:
      return 'dfb-pokal';
    case 832:
      return 'nwsl';
    case 668:
      return 'fa-cup';
    default:
      return 'epl';
  }
};
