import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

class DropDown extends React.Component {


  componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClick = (e) => {
    if (!this.node || this.node.contains(e.target)) {
      return;
    }
    const { onOutSideClick } = this.props;
    onOutSideClick && onOutSideClick(e);
  };

  render() {

    const { children, open, width, className } = this.props;

    return (
      <CSSTransition
        in={open}
        timeout={500}
        classNames="os-dropdown"
        unmountOnExit>
        <ul
          ref={ node => { this.node = node; }}
          className={`bg-widget-grey pl-0 shadow-xl ${width}${className ? ` ${className}` : ''}`}>
          {children}
        </ul>
      </CSSTransition>
    )

  }

}

DropDown.propTypes = {
  /** Current open state of this dropdown */
  open: PropTypes.bool,
  /** Classes to apply to the root element of this component. Classes added here will override any default classes or props that accept classes. For example width - use with caution. */
  className: PropTypes.string,
  /** Width supports a Tailwind width class passed on as string or a fixed width passed on as number in rem.**/
  width: PropTypes.string,
  /** Function to invoke while clicking outside of the drop down. The function will be invoked with the original click event. */
  onOutSideClick: PropTypes.func,
};

DropDown.defaultProps = {
  open: false,
  className: null,
  width: 'w-269',
  onOutSideClick: null,
};

export default DropDown;
