import styled, { css } from 'styled-components';

const ToggleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 16px;
`;

const ToggleItem = styled.button`
  background: white;
  border-radius: 4px;
  padding: 10px 14px;
  min-width: 100px;
  width: calc(100% - 8px);
  box-sizing: border-box;
  margin-left: 16px;
  border: 1px solid #e3e5eb;
  color: #a9aeb9;
  &:first-child {
    margin-left: 0;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    color: black;
  }
  ${(props) =>
    props.isActive &&
    css`
      border: 1px solid #1d2a43;
      color: #1d2a43;
    `}
`;

const Label = styled.label`
  margin-bottom: 8px;
  color: black;
  line-height: 16px;
  color: #1d2a43;
  font-weight: 500;
  display: block;
  font-size: 14px;
`;

export { ToggleContainer, ToggleItem, Label };
