import defaultConfig from 'constants/defaultConfig';
import { isEmpty } from 'lodash';
import { isMobileWebView } from 'utils';
import { getArticleSectionPathName } from 'utils/getAssetData';
import { getBaseHostname } from 'utils/path';

const isProd = defaultConfig?.env === 'prod';

function getChartBeatAPI() {
  const { pSUPERFLY } = window || {};
  return pSUPERFLY;
}

function chartBeatTrack(data) {
  const chartBeatAPI = getChartBeatAPI();
  if (chartBeatAPI && chartBeatAPI.virtualPage && data?.title && data?.path) {
    chartBeatAPI.virtualPage(data);
  }
}

function isTitle(str) {
  return !isEmpty(str) && str;
}

function chartBeatArticleDataMap(data) {
  const authors = data?.author?.name ?? '';
  const title = isTitle(data?.seoTitle) || isTitle(data?.title) || '';
  const sections = data?.category?.name ?? '';
  const path = getBaseHostname() + getArticleSectionPathName(data);
  return { authors, title, sections, path };
}

function chartBeatPageDataMap(data) {
  const title = data?.documentTitle ?? '';
  const path = getBaseHostname() + data?.pathname ?? '';
  const sections = '';
  const authors = '';

  return { authors, title, sections, path };
}

function chartBeatTrackEnd() {
  const chartBeatAPI = getChartBeatAPI();
  chartBeatAPI && chartBeatAPI.endTracking && chartBeatAPI.endTracking();
}

function setChartBeatUserType(userType) {
  const _cbq = (window._cbq = window._cbq || []);
  _cbq.push(['_acct', userType]);
}

function chartBeatInit({ data, chartBeatUid, callback }) {
  if (getChartBeatAPI() && typeof callback === 'function') {
    callback(true);
    return false;
  }
  const chartBeatMabScriptEl = document.createElement('script');
  chartBeatMabScriptEl.src = '//static.chartbeat.com/js/chartbeat_mab.js';
  chartBeatMabScriptEl.setAttribute('async', 'true');
  document.head.appendChild(chartBeatMabScriptEl);

  const _sf_async_config = (window._sf_async_config =
    window._sf_async_config || {});
  _sf_async_config.uid = chartBeatUid;
  _sf_async_config.domain = isProd
    ? 'sport.optus.com.au'
    : 'test.sport.optus.com.au';

  if (data?.title) _sf_async_config.title = data?.title;
  if (data?.sections) _sf_async_config.sections = data?.sections;
  if (data?.authors) _sf_async_config.authors = data?.authors;
  if (data?.path) _sf_async_config.path = data?.path;

  if (isMobileWebView()) {
    // if the user is on Optus Sport App
    _sf_async_config.mobileApp = true;
  }

  const chartBeatScriptEl = document.createElement('script');
  chartBeatScriptEl.src = '//static.chartbeat.com/js/chartbeat.js';
  chartBeatScriptEl.onload = () => {
    if (typeof callback === 'function') callback(true);
  };
  chartBeatScriptEl.setAttribute('type', 'text/javascript');
  chartBeatScriptEl.setAttribute('async', 'true');

  document.head.appendChild(chartBeatScriptEl);
}

export {
  chartBeatInit,
  chartBeatTrack,
  chartBeatTrackEnd,
  chartBeatArticleDataMap,
  chartBeatPageDataMap,
  setChartBeatUserType,
};
