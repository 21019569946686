import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function allresults(state = initialState.allresults, action) {
  let currentAllResults;

  switch (action.type) {

    case types.GET_ALLRESULTS_INPROGRESS:
      currentAllResults = {
        ...state,
        inProgress: true,
        isSuccess: false
      };

      return currentAllResults;

    case types.GET_ALLRESULTS_SUCCESS:    
      return {
        ...state,
        inProgress: false,
        isSuccess: true,
        allresults: [...action.allresults.rows]
      }


    case types.GET_ALLRESULTS_FAILURE:
      currentAllResults = {
        // for failure case, use whatever has been loaded before
        ...state,
        inProgress: false,
        isSuccess: false
      };

      return currentAllResults;

    default:
      return state;
  }
}

