import React, { useContext } from 'react';

import {
  ContentCardContext,
  ContentCardDispatchContext,
} from '../ContentCardContext';

function ActionComponent() {
  const dispatch = useContext(ContentCardDispatchContext);
  const contentCardContext = useContext(ContentCardContext);

  const { asset, cardClicked, primaryButtonClicked, secondaryButtonClicked } =
    contentCardContext || {};

  const {
    contentCard: {
      actions: {
        cardClickAction,
        primaryButtonAction,
        secondaryButtonAction,
      } = {},
    } = {},
  } = asset || {};

  const renderCardActionComponent = () => {
    const { component } = cardClickAction || {};
    return cardClicked && component ? (
      <>{component({ dispatch, contentCardContext })}</>
    ) : (
      <></>
    );
  };

  const renderPrimaryButtonActionComponent = () => {
    const { component } = primaryButtonAction || {};
    return primaryButtonClicked && component ? (
      <>{component({ dispatch, contentCardContext })}</>
    ) : (
      <></>
    );
  };

  const renderSecondaryButtonActionComponent = () => {
    const { component } = secondaryButtonAction || {};
    return secondaryButtonClicked && component ? (
      <>{component({ dispatch, contentCardContext })}</>
    ) : (
      <></>
    );
  };

  return (
    <>
      {renderCardActionComponent()}
      {renderPrimaryButtonActionComponent()}
      {renderSecondaryButtonActionComponent()}
    </>
  );
}

export default ActionComponent;
