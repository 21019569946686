import React from 'react';

const StatsContainer = ({ statsList, title }) => (
    <div className="bg-widget-grey flex flex-col w-full px-15 pb-8 xl:pb-16">
    <div className="w-full my-16 text-center">
    <span className="text-white text-base font-MarkProHeavy leading-none">
    {title}
    </span>
    </div>
    <div className-="w-full">
      {statsList}
    </div>
    </div>

);


export default StatsContainer;