import React from 'react';

import Loader from '../../../common/Loader';

function SectionLoader() {
  return (
    <div className='my-96'>
      <Loader strokeWidth={8} width={50} height={50} />
    </div>
  );
}

export default SectionLoader;
