import React, { useEffect } from 'react';
import styled from 'styled-components';

import { SearchBox } from '../search';

const SearchSectionStyled = styled.div.attrs((props) => ({
  className: `os-transition w-full xl:pt-0 mx-auto md:max-w-472 lg:max-w-630 overflow-y-auto opacity-0 pointer-events-none z-1 ${
    props.open ? 'opacity-100 pointer-events-auto' : ' hidden'
  }`,
  style: { maxHeight: 'calc(100vh - 48px)' },
}))``;

export default function SearchSection({
  open = false,
  className,
  children,
  action,
  actionText,
}) {
  useEffect(() => {
    if (document) {
      const htmlElement = document.getElementsByTagName('html')[0];
      const isElementReady =
        htmlElement &&
        htmlElement.classList &&
        typeof htmlElement.classList.remove === 'function' &&
        typeof htmlElement.classList.add === 'function';

      if (isElementReady) {
        if (open) {
          htmlElement.classList.remove('overflow-y-auto');
          htmlElement.classList.add('overflow-y-hidden');
        } else {
          htmlElement.classList.remove('overflow-y-hidden');
          htmlElement.classList.add('overflow-y-auto');
        }
      }
    }

    return () => {
      const htmlElement = document.getElementsByTagName('html')[0];
      const isElementReady =
        htmlElement &&
        htmlElement.classList &&
        typeof htmlElement.classList.remove === 'function' &&
        typeof htmlElement.classList.add === 'function';

      if (isElementReady) {
        htmlElement.classList.remove('overflow-y-hidden');
        htmlElement.classList.add('overflow-y-auto');
      }
    };
  }, [open]);

  return (
    <SearchSectionStyled open={open} className={className}>
      <div className="flex w-full items-stretch px-16 xl:px-0">
        {open && (
          <SearchBox
            actionText={actionText}
            action={action}
            className="flex-1"
          />
        )}
        {children}
      </div>
    </SearchSectionStyled>
  );
}
