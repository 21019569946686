import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import defaultConfig from '../../../../constants/defaultConfig';
import VideoCard from './asset-card/AssetCard';
import selectors from './selectors';
import { selectors as storeSelector } from '../../../../store/createPortalStore';
import Header from './Header/';
import ContentCardAUI from '../../PromotionalPlacements/layouts/ContentCardAUI';
import ContentCardAsset from '../../PromotionalPlacements/components/ContentCardAsset';
import { assetFilter } from '../../PromotionalPlacements/utils';
import { ContentCardTypeName } from '../../PromotionalPlacements/constants';
import SectionLoader from '../../../ui/ui-kit/SectionLoader';
/**
 * Responsive Component to create 2 Hero cards
 *
 * Desktop mode: 2 Hero cards in 1 row
 *
 * Tablet & Mobile: Smaller cards in 2 separate rows
 */

class LayoutB2Heroes extends Component {
  render() {
    const {
      imageBaseUrl,
      editorial,
      categoryMapping,
      categoryPath,
      carousel,
      theme,
      userTags,
      contentCardsInObj: {
        isReady: isContentCardsReady,
        data: contentCardsInObj,
      },
    } = this.props;
    if (!userTags || !isContentCardsReady) {
      return <SectionLoader />;
    }

    let assets = assetFilter(
      _.get(editorial, 'assets', []),
      userTags,
      contentCardsInObj,
    );
    assets = assets.slice(0, assets.length >= 2 ? 2 : assets.length);

    const videoCards = assets.map((asset) => {
      const { assetTypeName } = asset;
      return (
        <div key={`${editorial.id}-${asset?.id}`} className='item'>
          {assetTypeName === ContentCardTypeName ? (
            <ContentCardAsset
              assetId={asset?.id}
              uiComponent={ContentCardAUI}
              editorialId={editorial.id}
            />
          ) : (
            <VideoCard
              asset={asset}
              imageBaseUrl={imageBaseUrl}
              categoryMapping={categoryMapping}
              theme={theme}
              themeType='b'
              className='rounded-t-xl'
              editorialId={editorial.id}
            />
          )}
        </div>
      );
    });

    const showViewAllLink = carousel.showViewAllLink;
    return (
      <>
        {videoCards.length ? (
          <div
            className={`
              flex
              flex-col
              w-full
            `}
          >
            <Header
              title={_.get(carousel, 'title', _.get(editorial, 'title', ''))}
              categoryPath={categoryPath}
              theme={theme}
              showViewAllLink={showViewAllLink}
            />
            <div className='layout-b-card-list'>{videoCards}</div>
          </div>
        ) : null}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const editorial = state.editorials.find(
    (ed) => ed.id === ownProps.carousel.id,
  );
  const imageBaseUrl =
    _.get(state, 'systemConfig.image.baseUrl', null) ||
    defaultConfig.image.baseUrl;
  const carouselImageSize =
    _.get(state, 'systemConfig.image.sizes.carouselImageSize', null) ||
    defaultConfig.image.sizes.carouselImageSize;
  const assetTypeNames = _.get(
    state,
    'systemConfig.hideCarouselModal.assetTypeNames',
    [],
  );
  const categoryMapping = _.get(state, 'systemConfig.categoryMapping', []);
  const userTags = storeSelector.user.getUserTags(state);
  const contentCardsInObj = storeSelector.contentCards.getContentCardsInObj(
    state,
  );

  return {
    imageBaseUrl: imageBaseUrl,
    carouselImageSize: carouselImageSize,
    assetTypeNames: assetTypeNames,
    categoryMapping: categoryMapping,
    editorial: editorial,
    categoryPath: selectors.getCategoryPath(ownProps, editorial),
    userTags,
    contentCardsInObj,
  };
}

export default connect(mapStateToProps)(LayoutB2Heroes);
