import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function pages(state = initialState.pages, action) {
  let currentPage;
  switch (action.type) {
    case types.GET_PAGE_INPROGRESS:
      currentPage = {
        ...state.find(page => page.id === action.page.id),
        id: action.page.id,
        inProgress: true,
        isSuccess: false
      };

      return [
        ...state.filter(page => page.id !== action.page.id),
        currentPage
      ];

    case types.GET_PAGE_SUCCESS:
      return [
        ...state.filter(page => page.id !== action.page.id),
        {
          ...action.page,
          inProgress: false,
          isSuccess: true
        }
      ];

    case types.GET_PAGE_FAILURE:
      currentPage = {
        ...state.find(page => page.id === action.page.id),
        id: action.page.id,
        inProgress: false,
        isSuccess: false
      };

      return [
        ...state.filter(page => page.id !== action.page.id),
        currentPage
      ];

    default:
      return state;
  }
}
