import React from 'react';
import PropTypes from 'prop-types';

const IconSmallRight = ({ width, height, color, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32" {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M0 0h32v32H0z"/>
      <path stroke={color} strokeWidth="1.5" d="M13.97 21.275L19 15.638 13.97 10"/>
    </g>
  </svg>
);

IconSmallRight.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

IconSmallRight.defaultProps = {
  color: 'currentColor',
  width: 32,
  height: 32
};

export default IconSmallRight;
