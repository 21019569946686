import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function documentTitle(state = initialState.documentTitle, action) {

  switch (action.type) {
    case types.SET_DOCUMENT_TITLE: {
      return action.title
    }

    default:
      return state;
  }
}

