import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import set from 'lodash/set';
import * as types from '../constants/actionTypes';
import defaultConfig from 'constants/defaultConfig';
import API from '@aws-amplify/api';

export function getTeams(validCompetitionIds) {
  return (dispatch) => {
    const {
      VCMSConfig: { allTeamsUrl },
    } = defaultConfig;
    dispatch({ type: types.GET_TEAMS_INPROGRESS });
    API.get('fe-api-dev-metadataGetPage', allTeamsUrl, {})
      .then((res) => {
        const teams = get(res, 'teams', {});
        if (!isEmpty(teams)) {
          const validTeams = getValidTeams(teams, validCompetitionIds);

          teams.rows = validTeams;
          dispatch({ type: types.GET_TEAMS_SUCCESS, teams });
        } else {
          dispatch({ type: types.GET_TEAMS_FAILURE });
        }
      })
      .catch((error) => {
        dispatch({ type: types.GET_TEAMS_FAILURE });
      });
  };
}

export function getFilteredTeams(validCompetitionIds) {
  return (dispatch) => {
    const {
      VCMSConfig: { allTeamsUrl },
    } = defaultConfig;

    dispatch({ type: types.GET_TEAMS_INPROGRESS });
    API.get(
      'fe-api-dev-metadataGetPage',
      `${allTeamsUrl}?filterByShortCode=true`,
      {},
    )
      .then((res) => {
        const teams = get(res, 'teams', {});
        if (!isEmpty(teams)) {
          const validTeams = getValidTeams(teams, validCompetitionIds);

          teams.rows = validTeams;
          dispatch({ type: types.GET_FILTERED_TEAMS_SUCCESS, teams });
        } else {
          dispatch({ type: types.GET_TEAMS_FAILURE });
        }
      })
      .catch((error) => {
        dispatch({ type: types.GET_TEAMS_FAILURE });
      });
  };
}

const getValidTeams = (teams, validCompetitionIds) => {
  const validTeams = teams.filter((team) => {
    let teamCompetitions = team.competitions;
    teamCompetitions = teamCompetitions.filter((tc) =>
      validCompetitionIds.has(Number(tc.optaId)),
    );
    team.competitions = teamCompetitions;

    // Transform for backwards compatibility from VCMS <-> Infinit
    const teamId = team.TeamID || team.optaId;
    const teamShortName = team.SYMID || team.shortCode;
    set(team, 'TeamID', teamId);
    set(team, 'SYMID', teamShortName);

    return teamCompetitions.length > 0;
  });

  return validTeams;
};
