import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function teamPreview(state = initialState.teamPreview, action) {
  let currentTeamPreview;

  switch (action.type) {

    case types.GET_TEAMPREVIEW_INPROGRESS:
      currentTeamPreview = {
        ...state.find(teamPreview => teamPreview.competitionId === action.results.competitionId || teamPreview.season === action.results.season),
        ...action.results,
        rows: [],
        inProgress: true,
        isSuccess: false
      };

      return [
        ...state.filter(teamPreview => teamPreview.competitionId !== action.results.competitionId || teamPreview.season !== action.results.season),
        currentTeamPreview
      ];

    case types.GET_TEAMPREVIEW_SUCCESS:

      return [
        ...state.filter(teamPreview => teamPreview.competitionId !== action.results.competitionId || teamPreview.season !== action.results.season),
        {
          ...action.results,
          inProgress: false,
          isSuccess: true
        }
      ];

    case types.GET_TEAMPREVIEW_FAILURE:
      currentTeamPreview = {
        // for failure case, use whatever has been loaded before
        ...state.find(teamPreview => teamPreview.competitionId === action.results.competitionId || teamPreview.season === action.results.season),
        ...action.results,
        rows: [],
        inProgress: false,
        isSuccess: false
      };

      return [
        ...state.filter(teamPreview => teamPreview.competitionId !== action.results.competitionId || teamPreview.season !== action.results.season),
        currentTeamPreview
      ];

    default:
      return state;
  }
}
