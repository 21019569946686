import React from 'react';
import PropTypes from 'prop-types';

const WinIcon = ({ width, height, viewBox, ...props }) => (

<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox} {...props}>
    <g fill="none" fillRule="evenodd">
        <circle cx="10" cy="10" r="10" fill="#00FF91"/>
        <path fill="#172659" d="M16.069 6l-2.389 8h-1.931l-1.715-5.714L8.32 14H6.389L4 6h1.657l1.72 6.137L9.166 6h1.765l1.789 6.137L14.44 6z"/>
    </g>
</svg>
);

WinIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  viewBox: PropTypes.string
};

WinIcon.defaultProps = {
  width: 16,
  height: 16,
  viewBox: '0 0 20 20'
};

export default WinIcon;



