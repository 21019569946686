import get from 'lodash/get';
import axios from 'axios';
import * as types from '../constants/actionTypes';
import defaultConfig from 'constants/defaultConfig';

export function getMatchHighlights(competitionId, season, matchId) {
  return (dispatch) => {
    const {
      vcmsBaseUrl,
      VCMSConfig: { matchHighlightsEndpoint },
    } = defaultConfig;
    if (!competitionId || !matchId || !season) {
      return;
    }
    const url = `${vcmsBaseUrl}${matchHighlightsEndpoint}?season=${season}&competition=${competitionId}&match=${matchId}`;
    const matchhighlights = { competitionId, season, matchId };
    dispatch({ type: types.GET_MATCHHIGHLIGHTS_INPROGRESS, matchhighlights });

    axios
      .get(url)
      .then((res) => {
        const isSuccess = get(res, 'data.result[0].statuscode', 'N') === 'Y';

        if (isSuccess) {
          matchhighlights.rows = get(
            res,
            'data.APIinfo[0].HighlightInfo',
            [],
          );
          dispatch({
            type: types.GET_MATCHHIGHLIGHTS_SUCCESS,
            matchhighlights,
          });
        } else {
          dispatch({
            type: types.GET_MATCHHIGHLIGHTS_FAILURE,
            matchhighlights,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: types.GET_MATCHHIGHLIGHTS_FAILURE, matchhighlights });
      });
  };
}
