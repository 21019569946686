import React from 'react';
import PropTypes from 'prop-types';

const renderName = name => <span className="text-white text-2xs mx-5 leading-base">{name}</span>;

const renderTime = time => <span className="text-white text-2xs font-MarkProBold leading-base">{time}</span>;

const renderIcon = icon => <div className="h-16 w-16">{icon}</div>

const ResultSummaryRow = ({time, name, icon, className, isOnLeft}) => (
  <div className={`flex flex-row items-center ${isOnLeft ? 'justify-end' : 'justify-start'}${className === null ? '' : ` ${className}`}`}>
    {isOnLeft ? renderTime(time) : renderIcon(icon)}
    { renderName(name) }
    {isOnLeft ? renderIcon(icon) : renderTime(time)}
  </div>
);

ResultSummaryRow.propTypes = {
  /** Time to show */
  time: PropTypes.string.isRequired,
  /** Name of the player/team */
  name: PropTypes.string.isRequired,
  /** Icon to show */
  icon: PropTypes.node.isRequired,
  /** Whether icon is on the left or not. */
  isOnLeft: PropTypes.bool,
  /** Classes to apply to the root element of this component. Classes added here will override any default classes or props that accept classes. For example width - use with caution. */
  className: PropTypes.string,
};

ResultSummaryRow.defaultProps = {
  isOnLeft: true,
  className: null,
};

export default ResultSummaryRow;
