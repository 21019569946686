import React from 'react';
import PropTypes from 'prop-types';

const DownIcon = ({ width, height, color,invert, ...props }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={`0 0 32 32`} {...props} transform={`${invert ? 'rotate(180)': ''}`}>
  <g fill="none" fillRule="evenodd">
  <path fill="none" d="M0 0h32v32H0z"/>
  <path stroke={color} strokeWidth="1.5" d="M21.275 13.97L15.638 19 10 13.97"/>
  </g>
  </svg>
  );

DownIcon.propTypes = {
  /** Whether to flip this icon to become UpIcon **/
  invert: PropTypes.bool,
  /** Color for this icon **/
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

DownIcon.defaultProps = {
	width: 32,
	height: 32,
  invert: false,
  color: '#FFF'
};

export default DownIcon;
