import React, { Component } from "react";
import PropTypes from "prop-types";

class TeamColor extends Component {
  render() {
    return (
        <div className="teamlisting-team-color-bg w-full h-full relative" style={{ backgroundColor: this.props.teamColor}} />
    )
  }
}

TeamColor.propTypes = {
    /** Team Color */
    teamColor: PropTypes.string.isRequired
};

TeamColor.defaultProps = {
    teamColor: '#ff0'
};

export default TeamColor;