import React from 'react';
import PropTypes from 'prop-types';

const WhistleIcon = ({ width, height, viewBox, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox} {...props}>
    <g fill="none" fillRule="evenodd" stroke="#FFF">
        <path d="M10 1.317v3.53M14.72 2.436l-1.911 1.911M7.376 4.347L5.465 2.436M1.28 6.183l1.01 4.223 3.855.826s.918 3.488 4.039 3.488c3.121 0 4.223-2.387 4.223-3.856 0-1.927-1.285-4.13-4.039-4.406-2.754 0-9.088-.275-9.088-.275z"/>
    </g>
  </svg>

);

WhistleIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  viewBox: PropTypes.string
};

WhistleIcon.defaultProps = {
  width: 32,
  height: 32,
  viewBox: '0 0 32 32'
};

export default WhistleIcon;



