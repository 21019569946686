import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Countdown from 'react-countdown-now';
import jsCookie from 'js-cookie';
import { triggerGoogleAnalyticsEvent } from 'utils';
import durationToTime from 'utils/time';
import { getTimezoneAbbr } from 'utils/timezone';
import defaultConfig from 'constants/defaultConfig';
import { getPlaybackPath, getMobileDeviceQueryString } from 'utils/path';
import {
  getArticleSectionPathName,
  shouldShowFreeBadge,
} from 'utils/getAssetData';

import {
  getImageUrls,
  getImageUrl,
  placeholderImg,
} from '../ui-kit/Articles/utils';
import { replaceImageSize } from 'utils';
import FreemiumBadge from '../ui-kit/Icons/FreemiumBadge';
import Picture from '../Picture';
import { getCategoryTitle } from '../new-home/utils';
import ArticleIcon from '../ui-kit/Icons/ArticleIcon';
import styles from './styles';
import analytics from './analytics';
import { isLoggedIn, isPremiumUser } from 'selectors/user';
import { assetClickedEventDispatcher } from 'analytic/util';

class Card extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isLive: false,
    };
  }

  countdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      // return <Completionist />;
    } else {
      // Render a countdown
      if (days < 1 && hours < 12) {
        return (
          <span className='h-24 mt-12 countdown-wrap font-MarkProBold text-2xs text-white pt-0 pb-4 px-8'>
            <span className='countdown-label-text'>Coverage in </span>
            <span className='os-tabular-nums'>
              {hours}:{minutes}:{seconds}
            </span>
          </span>
        );
      }
    }
    return null;
  };

  clickEventDispatcher = () => {
    const { pages, navigations, asset, editorialId, editorials } = this.props;
    assetClickedEventDispatcher({
      pages,
      navigations,
      asset,
      editorialId,
      editorials,
    });
  };

  render() {
    const {
      eventDate,
      competitionData,
      timezoneAbbr,
      isLive,
      isReplay,
      title,
      duration,
      isArticle,
      isPremiumUser,
      categoryMapping,
      type,
      newHome,
      assetTypeName,
      categoryTitle,
      pictureDom,
      theme = 'dark',
      fitnessLanding,
      width,
      className,
      history,
      page,
      carouselId,
      userLoggedIn,
    } = this.props;

    // OLD URL
    const asset = this.props.asset;
    const carouselType = this.props.carouselType;
    const extURL = _.get(this.props.asset, 'externalUrl', null);
    const sectionPath = _.get(this.props.asset, 'sectionPath', null);
    const assetId = _.get(asset, 'id');
    const assetSlug = _.get(asset, 'slug');
    const hasAssetSlug = isArticle && assetId && assetSlug;
    let assetPath = hasAssetSlug
      ? getArticleSectionPathName(asset)
      : extURL || sectionPath || getPlaybackPath(asset, categoryMapping);

    const localTime = moment();
    const formattedMatchData = eventDate.format('llll');

    const url =
      carouselType === 'explore'
        ? asset.deeplinkUrl
        : `${assetPath}${getMobileDeviceQueryString(assetPath)}`;

    const hyperlinkWidth = 'w-269 sm:w-279';

    return (
      <a
        href={url}
        target={extURL ? '_blank' : this.props.target}
        className={`text-justify font-white cursor-pointer inline-block no-underline ${width ||
          hyperlinkWidth} ${className} ${styles[theme]?.container}`}
        onClick={(e) => {
          this.clickEventDispatcher();
          if (!extURL && this.props.target !== '_blank') {
            e.preventDefault();
            history.push(url);
          }

          if (page === 'fitness-vod') {
            const analyticsEvent = analytics[carouselId];
            if (analyticsEvent) {
              triggerGoogleAnalyticsEvent(
                analyticsEvent.category,
                analyticsEvent.action,
                analyticsEvent.label,
              );
            }
          }

          if (fitnessLanding) {
            jsCookie.set('trigger_fitnesslanding_analytics', true);
          }
        }}
      >
        <div className={`relative ${type === 'teamVideo' ? 'mb-0' : 'mb-4'}`}>
          <div className='pointer-events-none'>
            {this.props.lazyLoaded && pictureDom}
          </div>
          {isArticle ? null : (
            <div
              className={`ml-10 mt-10 rounded-md absolute pin-t pin-l max-h-24 bg-black80 text-center inline-flex items-center ${styles[theme]?.message}`}
            >
              {(isLive && !localTime.isBefore(eventDate)) ||
              this.state.isLive === true ? (
                <span className='font-MarkProHeavy leading-1.6 text-live-red text-2xs py-4 px-8'>
                  <span className='inline-block live-dot h-6 w-6 rounded-full bg-live-red mr-4' />
                  LIVE
                </span>
              ) : localTime.isBefore(eventDate) ? (
                <Countdown
                  date={formattedMatchData}
                  onComplete={() => this.setState({ isLive: true })}
                  renderer={this.countdownRenderer}
                />
              ) : (
                <span
                  className={`font-MarkProBold text-2xs leading-base py-4 px-8 ${
                    duration && !isReplay ? '' : 'hidden'
                  }`}
                >
                  {durationToTime(duration)}
                </span>
              )}
            </div>
          )}
          {newHome && isArticle && (
            <div className='absolute z-49 os-purple-gradient os-bottom-0 os-right-0 w-80 h-80'>
              <ArticleIcon
                className='absolute z-50 opacity-100 os-bottom-8 os-right-8'
                width={24}
                height={24}
                viewBox={`0 0 24 24`}
              />
            </div>
          )}
          {shouldShowFreeBadge(isPremiumUser, userLoggedIn, asset) ? (
            <FreemiumBadge />
          ) : null}
        </div>
        {type === 'teamVideo' && (
          <React.Fragment>
            <div
              className={`rounded-b-xl h-104 bg-dark-grey overflow-hidden w-full py-8 px-16
                ${styles[theme]?.content}`}
            >
              <div className='w-full flex justify-between h-16 mt-8 mb-8'>
                <span className='text-xs text-teal font-MarkProBold capitalize'>
                  {asset.assetTypeName}
                </span>
              </div>
              <div className='min-h-32 overflow-hidden w-full'>
                <span className='font-MarkProHeavy leading-24px text-base block text-left relative'>
                  <div className='text-ellipsis-1'>{title}</div>
                </span>
              </div>
            </div>
          </React.Fragment>
        )}
        {type !== 'teamVideo' && carouselType !== 'explore' && (
          <React.Fragment>
            <div
              className={`
                min-h-32
                ${
                  newHome
                    ? fitnessLanding
                      ? 'h-128 justify-center pt-8'
                      : 'h-auto pt-8'
                    : 'max-h-48 py-8'
                }
                overflow-hidden
                w-full
                flex
                flex-col
                ${styles[theme]?.content}
              `}
            >
              <span
                className={`
                  ${
                    newHome
                      ? 'font-MarkProBold mt-2 mb-4 '
                      : 'font-MarkProHeavy '
                  }
                  ${
                    fitnessLanding
                      ? 'text-base font-MarkProHeavy leading-20px'
                      : 'text-sm leading-16px'
                  } block text-left relative`}
              >
                <div className='text-ellipsis-2'>{title}</div>
              </span>
              {newHome && (
                <span
                  className={`font-MarkPro ${
                    fitnessLanding
                      ? 'text-base leading-lg '
                      : 'text-xs leading-loose'
                  } block text-left relative mt-4 mb-4`}
                >
                  <div className='text-ellipsis-1'>{categoryTitle}</div>
                </span>
              )}
            </div>
            {assetTypeName === 'game' &&
            (isLive || localTime.isBefore(eventDate)) ? (
              <React.Fragment>
                <div className='w-full text-left h-16 mb-8'>
                  <span className='text-xs text-left leading-loose'>
                    {formattedMatchData} {timezoneAbbr}
                  </span>
                </div>
                {(!newHome || (newHome && assetTypeName !== 'game')) &&
                  competitionData && (
                    <div className='w-full text-left h-16'>
                      <span className='text-xs text-left leading-loose'>
                        {competitionData}
                      </span>
                    </div>
                  )}
              </React.Fragment>
            ) : null}
          </React.Fragment>
        )}
      </a>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { navigation, pages, editorials } = state;
  const {
    asset,
    isArticle,
    articleCarousel,
    panelCoverSize,
    theme = 'dark',
    type,
  } = ownProps;

  const {
    assetTypeName = '',
    categoryTitle = 'Optus Sport',
    imageUrl_16_9 = null,
    match = null,
  } = asset;

  const userLoggedIn = isLoggedIn(state);
  const isUserPremium = isPremiumUser(state);

  let name,
    title,
    drmProtected = false,
    eventDate,
    isLive = false,
    isGame = false,
    isReplay = false,
    competitionData,
    timezoneAbbr,
    timezone,
    duration = '',
    imageUrl,
    imageUrls = null;

  const editorialsFlag = _.get(defaultConfig.apiFlags, 'editorials', false);
  imageUrl = editorialsFlag
    ? replaceImageSize(imageUrl_16_9, panelCoverSize)
    : `${ownProps.imageBaseUrl}${ownProps.asset.imagePackId}${panelCoverSize}`;
  let pictureDom;
  if (isArticle || articleCarousel || assetTypeName === 'article') {
    title = _.get(asset, 'title', _.get(asset, 'description', ''));
    title =
      title && title.length > 78 ? `${title.slice(0, 78)}...` : `${title}`;
    competitionData = title;
    eventDate = moment.utc(asset.updatedAt).local();
    timezone = moment
      .utc(asset.updatedAt)
      .tz(moment.tz.guess())
      .format('z');
    timezoneAbbr = timezone || getTimezoneAbbr(moment.tz.guess());

    if (articleCarousel) {
      const defaultMedia = _.get(asset, 'defaultMedia', null);
      imageUrl = _.get(defaultMedia, 'url', '');
      const images = _.get(defaultMedia, 'aspectRatio')
        ? _.get(
            defaultMedia.aspectRatio.find(({ title }) => title === '16:9'),
            'resolutions',
          )
        : undefined;
      imageUrls = articleCarousel
        ? getImageUrl(imageUrl, 320, images)
        : getImageUrls({
            imageUrl,
            images,
          });
    }
    pictureDom = (
      <Picture
        alt={title}
        src={imageUrl}
        srcSet={imageUrls}
        className={
          type === 'teamVideo'
            ? styles[theme]?.pictureTeamVideo
            : styles[theme]?.picture
        }
        //imageUrl_16_9={imageUrl_16_9}
      />
    );
  } else if (ownProps.carouselType === 'explore') {
    eventDate = moment.utc(asset.updatedAt).local();
    pictureDom = (
      <div
        className={`
          ${styles[theme]?.picture} overflow-hidden relative flex items-center justify-center flex-col
          h-152 sm:h-160 bg-cover
        `}
        style={{
          background: `url(${asset.backgroundImageUrl})`,
        }}
      >
        <img
          src={asset.tileIconUrl}
          alt='asset icon'
          className='h-64px sm:h-80px'
        />
        <span
          className={`sm:mt-10 font-MarkProBold sm:font-MarkProHeavy text-16px sm:text-24px font-bold sm:font-900 leading-24px sm:leading-32px text-center ${
            asset.textColor ? `text-${asset.textColor}` : ''
          }`}
        >
          {asset.title}
        </span>
      </div>
    );
  } else {
    isGame = assetTypeName === 'game';
    competitionData =
      asset && asset.optaCompetitionId
        ? defaultConfig.getCompetitionTitle(asset.optaCompetitionId)
        : null;
    eventDate = moment.utc(asset.liveBroadcastTime).local();
    timezone = moment
      .utc(asset.liveBroadcastTime)
      .tz(moment.tz.guess())
      .format('z');
    timezoneAbbr = timezone || getTimezoneAbbr(moment.tz.guess());
    name = asset.assetTypeName;
    title = asset.title;
    drmProtected = asset.drmProtected;
    isLive = asset.live;
    let { onDemandTimeBegin, onDemandTimeEnd } = asset;
    duration = asset.duration;
    if (onDemandTimeBegin && onDemandTimeEnd) {
      duration = parseInt(onDemandTimeEnd - onDemandTimeBegin, 10);
    }
    isReplay = isGame && duration;

    let viewProgress = 0;
    if (asset.duration && asset.position) {
      viewProgress = asset.position / (asset.duration * 1000);
      if (viewProgress > 1) viewProgress = 1;
    }
    pictureDom = (
      <div
        className={`
          ${
            type === 'teamVideo'
              ? styles[theme]?.pictureTeamVideo
              : styles[theme]?.picture
          }  overflow-hidden relative
        `}
      >
        <Picture
          alt={title}
          src={imageUrl}
          srcSet={imageUrls}
          //imageUrl_16_9={imageUrl_16_9}
        />
        {ownProps.carouselType === 'recently_watched' && (
          <div
            className='absolute h-6 bg-bright-light-blue'
            style={{
              width: `${viewProgress * 100}%`,
              bottom: 0,
            }}
          ></div>
        )}
      </div>
    );
  }

  return {
    eventDate,
    competitionData,
    timezone,
    timezoneAbbr,
    isGame,
    isLive,
    isReplay,
    title,
    name,
    categoryTitle: getCategoryTitle(assetTypeName, match, categoryTitle),
    labeledAsFree: _.get(asset, 'labeledAsFree', false),
    drmProtected,
    duration,
    imageUrl: imageUrl || placeholderImg,
    isArticle: isArticle || articleCarousel || assetTypeName === 'article',
    isPremiumUser: isUserPremium,
    userLoggedIn,
    assetTypeName,
    imageUrls,
    pictureDom,
    startDate: moment(_.get(asset, 'startDate', ''))
      .tz(moment.tz.guess())
      .format('ddd, DD MMM hh:mma z'),
    match,
    navigations: navigation?.navigations,
    pages,
    editorials,
  };
}

Card.propTypes = {
  imageBaseUrl: PropTypes.string.isRequired,
  panelCoverSize: PropTypes.string.isRequired,
  categoryMapping: PropTypes.array.isRequired,
  asset: PropTypes.object.isRequired,
  isPremiumUser: PropTypes.bool.isRequired,
  width: PropTypes.string,
  className: PropTypes.string,
  lazyLoaded: PropTypes.bool,
  theme: PropTypes.string,
  target: PropTypes.string,
  fitnessLanding: PropTypes.bool,
  page: PropTypes.string,
};

Card.defaultProps = {
  width: null,
  className: '',
  lazyLoaded: true,
  type: 'default',
  theme: 'dark',
  target: '_self',
  fitnessLanding: false,
  page: null,
};

export default connect(mapStateToProps)(withRouter(Card));
