import React from 'react';
import PropTypes from 'prop-types';

const DrawIcon = ({ width, height, viewBox, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox} {...props}>
    <g fill="none" fillRule="evenodd">
        <circle cx="10" cy="10" r="10" fill="#FFF"/>
        <path fill="#172659" d="M7 6h3.297c2.503 0 4.154 1.589 4.154 4s-1.651 4-4.154 4H7V6zm3.28 1.491H8.594v5.018h1.686c1.686 0 2.503-1.058 2.503-2.509 0-1.469-.817-2.509-2.503-2.509z"/>
    </g>
</svg>
);

DrawIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  viewBox: PropTypes.string
};

DrawIcon.defaultProps = {
    width: 16,
    height: 16,
    viewBox: '0 0 20 20'
};

export default DrawIcon;



