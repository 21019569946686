import React from 'react';
const IconPath = (props) => {
  const { fill = '#F00' } = props;
  return (
    <g fill="none" fillRule="evenodd">
      <path fill={fill} fillOpacity="0" d="M0 0h32v32H0z" />
      <path
        fill={fill}
        d="M24 11l-2 15H10L8 11h16zm-2.284 2H10.284l1.467 11h8.498l1.467-11zM15 15v7h-2v-7h2zm4 0v7h-2v-7h2zm1.214-10.984l.313 1.975 2.963-.47.313 1.976L8 10l-.313-1.975 2.963-.47-.313-1.975 9.877-1.564z"
      />
    </g>
  );
};

export default IconPath;
