export const disableBGScroll = () => (document.body.style.overflow = 'hidden');

export const enableBGScroll = () => (document.body.style.overflow = '');

export const getSelectorStyle = (indexValue, containerIndex) => {
  let initialTop,
    initialMargin,
    initialTotal,
    height = 0;

  if (containerIndex === 0) {
    initialTop = 72;
    initialMargin = 16;
    initialTotal = 144;
    height = 144;
  }

  if (containerIndex === 1) {
    initialTop = 144;
    initialMargin = 32;
    initialTotal = 288;
    height = 320;
  }

  if (containerIndex === 2) {
    initialTop = 288;
    initialMargin = 64;
    initialTotal = 288 * 2;
    height = 640;
  }
  switch (indexValue) {
    case 0:
      return { top: initialTop, right: '-16px', height: `${height}px` };
    default:
      const currentTotal = initialTotal * indexValue;
      const topValue =
        currentTotal + initialTop + (initialMargin + 10 * (indexValue - 2));
      return { top: `${topValue}px`, right: '-16px', height: `${height}px` };
  }
};

export const showConnectorLine = (compId, selectedRound, containerIndex) => {
  switch (compId) {
    default:
    case 5:
      return selectedRound !== 'R16' && containerIndex === 0;
    case 6:
      return selectedRound !== 'R32' && containerIndex === 0;
  }
};

export const getParameterByName = (name, url) => {
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const getManifestType = url => {
  const sourceStreamTypes = ['dash', 'hls'];
  const fileExtension = url.split(/#|\?/)[0].split('.').pop();
  return fileExtension === 'm3u8' ? sourceStreamTypes[1] : sourceStreamTypes[0];
};

export const checkIsNeededId3Type = type => {
  const id3Types = ['YMID', 'YDUR', 'YSEQ', 'YTYP', 'YCSP', 'YSCP'];
  return id3Types.includes(type);
};
