import React, { Component } from "react";

class LinearGradient extends Component {
  render() {
    return (
      <div className="opacity-70 teamlisting-linear-gradient-overlay w-full h-full" />
    )
  }
}

export default LinearGradient;