import get from 'lodash/get';
import axios from 'axios';
import * as types from '../constants/actionTypes';
import defaultConfig from 'constants/defaultConfig';

export function getTables(competitionId, season) {
  return (dispatch) => {
    const {
      vcmsBaseUrl,
      VCMSConfig: { resultsStandingsEndpoint },
    } = defaultConfig;
    const url = `${vcmsBaseUrl}${resultsStandingsEndpoint}?season=${season}&competition=${competitionId}`;
    const tables = { competitionId, season };

    dispatch({ type: types.GET_TABLES_INPROGRESS, tables });

    axios
      .get(url)
      .then((res) => {
        const isSuccess = get(res, 'data.result[0].statuscode', 'N') === 'Y';

        if (isSuccess) {
          tables.rows = get(res, 'data.APIinfo[0].Table', []);
          dispatch({ type: types.GET_TABLES_SUCCESS, tables });
        } else {
          dispatch({ type: types.GET_TABLES_FAILURE, tables });
        }
      })
      .catch((error) => {
        dispatch({ type: types.GET_TABLES_FAILURE, tables });
      });
  };
}
