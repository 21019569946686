import _ from 'lodash';
import pjson from '../../package.json';
import * as streamSessionAction from '../actions/streamSession';
import defaultConfig from '../constants/defaultConfig.js';

// Private vars, functions, and event listeners here while we get this working,
// shall be moved to config later
let lastTimeStamp = null;
let progressUpdaterTimeout = 60 * 1000; // 60 seconds

function _onHandleResponse(data) {
  const isKicked = _.get(data, 'item.isKicked');
  const respSessionId = _.get(data, 'item.sessionId');
  const currentViewingSessionId = _.get(PlayerSession, 'viewingSessionId');

  if (
    isKicked &&
    currentViewingSessionId &&
    currentViewingSessionId === respSessionId
  ) {
    // CHUCK NORRIS KICKS YOU
    const player = _.get(PlayerSession, 'player');
    const videoErrorCallback = _.get(PlayerSession, 'videoErrorCb');

    player && player.stop();

    if (videoErrorCallback) {
      const kickEventErrorObj = {
        code: 'CHUCK_NORRIS',
        message:
          'You have exceeded the maximum number of concurrent streams. Playback is stopped.',
        type: 'kicked',
      };
      videoErrorCallback(kickEventErrorObj);
    }
  }
}

function _triggerZombieKick() {
  const player = _.get(PlayerSession, 'player');
  player.stop();
  const videoErrorCallback = _.get(PlayerSession, 'videoErrorCb');

  const kickEventErrorObj = {
    code: 'ZOMBIE_STREAM_KICK',
    message:
      'You have exceeded the maximum stream session time. Playback is stopped.',
    type: 'kicked',
  };
  videoErrorCallback(kickEventErrorObj);
}

function _startZombieKickTimeout() {
  const zombieKickEnabled = PlayerSession.getFeatureFlags(
    'FEATURE_ZOMBIE_KICK',
  );
  let zombieKickInterval = PlayerSession.getFeatureFlags(
    'FEATURE_ZOMBIE_KICK_TIMEOUT_HOURS',
  );
  if (zombieKickEnabled && zombieKickInterval) {
    _clearZombieKickTimeout();
    zombieKickInterval = zombieKickInterval * 60 * 60 * 1000; // hours to milliseconds
    PlayerSession.zombieKickTimeout = window.setTimeout(
      _triggerZombieKick,
      zombieKickInterval,
    );
  }
}

function _clearZombieKickTimeout() {
  const zombieKickTimeout = _.get(PlayerSession, 'zombieKickTimeout', null);
  if (zombieKickTimeout) {
    window.clearTimeout(zombieKickTimeout);
    PlayerSession.zombieKickTimeout = null;
  }
}

function _trackPlayerEvent(playerEvent, overrideEventName) {
  const userLoggedIn = _.get(PlayerSession, 'userLoggedIn');

  if (userLoggedIn) {
    const player = _.get(PlayerSession, 'player');
    const asset = _.get(PlayerSession, 'asset');
    const viewingSessionMetadata = _.get(
      PlayerSession,
      'viewingSessionMetadata',
    );
    const viewingSessionId = _.get(PlayerSession, 'viewingSessionId');
    const errorObj = _.get(PlayerSession, 'errorObj');
    const device = _.get(viewingSessionMetadata, 'device');
    const app = _.get(viewingSessionMetadata, 'app');
    const attributes = {};
    const videoPlayer = _.get(viewingSessionMetadata, 'videoPlayer');
    const browser = _.get(viewingSessionMetadata, 'browser');
    const screen = _.get(viewingSessionMetadata, 'screen');
    const userPreference = _.get(viewingSessionMetadata, 'userPreference');

    // Only for on demand assets
    // Do not attempt to perform periodic interval tracking if current position is within progressUpdaterTimeout from duration
    // Use case: double tracking of completed asset
    const assetLive = _.get(asset, 'live', false);

    const dynamicData = _collectDynamicEventData(playerEvent);
    const fullEventData = _.merge(viewingSessionMetadata, dynamicData, true);
    const errors = playerEvent === 'error' && errorObj ? [errorObj] : [];

    let assetMetaData = _.get(viewingSessionMetadata, 'asset');

    if (player && !playerEvent && !assetLive) {
      const currentPlayerDuration = Math.round(player.getDuration() * 1000);
      const currentPlayerPosition = Math.round(player.getPosition() * 1000);
      assetMetaData.viewProgress = currentPlayerPosition;

      if (
        currentPlayerPosition >=
        currentPlayerDuration - progressUpdaterTimeout
      ) {
        return;
      }
    }

    if (overrideEventName) {
      fullEventData.eventName = overrideEventName;
    }

    if (fullEventData && fullEventData.progress) {
      fullEventData.progress.viewingTime =
        Date.now() - (lastTimeStamp || Date.now());
    }

    lastTimeStamp =
      player && player.getState() === 'paused' ? null : Date.now();

    streamSessionAction
      .saveStreamSession(
        viewingSessionId,
        device,
        app,
        attributes,
        videoPlayer,
        assetMetaData,
        browser,
        screen,
        userPreference,
        errors,
      )
      .then((data) => {
        _onHandleResponse(data);
        setProgressUpdaterTimeout(data);
      })
      .catch((err) => {
        console.log('Saving stream session error:', err);
        startEventPostTimeout();
      });
  }
}

function _collectDynamicEventData(playerEvent) {
  /*
    client.playerEvent
    client.playerState
    enc
    client.clientDate
    subtitle
    offset
    isLive - videoModel
    */

  // If player or asset are not available just return empty object
  const player = _.get(PlayerSession, 'player');
  const asset = _.get(PlayerSession, 'asset');
  if (!player || !asset) {
    return {};
  }

  playerEvent = playerEvent || 'period';
  const playerStateRaw = player.getState();
  const assetLive = _.get(asset, 'live', false);
  let subtitle, enc, playerState;

  switch (playerStateRaw) {
    case 'idle':
      playerState = 'stop';
      break;
    case 'paused':
      playerState = 'pause';
      break;
    default:
      playerState = playerStateRaw;
  }

  const captions = player.getCaptionsList();
  if (captions) {
    /*var selected = videoModel.textTracks.filter(function(tr) { return tr.isVisible; })[0];
        if (selected) {
          subtitle = selected.label;
        }*/
  }

  const currentPlayerDuration = player.getDuration();
  const currentPlayerPosition = player.getPosition();
  const playerType = _.get(player, 'type', '');
  const playerVersion = _.get(player, 'version', null);
  const playerHeight = player.getHeight();
  const playerWidth = player.getWidth();
  const playerMode = player.getFullscreen() ? 'fullscreen' : 'embedded';

  var dynamicData = {
    client: {
      playerEvent: playerEvent,
      playerState: playerState,
      clientDate: new Date().toISOString(),
    },
    progress: {
      offset: {
        duration: Math.round(currentPlayerDuration * 1000), // duration of asset in milliseconds
        pos: Math.round(currentPlayerPosition * 1000), // current position in milliseconds
      },
      playbackType: assetLive ? 'live' : 'vod',
    },
    videoPlayer: {
      type: playerType,
      version: playerVersion,
      height: playerHeight,
      width: playerWidth,
      mode: playerMode,
    },
    screen: {
      height: Number(window.innerHeight),
      width: Number(window.innerWidth),
    },
  };

  if (enc) {
    dynamicData.progress.enc = enc;
  }

  if (subtitle) {
    dynamicData.client.subtitle = subtitle;
  }

  return dynamicData;
}

function _generateViewingSessionId() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

async function _getViewingMetadata(player, asset, assetPlay, resume) {
  const assetId = _.get(asset, 'id', '');
  const assetTitle = _.get(asset, 'title', '');
  const assetType = _.get(asset, 'assetTypeName', '');
  const assetAccessType = _.get(asset, 'accessType', '');
  const assetCategory = _.get(asset, 'categoryTitle', '');
  const assetSource = _.get(asset, 'source', 'vimond'); //TODO: which field?!!!!
  const assetOptaMatchId = _.get(asset, 'id', '');
  const currentPlayerPosition = player.getPosition();
  const assetStreamUrl = _.get(asset, 'playbackUri', '');
  let assetViewProgress = Math.round(currentPlayerPosition * 1000); // current position in milliseconds

  const playbackData = _.get(assetPlay, 'playback', {});
  const playbackItem = _.get(playbackData, 'items.item[0]', {});
  const assetCdn = _.get(playbackItem, 'selectedCDN', '').toUpperCase();

  const homeTeamId = _.get(asset, 'optaHomeTeamId', '');
  const awayTeamId = _.get(asset, 'optaAwayTeamId', '');

  const assetTeam = {
    home: homeTeamId,
    away: awayTeamId,
  };

  let viewingSessionId = _.get(PlayerSession, 'viewingSessionId');

  if (!viewingSessionId) {
    viewingSessionId = _generateViewingSessionId();
    PlayerSession.viewingSessionId = viewingSessionId;
  }

  if (resume) {
    assetViewProgress = resume * 1000;
  }
  return {
    eventName: 'player-events',
    tenant: 'optus',
    versions: ['1.0'],
    device: {
      deviceTime: new Date().toISOString(),
    },
    app: {
      appId: pjson.name,
      version: pjson.version,
      appName: pjson.name,
      environment: defaultConfig.env,
      platform: 'web', // 'web', // ios, tvos, android etc
    },
    asset: {
      id: assetId,
      title: assetTitle,
      type: assetType,
      accessType: assetAccessType,
      category: assetCategory,
      source: assetSource,
      optaMatchId: assetOptaMatchId,
      viewProgress: assetViewProgress,
      streamUrl: assetStreamUrl,
      cdn: assetCdn,
      team: assetTeam,
    },
    browser: {
      userAgent: window.navigator.userAgent,
    },
    userPreference: {
      dayNightMode: 'night',
    },
    progress: {
      assetId: assetId, // '2984'
      liveResumePossible: false,
      title: assetTitle, // this is the asset title
    },
  };
}

function setProgressUpdaterTimeout(data) {
  const heartBeatIntervalLength = _.get(
    data,
    'item.heartBeatIntervalLength',
    '',
  );
  progressUpdaterTimeout = heartBeatIntervalLength
    ? heartBeatIntervalLength * 1000
    : progressUpdaterTimeout;
  startEventPostTimeout();
}

function startEventPostTimeout() {
  const eventPostTimeout = _.get(PlayerSession, 'eventPostTimeout');
  const viewingSessionId = _.get(PlayerSession, 'viewingSessionId');

  if (eventPostTimeout) {
    window.clearTimeout(eventPostTimeout);
    PlayerSession.eventPostTimeout = null;
  }
  if (viewingSessionId) {
    // check if sessionid is not null to prevent starting timer after video playback has finished
    // Initiate progress event tracker
    PlayerSession.eventPostTimeout = window.setTimeout(
      _trackPlayerEvent,
      progressUpdaterTimeout,
    );
  }
}

const PlayerSession = {
  // Context: Segment events that occur before actual playback session requires a session id
  preGenerateSessionId: () => {
    const viewingSessionId = _.get(PlayerSession, 'viewingSessionId');
    // There is a viewing session going on already so clear it before starting a new one
    if (viewingSessionId) {
      PlayerSession.closeSession();
    }
    PlayerSession.viewingSessionId = _generateViewingSessionId();
  },
  initSession: (
    userLoggedIn,
    player,
    asset,
    assetPlay,
    videoErrorCallback,
    resume,
    getFeatureFlags,
  ) => {
    // Close any previous live sessions only if player exists as we can be in a pre generated session state.
    // We do not want to clear a pre generated viewing session as we are awaiting playback to start
    // before starting the live player session
    const playerExists = _.get(PlayerSession, 'player');
    if (playerExists) {
      PlayerSession.closeSession();
    }

    PlayerSession.userLoggedIn = userLoggedIn;
    PlayerSession.player = player;
    PlayerSession.asset = asset;
    PlayerSession.videoErrorCb = videoErrorCallback;
    PlayerSession.getFeatureFlags = getFeatureFlags;

    // Generate unique viewing session id for current player session if it doesn't exist
    const viewingSessionIdExists = _.get(PlayerSession, 'viewingSessionId');
    if (!viewingSessionIdExists) {
      PlayerSession.viewingSessionId = _generateViewingSessionId();
    }

    // Only start the stream session tracking if user is logged in
    if (userLoggedIn) {
      _getViewingMetadata(player, asset, assetPlay, resume)
        .then((viewingSessionMetadata) => {
          const viewingSessionId = _.get(PlayerSession, 'viewingSessionId');
          const device = _.get(viewingSessionMetadata, 'device');
          const app = _.get(viewingSessionMetadata, 'app');
          const attributes = {};
          const videoPlayer = _.get(viewingSessionMetadata, 'videoPlayer');
          const assetMetaData = _.get(viewingSessionMetadata, 'asset');
          const browser = _.get(viewingSessionMetadata, 'browser');
          const screen = _.get(viewingSessionMetadata, 'screen');
          const userPreference = _.get(
            viewingSessionMetadata,
            'userPreference',
          );
          const errors = [];

          if (viewingSessionMetadata) {
            PlayerSession.viewingSessionMetadata = viewingSessionMetadata;
          }
          streamSessionAction
            .saveStreamSession(
              viewingSessionId,
              device,
              app,
              attributes,
              videoPlayer,
              assetMetaData,
              browser,
              screen,
              userPreference,
              errors,
            )
            .then((data) => {
              _onHandleResponse(data);
              setProgressUpdaterTimeout(data);
            })
            .catch((err) => {
              console.log(
                "Couldn't connect for viewing session tracking.",
                err,
              );

              startEventPostTimeout();
            });

          _startZombieKickTimeout();
        })
        .catch((error) => {
          console.log('_getViewingMetadata error', error);
        });
    } else {
      _startZombieKickTimeout();
    }
  },

  closeSession: () => {
    console.log('closeSession');
    // Clear timeout and session related data

    PlayerSession.viewingSessionId = null;
    PlayerSession.viewingSessionMetadata = null;
    PlayerSession.player = null;
    PlayerSession.asset = null;

    const eventPostTimeout = _.get(PlayerSession, 'eventPostTimeout');
    if (eventPostTimeout) {
      window.clearTimeout(eventPostTimeout);

      PlayerSession.eventPostTimeout = null;
    }

    _clearZombieKickTimeout();
  },

  trackEvent: (event, errorObj = {}) => {
    // Track an event
    switch (event) {
      case 'contentStarted':
        lastTimeStamp = Date.now();
        _trackPlayerEvent('str-start');
        break;
      case 'contentEnded':
        _trackPlayerEvent('end');
        setTimeout(PlayerSession.closeSession(), 1500);
        break;
      case 'error':
        PlayerSession.errorObj = errorObj;
        _trackPlayerEvent('error');
        setTimeout(PlayerSession.closeSession(), 1500);
        break;
      default:
        return;
    }
  },
};

export default PlayerSession;
