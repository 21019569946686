import * as types from '../constants/actionTypes';
import API from '@aws-amplify/api';

export function loadNavigation(id) {
  return function (dispatch) {
    if (!id) {
      return;
    }
    return API.get('fe-api-dev-metadataGetNavigation', `/navigations/${id}`, {})
      .then(navigation => {
        dispatch({type: types.GET_NAVIGATION_SUCCESS, navigation});
      }).catch(error => {
        dispatch({type: types.GET_NAVIGATION_FAILURE});
        throw(error);
      });
  }
}
