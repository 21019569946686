import { analyticAction } from '../../../ducks/analytic';
import { getSearchSessionId } from './utils';

const searchAutoSuggest = (
  dispatch,
  { query: search_partial_string = '', results: autocomplete_suggestions = [] },
) => {
  const autocomplete_empty = autocomplete_suggestions.length === 0;

  dispatch(
    analyticAction({
      eventName: 'Search Autocomplete Suggestion',
      search_session_id: getSearchSessionId(),
      search_partial_string,
      autocomplete_suggestions,
      autocomplete_empty,
    }),
  );
};

export default searchAutoSuggest;
