import _, { isEmpty, toUpper } from 'lodash';
import API from '@aws-amplify/api';
import moment from 'moment';
import queryString from 'query-string';
import { Player, Segment } from '@optussport/fe-bitmovin-player';
import { compareVersions } from 'compare-versions';
import * as types from '../constants/actionTypes';
import { getProtocolAndDRM } from '../utils/player';
import defaultConfig from '../constants/defaultConfig';
import { assetMapping } from '../middleware/assetMapping';
import { getParsedUA, getDevice, isMobile, getTvid } from '../utils/userAgent';
import { isFreePlayBack } from '../utils/player';
import { default as getSegmentErrorObj } from '../analytic/segment/errorMapping';
import { getUserId } from 'selectors/user';
import { dispatchAnalytic } from 'ducks/analytic';

const assetsFlag = _.get(defaultConfig.apiFlags, 'assets', false);
const searchFlag = _.get(defaultConfig.apiFlags, 'search', false);

export function getAsset(
  assetId,
  season,
  competition,
  match,
  isArticle = false,
  allowPreview,
) {
  return (dispatch, getState) => {
    const currentState = getState();
    const navigations = _.get(currentState, 'navigation.navigations', []);
    let path = '';
    if (assetId) {
      path = assetsFlag
        ? `/assets/v2/${assetId}/web${
            isArticle
              ? `?disableFormatting=true${
                  allowPreview ? '&allowPreview=true' : ''
                }`
              : ''
          }`
        : `/assets/${assetId}`;
      return API.get('fe-api-dev-metadataGetAsset', path, {})
        .then((asset) => {
          let parsedAsset = asset;
          const assetTypeName = _.get(asset, 'type', null);
          if (assetsFlag && _.toLower(assetTypeName) !== 'article') {
            parsedAsset = assetMapping(asset, navigations);
          }
          dispatch(setAssetSuccess(parsedAsset));
        })
        .catch((error) => {
          dispatch(setAssetFailure());
          throw error;
        });
    } else if (season && competition && match) {
      let params = {
        queryStringParameters: {
          season,
          competition,
          matchId: match,
        },
      };

      path = searchFlag ? `/search/v2/web/assets` : `/assets`;
      return API.get('fe-api-dev-metadataGetAsset', path, params)
        .then((asset) => {
          if (searchFlag) {
            const parsedAsset = _.get(asset, 'asset[0]', []);
            _.isEmpty(parsedAsset)
              ? dispatch(setAssetFailure())
              : dispatch(
                  setAssetSuccess(assetMapping(parsedAsset, navigations)),
                );
          } else {
            dispatch(setAssetSuccess(asset));
          }
        })
        .catch((error) => {
          dispatch(setAssetFailure());
          throw error;
        });
    }
  };
}

export function getAssetByType(type, value) {
  const typeParam = type === 'team' ? 'teamName' : 'relatedAssetId';
  const path = searchFlag ? `/search/v2/web/assets` : `/assets`;
  return (dispatch, getState) => {
    const currentState = getState();
    const navigations = _.get(currentState, 'navigation.navigations', []);
    let params = {
      queryStringParameters: {
        [typeParam]: value,
      },
    };

    dispatch(setAssetCategoryPending());
    return API.get('fe-api-dev-metadataGetAsset', path, params)
      .then((assets) => {
        let parsedAssets = assets;
        if (searchFlag) {
          parsedAssets = _.get(parsedAssets, 'asset', []);
          if (!_.isEmpty(parsedAssets)) {
            parsedAssets = parsedAssets.map((asset) =>
              assetMapping(asset, navigations),
            );
          }
        }
        dispatch(setAssetCategorySuccess(parsedAssets));
      })
      .catch((error) => {
        dispatch(setAssetCategoryFailure());
        throw error;
      });
  };
}

export function freeAsset() {
  return (dispatch) => dispatch(unsetAsset());
}

export function playAsset(
  assetId,
  props,
  state,
  assetAccessType,
  protocol,
  drm,
  watchMode,
  yspSdkOverride,
) {
  // getState() will return the current state of the store at point of calling this action
  // return (dispatch, getState)
  return async (dispatch, getState) => {
    const {
      yspSdk,
      yspSdkV3,
      yspSdkLive,
      defaultPlatform,
      platform,
      advertConsent,
      version: appVersion,
      // env,
    } = defaultConfig;
    const protocolAndDRM = getProtocolAndDRM();
    const parsedProtocol = _.get(protocolAndDRM, 'protocol');
    const parsedDRM = _.get(protocolAndDRM, 'drm');

    const defaultProtocol =
      protocol && typeof protocol !== 'undefined'
        ? protocol.toLowerCase()
        : parsedProtocol;
    const defaultDRM =
      drm && typeof drm !== 'undefined' ? drm.toLowerCase() : parsedDRM;
    const accessType =
      typeof assetAccessType !== 'undefined' ? assetAccessType : 'PREMIUM';

    const userId = getUserId(props.user);

    let queryStringParameters = {};

    let playbackName = 'fe-api-dev-playbackGetPlayback';
    let playbackPath = `/${platform}/users/${userId}/assets/${assetId}`;
    let segmentEventName = 'apiMlGeneralPlayback';
    // Free without login requested
    if (isFreePlayBack(accessType)) {
      segmentEventName = 'apiMlFreePlayback';
      playbackName = 'fe-api-dev-playbackGetFreePlayback';
      playbackPath = `/${platform}/assets/${assetId}`;

      // OSN-268 - add the user id for free playback requests
      queryStringParameters.analyticUserId = userId;
    }

    if (defaultProtocol) {
      queryStringParameters.type = defaultProtocol;
    }

    if (defaultDRM) {
      queryStringParameters.drm = defaultDRM;
    }

    if (watchMode) {
      queryStringParameters.watchMode = watchMode;
    }

    // OSN-1436 - Added new rule which can disable yspSdk for live assets
    const assetLive = _.get(props.asset, ['data', 'live']);
    if (typeof yspSdkOverride !== 'undefined') {
      queryStringParameters.yspSdk = yspSdkOverride;
    } else {
      if ((!assetLive && yspSdk) || (assetLive && yspSdkLive)) {
        queryStringParameters.yspSdk = yspSdkV3 && yspSdk ? 'v3' : yspSdk;
      } else {
        queryStringParameters.yspSdk = false;
      }
    }

    // queryStringParameters.yspSdk = yspSdk;
    const getFeatureFlags = _.get(props, 'getFeatureFlags');
    // const isProduction = env === 'prod';

    const location = props.location || _.get(window, 'location');
    const search = _.get(location, 'search', '');
    const searchParams = queryString.parse(search);
    const supportsCmaf = _.get(searchParams, 'supportsCmaf', false);

    const segmentEnabled = getFeatureFlags('FEATURE_BITMOVIN_SEGMENT_ENABLED');

    const parsedUA = getParsedUA();
    const osName = _.get(parsedUA, ['os', 'name'], '');
    const osVersion = _.get(parsedUA, ['os', 'version'], '0');
    const browserVersion = _.get(parsedUA, ['browser', 'version'], '0');
    const ppid = _.get(props.user, ['profile', 'ppid'], '0');

    let browserName = _.get(parsedUA, ['browser', 'name'], 'Unknown');
    if (isMobile()) {
      if (!browserName.toLowerCase().includes('mobile')) {
        browserName = `Mobile ${browserName}`;
      }
    }

    // https://optussport.atlassian.net/browse/OSN-2084 - CMAF restrictions to be aligned with ML
    let excludeCmaf = false;
    if (
      osName.toLowerCase() === 'android' &&
      compareVersions(osVersion, '10') < 0
    ) {
      excludeCmaf = true;
    } else if (
      browserName.toLowerCase().search('safari') >= 0 &&
      compareVersions(browserVersion, '12.1.2') < 0
    ) {
      excludeCmaf = true;
    } else if (browserName.toLowerCase().search('firefox') >= 0 && isMobile()) {
      excludeCmaf = true;
    }

    if (supportsCmaf === 'true' || !excludeCmaf) {
      queryStringParameters.supportsCmaf = true;
    }

    if (supportsCmaf === 'false') {
      queryStringParameters.supportsCmaf = false;
    }

    Player.setWatchMode(watchMode);
    const featurePlayerConfig = getFeatureFlags('PLAYER_CONFIG');
    const adHolidayEnabled = _.get(
      featurePlayerConfig,
      'adHoliday.enabled',
      false,
    );
    const isInAdHoliday = Player.isInAdHoliday(featurePlayerConfig);
    if (adHolidayEnabled) {
      queryStringParameters.adHol = isInAdHoliday;
    }

    const playerName = Player.getName();
    const playerVersion = Player.getVersion();

    if (segmentEnabled) {
      Segment.trackVideoRequested();
    }
    dispatchAnalytic({ name: 'Video Playback Requested' });

    // OSN-595 - We need to add the following parameters:
    // 1. browserName
    // 2. browserVersion
    // 3. osName
    // 4. osVersion
    // 5. playerName
    // 6. playerVersion
    // 7. appVersion

    const { deviceId } = getDevice();
    const { tvid } = await getTvid();
    const additionalQueryParameters = {
      deviceId,
      platform: defaultPlatform,
      advertConsent,
      browserName,
      browserVersion,
      osName,
      osVersion,
      playerName,
      playerVersion,
      appVersion,
      ppid,
      tvid,
    };

    const prohibitTargeting = _.get(
      props.user,
      ['settings', 'prohibitTargeting'],
      false,
    );

    additionalQueryParameters.prohibitTargeting = JSON.stringify(
      prohibitTargeting,
    );

    Object.keys(additionalQueryParameters).forEach((key) => {
      const item = additionalQueryParameters[key];
      if (typeof item === 'number' || item) {
        queryStringParameters[key] = item;
      }
    });

    const params = {
      headers: {},
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters,
    };

    API.get(playbackName, playbackPath, params)
      .then((response) => {
        const {
          data = null,
          status = '200',
          config: { headers: requestHeaders = {}, url = '' },
          headers = {},
        } = response || {};

        //mock error response
        // data.playback.playbackStatus = 'NOT_STARTED';
        // data = {
        //   error:{
        //     id:'4xx',
        //     code:"DEVICE_LIMIT_EXCEEDED",
        //     description:"Exceed device allowance"
        //   }
        // };

        if (data) {
          let { error: dataError } = data;

          const playbackStatus = toUpper(
            _.get(data, 'playback.playbackStatus', 'OK'),
          );

          if (!isEmpty(dataError) || playbackStatus !== 'OK') {
            const {
              message: errorMessage = '',
              errorRefCode = '',
            } = getSegmentErrorObj(dataError?.code, status, playbackStatus);

            segmentEnabled &&
              Segment.trackApiEvent({
                apiUrl: url,
                segmentEventName,
                requestParams: { ...params, headers: requestHeaders },
                response: {
                  errorStatus: status,
                  data,
                  headers,
                },
              });

            segmentEnabled && Segment.trackApiErrorEvent(errorMessage);

            dispatch(
              setAssetPlayFailure(
                { error: dataError, errorStatus: status },
                errorRefCode,
              ),
            );
          } else {
            segmentEnabled &&
              Segment.trackApiEvent({
                apiUrl: url,
                segmentEventName,
                requestParams: { ...params, headers: requestHeaders },
                response: {
                  status,
                  data,
                  headers,
                },
              });
          }
          // Successfully fetched user orders
          dispatch(setAssetPlay(data));
        } else {
          // Should never really end up here as it would be caught outside, so present user with generic server error if in here
          dispatch(setAssetPlayFailure({}, 'server_err'));
        }
      })
      .catch((err) => {
        const {
          config: { headers, url },
          response: {
            status: errorStatus = '403',
            data: {
              error = {
                code: '',
              },
            } = {},
          } = {},
        } = err || {};

        const apiResponse = {
          errorStatus,
          error,
          headers,
        };

        const { message: errorMessage, errorRefCode } = getSegmentErrorObj(
          error.code,
          errorStatus,
        );

        segmentEnabled &&
          Segment.trackApiEvent({
            apiUrl: url,
            segmentEventName,
            requestParams: params,
            response: apiResponse,
          });

        segmentEnabled && Segment.trackApiErrorEvent(errorMessage);

        dispatch(setAssetPlayFailure({ error, errorStatus }, errorRefCode));
      });
  };
}

export function getAssetPlayHistory(assetId) {
  return (dispatch) => {
    if (assetId) {
      API.get(
        'fe-api-streamSessionManager',
        `/assets/${assetId}/history/latest`,
      )
        .then((response) => {
          const responseAssetPlayHistory = _.get(response, 'data');
          const responseAssetId = _.get(responseAssetPlayHistory, 'item.id');

          if (responseAssetId === assetId) {
            dispatch(setAssetPlayHistorySuccess(responseAssetPlayHistory));
          } else {
            dispatch(setAssetPlayHistoryFailure());
          }
        })
        .catch((error) => {
          dispatch(setAssetPlayHistoryFailure());
        });
    } else {
      dispatch(setAssetPlayHistoryFailure());
    }
  };
}

export function getAssetEpg(assetId) {
  return (dispatch) => {
    const options = {
      timeout: defaultConfig.defaultApiTimeout,
    };
    API.get('fe-api-dev-metadataGetEpg', `/${assetId}`, options)
      .then((response) => {
        dispatch(setAssetEpgSuccess(_.get(response, 'epg')));
      })
      .catch((error) => {
        dispatch(setAssetEpgFailure());
      });
  };
}

export function getChannelEpg(channel) {
  return (dispatch) => {
    API.get('fe-api-dev-metadataGetChannelEpg', `/${channel}`, {
      queryStringParameters: {
        mergeAttrs: true,
        tz: moment.tz.guess() || 'Australia/Sydney',
      },
      timeout: defaultConfig.defaultApiTimeout,
    })
      .then((response) => {
        dispatch(setAssetEpgSuccess(_.get(response, 'epg')));
      })
      .catch((error) => {
        dispatch(setAssetEpgFailure());
      });
  };
}

export function setAssetSuccess(assetData) {
  return {
    type: types.GET_ASSET_SUCCESS,
    assetData,
  };
}

export function setAssetFailure() {
  return {
    type: types.GET_ASSET_FAILURE,
  };
}

export function setAssetCategoryPending() {
  return {
    type: types.GET_ASSET_CATEGORY_PENDING,
  };
}

export function setAssetCategorySuccess(assetData) {
  return {
    type: types.GET_ASSET_CATEGORY_SUCCESS,
    assetData,
  };
}

export function setAssetCategoryFailure() {
  return {
    type: types.GET_ASSET_CATEGORY_FAILURE,
  };
}

export function setAssetPlay(assetPlayData) {
  return {
    type: types.GET_ASSET_PLAY_SUCCESS,
    assetPlayData,
  };
}

export function setAssetPlayFailure(playFailureData, code) {
  if (typeof code !== 'undefined' && typeof playFailureData === 'object') {
    playFailureData.refCode = code;
  }

  return {
    type: types.GET_ASSET_PLAY_FAILURE,
    playFailureData,
  };
}

export function setAssetPlayHistorySuccess(assetPlayHistoryData) {
  return {
    type: types.GET_ASSET_PLAY_HISTORY_SUCCESS,
    assetPlayHistoryData,
  };
}

export function setAssetPlayHistoryFailure() {
  return {
    type: types.GET_ASSET_PLAY_HISTORY_FAILURE,
  };
}

export function setAssetEpgSuccess(assetEpgData) {
  return {
    type: types.GET_ASSET_EPG_SUCCESS,
    assetEpgData,
  };
}

export function setAssetEpgFailure() {
  return {
    type: types.GET_ASSET_EPG_FAILURE,
  };
}

export function unsetAssetPlay() {
  return {
    type: types.UNSET_ASSET_PLAY,
  };
}

export function unsetAsset() {
  return {
    type: types.UNSET_ASSET,
  };
}

export function unsetAssetPlayHistory() {
  return {
    type: types.UNSET_ASSET_PLAY_HISTORY,
  };
}

export function toggleLoadPlayInProgress(inprogress) {
  return (dispatch) => {
    if (inprogress) {
      dispatch({
        type: types.SET_ASSET_PLAY_INPROGRESS,
        inprogress,
      });
    } else {
      dispatch({
        type: types.UNSET_ASSET_PLAY_INPROGRESS,
        inprogress,
      });
    }
  };
}
