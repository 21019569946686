import React, { Component } from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';

import Notifications from 'react-notification-system-redux';

class NotificationStatusBar extends Component {

  render() {
    const {notifications} = this.props;

    //Optional styling
    const style = {
      NotificationItem: { // Override the notification item
        DefaultStyle: { // Applied to every notification, regardless of the notification level
          margin: '0',
          padding: '0',
          height: '4px',
          width: '100vw',
          position: 'absolute',
          left: '0px',
          top: '0px',
          boxShadow: 'none',
          border: '0'
        },

        success: { // Applied only to the success notification item
          // color: 'red',
          backgroundColor: '#00ff91',
          width: '100vw',
        },

        error: {
          backgroundColor: '#d93a1b',
          width: '100vw',
        }
      }
    };

    return (
      <Notifications
        notifications={notifications}
        style={style}
      />
    );
  }
}

NotificationStatusBar.contextTypes = {
  store: PropTypes.object
};

NotificationStatusBar.propTypes = {
  notifications: PropTypes.array
};

export default connect(
  state => ({ notifications: state.notifications })
)(NotificationStatusBar);