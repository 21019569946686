import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function tables(state = initialState.tables, action) {
  let currentTables;

  switch (action.type) {

    case types.GET_TABLES_INPROGRESS:
      currentTables = {
        ...state.find(tables => tables.competitionId === action.tables.competitionId && tables.season === action.tables.season),
        ...action.tables,
        inProgress: true,
        isSuccess: false
      };

      return [
        ...state.filter(tables => tables.competitionId !== action.tables.competitionId || tables.season !== action.tables.season),
        currentTables
      ];

    case types.GET_TABLES_SUCCESS:
      return [
        ...state.filter(tables => tables.competitionId !== action.tables.competitionId || tables.season !== action.tables.season),
        {
          ...action.tables,
          inProgress: false,
          isSuccess: true
        }
      ];

    case types.GET_TABLES_FAILURE:
      currentTables = {
        // for failure case, use whatever has been loaded before
        ...state.find(tables => tables.competitionId === action.tables.competitionId && tables.season === action.tables.season),
        ...action.tables,
        inProgress: false,
        isSuccess: false
      };

      return [
        ...state.filter(tables => tables.competitionId !== action.tables.competitionId || tables.season !== action.tables.season),
        currentTables
      ];

    default:
      return state;
  }
}

