import React, { useState } from 'react';
import { getAbsoluteStaticUrl } from 'utils/path';

const CloseButton = ({ onClick, imgURL, imgAlt }) => {
  return (
    <img
      className='cursor-pointer absolute pin-top pin-left z-2 w-51 h-51 block xl:hidden'
      src={getAbsoluteStaticUrl(imgURL)}
      alt={imgAlt}
      width='100%'
      height='auto'
      onClick={onClick}
    />
  )
}

const PromotionalBanner = ({
  withBackground,
  imgURL,
  imgAlt,
  mobileImgURL,
  mobileImgAlt,
  title,
  text,
  ctaText,
  ctaLink,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const onClose = () => {
    setIsOpen(false);
  };
  if (!isOpen) {
    return (
      <></>
    )
  }
  if (withBackground) {
    return (
      <section className='promo-container relative rounded-16px overflow-hidden w-full mx-auto mt-36 mb-12 xl:mt-64 xl:mb-32 xl:rounded-24px xl:h-400 xl:py-56 xl:px-104'>
        <CloseButton onClick={onClose} imgURL={'/images/promotional-close.png'} imgAlt={'Close button'} />
        <div className='w-full overflow-hidden text-center xl:absolute xl:pin-t xl:pin-r xl:z-0 xl:max-w-701'>
          <img
            className='block xl:hidden'
            src={getAbsoluteStaticUrl(mobileImgURL)}
            alt={imgAlt}
            width='100%'
            height='auto'
          />
          <img
            className='hidden xl:block'
            src={getAbsoluteStaticUrl(imgURL)}
            alt={mobileImgAlt}
            width='100%'
            height='auto'
          />
        </div>

        <div className='pt-16 pb-32 px-16 xl:p-0 xl:relative xl:z-1 xl:max-w-611'>
          <h1 className='font-MarkProHeavy text-center text-xl leading-loose mb-8 md:mb-24 md:text-3xl xl:leading-1_14 xl:text-5xl xl:text-left'>
            {title}
          </h1>
          <p className='font-MarkPro text-center mb-16 leading-normal md:mb-24 md:text-lg xl:text-left xl:leading-extra'>
            {text}
          </p>
          <div className='flex flex-row justify-center items-center xl:justify-start'>
            <a
              href={ctaLink}
              target='_blank'
              rel='noopener noreferrer'
              className='h-48 flex items-center justify-center px-16 text-normal w-full max-w-347 os-transition-btn truncate bg-teal text-black hover:bg-teal-light active:bg-teal font-MarkProBold no-underline rounded-4xl xl:w-300'
            >
              {ctaText}
            </a>
          </div>
        </div>
      </section>
    )
  }
  return (
    <section className='flex flex-row-reverse flex-wrap overflow-hidden max-w-1252 mx-auto px-24 pt-40 md:pt-64 lg:pt-128'>
      <div className='w-full overflow-hidden text-center lg:w-1/2'>
        <img
          className='max-w-592 lg:ml-5'
          src={getAbsoluteStaticUrl(imgURL)}
          alt={imgAlt}
          width='100%'
          height='auto'
        />
      </div>
      <div className='w-full overflow-hidden lg:w-1/2 lg:pr-40'>
        <h1 className='font-MarkProHeavy text-center text-4xl px-16 mt-12 mb-16 leading-1.14 max-w-630 mx-auto lg:px-0 lg:mb-24 lg:text-left lg:text-5xl xl:mt-105'>
          {title}
        </h1>
        <p className='font-MarkPro text-center mb-16 leading-extra max-w-630 mx-auto lg:mb-56 lg:text-lg lg:text-left'>
          {text}
        </p>
        <div className='flex flex-row justify-center items-center lg:justify-start'>
          <a
            href={ctaLink}
            target='_blank'
            rel='noopener noreferrer'
            className='h-48 flex items-center justify-center px-16 text-normal w-300 os-transition-btn truncate bg-teal text-black hover:bg-teal-light active:bg-teal font-MarkProBold no-underline rounded-4xl'
          >
            {ctaText}
          </a>
        </div>
      </div>
    </section>
  )
}

export default PromotionalBanner