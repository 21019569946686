import { get } from 'lodash';
import { start, success, fail, apiAction } from '../middleware/fetch';
import { getUserId } from 'selectors/user';
import { analyticAction } from './analytic';

export const DEVICEMANAGEMENT_DETAILS_FETCH = `deviceManagement/DETAILS_FETCH`;
export const GLOBAL_LOGOUT = `deviceManagement/GLOBAL_LOGOUT`;
export const RESET_SLICE = `deviceManagement/RESET_SLICE`;

const initialState = {
  recentActivity: null,
  recentActivityError: null,
  logoutDone: false,
  logoutError: null,
};



export function fetchRecentActivity() {
  return (dispatch, getState) => {
    const state = getState();
    const { user } = state;
    const currentUserId = getUserId(user);
    dispatch(
      apiAction(DEVICEMANAGEMENT_DETAILS_FETCH, `fe-api-recentActivity`, {
        path: `/${currentUserId}`,
      }),
    );
  };
}

export function globalLogoutUsers() {
  return (dispatch, getState) => {
    const state = getState();
    const { user } = state;
    const currentUserId = getUserId(user);
    dispatch(
      apiAction(GLOBAL_LOGOUT, `fe-api-globalLogout`, {
        method: `delete`,
        path: `/${currentUserId}`,
      }),
    );
  };
}

export function resetSlice() {
  return (dispatch) => {
    dispatch({
      type: RESET_SLICE,
    });
  };
}

export const triggerDeviceManagementSegmentAnalytics = (
  eventName,
  action,
) => {
  // const { payload } = action;

  let analyticObj = {
    eventName,
  };
  action.asyncDispatch(
    analyticAction({
      ...analyticObj,
    }),
  );
};


export default function deviceManagementReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case RESET_SLICE: {
      return {
        recentActivity: null,
        recentActivityError: null,
        logoutDone: false,
        logoutError: null,
      };
    }
    case start(DEVICEMANAGEMENT_DETAILS_FETCH): {
      return {
        ...state,
        recentActivity: null,
      };
    }
    case success(DEVICEMANAGEMENT_DETAILS_FETCH): {
      const recentActivity = get(payload, 'data', []);
      // mock data
      // const recentActivity = [
      //   {
      //     'subname': 'iOS 16.0.2',
      //     'appVersion': '5.0.0',
      //     'platform': 'mobile',
      //     'updatedAt': '2022-11-23T05:01:00.000Z',
      //     'name': 'iPhone 12 Pro max',
      //     'osVersion': '12',
      //     'id': '67a24bdd-75ea-422d-a529-bf603f716325',
      //     'lastIPLocation': 'Sydney, NSW',
      //     'lastIPAddress': '11.22.33.44'
      //   },
      //   {
      //     'appVersion': '5.0.0',
      //     'platform': 'ctv',
      //     'updatedAt': '2022-11-23T00:03:00.000Z',
      //     'name': 'Samsung TV',
      //     'osVersion': '12',
      //     'id': 'fudanyinxin+test6',
      //     'lastIPLocation': 'Sydney, NSW',
      //     'lastIPAddress': '11.22.33.44',
      //     'model': 'testing'
      //   },
      //   {
      //     'subname': 'iPhone12,1',
      //     'appVersion': '5.0.0',
      //     'platform': 'mobile',
      //     'updatedAt': '2022-11-22T23:00:00.000Z',
      //     'name': 'ios',
      //     'osVersion': '16.0.0',
      //     'id': 'fudanyinxin+test3',
      //     'lastIPLocation': 'Sydney, NSW',
      //     'lastIPAddress': '11.22.33.44'
      //   },
      //   {
      //     'subname': 'KDL-55HX920',
      //     'appVersion': '5.0.0',
      //     'platform': 'ctv',
      //     'updatedAt': '2022-11-21T00:00:00.000Z',
      //     'name': 'androidtv',
      //     'osVersion': '12',
      //     'id': 'fudanyinxin+test7',
      //     'lastIPLocation': 'Sydney, NSW',
      //     'lastIPAddress': '11.22.33.44'
      //   },
      //   {
      //     'subname': 'AFTLBT962E2',
      //     'appVersion': '5.0.0',
      //     'platform': 'ctv',
      //     'updatedAt': '2022-11-21T00:00:00.000Z',
      //     'name': 'firetv',
      //     'osVersion': '12',
      //     'id': 'fudanyinxin+test8',
      //     'lastIPLocation': 'Sydney, NSW',
      //     'lastIPAddress': '11.22.33.44'
      //   },
      //   {
      //     'subname': 'Chromecast',
      //     'appVersion': '5.0.0',
      //     'platform': 'mobile',
      //     'updatedAt': '2022-11-21T00:00:00.000Z',
      //     'name': 'chromecast',
      //     'osVersion': '12',
      //     'id': 'fudanyinxin+test12',
      //     'lastIPLocation': 'Sydney, NSW',
      //     'lastIPAddress': '11.22.33.44'
      //   },
      //   {
      //     'subname': 'Fetch Mini',
      //     'appVersion': '5.0.0',
      //     'platform': 'stb',
      //     'updatedAt': '2021-11-21T00:00:00.000Z',
      //     'name': 'stb',
      //     'osVersion': '12',
      //     'id': 'fudanyinxin+test11',
      //     'lastIPLocation': 'Sydney, NSW',
      //     'lastIPAddress': '11.22.33.44'
      //   },
      //   {
      //     'appVersion': '2.13.4',
      //     'name': 'web',
      //     'browserName': 'chrome',
      //     'createdAt': '2022-11-10T03:01:07.530Z',
      //     'browserVersion': '107.0.0.0',
      //     'updatedAt': '2022-11-20T05:09:35.758Z',
      //     'osVersion': '10.15.7',
      //     'id': 'e4772374-5eab-4aba-a486-ba1fe0268bca',
      //     'lastIPLocation': 'Sydney, NSW',
      //     'lastIPAddress': '149.167.34.180'
      //   },
      //   {
      //     'appVersion': '5.0.0',
      //     'platform': 'atv',
      //     'updatedAt': '2022-11-21T00:00:00.000Z',
      //     'name': 'atv',
      //     'osVersion': '16.0.0',
      //     'id': 'fudanyinxin+test4',
      //     'lastIPLocation': 'Sydney, NSW',
      //     'lastIPAddress': '11.22.33.44'
      //   },
      //   {
      //     'browserVersion': '10.0.0',
      //     'platform': 'mobileweb',
      //     'updatedAt': '2022-11-21T00:00:00.000Z',
      //     'name': 'web',
      //     'browserName': 'mobile safari',
      //     'id': 'fudanyinxin+test2',
      //     'lastIPLocation': 'Sydney, NSW',
      //     'lastIPAddress': '11.22.33.44'
      //   },
      //   {
      //     'subname': 'Xbox 360',
      //     'appVersion': '5.0.0',
      //     'platform': 'xbox',
      //     'updatedAt': '2022-11-21T00:00:00.000Z',
      //     'name': 'xbox',
      //     'osVersion': '12',
      //     'id': 'fudanyinxin+test10',
      //     'lastIPLocation': 'Sydney, NSW',
      //     'lastIPAddress': '11.22.33.44'
      //   },
      //   {
      //     'subname': 'PS4',
      //     'appVersion': '5.0.0',
      //     'platform': 'playstation',
      //     'updatedAt': '2022-11-21T00:00:00.000Z',
      //     'name': 'playstation',
      //     'osVersion': '12',
      //     'id': 'fudanyinxin+test9',
      //     'lastIPLocation': 'Sydney, NSW',
      //     'lastIPAddress': '11.22.33.44'
      //   },
      //   {
      //     'subname': 'OLED65G2PSA',
      //     'appVersion': '5.0.0',
      //     'platform': 'ctv',
      //     'updatedAt': '2022-11-21T00:00:00.000Z',
      //     'name': 'lgtv',
      //     'osVersion': '12',
      //     'id': 'fudanyinxin+test5',
      //     'lastIPLocation': 'Sydney, NSW',
      //     'lastIPAddress': '11.22.33.44'
      //   },
      // ];
      return {
        ...state,
        recentActivity,
        recentActivityError: null,
      };
    }
    case fail(DEVICEMANAGEMENT_DETAILS_FETCH): {
      return {
        ...state,
        recentActivity: null,
        recentActivityError: payload,
      };
    }
    case start(GLOBAL_LOGOUT): {
      return {
        ...state,
        logoutDone: false,
      };
    }
    case success(GLOBAL_LOGOUT): {
      triggerDeviceManagementSegmentAnalytics('Logout All Devices Performed', action);
      return {
        ...state,
        logoutDone: true,
        logoutError: null,
      };
    }
    case fail(GLOBAL_LOGOUT): {
      return {
        ...state,
        logoutDone: false,
        logoutError: payload,
      };
    }
    default:
      return state;
  }
};