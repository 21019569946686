import { analyticAction } from '../../../ducks/analytic';
import {
  getSearchSessionId,
  saveSearchDetailRequestedAssetInCookie,
  SEARCH_SOURCES,
} from './utils';

const searchRequested = (
  dispatch,
  {
    query: search_string = '',
    searchSource: search_source = SEARCH_SOURCES.MANUAL,
    clickedAutoCompleteIndex: search_index = null,
  },
) => {
  saveSearchDetailRequestedAssetInCookie({
    search_string,
    search_source,
    search_index,
  });

  dispatch(
    analyticAction({
      eventName: 'Search Requested',
      search_session_id: getSearchSessionId(),
      search_string,
      search_source,
      search_index: search_index ? Number(search_index) : null,
    }),
  );
};

export default searchRequested;
