import styled, { css } from 'styled-components';

const iconPositions = css`
  position: absolute;
  z-index: 1;
`;

const SearchFormIcon = styled.span`
  ${iconPositions}
  top: 8px;
  right: 8px;
`;

const BtnSearch = styled.button.attrs(() => ({
  className: 'invisible m-0 border-0 p-0 r-0',
  tabIndex: '-1',
}))`
  ${iconPositions}
`;

const BtnClear = styled.button.attrs(() => ({
  className: 'bg-none m-0 p-16 border-0 flex',
}))`
  ${iconPositions};
  top: 1px;
  right: 0;
`;

const SearchHistoryBtnClear = styled.button.attrs(() => ({
  className: 'flex',
}))`
  top: 12px;
  right: 16px;
  border-radius: 50%;
`;

const SearchForm = styled.form`
  ${(props) => {
    const { theme: { palette } = {} } = props;

    return css`
      position: relative;
      margin: 0 auto;
      width: 100%;

      .auto-suggestions-container {
        position: relative;
      }

      .auto-suggestions-input {
        padding-left: 14px;
        padding-right: 16px;
      }

      .suggestions-container {
        width: 100%;
        z-index: 300;
        ${(props) => (props.isInSearchPage ? 'position: absolute;' : '')}
        border-bottom-right-radius: 16px;
        border-bottom-left-radius: 16px;
        background: ${palette['medium-dark-grey']};
      }

      .suggestions-list {
        list-style: none;
        margin: 0;
        padding: 0;
      }

      .suggestion:last-child {
        border-bottom-right-radius: 16px;
        border-bottom-left-radius: 16px;
      }

      .suggestion {
        cursor: pointer;
        margin: 0 0 1px 0;
        padding: 16px 24px;
        background: ${palette['widget-grey']};
      }

      .suggestion-high-lighted {
        background: ${palette['medium-dark-grey']};
      }
    `;
  }}
`;

//Tailwind classes used by the component
const styleClasses = (error, disabled, marginBottom, disableSpinButton) =>
  `${
    disableSpinButton ? 'disable-spin-button' : ''
  } os-transition os-transitionproperty-all font-MarkPro ${
    !disabled ? 'bg-dark-grey' : 'bg-medium-grey'
  } text-white px-16 ${
    marginBottom ? `mb-${marginBottom}` : 'mb-0'
  } h-48 focus:outline-none w-full ${
    error
      ? 'border-live-red border-2 rounded'
      : 'border-light-grey border-2 focus:border-white rounded'
  }`;

const autosuggestTheme = {
  container: 'auto-suggestions-container',
  input: `${styleClasses()} auto-suggestions-input`,
  inputOpen: '',
  inputFocused: '',
  suggestionsContainer: 'bg-widget-grey suggestions-container',
  suggestionsContainerOpen: '',
  suggestionsList: 'suggestions-list',
  suggestion: 'suggestion',
  suggestionFirst: '',
  suggestionHighlighted: 'suggestion-high-lighted',
  sectionContainer: '',
  sectionContainerFirst: '',
  sectionTitle: '',
};

export {
  SearchForm,
  SearchFormIcon,
  BtnSearch,
  BtnClear,
  SearchHistoryBtnClear,
  autosuggestTheme,
};
