import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function contentCards(state, action) {
  const initContentCardState = initialState?.contentCards || {};
  state = typeof state === 'undefined' ? initContentCardState : state;

  switch (action.type) {
    case types.GET_CONTENT_CARDS: {
      return {
        ...state,
        ...initContentCardState,
        ...{
          inProgress: true,
        },
      };
    }
    case types.GET_CONTENT_CARDS_SUCCESS: {
      const { contentCards } = action;
      return {
        ...state,
        ...{
          isReady: true,
          inProgress: false,
          isSuccess: true,
          data: contentCards,
        },
      };
    }
    case types.ADD_CONTENT_CARDS: {
      const { contentCards } = action;
      if (state.data) {
        const data = [...state.data, ...contentCards];
        return {
          ...state,
          ...{
            data,
          },
        };
      }
      return { ...state };
    }
    case types.GET_CONTENT_CARDS_FAILURE: {
      return {
        ...state,
        ...{
          isReady: true,
          inProgress: false,
          isSuccess: false,
          data: [],
        },
      };
    }
    default: {
      return state;
    }
  }
}

export function contentCardsUnfiltered(state, action) {
  const initContentCardsUnfilteredState =
    initialState?.contentCardsUnfiltered || {};
  state =
    typeof state === 'undefined' ? initContentCardsUnfilteredState : state;

  switch (action.type) {
    case types.GET_CONTENT_CARDS_UNFILTERED: {
      return {
        ...state,
        ...initContentCardsUnfilteredState,
        ...{
          inProgress: true,
        },
      };
    }
    case types.GET_CONTENT_CARDS_UNFILTERED_SUCCESS: {
      const { contentCardsUnfiltered } = action;
      return {
        ...state,
        ...{
          isReady: true,
          inProgress: false,
          isSuccess: true,
          data: contentCardsUnfiltered,
        },
      };
    }
    case types.GET_CONTENT_CARDS_UNFILTERED_FAILURE: {
      return {
        ...state,
        ...{
          isReady: true,
          inProgress: false,
          isSuccess: false,
          data: [],
        },
      };
    }
    default: {
      return state;
    }
  }
}

export function dynamicContentCards(state, action) {
  const initDynamicContentCardsState = initialState?.dynamicContentCards || [];
  state = typeof state === 'undefined' ? initDynamicContentCardsState : state;

  switch (action.type) {
    case types.SET_DYNAMIC_CONTENT_CARDS: {
      const { dynamicContentCards } = action;

      return dynamicContentCards;
    }
    default: {
      return state;
    }
  }
}
