import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function teamSummary(state = initialState.teamSummary, action) {
  let currentTeamSummary;

  switch (action.type) {
    case types.GET_TEAMSUMMARY_INPROGRESS:
      currentTeamSummary = {
        ...state.find(
          (teamSummary) =>
            teamSummary.teamId === action.results.teamId ||
            teamSummary.competitionId === action.results.competitionId ||
            teamSummary.season === action.results.season,
        ),
        ...action.results,
        inProgress: true,
        isSuccess: false,
      };

      return [
        ...state.filter(
          (teamSummary) =>
            teamSummary.teamId === action.results.teamId ||
            teamSummary.competitionId === action.results.competitionId ||
            teamSummary.season === action.results.season,
        ),
        currentTeamSummary,
      ];

    case types.GET_TEAMSUMMARY_SUCCESS:
      return [
        ...state.filter(
          (teamSummary) =>
            teamSummary.teamId !== action.results.teamId ||
            action.results.competitionId !== teamSummary.competitionId ||
            teamSummary.season !== action.results.season,
        ),
        {
          ...action.results,
          inProgress: false,
          isSuccess: true,
        },
      ];

    case types.GET_TEAMSUMMARY_FAILURE:
      currentTeamSummary = {
        // for failure case, use whatever has been loaded before
        ...state.find(
          (teamSummary) =>
            teamSummary.teamId === action.results.teamId ||
            teamSummary.competitionId === action.results.competitionId ||
            teamSummary.season === action.results.season,
        ),
        ...action.results,
        inProgress: false,
        isSuccess: false,
      };

      return [
        ...state.filter(
          (teamSummary) =>
            teamSummary.teamId !== action.results.teamId ||
            teamSummary.competitionId !== action.results.competitionId ||
            teamSummary.season !== action.results.season,
        ),
        currentTeamSummary,
      ];

    default:
      return state;
  }
}
