import moment from 'moment';

export default function durationToTime(duration) {
  var hours = Math.floor(duration / 3600);
  var minutes = Math.floor((duration - hours * 3600) / 60);
  var seconds = duration - hours * 3600 - minutes * 60;

  // round seconds
  seconds = Math.round(seconds * 100) / 100;

  var result = hours < 10 ? '0' + hours : hours;
  result += ':' + (minutes < 10 ? '0' + minutes : minutes);
  result += ':' + (seconds < 10 ? '0' + seconds : seconds);
  return result;
}

export function parseDate(date) {
  if (typeof date === 'string') {
    var dateMomentObject = moment(date, 'DD/MM/YYYY');
    return dateMomentObject.toDate();
  }
  return date;
}
