import React from 'react';
import PropTypes from 'prop-types';
import LoadingIcon from '../../ui/ui-kit/Icons/LoadingIcon';
import ResultSummary from './ResultSummary';
import ResultSummaryRow from './ResultSummaryRow';
import { getIconForHighlights } from '../../../utils/typeToIconMapper';

class ResultSummaryPopover extends React.Component {
  constructor(props) {
    super(props);

    this.loadMatchHighlights = this.loadMatchHighlights.bind(this);

    this.state = {};
  }

  loadMatchHighlights(competitionId, season, matchId) {
    if (this.props.competitionId && this.props.season && this.props.matchId) {
      this.props.matchHighlightsActions.getMatchHighlights(
        this.props.competitionId,
        this.props.season,
        this.props.matchId,
      );
    }
  }

  renderHighlights = (highlights) => {
    if (highlights) {
      return highlights.map((highlight, i) => {
        return (
          <ResultSummaryRow
            key={i}
            time={`${highlight.EventTime}'`}
            name={highlight.PlayerDisplayName}
            icon={
              <div className='h-16 w-16'>
                {getIconForHighlights(highlight.EventType)}
              </div>
            }
          />
        );
      });
    }
    return null;
  };

  render() {
    const sectionHighlights = this.props.sectionHighlights;
    const relevantSectionHighlights = sectionHighlights.filter(
      (highlight) => highlight.PlayerDisplayName !== '',
    );
    const leftHighlights = relevantSectionHighlights.filter(
      (highlight) => highlight.HomeAway === 'Home',
    );
    const rightHighlights = relevantSectionHighlights.filter(
      (highlight) => highlight.HomeAway === 'Away',
    );

    return (
      <div
        className={`resultsummary-popover-wrap absolute w-331 min-h-104 bg-medium-dark-grey os-triangle-after-popup p-16 shadow-fixture-score z-99999 ${
          this.props.showSummary
            ? 'md:flex md:flex-wrap md:justify-center md:items-center popover-fadein'
            : 'hidden popover-fadeout'
        }`}
      >
        {this.props.inProgress ? (
          <LoadingIcon className='h-64 w-64' />
        ) : (
          <ResultSummary
            resultLeft={this.renderHighlights(leftHighlights)}
            resultRight={this.renderHighlights(rightHighlights)}
          />
        )}
      </div>
    );
  }
}

ResultSummaryPopover.propTypes = {
  /** Opta Competition ID */
  competitionId: PropTypes.number,
  /** Opta Season ID */
  season: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Opta Match ID */
  matchId: PropTypes.string,
};

ResultSummaryPopover.defaultProps = {
  competitionId: null,
  season: null,
  matchId: null,
};

export default ResultSummaryPopover;
