import moment from 'moment';
import { getTimezoneAbbr } from '../utils/timezone';

export const LOCALE_TIMEZONE_FORMAT_LLLL = 'llll';

/**
 * Returns formatted date time string for the time zone passed
 * @param datetime
 * @param timezone eg: llll
 * @returns {string}
 */
export const getFormattedDateTimeString = (datetime, timezone) => {
  let formattedDateTimeString = '',
    localeTimeZone = timezone ? timezone : LOCALE_TIMEZONE_FORMAT_LLLL;
  if (datetime) {
    let utcTimeZone = moment
      .utc(datetime)
      .tz(moment.tz.guess())
      .format('z');
    const timezoneAbbr = utcTimeZone || getTimezoneAbbr(moment.tz.guess());
    const formattedUtcDate = moment.utc(datetime).local();
    formattedDateTimeString = `${formattedUtcDate.format(
      localeTimeZone
    )} ${timezoneAbbr}`;
  }
  return formattedDateTimeString;
};

/**
 * Returns boolean flag indicator if asset type is game and layout id is a
 *  @param assetType
 * @returns {boolean}
 */
export const isEditorialAssetGame = (assetType) => {
  // return assetType === 'game' || assetType === 'game-nostats';
  return assetType === 'game';
};
