import React from 'react';
import PropTypes from 'prop-types';

const LoadingIcon = ({ width, height, viewBox, className, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={`os-loading ${className || ''}`} width={width} height={height} viewBox={viewBox} {...props}>
    <circle cx="45" cy="45" r="38"/>
  </svg>
);

LoadingIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  viewBox: PropTypes.string
};

LoadingIcon.defaultProps = {
  width: 32,
  height: 32,
  viewBox: '0 0 90 90'
};

export default LoadingIcon;
