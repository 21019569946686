import React, { Fragment } from 'react';
import _ from 'lodash';
import defaultConfig from '../../../constants/defaultConfig';
import LayoutAHeroGrid from './layouts/LayoutAHeroGrid';
import VideoCardList from './layouts/LayoutDVideoCardList';
import { RailCarousel } from './layouts/LayoutCRailCarousel';
import LayoutB2Heroes from './layouts/LayoutB2Heroes';
import PromotionalBanner from '../PromotionalBanner';

export const getConfigValue = (query, state, configKey, defaultValue) =>
  _.get(query, configKey, null) != null
    ? query[configKey]
    : _.get(state, `systemConfig.${configKey}`, defaultValue);

export const getCategoryTitle = (assetTypeName, match, categoryTitle) => {
  if (assetTypeName === 'game' && _.get(match, 'competition.id', null)) {
    return defaultConfig.getCompetitionTitle(match.competition.id);
  }
  return categoryTitle === 'undefined' ? 'Optus Sport' : categoryTitle;
};

export const getCarouselLayout = ({
  carousel,
  carouselIndex,
  gridVersion = 'v1',
  theme = 'dark',
  sectionPath = '/',
}) => {
  if (_.isEmpty(carousel)) {
    return null;
  }

  const getHeroLayout = (carousel, sectionPath, gridVersion, theme) =>
    carousel ? (
      <LayoutAHeroGrid
        key={`${carousel.id}_${carouselIndex}`}
        carousel={carousel}
        sectionPath={sectionPath}
        gridVersion={gridVersion}
        theme={theme}
        carouselIndex={carouselIndex}
      />
    ) : null;

  const { layout } = carousel;
  const layoutId = _.toLower(_.get(layout, 'id', 'c'));
  const showViewAllLink = carousel.showViewAllLink;

  switch (layoutId) {
    default:
    case 'explore':
    case 'c':
      return (
        <RailCarousel
          key={`${carousel.id}_${carouselIndex}`}
          carouselId={carousel.id}
          carouselIndex={carouselIndex}
          title={carousel.title}
          sectionPath={sectionPath}
          theme={theme}
          showViewAllLink={showViewAllLink}
          type={carousel.panelType}
          containerClass={carousel.containerClass}
        />
      );
    case 'a':
      return getHeroLayout(carousel, sectionPath, gridVersion, theme);
    case 'b':
      return (
        <LayoutB2Heroes
          key={`${carousel.id}_${carouselIndex}`}
          carousel={carousel}
          sectionPath={sectionPath}
          theme={theme}
        />
      );
    case 'd':
      const rows = _.get(layout, 'rows', 1);
      return (
        <VideoCardList
          key={`${carousel.id}_${carouselIndex}`}
          carousel={carousel}
          noOfRows={rows}
          sectionPath={sectionPath}
          theme={theme}
          showViewAllLink={showViewAllLink}
        />
      );
    case 'promo':
      return (
        <PromotionalBanner
          withBackground
          imgURL='/images/promotional-home-desktop-1.png'
          imgAlt='Win the ultimate Premier League trip'
          mobileImgURL='/images/promotional-home-mobile-1.png'
          mobileImgAlt='Win the ultimate Premier League trip'
          title='Win the ultimate Premier League trip!'
          text={
            <Fragment>
              <b>Optus Sport and SubHub</b> are giving you the chance to win the
              football trip of a lifetime with our Ultimate Premier League
              Giveaway.
            </Fragment>
          }
          ctaText='Learn more'
          ctaLink='https://subhub.optus.com.au/optus-sport-competition?utm_source=os&utm_medium=login_banner&utm_campaign=os_subhubcomp'
        />
      );
  }
};

export function getEditorialLimit(panel) {
  const layout = _.get(panel, 'layout');
  const layoutId = _.toLower(_.get(layout, 'id'));
  const layoutRows = _.get(layout, 'rows');

  // Restrict limits based on layout
  let limit = 15;

  switch (layoutId) {
    case 'a':
      limit = 6;
      break;
    case 'b':
      limit = 2;
      break;
    case 'c':
      limit = 16;
      break;
    case 'd':
      limit = layoutRows ? 4 * layoutRows : 4;
      break;
    default:
      limit = 16;
  }

  return limit;
}
