import { analyticAction } from '../../../ducks/analytic';
import {
  cloneSearchSessionId,
  getClonedSearchSessionId,
  popSearchDetailRequestedAssetInCookie,
  SEARCH_SOURCES,
} from './utils';

const searchResponded = (
  dispatch,
  { query, statusCode: status_code = null, assets = [], filters = [] },
) => {
  const assetIds = assets
    .slice(0, 10)
    .map((asset) => asset.id)
    .join(', ');
  const asset_count = assets.length;
  const filters_comma_seperated =
    filters && filters.map((filter) => filter.id).join(', ');

  cloneSearchSessionId();

  const {
    search_string = query,
    search_source = SEARCH_SOURCES.MANUAL,
    search_index = null,
  } = popSearchDetailRequestedAssetInCookie({ keepTheCookies: true });

  dispatch(
    analyticAction({
      eventName: 'Search Responded',
      search_session_id: getClonedSearchSessionId(),
      search_string,
      search_source,
      search_index: search_index ? Number(search_index) : null,
      asset_count,
      status_code,
      assets: assetIds,
      filters: filters_comma_seperated,
    }),
  );
};

export default searchResponded;
