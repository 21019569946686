import React, { createContext, useReducer } from 'react';

export const ContentCardContext = createContext(null);
export const ContentCardDispatchContext = createContext(null);

export function ContentCardProvider({ children }) {
  const [contentCardContext, dispatch] = useReducer(
    contentCardReducer,
    initialContentCard,
  );

  return (
    <ContentCardContext.Provider value={contentCardContext}>
      <ContentCardDispatchContext.Provider value={dispatch}>
        {children}
      </ContentCardDispatchContext.Provider>
    </ContentCardContext.Provider>
  );
}

function contentCardReducer(context, action) {
  switch (action?.type) {
    case 'assetReady': {
      return {
        ...context,
        asset: action?.asset,
      };
    }
    case 'cardClicked': {
      return {
        ...context,
        cardClicked: true,
      };
    }
    case 'undoCardClicked': {
      return {
        ...context,
        cardClicked: false,
      };
    }
    case 'primaryButtonClicked': {
      return {
        ...context,
        primaryButtonClicked: true,
      };
    }
    case 'undoPrimaryButtonClicked': {
      return {
        ...context,
        primaryButtonClicked: false,
      };
    }
    case 'secondaryButtonClicked': {
      return {
        ...context,
        secondaryButtonClicked: true,
      };
    }
    case 'undoSecondaryButtonClicked': {
      return {
        ...context,
        secondaryButtonClicked: false,
      };
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}

const initialContentCard = {
  asset: null,
  cardClicked: false,
  primaryButtonClicked: false,
  secondaryButtonClicked: false,
};
