import styled from 'styled-components';

const Log = styled.div`
  display: flex;
  width: 100%;
  background: white;
  border: 1px solid #323439;
  color: #323439;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
  margin-top: 16px;
  border-radius: 4px;
  overflow: hidden;
  &.dashed {
    border: 1px dashed #e0e0e0;
    color: #e0e0e0;
  }
`;

const Group = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 16px;
  margin-top: 8px;
  width: 100%;
  box-sizing: border-box;

  ${Log} {
    &:first-child {
      margin-top: 0;
    }
  }
`;

export { Log, Group };
