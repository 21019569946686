import _ from 'lodash';
import { getDevice, getParsedUA } from '../utils';

export function supportBrowser(userAgent) {
  var browser = userAgent.name;
  var version = parseFloat(userAgent.version);
  const deviceData = getDevice();

  // Do some manual pre parsing for crawlers/bots
  // Also needed for the prerender.cloud bot
  let isBot = false;
  const crawlers = [
    'Twitterbot',
    'Facebot',
    'Googlebot',
    'Slack',
    'LinkedInBot/1.0',
    'Pinterest',
    'facebookexternalhit/1.1',
    'WhatsApp/2',
    'prerendercloud',
    'OptusCloudSearchCrawler',
    'SkypeUriPreview',
    // 'Prerender (+https://github.com/prerender/prerender)',
  ];
  const parsedUA = getParsedUA();
  const rawUA = _.get(parsedUA, 'ua', '');
  _.each(crawlers, crawler => {
    isBot = rawUA.indexOf(crawler) >= 0;

    return !isBot;
  });

  if (isBot) {
    return true;
  }

  var supported =
    (browser === 'WebKit' && version >= 605) || // iPad iOS 13+
    (browser === 'Safari' && version >= 9) ||  // safari 9+
    (browser === 'Edge' && version >= 79) ||  // edge 79+
    (browser === 'Chrome' && version >= 95) || // chrome 95+
    (browser === 'Firefox' && version >= 65) || // firefox 65+
    (deviceData.type === 'mobile') || // mobile
    (deviceData.type === 'tablet') || // tablet
    (deviceData.type === 'smarttv'); // smart tv
  return supported;
};
