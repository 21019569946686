import React from 'react';
import PropTypes from 'prop-types';

const CornerKickIcon = ({ width, height, viewBox, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox} {...props}>
    <g fill="none" fillRule="evenodd">
        <path fill="#FFF" d="M5.047 12.405l-2.484 1.664-.557-.831L8 9.224V2h1v7.224l5.994 4.014-.557.83-2.484-1.663-.067.102c-.794 1.222-1.941 1.847-3.387 1.845-1.444-.001-2.59-.626-3.385-1.844l-.067-.103zm.831-.557l.074.114c.61.936 1.442 1.39 2.549 1.39 1.105.002 1.936-.451 2.547-1.39l.074-.114L8.5 10.093l-2.622 1.755z"/>
        <path fill="#FF126D" d="M2 2.11c.251-.269.84-.403 1.765-.403 1.178 0 1.381.51 2.33.55.4.016.868-.033 1.405-.147v4.125c-.04.114-.507.174-1.404.18-1.346.007-1.451-.558-2.33-.596-.587-.026-1.176.113-1.766.416V2.11z"/>
    </g>
  </svg>
);

CornerKickIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  viewBox: PropTypes.string
};

CornerKickIcon.defaultProps = {
  width: 32,
  height: 32,
  viewBox: '0 0 32 32'
};

export default CornerKickIcon;



