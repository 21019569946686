import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { useSearchContext } from '../SearchProvider';
import SearchAutoSuggest from './SearchAutoSuggest';
import SearchHistory from './SearchHistory';
import { SearchForm, BtnSearch, BtnClear, SearchFormIcon } from './styled';
import SearchIcon from '../../ui/ui-kit/Icons/SearchIcon';
import CloseIcon from '../../ui/ui-kit/Icons/CloseIcon';
import { useClickOutSide2 } from '../../../utils/hooks/useClickOutside';
import { removeNonAscii } from '../../../utils/sanitiseString';
import {
  analyticSearchRequested,
  analyticSearchSessionStarted,
} from '../__analytics__';
import { handleRequest } from '../SearchService';

export default function SearchBox({
  isInSearchPage = false,
  className,
  action,
  actionText,
}) {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    state: { config, isShowSearchSectionInNavbar, searchText },
    dispatch: searchDispatch,
  } = useSearchContext();

  const [value, setValue] = useState('');
  const [isShowHistory, setIsShowHistory] = useState(false);
  useEffect(() => {
    if (isInSearchPage) {
      // const urlValue = history.location.pathname.split('/')[2];
      //
      // if (urlValue) {
      //   setValue(decodeURIComponentSafe(urlValue));
      // } else {
      //   setIsShowHistory(true);
      // }

      if (searchText) {
        setValue(searchText);
      } else {
        setIsShowHistory(true);
      }
    }
  }, [searchText, isInSearchPage]);

  const historyShowCondition = isShowHistory && !value;
  const toggleIsShowHistory = useCallback(
    (bool) => {
      setIsShowHistory((prev) => (typeof bool === 'undefined' ? !prev : bool));
    },
    [setIsShowHistory],
  );

  const timer = useRef();
  const clearSuggestionRequestTimer = () => {
    clearTimeout(timer.current);
    timer.current = null;
  };
  const setSuggestionRequestTimer = (callback) => {
    timer.current = setTimeout(callback, 500);
  };

  const inputRef = useRef(null);
  const handleClearResult = (e) => {
    e.preventDefault();
    setValue('');

    searchDispatch({ type: 'SET_SEARCH_TEXT', data: '' });

    toggleIsShowHistory(true);

    // Fix focusing the input field after clearing result with 'x' icon
    inputRef.current.focus();
  };
  const handleSearch = (value) => {
    searchDispatch({ type: 'TOGGLE_SHOW_SEARCH_SECTION', data: false });
    toggleIsShowHistory(false);

    // Workaround as history decodes it i.e. %25 -> %
    const historyPathTo = `/search/${encodeURIComponent(
      encodeURIComponent(removeNonAscii(value))
    )}`;
    if (value === searchText) {
      history.replace(historyPathTo);
    } else {
      history.push(historyPathTo);
    }
    handleRequest({
      value,
      config,
      dispatch,
      searchDispatch,
    });
  };
  const handleSubmit = (e) => {
    if (e) e.preventDefault();
    clearSuggestionRequestTimer();

    analyticSearchRequested(dispatch, { query: value });
    handleSearch(value && value.trim());
  };

  const formRef = useRef(null);
  useClickOutSide2({
    ref: formRef,
    enabled: isShowSearchSectionInNavbar || (isInSearchPage && isShowHistory),
    callback: () => {
      if (isInSearchPage) {
        toggleIsShowHistory(false);
      } else {
        searchDispatch({ type: 'TOGGLE_SHOW_SEARCH_SECTION', data: false });
      }
    },
  });

  const inputProps = {
    ref: inputRef,
    placeholder: 'Search',
    value: value,
    autoFocus: !isInSearchPage,
    onChange: (event, { newValue }) => {
      if (!newValue) toggleIsShowHistory(true);
      setValue(newValue);
    },
    onFocus: () => {
      analyticSearchSessionStarted(dispatch);
      if (!value) toggleIsShowHistory(true);
    },
    onBlur: () => clearSuggestionRequestTimer(),
  };

  const actionRequired = action && actionText;

  const renderInputComponent = (inputProps) => {
    return (
      <div className={`flex relative bg-widget-grey`}>
        <div className="relative inline-block flex-1 p-1">
          <input {...inputProps} />
          <BtnSearch type="search" onClick={handleSubmit} />
          {value ? (
            <BtnClear onClick={handleClearResult}>
              <CloseIcon />
            </BtnClear>
          ) : (
            <SearchFormIcon>
              <SearchIcon />
            </SearchFormIcon>
          )}
        </div>

        {actionRequired ? (
          <div
            onClick={action}
            className="flex-none text-teal font-MarkProBold p-16 flex mb-0 cursor-pointer justify-center items-center self-start"
          >
            {actionText}
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <SearchForm
      ref={formRef}
      id="search-form"
      onSubmit={handleSubmit}
      isInSearchPage={isInSearchPage}
      className={className}
    >
      <div className="flex">
        <div className="relative w-full mx-auto">
          <SearchAutoSuggest
            ref={inputRef}
            renderInputComponent={renderInputComponent}
            inputProps={inputProps}
            handleSearch={handleSearch}
            setSuggestionRequestTimer={setSuggestionRequestTimer}
            clearSuggestionRequestTimer={clearSuggestionRequestTimer}
          />
        </div>
      </div>

      {historyShowCondition && (
        <SearchHistory
          handleSearch={handleSearch}
          className={`rounded-b-16px ${
            isInSearchPage ? 'absolute z-1 os-left-0 os-right-0' : ''
          }`}
        />
      )}
    </SearchForm>
  );
}
