const selectors = {
  getCategoryPath: (ownProps, editorial) => {
    const {
      sectionPath,
    } = ownProps;

    const editorialId = editorial && editorial.id;

    return sectionPath ? `/${sectionPath}/category/${editorialId}` : `/category/${editorialId}`;
  },
};

export default selectors;
