import React from 'react';
import PropTypes from 'prop-types';

const HamIcon = ({ width, height, color, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32" {...props}>
    <g fill={color} fillRule="evenodd">
      <path fill="none" d="M0 0h32v32H0z"/>
      <path d="M8 8h16v2H8V8zm0 7h16v2H8v-2zm0 7h16v2H8v-2z"/>
    </g>
  </svg>
);

HamIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

HamIcon.defaultProps = {
  color: 'currentColor',
  width: 32,
  height: 32
};

export default HamIcon;
