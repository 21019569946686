import get from 'lodash/get';
import API from '@aws-amplify/api';
import {
  LOADER_IDLE,
  LOADER_LOADING,
  LOADER_LOADED,
  LOADER_SUCCESS,
  LOADER_FAIL,
} from '../ducks/loading';

function toMethod(method) {
  if (method.toLowerCase() === `delete`) {
    return `del`;
  }
  return method.toLowerCase();
}

export async function fetchApi(url, options = {}) {
  const {
    method = `get`,
    headers = {},
    body = {},
    queryStringParameters,
    ...others
  } = options;
  const { path = `` } = others || {};
  let response = null;
  let error = null;
  try {
    const { waitBeforeAction, timeout = null } = options || {};
    await waitFor(waitBeforeAction);
    response = await API[toMethod(method)](url, path, {
      headers,
      queryStringParameters,
      body,
      ...(timeout ? { timeout } : {}),
    });
    // JSON-RPC schema has error inside the response
    if (response.error) {
      error = response.error;
    }
  } catch (err) {
    error = get(err, `response.data`) || err;
  }

  // Rust style response followed by list of errors.
  // For now, just one error.
  return [response, error];
}

export function start(name) {
  return `${name}_START`;
}

export function success(name) {
  return `${name}_SUCCESS`;
}

export function fail(name) {
  return `${name}_FAIL`;
}

export function apiAction(actionTypePrefix, url, options) {
  return {
    type: `fetch`,
    payload: {
      name: actionTypePrefix,
      url,
      options,
    },
  };
}

async function waitFor(ms) {
  if (!ms) {
    return;
  }
  await new Promise((resolve) => setTimeout(resolve, ms));
}

export default ({ dispatch }) => (next) => async (action) => {
  const { type, payload } = action;
  if (type !== `fetch`) {
    return next(action);
  }
  const { name } = payload;
  dispatch({ type: LOADER_IDLE, payload: name });
  if (!name) {
    throw new Error(`'payload.name' is required.`);
  }
  const { url, options } = payload;
  dispatch({ type: start(name), payload: options, apiActionContext: payload });
  dispatch({ type: LOADER_LOADING, payload: name });
  const [response, error] = await fetchApi(url, options);
  dispatch({ type: LOADER_LOADED, payload: name });
  if (error) {
    dispatch({ type: fail(name), payload: error, apiActionContext: payload });
    dispatch({ type: LOADER_FAIL, payload: name });
    return;
  }
  dispatch({
    type: success(name),
    payload: response,
    apiActionContext: payload,
  });
  dispatch({ type: LOADER_SUCCESS, payload: name });

  next(action);
};
