import React from 'react';
import PropTypes from 'prop-types';
import ResultSummaryRow from "./ResultSummaryRow";

const ResultSummary = ({resultLeft, resultRight, className}) => (
  <div className={`flex flex-row w-full ${className === null ? '' : ` ${className}`}`}>
    <div className="flex-0 w-1/2 pr-6">
      {resultLeft}
    </div>
    <div className="flex-0 w-1/2 pl-6">
      { resultRight.map(right => React.cloneElement(right, { isOnLeft: false})) }
    </div>
  </div>
);

ResultSummary.propTypes = {
  /** Result summary row items to show on the left */
  resultLeft: PropTypes.arrayOf(PropTypes.objectOf(ResultSummaryRow)),
  /** Result summary row items to show on the right */
  resultRight: PropTypes.arrayOf(PropTypes.objectOf(ResultSummaryRow)),
  /** Classes to apply to the root element of this component. Classes added here will override any default classes or props that accept classes. For example width - use with caution. */
  className: PropTypes.string,
};

ResultSummary.defaultProps = {
  className: null,
};

export default ResultSummary;
