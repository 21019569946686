import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { Label } from './styles';

const InputLabel = (props) => {
  const {
    children,
    className = '',
    color = '',
    fontVariant = '',
    isError = false,
    textSize = '',
    verticalPull = 0,
    verticalPush = 0,
    asBlock = false,
    ...rest
  } = props;
  return (
    <Label
      fontVariant={fontVariant}
      textSize={textSize}
      color={color}
      isError={isError}
      className={className}
      asBlock={asBlock}
      verticalPush={verticalPush}
      verticalPull={verticalPull}
      {...rest}
    >
      {children}
    </Label>
  );
};

InputLabel.propTypes = {
  /** Child components */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Classname for styling */
  className: PropTypes.string,
  /** color value, default is: white */
  color: PropTypes.string,
  /** Warning theme */
  isError: PropTypes.bool,
  /** textsize*/
  textSize: PropTypes.string,
  /** Determine display type: default is inline */
  asBlock: PropTypes.bool,
  /** Margin top value */
  verticalPush: PropTypes.number,
  /** Margin bottom value */
  verticalPull: PropTypes.number,
  /** Font family */
  fontVariant: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
};

InputLabel.defaultProps = {
  color: 'white',
  textSize: 'sm',
  className: '',
  fontVariant: 'secondary',
  verticalPush: 4,
  verticalPull: 0,
};

export default withTheme(InputLabel);
