import styled, { css } from 'styled-components';
import { asRem } from '../Theme';

const Container = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.direction && props.direction) || 'row'};
  width: 100%;
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
`;

const InputFooter = styled.label`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  line-height: 1.33;
  padding: 8px 0 0 0;
  font-weight: bold;
  ${(props) => {
    const {
      theme: { palette, font, textSize },
    } = props;
    return css`
      font-size: ${textSize['xs']};
      font-family: ${font?.['primary']};
      color: ${palette?.['live-red']};
    `;
  }}
`;

const InputContainer = styled.div`
  position: relative;
  ${(props) => {
    const { verticalPush } = props;
    return css`
      margin-top: ${verticalPush !== 0 ? verticalPush : 0}px;
    `;
  }}
`;

const InputLoader = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-16px, -50%);
  z-index: 1;
`;

const InputTail = styled.span`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translate(0, -50%);
  font-weight: bold;
  cursor: pointer;
  ${(props) => {
    const {
      theme: { palette, font },
    } = props;
    return css`
      color: ${palette?.['os-teal']};
      line-height: ${asRem(24)};
      font-family: ${font?.['primary']};

      &:hover {
        color: ${palette?.['os-teal-dark']};
      }

      &:active {
        color: ${palette?.['os-teal-light']};
      }
    `;
  }}
`;

export { Container, InputContainer, InputFooter, InputLoader, InputTail };
