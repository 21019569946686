import { createGlobalStyle } from 'styled-components';
import { getAbsoluteStaticUrl } from 'utils/path';

const GlobalStyle = createGlobalStyle`
    html {
        box-sizing: border-box;
    }
    *, *:before, *:after {
        box-sizing: inherit;
    }
    #modal-root {
        overflow: hidden;
    }

    @font-face {
        font-family: "MarkPro";
        src:  url(${getAbsoluteStaticUrl('/fonts/MarkPro.otf')}) format("opentype"),
            url(${getAbsoluteStaticUrl('/fonts/MarkPro.woff2')}) format("woff2"),
            url(${getAbsoluteStaticUrl('/fonts/MarkPro.woff')}) format("woff"),
            url(${getAbsoluteStaticUrl('/fonts/MarkPro.ttf')}) format("truetype");
        font-weight: normal;
        font-style: normal;
        font-display: swap;
}
    @font-face {
        font-family: "MarkPro-Bold";
        src:  url(${getAbsoluteStaticUrl('/fonts/MarkPro-Bold.otf')}) format("opentype"),
                url(${getAbsoluteStaticUrl('/fonts/MarkPro-Bold.woff2')}) format("woff2"),
                url(${getAbsoluteStaticUrl('/fonts/MarkPro-Bold.woff')}) format("woff"),
                url(${getAbsoluteStaticUrl('/fonts/MarkPro-Bold.ttf')}) format("truetype");
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: "MarkPro-Heavy";
        src:  url(${getAbsoluteStaticUrl('/fonts/MarkPro-Heavy.otf')}) format("opentype"),
                url(${getAbsoluteStaticUrl('/fonts/MarkPro-Heavy.woff2')}) format("woff2"),
                url(${getAbsoluteStaticUrl('/fonts/MarkPro-Heavy.woff')}) format("woff"),
                url(${getAbsoluteStaticUrl('/fonts/MarkPro-Heavy.ttf')}) format("truetype");
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }
`;

export default GlobalStyle;
