import React from 'react';
import PropTypes from 'prop-types';

const OwnGoalIcon = ({ width, height, viewBox, ...props }) => (

<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={width} height={height} viewBox={viewBox} {...props}>
    <defs>
        <path id="ownGoal_a" d="M11.999 0v9.293H0V0h11.999z"/>
        <path id="ownGoal_c" d="M11.999 0v9.293H0V0h11.999z"/>
    </defs>
    <g fill="none" fillRule="evenodd">
        <g transform="translate(2 2)">
            <mask id="ownGoal_b" fill="#fff">
                <use xlinkHref="#ownGoal_a"/>
            </mask>
            <path fill="#FFF" d="M.645 4.532a5.539 5.539 0 0 1 .752-1.637l-.101 1.336-.02.25-.131.214-.692 1.123c.014-.44.079-.87.192-1.286zM1.938 2.22A5.558 5.558 0 0 1 3.653.968l2.092.507v2.093l-.04.028-1.91 1.388-.055.041-1.967-.639.165-2.167zM5.745.456a5.424 5.424 0 0 1 .488 0A5.55 5.55 0 0 1 7.522.66l-1.29.314-.243.06-.244-.06-1.278-.31A5.565 5.565 0 0 1 5.745.455zm2.594.508a5.556 5.556 0 0 1 1.724 1.255l.164 2.153-1.988.646-.048-.034-1.91-1.388-.048-.035V1.475l2.106-.51zM11.2 4.055a5.567 5.567 0 0 1 .345 1.748l-.692-1.122-.131-.213-.02-.25-.1-1.32c.246.362.446.75.598 1.157zM1.143 8.686A5.48 5.48 0 0 1 .49 6.684L1.623 4.85l1.99.646.014.046.285.876a3.79 3.79 0 0 1 .505-.048l-.32-.985 1.898-1.379 1.9 1.38-.71 2.184c.068.078.149.144.21.228.107-.147.24-.27.369-.398l.608-1.872.015-.045 1.99-.647 1.054 1.71c.18.055.355.122.52.205a5.981 5.981 0 0 0-1.709-4.994A6.014 6.014 0 0 0 8.06.363a6.012 6.012 0 0 0-6.302 1.394 6.386 6.386 0 0 0-.484.546A5.994 5.994 0 0 0 .05 6.771a5.972 5.972 0 0 0 .936 2.522c.034-.21.089-.412.157-.607z" mask="url(#ownGoal_b)"/>
            <mask id="ownGoal_d" fill="#fff">
                <use xlinkHref="#ownGoal_c"/>
            </mask>
            <path fill="#FFF" d="M.645 4.532a5.539 5.539 0 0 1 .752-1.637l-.101 1.336-.02.25-.131.214-.692 1.123c.014-.44.079-.87.192-1.286zM1.938 2.22A5.558 5.558 0 0 1 3.653.968l2.092.507v2.093l-.04.028-1.91 1.388-.055.041-1.967-.639.165-2.167zM5.745.456a5.424 5.424 0 0 1 .488 0A5.55 5.55 0 0 1 7.522.66l-1.29.314-.243.06-.244-.06-1.278-.31A5.565 5.565 0 0 1 5.745.455zm2.594.508a5.556 5.556 0 0 1 1.724 1.255l.164 2.153-1.988.646-.048-.034-1.91-1.388-.048-.035V1.475l2.106-.51zM11.2 4.055a5.567 5.567 0 0 1 .345 1.748l-.692-1.122-.131-.213-.02-.25-.1-1.32c.246.362.446.75.598 1.157zM1.143 8.686A5.48 5.48 0 0 1 .49 6.684L1.623 4.85l1.99.646.014.046.285.876a3.79 3.79 0 0 1 .505-.048l-.32-.985 1.898-1.379 1.9 1.38-.71 2.184c.068.078.149.144.21.228.107-.147.24-.27.369-.398l.608-1.872.015-.045 1.99-.647 1.054 1.71c.18.055.355.122.52.205a5.981 5.981 0 0 0-1.709-4.994A6.014 6.014 0 0 0 8.06.363a6.012 6.012 0 0 0-6.302 1.394 6.386 6.386 0 0 0-.484.546A5.994 5.994 0 0 0 .05 6.771a5.972 5.972 0 0 0 .936 2.522c.034-.21.089-.412.157-.607z" mask="url(#ownGoal_d)"/>
        </g>
        <text fill="#FF126D" fontFamily="MarkPro-Bold, Mark Pro" fontSize="7" fontWeight="bold" transform="translate(2 2)">
            <tspan x="1.607" y="13">OG</tspan>
        </text>
    </g>
</svg>
);

OwnGoalIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  viewBox: PropTypes.string
};

OwnGoalIcon.defaultProps = {
  width: 32,
  height: 32,
  viewBox: '0 0 32 32'
};

export default OwnGoalIcon;



