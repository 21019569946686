import React from 'react';
import PropTypes from 'prop-types';
import Label from './Label';

const classes = {
  common:
    'font-MarkProBold leading-lg text-teal hover:text-teal-dark active:text-teal-light active:no-underline os-transition os-transitionproperty-all',
  specific:
    'font-MarkPro leading-1.29 md:leading-1.13 text-teal hover:text-teal-dark active:text-teal-light active:no-underline os-transition os-transitionproperty-all text-sm md:text-base',
};

const Link = ({
  children,
  link,
  target,
  textSize,
  type,
  underline,
  onClick,
  ...props
}) => (
  <Label
    textSize={textSize}
    className={`${type === 'helpSupport' ? classes.specific : classes.common}`}
    {...props}
  >
    <a
      className={`${
        type === 'helpSupport' ? classes.specific : classes.common
      } ${underline ? '' : 'no-underline'}`}
      href={link}
      target={target}
      onClick={onClick}
    >
      {children}
    </a>
  </Label>
);

Link.propTypes = {
  /** Link content*/
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Link to use. In case no link is provided, '#' will be used */
  link: PropTypes.string,
  /** Link target - whether to open the link in a new window, same window or in a new tab. Defaults to blank */
  target: PropTypes.oneOf(['self', 'blank', 'parent', 'top']),
  /** Taiwind textsize class*/
  textSize: PropTypes.string,
  /** Type of the link*/
  type: PropTypes.string,
  /** Show underline for the link*/
  underline: PropTypes.bool,
};

Link.defaultProps = {
  target: 'blank',
  textSize: 'text-base',
  link: '#',
  type: 'default',
  underline: true,
  onClick: null,
};

export default Link;
