import {
  isLoading as globalLoading,
  isLoaded as globalLoaded,
  isSuccess as globalSuccess,
  isLoadingAnyOf as globalLoadingAnyOf,
} from '../ducks/loading';

const isLoading = ({ action }) => (state) =>
  globalLoading(state.loading, action);
const isLoadingAnyOf = ({ actions }) => (state) =>
  globalLoadingAnyOf(state.loading, actions);
const isLoaded = ({ action }) => (state) => globalLoaded(state.loading, action);
const isSuccess = ({ action }) => (state) =>
  globalSuccess(state.loading, action);

export { isLoaded, isSuccess, isLoading, isLoadingAnyOf };
