import get from 'lodash/get';
import axios from 'axios';
import { success } from 'react-notification-system-redux';
import defaultConfig from '../constants/defaultConfig';
import * as types from '../constants/actionTypes';

const notificationOpts = {
  // uid: 'once-please', // you can specify your own uid if required
  title: '',
  message: '',
  position: 'tl',
  autoDismiss: 3,
  action: null,
  dismissible: 'none',
};

export function getResults(competitionId, season) {
  return (dispatch) => {
    const results = { competitionId, season };
    if (!competitionId || !season) {
      dispatch({ type: types.GET_RESULTS_FAILURE, results });
      return;
    }
    const {
      vcmsBaseUrl,
      VCMSConfig: { resultsEndpoint },
    } = defaultConfig;
    const url = `${vcmsBaseUrl}${resultsEndpoint}?season=${season}&competition=${competitionId}`;

    dispatch({ type: types.GET_RESULTS_INPROGRESS, results });
    axios
      .get(url)
      .then((res) => {
        const isSuccess = get(res, 'data.result[0].statuscode', 'N') === 'Y';

        if (isSuccess) {
          results.rows = get(res, 'data.APIinfo[0].FixtureInfo', []); // results return FixtureInfo as well
          setTimeout(() => {
            dispatch({ type: types.GET_RESULTS_SUCCESS, results });
          }, 1000);
          dispatch(success(notificationOpts));
        } else {
          dispatch({ type: types.GET_RESULTS_FAILURE, results });
        }
      })
      .catch(() => {
        dispatch({ type: types.GET_RESULTS_FAILURE, results });
      });
  };
}
