import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import _ from 'lodash';
// import rg4js from 'raygun4js';

class ScrollToTopRedirect extends Component {
  /*
  constructor(props, context) {
    super(props, context);

    this.handleRayGunEvent = this.handleRayGunEvent.bind(this);
  }
  */

  handleRayGunEvent(pathname) {
    // rg4js('trackEvent', { type: 'pageView', path: pathname });
  }

  componentDidMount() {
    // Prevent auto scroll restoration on root page reload/refresh
    //if (window.history.scrollRestoration && window.location.pathname === '/') {
    //  window.history.scrollRestoration = 'manual';
    //}
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      //window.history.scrollRestoration = 'auto';

      // Only scroll to top for non hash changes
      const prevLocation = _.get(prevProps, 'location');
      const prevPathName = _.get(prevLocation, 'pathname');
      const prevSearch = _.get(prevLocation, 'search');

      const currentLocation = _.get(this.props, 'location');
      const currentPathName = _.get(currentLocation, 'pathname');
      const currentSearch = _.get(currentLocation, 'search');

      if (!(currentPathName === prevPathName &&
            currentSearch === prevSearch)
      ) {
        window.scrollTo(0, 0);

        // setTimeout(() => {
        //   if (window.scrollY !== 0) {console.log('forcing scroll top');
        //     window.scrollTo(0, 0);
        //   }
        // }, 1);
      }

      this.handleRayGunEvent(currentPathName);
    }

    const search = _.get(this.props, 'location.search', '');
    const query = qs.parse(search, { ignoreQueryPrefix: true });

    if (query.referringAction) {
      switch (query.referringAction) {
        case 'fanHubLogin':
          this.props.history.push('login');
          break;
        case 'basicSignup':
          this.props.history.push('signup');
          break;

        default:
          break;
      }
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTopRedirect);
