import get from 'lodash/get';
import defaultConfig from '../constants/defaultConfig';

const getFlagWithFallback = (features) => {
  return (featureFlag) => {
    if (!featureFlag) {
      return features;
    }
    const defaultFlagValue = get(defaultConfig, ['features', featureFlag]);
    return get(features, featureFlag, defaultFlagValue);
  };
};

const getFlags = ({ systemConfig }) => {
  return getFlagWithFallback(systemConfig.features);
};

export { getFlagWithFallback };

export default getFlags;
