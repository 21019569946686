export default {
  lightv2: {
    default: {
      classes: 'bg-white text-black',
      videoCard: 'rounded-xl',
      categoryTitle: 'text-cyan-green',
      title: 'text-black',
      timeString:
        'absolute bottom-0 time-text-footer pb-14 sm:pb-16 text-black',
      broadcastDateString:
        'absolute bottom-0 time-string font-MarkPro pb-14 sm:pb-16 time-text-footer text-black',
    },
    a: {
      classes: 'bg-grey text-black',
      videoCard: 'rounded-t-xl',
      categoryTitle: 'text-cyan-green',
      title: 'text-black',
      timeString:
        'absolute bottom-0 time-text-footer pb-14 sm:pb-16 text-black',
      broadcastDateString:
        'absolute bottom-0 time-string font-MarkPro pb-14 sm:pb-16 time-text-footer text-black',
    },
    b: {
      classes: 'bg-grey text-black',
      videoCard: 'rounded-t-xl',
      categoryTitle: 'text-cyan-green',
      title: 'text-black',
      timeString:
        'absolute bottom-0 time-text-footer pb-14 sm:pb-16 text-black',
      broadcastDateString:
        'absolute bottom-0 time-string font-MarkPro pb-14 sm:pb-16 time-text-footer text-black',
    },
    c: {
      classes: 'bg-grey text-black',
      videoCard: 'rounded-t-xl',
      categoryTitle: 'text-cyan-green',
      title: 'text-black',
      timeString:
        'absolute bottom-0 time-text-footer pb-14 sm:pb-16 text-black',
      broadcastDateString:
        'absolute bottom-0 time-string font-MarkPro pb-14 sm:pb-16 time-text-footer text-black',
    },
    d: {
      classes: 'bg-grey text-black',
      videoCard: 'rounded-t-xl',
      categoryTitle: 'text-cyan-green',
      title: 'text-black',
      timeString:
        'absolute bottom-0 time-text-footer pb-14 sm:pb-16 text-black',
      broadcastDateString:
        'absolute bottom-0 time-string font-MarkPro pb-14 sm:pb-16 time-text-footer text-black',
    },
  },
  light: {
    default: {
      classes: 'bg-white text-black card-shadow',
      videoCard: 'rounded-t-xl',
      categoryTitle: 'text-cyan',
      title: 'text-black',
      timeString:
        'absolute bottom-0 time-text-footer pb-14 sm:pb-16 text-black',
      broadcastDateString:
        'absolute bottom-0 time-string font-MarkPro pb-14 sm:pb-16 time-text-footer text-black',
    },
  },
  dark: {
    default: {
      classes: 'bg-dark-grey text-white',
      videoCard: 'rounded-xl',
      categoryTitle: 'text-teal',
      title: 'text-white',
      timeString:
        'absolute bottom-0 time-text-footer pb-14 sm:pb-16 text-white',
      broadcastDateString:
        'absolute bottom-0 time-string font-MarkPro pb-14 sm:pb-16 time-text-footer text-white',
    },
    a: {
      classes: 'bg-dark-grey text-white',
      videoCard: 'rounded-t-xl',
      categoryTitle: 'text-teal',
      title: 'text-white',
      timeString:
        'absolute bottom-0 time-text-footer pb-14 sm:pb-16 text-white',
      broadcastDateString:
        'absolute bottom-0 time-string font-MarkPro pb-14 sm:pb-16 time-text-footer text-white',
    },
    b: {
      classes: 'bg-dark-grey text-white',
      videoCard: 'rounded-t-xl',
      categoryTitle: 'text-teal',
      title: 'text-white',
      timeString:
        'absolute bottom-0 time-text-footer pb-14 sm:pb-16 text-white',
      broadcastDateString:
        'absolute bottom-0 time-string font-MarkPro pb-14 sm:pb-16 time-text-footer text-white',
    },
    c: {
      classes: 'bg-dark-grey text-white',
      videoCard: 'rounded-xl',
      categoryTitle: 'text-teal',
      title: 'text-white',
      timeString:
        'absolute bottom-0 time-text-footer pb-14 sm:pb-16 text-white',
      broadcastDateString:
        'absolute bottom-0 time-string font-MarkPro pb-14 sm:pb-16 time-text-footer text-white',
    },
    d: {
      classes: 'bg-dark-grey text-white',
      videoCard: 'rounded-t-xl',
      categoryTitle: 'text-teal',
      title: 'text-white',
      timeString:
        'absolute bottom-0 time-text-footer pb-14 sm:pb-16 items-end text-white',
      broadcastDateString:
        'absolute bottom-0 time-string font-MarkPro pb-14 sm:pb-16 time-text-footer text-white',
    },
  },
};
