import React from 'react';
import PropTypes from 'prop-types';

const FooterLink = ({label, link, ...props}) => (
  <a className="text-teal font-MarkProBold no-underline" href={link} {...props}>{label}</a>
);

FooterLink.propTypes = {
  label: PropTypes.string,
  link: PropTypes.string,
};

FooterLink.defaultProps = {
  label: '',
  link: '',
};

export default FooterLink;
