import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function commentary(state = initialState.commentary, action) {
  let currentCommentary;

  switch (action.type) {

    case types.GET_COMMENTARY_INPROGRESS:
      currentCommentary = {
        ...state.find(commentary => commentary.competitionId === action.results.competitionId &&  commentary.season === action.results.season && commentary.matchId === action.results.matchId),
        ...action.results,
        inProgress: true,
        isSuccess: false
      };

      return [
        ...state.filter(commentary => commentary.competitionId !== action.results.competitionId || commentary.season !== action.results.season),
        currentCommentary
      ];

    case types.GET_COMMENTARY_SUCCESS:

      return [
        ...state.filter(commentary => commentary.competitionId !== action.results.competitionId || commentary.season !== action.results.season),
        {
          ...action.results,
          inProgress: false,
          isSuccess: true
        }
      ];

    case types.GET_COMMENTARY_FAILURE:
      currentCommentary = {
        // for failure case, use whatever has been loaded before
        ...state.find(commentary => commentary.competitionId === action.results.competitionId &&  commentary.season === action.results.season && commentary.matchId === action.results.matchId),
        ...action.results,
        inProgress: false,
        isSuccess: false
      };

      return [
        ...state.filter(commentary => commentary.competitionId !== action.results.competitionId || commentary.season !== action.results.season),
        currentCommentary
      ];

    default:
      return state;
  }
}
