import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function saagStatus(state = initialState.saagStatus, action) {
  switch (action.type) {
    case types.SET_SAAG_STATUS_INPROGRESS:
      return {
        inProgress: true,
        isSuccess: false,
      };
    case types.SET_SAAG_STATUS_SUCCESS:
      return { inProgress: false, isSuccess: true };
    case types.SET_SAAG_STATUS_FAILURE:
      return { inProgress: false, isSuccess: false };
    default:
      return state;
  }
}
