export const showAllId = 'All';

export const filterMapping = {
  'article': 'Articles',
  'classics': 'Classics',
  'egame': 'Games',
  'game': 'Games',
  'minimatch': 'Mini Matches',
  'highlights': 'Highlights',
  'interview': 'Interviews',
  'linear-channel': 'Live Channels',
  'news': 'News',
  'pressconference': 'Press Conferences',
  'short': 'Highlights',
  'show': 'Shows',
  'game-nostats': 'Games',
  'other': 'Others',
};

function remapItemFilter(item = {}) {
  const matchedFilterMapping = getItemFilter(item);

  return {...item, filter: matchedFilterMapping};
}

export function getItemFilter(item) {
  const itemRawFilter = typeof item === 'string' ? item : item.filter;

  // Default to 'other' if for whatever reason filter doesn't exist (i.e. BE issue)
  return filterMapping[itemRawFilter] || filterMapping.other;
}

export function getFiltersByData(data) {
  const filterData = {};
  let result;
  let totalCount = 0;
  data.length &&
    data.forEach((item) => {
      const { filter } = remapItemFilter(item);

      const filterItem = filterData[filter] || {
        count: 0,
        id: filter,
        text: filter,
        isSelected: false,
      };
      filterItem.count++;
      filterData[filter] = filterItem;
    });
  result = Object.keys(filterData)
    .map((key) => {
      const item = filterData[key];
      totalCount = totalCount + item.count;
      return item;
    })
    .sort((a, b) => (a.text < b.text ? -1 : a.text > b.text ? 1 : 0));

  if (result.length) {
    result = [
      {
        id: showAllId,
        text: 'All',
        isSelected: true,
        count: totalCount,
      },
      ...result,
    ];
  } else {
    result = null;
  }

  return result;
}

export function updateSelectedFilters(
  allFilters = [],
  selectedFiltersIds = [],
) {
  let nextFiltersData;
  let nextSelectedFilterIds = [];
  const isShowAllSelected =
    !selectedFiltersIds || selectedFiltersIds.length === 0;

  if (isShowAllSelected) {
    nextSelectedFilterIds.push(showAllId);
    nextFiltersData = allFilters.map((item) => {
      item.isSelected = item.id === showAllId;
      return item;
    });
  } else {
    nextFiltersData = allFilters.map((item) => {
      const isSelected = selectedFiltersIds.find((id) => id === item.id);
      if (isSelected) nextSelectedFilterIds.push(item.id);

      item.isSelected = isSelected;
      return item;
    });
  }

  return {
    newData: nextFiltersData,
    filteredData: nextSelectedFilterIds,
  };
}

export function getResultByFilter(data, filtersData) {
  const isShowAll =
    !filtersData ||
    filtersData?.length === 0 ||
    filtersData.includes(showAllId);

  if (isShowAll) return data;
  return data.filter((dataItem) => {
    const item = remapItemFilter(dataItem);
    return filtersData.includes(item.filter);
  });
}
