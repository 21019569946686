export const GET_SYSTEM_CONFIG_INPROGRESS = 'GET_SYSTEM_CONFIG_INPROGRESS';
export const GET_SYSTEM_CONFIG_SUCCESS = 'GET_SYSTEM_CONFIG_SUCCESS';
export const GET_SYSTEM_CONFIG_FAILURE = 'GET_SYSTEM_CONFIG_FAILURE';

export const GET_NAVIGATION_INPROGRESS = 'GET_NAVIGATION_INPROGRESS';
export const GET_NAVIGATION_SUCCESS = 'GET_NAVIGATION_SUCCESS';
export const GET_NAVIGATION_FAILURE = 'GET_NAVIGATION_FAILURE';

export const GET_PAGE_INPROGRESS = 'GET_PAGE_INPROGRESS';
export const GET_PAGE_SUCCESS = 'GET_SECTION_SUCCESS';
export const GET_PAGE_FAILURE = 'GET_PAGE_FAILURE';

export const GET_EDITORIAL_INPROGRESS = 'GET_EDITORIAL_INPROGRESS';
export const GET_EDITORIAL_SUCCESS = 'GET_EDITORIAL_SUCCESS';
export const GET_EDITORIAL_FAILURE = 'GET_EDITORIAL_FAILURE';
export const GET_EDITORIAL_PURGE = 'GET_EDITORIAL_PURGE';

export const GET_BANNER_ASSET = 'GET_BANNER_ASSET';
export const GET_BANNER_ASSET_SUCCESS = 'GET_BANNER_ASSET_SUCCESS';
export const GET_BANNER_ASSET_FAILURE = 'GET_BANNER_ASSET_FAILURE';

export const GET_ASSET_SUCCESS = 'GET_ASSET_SUCCESS';
export const GET_ASSET_FAILURE = 'GET_ASSET_FAILURE';
export const GET_ASSET_CATEGORY_PENDING = 'GET_ASSET_CATEGORY_PENDING';
export const GET_ASSET_CATEGORY_SUCCESS = 'GET_ASSET_CATEGORY_SUCCESS';
export const GET_ASSET_CATEGORY_FAILURE = 'GET_ASSET_CATEGORY_FAILURE';
export const GET_ASSET_PLAY_SUCCESS = 'GET_ASSET_PLAY_SUCCESS';
export const GET_ASSET_PLAY_FAILURE = 'GET_ASSET_PLAY_FAILURE';
export const GET_ASSET_PLAY_HISTORY_SUCCESS = 'GET_ASSET_PLAY_HISTORY_SUCCESS';
export const GET_ASSET_PLAY_HISTORY_FAILURE = 'GET_ASSET_PLAY_HISTORY_FAILURE';
export const GET_ASSET_EPG_SUCCESS = 'GET_ASSET_EPG_SUCCESS';
export const GET_ASSET_EPG_FAILURE = 'GET_ASSET_EPG_FAILURE';
export const UNSET_ASSET_PLAY = 'UNSET_ASSET_PLAY';
export const UNSET_ASSET = 'UNSET_ASSET';
export const UNSET_ASSET_PLAY_HISTORY = 'UNSET_ASSET_PLAY_HISTORY';
export const SET_ASSET_PLAY_INPROGRESS = 'SET_ASSET_PLAY_INPROGRESS';
export const UNSET_ASSET_PLAY_INPROGRESS = 'UNSET_ASSET_PLAY_INPROGRESS';

export const SET_USER = 'SET_USER';
export const UNSET_USER = 'UNSET_USER';
export const SET_USER_ORDERS = 'SET_USER_ORDERS';
export const UNSET_USER_ORDERS = 'UNSET_USER_ORDERS';
export const SET_AUTH_INPROGRESS = 'SET_AUTH_INPROGRESS';
export const UNSET_AUTH_INPROGRESS = 'UNSET_AUTH_INPROGRESS';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_FAILURE = 'USER_LOGOUT_FAILURE';
export const SET_USER_FORGOT_PASSWORD = 'SET_USER_FORGOT_PASSWORD';
export const SEND_DATA_TO_SUBHUB = 'SEND_DATA_TO_SUBHUB';
export const UNSET_USER_FORGOT_PASSWORD = 'UNSET_USER_FORGOT_PASSWORD';
export const SET_USER_RESET_PASSWORD = 'SET_USER_RESET_PASSWORD';
export const UNSET_USER_RESET_PASSWORD = 'UNSET_USER_RESET_PASSWORD';
export const SET_USER_CHANGE_PASSWORD = 'SET_USER_CHANGE_PASSWORD';
export const UNSET_USER_CHANGE_PASSWORD = 'UNSET_USER_CHANGE_PASSWORD';
export const VALIDATE_EMAIL_INPROGRESS = 'VALIDATE_EMAIL_INPROGRESS';
export const VALIDATE_EMAIL_SUCCESS = 'VALIDATE_EMAIL_SUCCESS';
export const VALIDATE_EMAIL_FAILURE = 'VALIDATE_EMAIL_FAILURE';
export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';

export const CREATE_USER_BY_VOUCHER_INPROGRESS = `CREATE_USER_BY_VOUCHER_INPROGRESS`;
export const CREATE_USER_BY_VOUCHER_SUCCESS = 'CREATE_USER_BY_VOUCHER_SUCCESS';
export const CREATE_USER_BY_VOUCHER_FAILURE = 'CREATE_USER_BY_VOUCHER_FAILURE';

export const VALIDATE_COUPON_INPROGRESS = 'VALIDATE_COUPON_INPROGRESS';
export const VALIDATE_COUPON_SUCCESS = 'VALIDATE_COUPON_SUCCESS';
export const VALIDATE_COUPON_FAILURE = 'VALIDATE_COUPON_FAILURE';

export const SUBSCRIPTION_VOUCHER_INPROGRESS = `SUBSCRIPTION_VOUCHER_INPROGRESS`;
export const SUBSCRIPTION_VOUCHER_SUCCESS = 'SUBSCRIPTION_VOUCHER_SUCCESS';
export const SUBSCRIPTION_VOUCHER_FAILURE = 'SUBSCRIPTION_VOUCHER_FAILURE';
export const SUBSCRIPTION_USER_CREATE_INPROGRESS = `SUBSCRIPTION_USER_CREATE_INPROGRESS`;
export const SUBSCRIPTION_USER_CREATE_SUCCESS = `SUBSCRIPTION_USER_CREATE_SUCCESS`;
export const SUBSCRIPTION_USER_CREATE_FAILURE = `SUBSCRIPTION_USER_CREATE_FAILURE`;
export const UPDATE_VOUCHER_SUBMIT_BUTTON = 'UPDATE_VOUCHER_SUBMIT_BUTTON';
export const SET_USER_TYPE = 'SET_USER_TYPE';
export const SET_ANALYTICS_USER_ID = 'SET_ANALYTICS_USER_ID';
export const SET_IS_INTL_USER = 'SET_IS_INTL_USER';
export const SET_USER_TAGS = 'SET_USER_TAGS';
// May not be used
export const SET_USER_CUSTOM_TOKEN = 'SET_USER_CUSTOM_TOKEN';
export const SET_USER_SETTINGS_INPROGRESS = 'SET_USER_SETTINGS_INPROGRESS';
export const UPDATE_USER_SETTINGS_INPROGRESS = 'UPDATE_USER_SETTINGS_INPROGRESS';
export const UPDATE_USER_SETTINGS_SUCCESS = 'UPDATE_USER_SETTINGS_SUCCESS';
export const UPDATE_USER_SETTINGS_FAILURE = 'UPDATE_USER_SETTINGS_FAILURE';
export const SET_USER_SETTINGS_SUCCESS = 'SET_USER_SETTINGS_SUCCESS';
export const SET_USER_SETTINGS_FAILURE = 'SET_USER_SETTINGS_FAILURE';
export const SHOW_USER_SETTINGS_SUCCESS_ALERT = `SHOW_USER_SETTINGS_SUCCESS_ALERT`;
export const SHOW_USER_SETTINGS_FAILURE_ALERT = `SHOW_USER_SETTINGS_FAILURE_ALERT`;
export const RESET_USER_SETTINGS_ALERT = 'RESET_USER_SETTINGS_ALERT';

// Ticket manager
export const CREATE_TICKET_INPROGRESS = 'CREATE_TICKET_INPROGRESS';
export const CREATE_TICKET_SUCCESS = 'CREATE_TICKET_SUCCESS';
export const CREATE_TICKET_FAILURE = 'CREATE_TICKET_FAILURE';

export const GET_ALL_TICKETS_INPROGRESS = 'GET_ALL_TICKETS_INPROGRESS';
export const GET_ALL_TICKETS_SUCCESS = 'GET_ALL_TICKETS_SUCCESS';
export const GET_ALL_TICKETS_FAILURE = 'GET_ALL_TICKETS_FAILURE';

export const GET_ALL_COMMENTS_INPROGRESS = 'GET_ALL_COMMENTS_INPROGRESS';
export const GET_ALL_COMMENTS_SUCCESS = 'GET_ALL_COMMENTS_SUCCESS';
export const GET_ALL_COMMENTS_FAILURE = 'GET_ALL_COMMENTS_FAILURE';

export const CREATE_COMMENT_INPROGRESS = 'CREATE_COMMENT_INPROGRESS';
export const CREATE_COMMENT_SUCCESS = 'CREATE_COMMENT_SUCCESS';
export const CREATE_COMMENT_FAILURE = 'CREATE_COMMENT_FAILURE';

export const CLEAR_ALL_TICKETS = 'CLEAR_ALL_TICKETS';
export const CLEAR_ALL_COMMENTS = 'CLEAR_ALL_COMMENTS';

// Sport metadata
export const GET_FIXTURES_INPROGRESS = 'GET_FIXTURES_INPROGRESS';
export const GET_FIXTURES_SUCCESS = 'GET_FIXTURES_SUCCESS';
export const GET_FIXTURES_FAILURE = 'GET_FIXTURES_FAILURE';

export const GET_RESULTS_INPROGRESS = 'GET_RESULTS_INPROGRESS';
export const GET_RESULTS_SUCCESS = 'GET_RESULTS_SUCCESS';
export const GET_RESULTS_FAILURE = 'GET_RESULTS_FAILURE';

export const GET_TABLES_INPROGRESS = 'GET_TABLES_INPROGRESS';
export const GET_TABLES_SUCCESS = 'GET_TABLES_SUCCESS';
export const GET_TABLES_FAILURE = 'GET_TABLES_FAILURE';

//Match Stats

export const GET_STATS_INPROGRESS = 'GET_STATS_INPROGRESS';
export const GET_STATS_SUCCESS = 'GET_STATS_SUCCESS';
export const GET_STATS_FAILURE = 'GET_STATS_FAILURE';

export const GET_COMMENTARY_INPROGRESS = 'GET_COMMENTARY_INPROGRESS';
export const GET_COMMENTARY_SUCCESS = 'GET_COMMENTARY_SUCCESS';
export const GET_COMMENTARY_FAILURE = 'GET_COMMENTARY_FAILURE';

export const GET_HIGHLIGHTS_INPROGRESS = 'GET_HIGHLIGHTS_INPROGRESS';
export const GET_HIGHLIGHTS_SUCCESS = 'GET_HIGHLIGHTS_SUCCESS';
export const GET_HIGHLIGHTS_FAILURE = 'GET_HIGHLIGHTS_FAILURE';

export const GET_LINEUP_INPROGRESS = 'GET_LINEUP_INPROGRESS';
export const GET_LINEUP_SUCCESS = 'GET_LINEUP_SUCCESS';
export const GET_LINEUP_FAILURE = 'GET_LINEUP_FAILURE';

export const GET_SQUAD_INPROGRESS = 'GET_SQUAD_INPROGRESS';
export const GET_SQUAD_SUCCESS = 'GET_SQUAD_SUCCESS';
export const GET_SQUAD_FAILURE = 'GET_SQUAD_FAILURE';

export const GET_TEAMSTATS_INPROGRESS = 'GET_TEAMSTATS_INPROGRESS';
export const GET_TEAMSTATS_SUCCESS = 'GET_TEAMSTATS_SUCCESS';
export const GET_TEAMSTATS_FAILURE = 'GET_TEAMSTATS_FAILURE';

export const GET_TEAMSUMMARY_INPROGRESS = 'GET_TEAMSUMMARY_INPROGRESS';
export const GET_TEAMSUMMARY_SUCCESS = 'GET_TEAMSUMMARY_SUCCESS';
export const GET_TEAMSUMMARY_FAILURE = 'GET_TEAMSUMMARY_FAILURE';
export const GET_TEAMS_INPROGRESS = 'GET_TEAMS_INPROGRESS';
export const GET_TEAMS_SUCCESS = 'GET_TEAMS_SUCCESS';
export const GET_FILTERED_TEAMS_SUCCESS = 'GET_FILTERED_TEAMS_SUCCESS';
export const GET_TEAMS_FAILURE = 'GET_TEAMS_FAILURE';

export const GET_TEAMCOLORS_INPROGRESS = 'GET_TEAMCOLORS_INPROGRESS';
export const GET_TEAMCOLORS_SUCCESS = 'GET_TEAMCOLORS_SUCCESS';
export const GET_TEAMCOLORS_FAILURE = 'GET_TEAMCOLORS_FAILURE';

export const GET_ALLTEAMS_INPROGRESS = 'GET_ALLTEAMS_INPROGRESS';
export const GET_ALLTEAMS_SUCCESS = 'GET_ALLTEAMS_SUCCESS';
export const GET_ALLTEAMS_FAILURE = 'GET_ALLTEAMS_FAILURE';

export const GET_MATCHHIGHLIGHTS_INPROGRESS = 'GET_MATCHHIGHLIGHTS_INPROGRESS';
export const GET_MATCHHIGHLIGHTS_SUCCESS = 'GET_MATCHHIGHLIGHTS_SUCCESS';
export const GET_MATCHHIGHLIGHTS_FAILURE = 'GET_MATCHHIGHLIGHTS_FAILURE';

export const GET_COMPARISON_INPROGRESS = 'GET_COMPARISON_INPROGRESS';
export const GET_COMPARISON_SUCCESS = 'GET_COMPARISON_SUCCESS';
export const GET_COMPARISON_FAILURE = 'GET_COMPARISON_FAILURE';

export const GET_TEAMPREVIEW_INPROGRESS = 'GET_TEAMPREVIEW_INPROGRESS';
export const GET_TEAMPREVIEW_SUCCESS = 'GET_TEAMPREVIEW_SUCCESS';
export const GET_TEAMPREVIEW_FAILURE = 'GET_TEAMPREVIEW_FAILURE';

export const GET_ALLRESULTS_INPROGRESS = 'GET_ALLRESULTS_INPROGRESS';
export const GET_ALLRESULTS_SUCCESS = 'GET_ALLRESULTS_SUCCESS';
export const GET_ALLRESULTS_FAILURE = 'GET_ALLRESULTS_FAILURE';

export const GET_ALLFIXTURES_INPROGRESS = 'GET_ALLFIXTURES_INPROGRESS';
export const GET_ALLFIXTURES_SUCCESS = 'GET_ALLFIXTURES_SUCCESS';
export const GET_ALLFIXTURES_FAILURE = 'GET_ALLFIXTURES_FAILURE';

export const GET_LIVESCORE_INPROGRESS = 'GET_LIVESCORE_INPROGRESS';
export const GET_LIVESCORE_SUCCESS = 'GET_LIVESCORE_SUCCESS';
export const GET_LIVESCORE_FAILURE = 'GET_LIVESCORE_FAILURE';

export const GET_LIVEMATCHES_INPROGRESS = 'GET_LIVEMATCHES_INPROGRESS';
export const GET_LIVEMATCHES_SUCCESS = 'GET_LIVEMATCHES_SUCCESS';
export const GET_LIVEMATCHES_FAILURE = 'GET_LIVEMATCHES_FAILURE';

export const GET_SAAGDATA_INPROGRESS = 'GET_SAAGDATA_INPROGRESS';
export const GET_SAAGDATA_SUCCESS = 'GET_SAAGDATA_SUCCESS';
export const GET_SAAGDATA_FAILURE = 'GET_SAAGDATA_FAILURE';

export const SET_SAAG_STATUS_INPROGRESS = 'SET_SAAG_STATUS_INPROGRESS';
export const SET_SAAG_STATUS_SUCCESS = 'SET_SAAG_STATUS_SUCCESS';
export const SET_SAAG_STATUS_FAILURE = 'SET_SAAG_STATUS_FAILURE';

export const GET_ALL_CUSTOMER_SUBSCRIPTIONS_INPROGRESS = `GET_ALL_CUSTOMER_SUBSCRIPTIONS_INPROGRESS`;
export const GET_ALL_CUSTOMER_SUBSCRIPTIONS_SUCCESS = `GET_ALL_CUSTOMER_SUBSCRIPTIONS_SUCCESS`;
export const GET_ALL_CUSTOMER_SUBSCRIPTIONS_FAILURE = `GET_ALL_CUSTOMER_SUBSCRIPTIONS_FAILURE`;

export const GET_DOCUMENT_TITLE = 'GET_DOCUMENT_TITLE';
export const SET_DOCUMENT_TITLE = 'SET_DOCUMENT_TITLE';

export const SET_META_DESCRIPTION = 'SET_META_DESCRIPTION';

// Theme
export const TOGGLE_THE_THEME = 'TOGGLE_THE_THEME';
export const SET_THEME_ALERT = 'SET_THEME_ALERT';

export const SET_CONTENT_SAAG_VISIBILITY = 'SET_CONTENT_SAAG_VISIBILITY';

// Content Cards
export const GET_CONTENT_CARDS = 'GET_CONTENT_CARDS';
export const GET_CONTENT_CARDS_SUCCESS = 'GET_CONTENT_CARDS_SUCCESS';
export const GET_CONTENT_CARDS_FAILURE = 'GET_CONTENT_CARDS_FAILURE';
export const ADD_CONTENT_CARDS = 'ADD_CONTENT_CARDS';

// Content Cards Unfiltered to be matched by braze content card
export const GET_CONTENT_CARDS_UNFILTERED = 'GET_CONTENT_CARDS_UNFILTERED';
export const GET_CONTENT_CARDS_UNFILTERED_SUCCESS =
  'GET_CONTENT_CARDS_UNFILTERED_SUCCESS';
export const GET_CONTENT_CARDS_UNFILTERED_FAILURE =
  'GET_CONTENT_CARDS_UNFILTERED_FAILURE';
export const MATCH_CONTENT_CARDS_UNFILTERED_BRAZE =
  'MATCH_CONTENT_CARDS_UNFILTERED_BRAZE';

// Dynamic Content
export const SET_DYNAMIC_CONTENT_CARDS = 'SET_DYNAMIC_CONTENT_CARDS';
