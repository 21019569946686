import styled, { css } from 'styled-components';

const Container = styled.div`
  position: relative;
  width: 100%;
  box-sizing: border-box;
`;

const Header = styled.div`
  width: 100%;
`;

// Refactor
const Title = styled.span`
  ${props => {
    const {
      theme: { palette, font }
    } = props;
    return css`
      color: ${palette.primary};
      font-family: ${font['primary']};
    `;
  }}
`;

const Content = styled.div`
  ${props => {
    const { maxHeight, isActive } = props;
    return css`
      transition: max-height 0.3s ease-in-out;
      overflow: hidden;
      max-height: ${isActive ? maxHeight : '0'}px;
      height: ${isActive ? '100%' : '0'}px;
    `;
  }}
`;

export { Container, Title, Content, Header };
