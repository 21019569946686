export default [
    {
        "TeamID":"3014",
        "TeamFullName":"AEK Larnaka",
        "TeamShortName":"Larnaka",
        "TeamImageUrl":"/TeamIcons/3014.png",
        "Stadium":"AEK Arena",
        "Country":"Cyprus",
        "ManagerName":"Andoni Iraola Sagarna",
        "SYMID":"AEK",
        "Competition":[
            {
                "CompetitionID":"6",
                "CompetitionName":"UEFA Europa Cup",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UEL.png"
            }
        ]
    },
    {
        "TeamID":"6796",
        "TeamFullName":"Akhisarspor",
        "TeamShortName":"Akhisarspor",
        "TeamImageUrl":"/TeamIcons/6796.png",
        "Stadium":"Spor Toto Akhisar Stadi",
        "Country":"Turkey",
        "ManagerName":"Cihat Arslan",
        "SYMID":"AKH",
        "Competition":[
            {
                "CompetitionID":"6",
                "CompetitionName":"UEFA Europa Cup",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UEL.png"
            }
        ]
    },
    {
        "TeamID":"1003",
        "TeamFullName":"Apollon Limassol",
        "TeamShortName":"Apollon",
        "TeamImageUrl":"/TeamIcons/1003.png",
        "Stadium":"Tsirion",
        "Country":"Cyprus",
        "ManagerName":"Sofronis Avgousti",
        "SYMID":"APL",
        "Competition":[
            {
                "CompetitionID":"6",
                "CompetitionName":"UEFA Europa Cup",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UEL.png"
            }
        ]
    },
    {
        "TeamID":"3",
        "TeamFullName":"Arsenal",
        "TeamShortName":"Arsenal",
        "TeamImageUrl":"/TeamIcons/3.png",
        "Stadium":"Emirates Stadium",
        "Country":"England",
        "ManagerName":"Unai Emery",
        "SYMID":"ARS",
        "Featured": "1",
        "Competition":[
            {
                "CompetitionID":"6",
                "CompetitionName":"UEFA Europa Cup",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UEL.png"
            },
            {
                "CompetitionID":"8",
                "CompetitionName":"English Barclays Premier League",
                "CompetitionImageUrl":"httpss://infinit.blob.core.windows.net/cms/AIS/Competition/competition8.jpg"
            }
        ]
    },
    {
        "TeamID":"2155",
        "TeamFullName":"BATE Borisov",
        "TeamShortName":"BATE",
        "TeamImageUrl":"/TeamIcons/2155.png",
        "Stadium":"Borisov Arena",
        "Country":"Belarus",
        "ManagerName":"Aliaksei Baga",
        "SYMID":"BBV",
        "Competition":[
            {
                "CompetitionID":"6",
                "CompetitionName":"UEFA Europa Cup",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UEL.png"
            }
        ]
    },
    {
        "TeamID":"164",
        "TeamFullName":"Bayer 04 Leverkusen",
        "TeamShortName":"Bayer 04",
        "TeamImageUrl":"/TeamIcons/164.png",
        "Stadium":"BayArena",
        "Country":"Germany",
        "ManagerName":"Heiko Herrlich",
        "SYMID":"B04",
        "Competition":[
            {
                "CompetitionID":"6",
                "CompetitionName":"UEFA Europa Cup",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UEL.png"
            }
        ]
    },
    {
        "TeamID":"378",
        "TeamFullName":"Besiktas",
        "TeamShortName":"Besiktas",
        "TeamImageUrl":"/TeamIcons/378.png",
        "Stadium":"Vodafone Park",
        "Country":"Turkey",
        "ManagerName":"Senol Gunes",
        "SYMID":"BJK",
        "Competition":[
            {
                "CompetitionID":"6",
                "CompetitionName":"UEFA Europa Cup",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UEL.png"
            }
        ]
    },
    {
        "TeamID":"140",
        "TeamFullName":"Bordeaux",
        "TeamShortName":"Bordeaux",
        "TeamImageUrl":"/TeamIcons/140.png",
        "Stadium":"Matmut Atlantique",
        "Country":"France",
        "ManagerName":"Gustavo Poyet",
        "SYMID":"BOR",
        "Competition":[
            {
                "CompetitionID":"6",
                "CompetitionName":"UEFA Europa Cup",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UEL.png"
            }
        ]
    },
    {
        "TeamID":"61",
        "TeamFullName":"Celtic",
        "TeamShortName":"Celtic",
        "TeamImageUrl":"/TeamIcons/61.png",
        "Stadium":"Celtic Park",
        "Country":"Scotland",
        "ManagerName":"Brendan Rodgers",
        "SYMID":"CEL",
        "Competition":[
            {
                "CompetitionID":"6",
                "CompetitionName":"UEFA Europa Cup",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UEL.png"
            }
        ]
    },
    {
        "TeamID":"8",
        "TeamFullName":"Chelsea",
        "TeamShortName":"Chelsea",
        "TeamImageUrl":"/TeamIcons/8.png",
        "Stadium":"Stamford Bridge",
        "Country":"England",
        "ManagerName":"Maurizio Sarri",
        "SYMID":"CHE",
        "Featured": "1",
        "Competition":[
            {
                "CompetitionID":"6",
                "CompetitionName":"UEFA Europa Cup",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UEL.png"
            },
            {
                "CompetitionID":"8",
                "CompetitionName":"English Barclays Premier League",
                "CompetitionImageUrl":"httpss://infinit.blob.core.windows.net/cms/AIS/Competition/competition8.jpg"
            }
        ]
    },
    {
        "TeamID":"394",
        "TeamFullName":"Dinamo Zagreb",
        "TeamShortName":"D. Zagreb",
        "TeamImageUrl":"/TeamIcons/394.png",
        "Stadium":"Maksimir",
        "Country":"Croatia",
        "ManagerName":"Nenad Bjelica",
        "SYMID":"DZG",
        "Competition":[
            {
                "CompetitionID":"6",
                "CompetitionName":"UEFA Europa Cup",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UEL.png"
            }
        ]
    },
    {
        "TeamID":"194",
        "TeamFullName":"Dynamo Kyiv",
        "TeamShortName":"Dynamo Kyiv",
        "TeamImageUrl":"/TeamIcons/194.png",
        "Stadium":"Kiev Olympic Stadium",
        "Country":"Ukraine",
        "ManagerName":"Aleksandr Khatskevich",
        "SYMID":"DYK",
        "Competition":[
            {
                "CompetitionID":"6",
                "CompetitionName":"UEFA Europa Cup",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UEL.png"
            }
        ]
    },
    {
        "TeamID":"159",
        "TeamFullName":"Eintracht Frankfurt",
        "TeamShortName":"Eintracht Frankfurt",
        "TeamImageUrl":"/TeamIcons/159.png",
        "Stadium":"Commerzbank-Arena",
        "Country":"Germany",
        "ManagerName":"Adolf H\u00fctter",
        "SYMID":"SGE",
        "Competition":[
            {
                "CompetitionID":"6",
                "CompetitionName":"UEFA Europa Cup",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UEL.png"
            }
        ]
    },
    {
        "TeamID":"2161",
        "TeamFullName":"F91 Dudelange",
        "TeamShortName":"Dudelange",
        "TeamImageUrl":"/TeamIcons/2161.png",
        "Stadium":"Stade Jos Nosbaum",
        "Country":"Luxembourg",
        "ManagerName":"Dino Toppm\u00f6ller",
        "SYMID":"F91",
        "Competition":[
            {
                "CompetitionID":"6",
                "CompetitionName":"UEFA Europa Cup",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UEL.png"
            }
        ]
    },
    {
        "TeamID":"3751",
        "TeamFullName":"FC Astana",
        "TeamShortName":"Astana",
        "TeamImageUrl":"/TeamIcons/3751.png",
        "Stadium":"Astana Arena",
        "Country":"Kazakhstan",
        "ManagerName":"Roman Grygorchuk",
        "SYMID":"AST",
        "Competition":[
            {
                "CompetitionID":"6",
                "CompetitionName":"UEFA Europa Cup",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UEL.png"
            }
        ]
    },
    {
        "TeamID":"569",
        "TeamFullName":"FC K\u00f8benhavn",
        "TeamShortName":"K\u00f8benhavn",
        "TeamImageUrl":"/TeamIcons/569.png",
        "Stadium":"Telia Parken",
        "Country":"Denmark",
        "ManagerName":"Stale Solbakken",
        "SYMID":"CPH",
        "Competition":[
            {
                "CompetitionID":"6",
                "CompetitionName":"UEFA Europa Cup",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UEL.png"
            }
        ]
    },
    {
        "TeamID":"857",
        "TeamFullName":"FC Red Bull Salzburg",
        "TeamShortName":"RB Salzburg",
        "TeamImageUrl":"/TeamIcons/857.png",
        "Stadium":"Red Bull Arena Salzburg",
        "Country":"Austria",
        "ManagerName":"Marco Rose",
        "SYMID":"RBS",
        "Competition":[
            {
                "CompetitionID":"6",
                "CompetitionName":"UEFA Europa Cup",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UEL.png"
            }
        ]
    },
    {
        "TeamID":"253",
        "TeamFullName":"Fenerbah\u00e7e",
        "TeamShortName":"Fenerbah\u00e7e",
        "TeamImageUrl":"/TeamIcons/253.png",
        "Stadium":"Ulker Stadyumu",
        "Country":"Turkey",
        "ManagerName":"Erwin Koeman",
        "SYMID":"FEN",
        "Competition":[
            {
                "CompetitionID":"6",
                "CompetitionName":"UEFA Europa Cup",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UEL.png"
            }
        ]
    },
    {
        "TeamID":"884",
        "TeamFullName":"FK Jablonec",
        "TeamShortName":"Jablonec",
        "TeamImageUrl":"/TeamIcons/884.png",
        "Stadium":"Stadion Strelnice",
        "Country":"Czech Republic",
        "ManagerName":"Petr Rada",
        "SYMID":"JAB",
        "Competition":[
            {
                "CompetitionID":"6",
                "CompetitionName":"UEFA Europa Cup",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UEL.png"
            }
        ]
    },
    {
        "TeamID":"4169",
        "TeamFullName":"FK Krasnodar",
        "TeamShortName":"Krasnodar",
        "TeamImageUrl":"/TeamIcons/4169.png",
        "Stadium":"Krasnodar Stadium",
        "Country":"Russia",
        "ManagerName":"Oleg Fomenko",
        "SYMID":"KRA",
        "Competition":[
            {
                "CompetitionID":"6",
                "CompetitionName":"UEFA Europa Cup",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UEL.png"
            }
        ]
    },
    {
        "TeamID":"3107",
        "TeamFullName":"FK Qarabag",
        "TeamShortName":"Qarabag",
        "TeamImageUrl":"/TeamIcons/3107.png",
        "Stadium":"Tofik Bakhramov Stadium",
        "Country":"Azerbaijan",
        "ManagerName":"Gurban Gurbanov",
        "SYMID":"FKQ",
        "Competition":[
            {
                "CompetitionID":"6",
                "CompetitionName":"UEFA Europa Cup",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UEL.png"
            }
        ]
    },
    {
        "TeamID":"414",
        "TeamFullName":"KRC Genk",
        "TeamShortName":"Genk",
        "TeamImageUrl":"/TeamIcons/414.png",
        "Stadium":"Luminus Arena",
        "Country":"Belgium",
        "ManagerName":"Philippe Clement",
        "SYMID":"GNK",
        "Competition":[
            {
                "CompetitionID":"6",
                "CompetitionName":"UEFA Europa Cup",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UEL.png"
            }
        ]
    },
    {
        "TeamID":"129",
        "TeamFullName":"Lazio",
        "TeamShortName":"SS Lazio",
        "TeamImageUrl":"/TeamIcons/129.png",
        "Stadium":"Olimpico",
        "Country":"Italy",
        "ManagerName":"Simone Inzaghi",
        "SYMID":"LAZ",
        "Competition":[
            {
                "CompetitionID":"6",
                "CompetitionName":"UEFA Europa Cup",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UEL.png"
            }
        ]
    },
    {
        "TeamID":"6128",
        "TeamFullName":"Ludogorets Razgrad",
        "TeamShortName":"Ludogorets",
        "TeamImageUrl":"/TeamIcons/6128.png",
        "Stadium":"Ludogorets Arena",
        "Country":"Bulgaria",
        "ManagerName":"Paulo Autuori de Mello",
        "SYMID":"LUD",
        "Competition":[
            {
                "CompetitionID":"6",
                "CompetitionName":"UEFA Europa Cup",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UEL.png"
            }
        ]
    },
    {
        "TeamID":"993",
        "TeamFullName":"Malm\u00f6 FF",
        "TeamShortName":"Malm\u00f6",
        "TeamImageUrl":"/TeamIcons/993.png",
        "Stadium":"Swedbank Stadion",
        "Country":"Sweden",
        "ManagerName":"Uwe R\u00f6sler",
        "SYMID":"MFF",
        "Competition":[
            {
                "CompetitionID":"6",
                "CompetitionName":"UEFA Europa Cup",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UEL.png"
            }
        ]
    },
    {
        "TeamID":"144",
        "TeamFullName":"Marseille",
        "TeamShortName":"Marseille",
        "TeamImageUrl":"/TeamIcons/144.png",
        "Stadium":"Stade V\u00e9lodrome",
        "Country":"France",
        "ManagerName":"Rudi Garcia",
        "SYMID":"MAR",
        "Competition":[
            {
                "CompetitionID":"6",
                "CompetitionName":"UEFA Europa Cup",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UEL.png"
            }
        ]
    },
    {
        "TeamID":"120",
        "TeamFullName":"Milan",
        "TeamShortName":"Milan",
        "TeamImageUrl":"/TeamIcons/120.png",
        "Stadium":"Giuseppe Meazza",
        "Country":"Italy",
        "ManagerName":"Gennaro Gattuso",
        "SYMID":"ACM",
        "Competition":[
            {
                "CompetitionID":"6",
                "CompetitionName":"UEFA Europa Cup",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UEL.png"
            }
        ]
    },
    {
        "TeamID":"2714",
        "TeamFullName":"MOL Vidi",
        "TeamShortName":"MOL Vidi",
        "TeamImageUrl":"/TeamIcons/2714.png",
        "Stadium":"Stadion S\u00f3st\u00f3i",
        "Country":"Hungary",
        "ManagerName":"Marko Nikolic",
        "SYMID":"VID",
        "Competition":[
            {
                "CompetitionID":"6",
                "CompetitionName":"UEFA Europa Cup",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UEL.png"
            }
        ]
    },
    {
        "TeamID":"202",
        "TeamFullName":"Olympiakos",
        "TeamShortName":"Olympiakos",
        "TeamImageUrl":"/TeamIcons/202.png",
        "Stadium":"Georgios Karaiskakis Stadium",
        "Country":"Greece",
        "ManagerName":"Pedro Martins",
        "SYMID":"OLY",
        "Competition":[
            {
                "CompetitionID":"6",
                "CompetitionName":"UEFA Europa Cup",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UEL.png"
            }
        ]
    },
    {
        "TeamID":"237",
        "TeamFullName":"PAOK Salonika",
        "TeamShortName":"PAOK",
        "TeamImageUrl":"/TeamIcons/237.png",
        "Stadium":"Toumba Stadium",
        "Country":"Greece",
        "ManagerName":"Razvan Lucescu",
        "SYMID":"PAO",
        "Competition":[
            {
                "CompetitionID":"6",
                "CompetitionName":"UEFA Europa Cup",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UEL.png"
            }
        ]
    },
    {
        "TeamID":"67",
        "TeamFullName":"Rangers",
        "TeamShortName":"Rangers",
        "TeamImageUrl":"/TeamIcons/67.png",
        "Stadium":"Ibrox Stadium",
        "Country":"Scotland",
        "ManagerName":"Steven Gerrard",
        "SYMID":"RAN",
        "Competition":[
            {
                "CompetitionID":"6",
                "CompetitionName":"UEFA Europa Cup",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UEL.png"
            }
        ]
    },
    {
        "TeamID":"6339",
        "TeamFullName":"RB Leipzig",
        "TeamShortName":"RB Leipzig",
        "TeamImageUrl":"/TeamIcons/6339.png",
        "Stadium":"Red Bull Arena",
        "Country":"Germany",
        "ManagerName":"Ralf Rangnick",
        "SYMID":"RBL",
        "Competition":[
            {
                "CompetitionID":"6",
                "CompetitionName":"UEFA Europa Cup",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UEL.png"
            }
        ]
    },
    {
        "TeamID":"185",
        "TeamFullName":"Real Betis",
        "TeamShortName":"Betis",
        "TeamImageUrl":"/TeamIcons/185.png",
        "Stadium":"Benito Villamar\u00edn",
        "Country":"Spain",
        "ManagerName":"Quique Seti\u00e9n",
        "SYMID":"BET",
        "Competition":[
            {
                "CompetitionID":"6",
                "CompetitionName":"UEFA Europa Cup",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UEL.png"
            }
        ]
    },
    {
        "TeamID":"150",
        "TeamFullName":"Rennes",
        "TeamShortName":"Rennes",
        "TeamImageUrl":"/TeamIcons/150.png",
        "Stadium":"Roazhon Park",
        "Country":"France",
        "ManagerName":"Sabri Lamouchi",
        "SYMID":"REN",
        "Competition":[
            {
                "CompetitionID":"6",
                "CompetitionName":"UEFA Europa Cup",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UEL.png"
            }
        ]
    },
    {
        "TeamID":"197",
        "TeamFullName":"Rosenborg",
        "TeamShortName":"Rosenborg",
        "TeamImageUrl":"/TeamIcons/197.png",
        "Stadium":"Lerkendal Stadium",
        "Country":"Norway",
        "ManagerName":"Rini Coolen",
        "SYMID":"RSB",
        "Competition":[
            {
                "CompetitionID":"6",
                "CompetitionName":"UEFA Europa Cup",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UEL.png"
            }
        ]
    },
    {
        "TeamID":"241",
        "TeamFullName":"RSC Anderlecht",
        "TeamShortName":"Anderlecht",
        "TeamImageUrl":"/TeamIcons/241.png",
        "Stadium":"Constant Vanden Stock stadion",
        "Country":"Belgium",
        "ManagerName":"Hein Vanhaezebrouck",
        "SYMID":"AND",
        "Competition":[
            {
                "CompetitionID":"6",
                "CompetitionName":"UEFA Europa Cup",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UEL.png"
            }
        ]
    },
    {
        "TeamID":"2997",
        "TeamFullName":"Sarpsborg 08",
        "TeamShortName":"Sarpsborg",
        "TeamImageUrl":"/TeamIcons/2997.png",
        "Stadium":"Sarpsborg Stadion",
        "Country":"Norway",
        "ManagerName":"Geir Bakke",
        "SYMID":"SRP",
        "Competition":[
            {
                "CompetitionID":"6",
                "CompetitionName":"UEFA Europa Cup",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UEL.png"
            }
        ]
    },
    {
        "TeamID":"179",
        "TeamFullName":"Sevilla",
        "TeamShortName":"Sevilla FC",
        "TeamImageUrl":"/TeamIcons/179.png",
        "Stadium":"Ram\u00f3n S\u00e1nchez-Pizju\u00e1n",
        "Country":"Spain",
        "ManagerName":"Pablo Mach\u00edn",
        "SYMID":"SEV",
        "Competition":[
            {
                "CompetitionID":"6",
                "CompetitionName":"UEFA Europa Cup",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UEL.png"
            }
        ]
    },
    {
        "TeamID":"212",
        "TeamFullName":"SK Rapid Wien",
        "TeamShortName":"SK Rapid",
        "TeamImageUrl":"/TeamIcons/212.png",
        "Stadium":"Allianz Stadion",
        "Country":"Austria",
        "ManagerName":"Goran Djuricin",
        "SYMID":"SCR",
        "Competition":[
            {
                "CompetitionID":"6",
                "CompetitionName":"UEFA Europa Cup",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UEL.png"
            }
        ]
    },
    {
        "TeamID":"273",
        "TeamFullName":"Slavia Prague",
        "TeamShortName":"Slavia",
        "TeamImageUrl":"/TeamIcons/273.png",
        "Stadium":"Eden Arena",
        "Country":"Czech Republic",
        "ManagerName":"Jindrich Trpisovsky",
        "SYMID":"SLP",
        "Competition":[
            {
                "CompetitionID":"6",
                "CompetitionName":"UEFA Europa Cup",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UEL.png"
            }
        ]
    },
    {
        "TeamID":"205",
        "TeamFullName":"Spartak Moscow",
        "TeamShortName":"Spartak Moscow",
        "TeamImageUrl":"/TeamIcons/205.png",
        "Stadium":"Otkrytie Arena",
        "Country":"Russia",
        "ManagerName":"Oleg Kononov",
        "SYMID":"SPM",
        "Competition":[
            {
                "CompetitionID":"6",
                "CompetitionName":"UEFA Europa Cup",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UEL.png"
            }
        ]
    },
    {
        "TeamID":"260",
        "TeamFullName":"Spartak Trnava",
        "TeamShortName":"Spartak Trnava",
        "TeamImageUrl":"/TeamIcons/260.png",
        "Stadium":"Stadi\u00f3n Antona Malatinsk\u00e9ho",
        "Country":"Slovakia",
        "ManagerName":"Radoslav Latal",
        "SYMID":"TRN",
        "Competition":[
            {
                "CompetitionID":"6",
                "CompetitionName":"UEFA Europa Cup",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UEL.png"
            }
        ]
    },
    {
        "TeamID":"255",
        "TeamFullName":"Sporting CP",
        "TeamShortName":"Sporting",
        "TeamImageUrl":"/TeamIcons/255.png",
        "Stadium":"Est\u00e1dio Jos\u00e9 Alvalade",
        "Country":"Portugal",
        "ManagerName":"Marcel Keizer",
        "SYMID":"SCL",
        "Competition":[
            {
                "CompetitionID":"6",
                "CompetitionName":"UEFA Europa Cup",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UEL.png"
            }
        ]
    },
    {
        "TeamID":"373",
        "TeamFullName":"Standard Li\u00e8ge",
        "TeamShortName":"Standard",
        "TeamImageUrl":"/TeamIcons/373.png",
        "Stadium":"Stade de Sclessin",
        "Country":"Belgium",
        "ManagerName":"Michel Preud'homme",
        "SYMID":"STA",
        "Competition":[
            {
                "CompetitionID":"6",
                "CompetitionName":"UEFA Europa Cup",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UEL.png"
            }
        ]
    },
    {
        "TeamID":"449",
        "TeamFullName":"Villarreal",
        "TeamShortName":"Villarreal",
        "TeamImageUrl":"/TeamIcons/449.png",
        "Stadium":"Estadio de la Cer\u00e1mica",
        "Country":"Spain",
        "ManagerName":"Javier Calleja",
        "SYMID":"VIL",
        "Competition":[
            {
                "CompetitionID":"6",
                "CompetitionName":"UEFA Europa Cup",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UEL.png"
            }
        ]
    },
    {
        "TeamID":"2759",
        "TeamFullName":"Vorskla Poltava",
        "TeamShortName":"V. Poltava",
        "TeamImageUrl":"/TeamIcons/2759.png",
        "Stadium":"Butovsky Vorskla Stadium",
        "Country":"Ukraine",
        "ManagerName":"Vasyl Sachko",
        "SYMID":"VOL",
        "Competition":[
            {
                "CompetitionID":"6",
                "CompetitionName":"UEFA Europa Cup",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UEL.png"
            }
        ]
    },
    {
        "TeamID":"1341",
        "TeamFullName":"Zenit St Petersburg",
        "TeamShortName":"Zenit",
        "TeamImageUrl":"/TeamIcons/1341.png",
        "Stadium":"Saint Petersburg Stadium",
        "Country":"Russia",
        "ManagerName":"Sergei Semak",
        "SYMID":"ZNT",
        "Competition":[
            {
                "CompetitionID":"6",
                "CompetitionName":"UEFA Europa Cup",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UEL.png"
            }
        ]
    },
    {
        "TeamID":"595",
        "TeamFullName":"Z\u00fcrich",
        "TeamShortName":"Z\u00fcrich",
        "TeamImageUrl":"/TeamIcons/595.png",
        "Stadium":"Stadion Letzigrund",
        "Country":"Switzerland",
        "ManagerName":"Ludovic Magnin",
        "SYMID":"FCZ",
        "Competition":[
            {
                "CompetitionID":"6",
                "CompetitionName":"UEFA Europa Cup",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UEL.png"
            }
        ]
    },
        {  
        "TeamID":"3",
        "TeamFullName":"Arsenal",
        "TeamShortName":"Arsenal",
        "TeamImageUrl":"/TeamIcons/3.png",
        "Stadium":"Emirates Stadium",
        "Country":"England",
        "ManagerName":"Unai Emery",
        "SYMID":"ARS",
        "Competition":[  
            {  
                "CompetitionID":"6",
                "CompetitionName":"UEFA Europa Cup",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UEL.png"
            },
            {  
                "CompetitionID":"8",
                "CompetitionName":"English Barclays Premier League",
                "CompetitionImageUrl":"httpss://infinit.blob.core.windows.net/cms/AIS/Competition/competition8.jpg"
            }
        ]
    },
    {  
        "TeamID":"91",
        "TeamFullName":"Bournemouth",
        "TeamShortName":"Bournemouth",
        "TeamImageUrl":"/TeamIcons/91.png",
        "Stadium":"Vitality Stadium",
        "Country":"England",
        "ManagerName":"Eddie Howe",
        "SYMID":"BOU",
        "Competition":[  
            {  
                "CompetitionID":"8",
                "CompetitionName":"English Barclays Premier League",
                "CompetitionImageUrl":"httpss://infinit.blob.core.windows.net/cms/AIS/Competition/competition8.jpg"
            }
        ]
    },
    {  
        "TeamID":"36",
        "TeamFullName":"Brighton and Hove Albion",
        "TeamShortName":"Brighton",
        "TeamImageUrl":"/TeamIcons/36.png",
        "Stadium":"Amex Stadium",
        "Country":"England",
        "ManagerName":"Chris Hughton",
        "SYMID":"BHA",
        "Competition":[  
            {  
                "CompetitionID":"8",
                "CompetitionName":"English Barclays Premier League",
                "CompetitionImageUrl":"httpss://infinit.blob.core.windows.net/cms/AIS/Competition/competition8.jpg"
            }
        ]
    },
    {  
        "TeamID":"90",
        "TeamFullName":"Burnley",
        "TeamShortName":"Burnley",
        "TeamImageUrl":"/TeamIcons/90.png",
        "Stadium":"Turf Moor",
        "Country":"England",
        "ManagerName":"Sean Dyche",
        "SYMID":"BRN",
        "Competition":[  
            {  
                "CompetitionID":"8",
                "CompetitionName":"English Barclays Premier League",
                "CompetitionImageUrl":"httpss://infinit.blob.core.windows.net/cms/AIS/Competition/competition8.jpg"
            }
        ]
    },
    {  
        "TeamID":"97",
        "TeamFullName":"Cardiff City",
        "TeamShortName":"Cardiff",
        "TeamImageUrl":"/TeamIcons/97.png",
        "Stadium":"Cardiff City Stadium",
        "Country":"England",
        "ManagerName":"Neil Warnock",
        "SYMID":"CAR",
        "Competition":[  
            {  
                "CompetitionID":"8",
                "CompetitionName":"English Barclays Premier League",
                "CompetitionImageUrl":"httpss://infinit.blob.core.windows.net/cms/AIS/Competition/competition8.jpg"
            }
        ]
    },
    {  
        "TeamID":"8",
        "TeamFullName":"Chelsea",
        "TeamShortName":"Chelsea",
        "TeamImageUrl":"/TeamIcons/8.png",
        "Stadium":"Stamford Bridge",
        "Country":"England",
        "ManagerName":"Maurizio Sarri",
        "SYMID":"CHE",
        "Competition":[  
            {  
                "CompetitionID":"6",
                "CompetitionName":"UEFA Europa Cup",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UEL.png"
            },
            {  
                "CompetitionID":"8",
                "CompetitionName":"English Barclays Premier League",
                "CompetitionImageUrl":"httpss://infinit.blob.core.windows.net/cms/AIS/Competition/competition8.jpg"
            }
        ]
    },
    {  
        "TeamID":"31",
        "TeamFullName":"Crystal Palace",
        "TeamShortName":"C Palace",
        "TeamImageUrl":"/TeamIcons/31.png",
        "Stadium":"Selhurst Park",
        "Country":"England",
        "ManagerName":"Roy Hodgson",
        "SYMID":"CRY",
        "Competition":[  
            {  
                "CompetitionID":"8",
                "CompetitionName":"English Barclays Premier League",
                "CompetitionImageUrl":"httpss://infinit.blob.core.windows.net/cms/AIS/Competition/competition8.jpg"
            }
        ]
    },
    {  
        "TeamID":"11",
        "TeamFullName":"Everton",
        "TeamShortName":"Everton",
        "TeamImageUrl":"/TeamIcons/11.png",
        "Stadium":"Goodison Park",
        "Country":"England",
        "ManagerName":"Marco Silva",
        "SYMID":"EVE",
        "Competition":[  
            {  
                "CompetitionID":"8",
                "CompetitionName":"English Barclays Premier League",
                "CompetitionImageUrl":"httpss://infinit.blob.core.windows.net/cms/AIS/Competition/competition8.jpg"
            }
        ]
    },
    {  
        "TeamID":"54",
        "TeamFullName":"Fulham",
        "TeamShortName":"Fulham",
        "TeamImageUrl":"/TeamIcons/54.png",
        "Stadium":"Craven Cottage",
        "Country":"England",
        "ManagerName":"Claudio Ranieri",
        "SYMID":"FUL",
        "Competition":[  
            {  
                "CompetitionID":"8",
                "CompetitionName":"English Barclays Premier League",
                "CompetitionImageUrl":"httpss://infinit.blob.core.windows.net/cms/AIS/Competition/competition8.jpg"
            }
        ]
    },
    {  
        "TeamID":"38",
        "TeamFullName":"Huddersfield Town",
        "TeamShortName":"Huddersfield",
        "TeamImageUrl":"/TeamIcons/38.png",
        "Stadium":"John Smith's Stadium",
        "Country":"England",
        "ManagerName":"David Wagner",
        "SYMID":"HUD",
        "Competition":[  
            {  
                "CompetitionID":"8",
                "CompetitionName":"English Barclays Premier League",
                "CompetitionImageUrl":"httpss://infinit.blob.core.windows.net/cms/AIS/Competition/competition8.jpg"
            }
        ]
    },
    {  
        "TeamID":"13",
        "TeamFullName":"Leicester City",
        "TeamShortName":"Leicester",
        "TeamImageUrl":"/TeamIcons/13.png",
        "Stadium":"King Power Stadium",
        "Country":"England",
        "ManagerName":"Claude Puel",
        "SYMID":"LEI",
        "Competition":[  
            {  
                "CompetitionID":"8",
                "CompetitionName":"English Barclays Premier League",
                "CompetitionImageUrl":"httpss://infinit.blob.core.windows.net/cms/AIS/Competition/competition8.jpg"
            }
        ]
    },
    {  
        "TeamID":"14",
        "TeamFullName":"Liverpool",
        "TeamShortName":"Liverpool",
        "TeamImageUrl":"/TeamIcons/14.png",
        "Stadium":"Anfield",
        "Country":"England",
        "ManagerName":"J\u00fcrgen Klopp",
        "SYMID":"LIV",
        "Featured": "1",
        "Competition":[  
            {  
                "CompetitionID":"5",
                "CompetitionName":"UEFA Champions League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UCL.png"
            },
            {  
                "CompetitionID":"8",
                "CompetitionName":"English Barclays Premier League",
                "CompetitionImageUrl":"httpss://infinit.blob.core.windows.net/cms/AIS/Competition/competition8.jpg"
            }
        ]
    },
    {  
        "TeamID":"43",
        "TeamFullName":"Manchester City",
        "TeamShortName":"Man City",
        "TeamImageUrl":"/TeamIcons/43.png",
        "Stadium":"Etihad Stadium",
        "Country":"England",
        "ManagerName":"Josep Guardiola",
        "SYMID":"MCI",
        "Featured": "1",
        "Competition":[  
            {  
                "CompetitionID":"5",
                "CompetitionName":"UEFA Champions League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UCL.png"
            },
            {  
                "CompetitionID":"8",
                "CompetitionName":"English Barclays Premier League",
                "CompetitionImageUrl":"httpss://infinit.blob.core.windows.net/cms/AIS/Competition/competition8.jpg"
            }
        ]
    },
    {  
        "TeamID":"1",
        "TeamFullName":"Manchester United",
        "TeamShortName":"Man Utd",
        "TeamImageUrl":"/TeamIcons/1.png",
        "Stadium":"Old Trafford",
        "Country":"England",
        "ManagerName":"Jos\u00e9 Mourinho",
        "SYMID":"MUN",
        "Featured": "1",
        "Competition":[  
            {  
                "CompetitionID":"5",
                "CompetitionName":"UEFA Champions League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UCL.png"
            },
            {  
                "CompetitionID":"8",
                "CompetitionName":"English Barclays Premier League",
                "CompetitionImageUrl":"httpss://infinit.blob.core.windows.net/cms/AIS/Competition/competition8.jpg"
            }
        ]
    },
    {  
        "TeamID":"4",
        "TeamFullName":"Newcastle United",
        "TeamShortName":"Newcastle",
        "TeamImageUrl":"/TeamIcons/4.png",
        "Stadium":"St. James' Park",
        "Country":"England",
        "ManagerName":"Rafael Ben\u00edtez",
        "SYMID":"NEW",
        "Competition":[  
            {  
                "CompetitionID":"8",
                "CompetitionName":"English Barclays Premier League",
                "CompetitionImageUrl":"httpss://infinit.blob.core.windows.net/cms/AIS/Competition/competition8.jpg"
            }
        ]
    },
    {  
        "TeamID":"20",
        "TeamFullName":"Southampton",
        "TeamShortName":"Southampton",
        "TeamImageUrl":"/TeamIcons/20.png",
        "Stadium":"St. Mary's Stadium",
        "Country":"England",
        "ManagerName":"Mark Hughes",
        "SYMID":"SOU",
        "Competition":[  
            {  
                "CompetitionID":"8",
                "CompetitionName":"English Barclays Premier League",
                "CompetitionImageUrl":"httpss://infinit.blob.core.windows.net/cms/AIS/Competition/competition8.jpg"
            }
        ]
    },
    {  
        "TeamID":"6",
        "TeamFullName":"Tottenham Hotspur",
        "TeamShortName":"Spurs",
        "TeamImageUrl":"/TeamIcons/6.png",
        "Stadium":"Tottenham Hotspur Stadium",
        "Country":"England",
        "ManagerName":"Mauricio Pochettino",
        "SYMID":"TOT",
        "Competition":[  
            {  
                "CompetitionID":"5",
                "CompetitionName":"UEFA Champions League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UCL.png"
            },
            {  
                "CompetitionID":"8",
                "CompetitionName":"English Barclays Premier League",
                "CompetitionImageUrl":"httpss://infinit.blob.core.windows.net/cms/AIS/Competition/competition8.jpg"
            }
        ]
    },
    {  
        "TeamID":"57",
        "TeamFullName":"Watford",
        "TeamShortName":"Watford",
        "TeamImageUrl":"/TeamIcons/57.png",
        "Stadium":"Vicarage Road",
        "Country":"England",
        "ManagerName":"Javier Gracia",
        "SYMID":"WAT",
        "Competition":[  
            {  
                "CompetitionID":"8",
                "CompetitionName":"English Barclays Premier League",
                "CompetitionImageUrl":"httpss://infinit.blob.core.windows.net/cms/AIS/Competition/competition8.jpg"
            }
        ]
    },
    {  
        "TeamID":"21",
        "TeamFullName":"West Ham United",
        "TeamShortName":"West Ham",
        "TeamImageUrl":"/TeamIcons/21.png",
        "Stadium":"London Stadium",
        "Country":"England",
        "ManagerName":"Manuel Pellegrini",
        "SYMID":"WHU",
        "Competition":[  
            {  
                "CompetitionID":"8",
                "CompetitionName":"English Barclays Premier League",
                "CompetitionImageUrl":"httpss://infinit.blob.core.windows.net/cms/AIS/Competition/competition8.jpg"
            }
        ]
    },
    {  
        "TeamID":"39",
        "TeamFullName":"Wolverhampton Wanderers",
        "TeamShortName":"Wolves",
        "TeamImageUrl":"/TeamIcons/39.png",
        "Stadium":"Molineux Stadium",
        "Country":"England",
        "ManagerName":"Nuno Esp\u00edrito Santo",
        "SYMID":"WOL",
        "Competition":[  
            {  
                "CompetitionID":"8",
                "CompetitionName":"English Barclays Premier League",
                "CompetitionImageUrl":"httpss://infinit.blob.core.windows.net/cms/AIS/Competition/competition8.jpg"
            }
        ]
    },
        {  
        "TeamID":"234",
        "TeamFullName":"AEK Athens",
        "TeamShortName":"AEK",
        "TeamImageUrl":"/TeamIcons/234.png",
        "Stadium":"Athens Olympic Stadium",
        "Country":"Greece",
        "ManagerName":"Marinos Ouzounidis",
        "SYMID":"AEK",
        "Competition":[  
            {  
                "CompetitionID":"5",
                "CompetitionName":"UEFA Champions League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UCL.png"
            }
        ]
    },
    {  
        "TeamID":"215",
        "TeamFullName":"Ajax",
        "TeamShortName":"Ajax",
        "TeamImageUrl":"/TeamIcons/215.png",
        "Stadium":"Johan Cruijff Arena",
        "Country":"Netherlands",
        "ManagerName":"Erik ten Hag",
        "SYMID":"AJA",
        "Competition":[  
            {  
                "CompetitionID":"5",
                "CompetitionName":"UEFA Champions League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UCL.png"
            }
        ]
    },
    {  
        "TeamID":"175",
        "TeamFullName":"Atl\u00e9tico de Madrid",
        "TeamShortName":"Atl\u00e9tico",
        "TeamImageUrl":"/TeamIcons/175.png",
        "Stadium":"Wanda Metropolitano",
        "Country":"Spain",
        "ManagerName":"Diego Simeone",
        "SYMID":"ATM",
        "Competition":[  
            {  
                "CompetitionID":"5",
                "CompetitionName":"UEFA Champions League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UCL.png"
            }
        ]
    },
    {  
        "TeamID":"178",
        "TeamFullName":"Barcelona",
        "TeamShortName":"Bar\u00e7a",
        "TeamImageUrl":"/TeamIcons/178.png",
        "Stadium":"Camp Nou",
        "Country":"Spain",
        "ManagerName":"Ernesto Valverde",
        "SYMID":"BAR",
        "Featured": "1",
        "Competition":[  
            {  
                "CompetitionID":"5",
                "CompetitionName":"UEFA Champions League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UCL.png"
            }
        ]
    },
    {  
        "TeamID":"251",
        "TeamFullName":"Benfica",
        "TeamShortName":"Benfica",
        "TeamImageUrl":"/TeamIcons/251.png",
        "Stadium":"Est\u00e1dio da Luz",
        "Country":"Portugal",
        "ManagerName":"Rui Vit\u00f3ria",
        "SYMID":"BEN",
        "Competition":[  
            {  
                "CompetitionID":"5",
                "CompetitionName":"UEFA Champions League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UCL.png"
            }
        ]
    },
    {  
        "TeamID":"157",
        "TeamFullName":"Borussia Dortmund",
        "TeamShortName":"Borussia Dortmund",
        "TeamImageUrl":"/TeamIcons/157.png",
        "Stadium":"SIGNAL IDUNA PARK",
        "Country":"Germany",
        "ManagerName":"Lucien Favre",
        "SYMID":"BVB",
        "Competition":[  
            {  
                "CompetitionID":"5",
                "CompetitionName":"UEFA Champions League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UCL.png"
            }
        ]
    },
    {  
        "TeamID":"413",
        "TeamFullName":"Club Brugge",
        "TeamShortName":"Club Brugge",
        "TeamImageUrl":"/TeamIcons/413.png",
        "Stadium":"Jan Breydelstadion",
        "Country":"Belgium",
        "ManagerName":"Ivan Leko",
        "SYMID":"BRU",
        "Competition":[  
            {  
                "CompetitionID":"5",
                "CompetitionName":"UEFA Champions League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UCL.png"
            }
        ]
    },
    {  
        "TeamID":"214",
        "TeamFullName":"Crvena Zvezda",
        "TeamShortName":"Crvena Zvezda",
        "TeamImageUrl":"/TeamIcons/214.png",
        "Stadium":"Rajko Mitic Stadium",
        "Country":"Serbia",
        "ManagerName":"Vladan Milojevic",
        "SYMID":"CRV",
        "Competition":[  
            {  
                "CompetitionID":"5",
                "CompetitionName":"UEFA Champions League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UCL.png"
            }
        ]
    },
    {  
        "TeamID":"1340",
        "TeamFullName":"CSKA Moscow",
        "TeamShortName":"CSKA Moscow",
        "TeamImageUrl":"/TeamIcons/1340.png",
        "Stadium":"VEB Arena",
        "Country":"Russia",
        "ManagerName":"Viktor Goncharenko",
        "SYMID":"CSK",
        "Competition":[  
            {  
                "CompetitionID":"5",
                "CompetitionName":"UEFA Champions League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UCL.png"
            }
        ]
    },
    {  
        "TeamID":"156",
        "TeamFullName":"FC Bayern M\u00fcnchen",
        "TeamShortName":"FC Bayern",
        "TeamImageUrl":"/TeamIcons/156.png",
        "Stadium":"Allianz Arena",
        "Country":"Germany",
        "ManagerName":"Niko Kovac",
        "SYMID":"FCB",
        "Competition":[  
            {  
                "CompetitionID":"5",
                "CompetitionName":"UEFA Champions League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UCL.png"
            }
        ]
    },
    {  
        "TeamID":"201",
        "TeamFullName":"FC Porto",
        "TeamShortName":"Porto",
        "TeamImageUrl":"/TeamIcons/201.png",
        "Stadium":"Est\u00e1dio do Drag\u00e3o",
        "Country":"Portugal",
        "ManagerName":"S\u00e9rgio Concei\u00e7\u00e3o",
        "SYMID":"PTO",
        "Competition":[  
            {  
                "CompetitionID":"5",
                "CompetitionName":"UEFA Champions League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UCL.png"
            }
        ]
    },
    {  
        "TeamID":"167",
        "TeamFullName":"FC Schalke 04",
        "TeamShortName":"Schalke 04",
        "TeamImageUrl":"/TeamIcons/167.png",
        "Stadium":"VELTINS-Arena",
        "Country":"Germany",
        "ManagerName":"Domenico Tedesco",
        "SYMID":"S04",
        "Competition":[  
            {  
                "CompetitionID":"5",
                "CompetitionName":"UEFA Champions League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UCL.png"
            }
        ]
    },
    {  
        "TeamID":"208",
        "TeamFullName":"Galatasaray",
        "TeamShortName":"Galatasaray",
        "TeamImageUrl":"/TeamIcons/208.png",
        "Stadium":"T\u00fcrk Telekom Stadi",
        "Country":"Turkey",
        "ManagerName":"Fatih Terim",
        "SYMID":"GAL",
        "Competition":[  
            {  
                "CompetitionID":"5",
                "CompetitionName":"UEFA Champions League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UCL.png"
            }
        ]
    },
    {  
        "TeamID":"127",
        "TeamFullName":"Internazionale",
        "TeamShortName":"Inter",
        "TeamImageUrl":"/TeamIcons/127.png",
        "Stadium":"Giuseppe Meazza",
        "Country":"Italy",
        "ManagerName":"Luciano Spalletti",
        "SYMID":"INT",
        "Competition":[  
            {  
                "CompetitionID":"5",
                "CompetitionName":"UEFA Champions League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UCL.png"
            }
        ]
    },
    {  
        "TeamID":"128",
        "TeamFullName":"Juventus",
        "TeamShortName":"Juventus",
        "TeamImageUrl":"/TeamIcons/128.png",
        "Stadium":"Allianz Stadium",
        "Country":"Italy",
        "ManagerName":"Massimiliano Allegri",
        "SYMID":"JUV",
        "Competition":[  
            {  
                "CompetitionID":"5",
                "CompetitionName":"UEFA Champions League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UCL.png"
            }
        ]
    },
    {  
        "TeamID":"14",
        "TeamFullName":"Liverpool",
        "TeamShortName":"Liverpool",
        "TeamImageUrl":"/TeamIcons/14.png",
        "Stadium":"Anfield",
        "Country":"England",
        "ManagerName":"J\u00fcrgen Klopp",
        "SYMID":"LIV",
        "Competition":[  
            {  
                "CompetitionID":"5",
                "CompetitionName":"UEFA Champions League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UCL.png"
            },
            {  
                "CompetitionID":"8",
                "CompetitionName":"English Barclays Premier League",
                "CompetitionImageUrl":"httpss://infinit.blob.core.windows.net/cms/AIS/Competition/competition8.jpg"
            }
        ]
    },
    {  
        "TeamID":"271",
        "TeamFullName":"Lokomotiv Moscow",
        "TeamShortName":"Lokomotiv Moscow",
        "TeamImageUrl":"/TeamIcons/271.png",
        "Stadium":"RZD Arena",
        "Country":"Russia",
        "ManagerName":"Yuri Semin",
        "SYMID":"LKM",
        "Competition":[  
            {  
                "CompetitionID":"5",
                "CompetitionName":"UEFA Champions League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UCL.png"
            }
        ]
    },
    {  
        "TeamID":"143",
        "TeamFullName":"Lyon",
        "TeamShortName":"Lyon",
        "TeamImageUrl":"/TeamIcons/143.png",
        "Stadium":"Groupama Stadium",
        "Country":"France",
        "ManagerName":"Bruno G\u00e9n\u00e9sio",
        "SYMID":"LYN",
        "Competition":[  
            {  
                "CompetitionID":"5",
                "CompetitionName":"UEFA Champions League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UCL.png"
            }
        ]
    },
    {  
        "TeamID":"43",
        "TeamFullName":"Manchester City",
        "TeamShortName":"Man City",
        "TeamImageUrl":"/TeamIcons/43.png",
        "Stadium":"Etihad Stadium",
        "Country":"England",
        "ManagerName":"Josep Guardiola",
        "SYMID":"MCI",
        "Competition":[  
            {  
                "CompetitionID":"5",
                "CompetitionName":"UEFA Champions League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UCL.png"
            },
            {  
                "CompetitionID":"8",
                "CompetitionName":"English Barclays Premier League",
                "CompetitionImageUrl":"httpss://infinit.blob.core.windows.net/cms/AIS/Competition/competition8.jpg"
            }
        ]
    },
    {  
        "TeamID":"1",
        "TeamFullName":"Manchester United",
        "TeamShortName":"Man Utd",
        "TeamImageUrl":"/TeamIcons/1.png",
        "Stadium":"Old Trafford",
        "Country":"England",
        "ManagerName":"Jos\u00e9 Mourinho",
        "SYMID":"MUN",
        "Competition":[  
            {  
                "CompetitionID":"5",
                "CompetitionName":"UEFA Champions League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UCL.png"
            },
            {  
                "CompetitionID":"8",
                "CompetitionName":"English Barclays Premier League",
                "CompetitionImageUrl":"httpss://infinit.blob.core.windows.net/cms/AIS/Competition/competition8.jpg"
            }
        ]
    },
    {  
        "TeamID":"146",
        "TeamFullName":"Monaco",
        "TeamShortName":"Monaco",
        "TeamImageUrl":"/TeamIcons/146.png",
        "Stadium":"Stade Louis II",
        "Country":"France",
        "ManagerName":"Thierry Henry",
        "SYMID":"MCO",
        "Competition":[  
            {  
                "CompetitionID":"5",
                "CompetitionName":"UEFA Champions League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UCL.png"
            }
        ]
    },
    {  
        "TeamID":"459",
        "TeamFullName":"Napoli",
        "TeamShortName":"Napoli",
        "TeamImageUrl":"/TeamIcons/459.png",
        "Stadium":"San Paolo",
        "Country":"Italy",
        "ManagerName":"Carlo Ancelotti",
        "SYMID":"NAP",
        "Competition":[  
            {  
                "CompetitionID":"5",
                "CompetitionName":"UEFA Champions League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UCL.png"
            }
        ]
    },
    {  
        "TeamID":"149",
        "TeamFullName":"Paris Saint-Germain",
        "TeamShortName":"Paris SG",
        "TeamImageUrl":"/TeamIcons/149.png",
        "Stadium":"Parc des Princes",
        "Country":"France",
        "ManagerName":"Thomas Tuchel",
        "SYMID":"PSG",
        "Competition":[  
            {  
                "CompetitionID":"5",
                "CompetitionName":"UEFA Champions League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UCL.png"
            }
        ]
    },
    {  
        "TeamID":"204",
        "TeamFullName":"PSV",
        "TeamShortName":"PSV",
        "TeamImageUrl":"/TeamIcons/204.png",
        "Stadium":"Philips Stadion",
        "Country":"Netherlands",
        "ManagerName":"Mark van Bommel",
        "SYMID":"PSV",
        "Competition":[  
            {  
                "CompetitionID":"5",
                "CompetitionName":"UEFA Champions League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UCL.png"
            }
        ]
    },
    {  
        "TeamID":"186",
        "TeamFullName":"Real Madrid",
        "TeamShortName":"Real Madrid",
        "TeamImageUrl":"/TeamIcons/186.png",
        "Stadium":"Santiago Bernab\u00e9u",
        "Country":"Spain",
        "ManagerName":"Santiago Solari",
        "SYMID":"RMD",
        "Featured": "1",
        "Competition":[  
            {  
                "CompetitionID":"5",
                "CompetitionName":"UEFA Champions League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UCL.png"
            }
        ]
    },
    {  
        "TeamID":"121",
        "TeamFullName":"Roma",
        "TeamShortName":"A.S. Roma",
        "TeamImageUrl":"/TeamIcons/121.png",
        "Stadium":"Olimpico",
        "Country":"Italy",
        "ManagerName":"Eusebio Di Francesco",
        "SYMID":"RMA",
        "Featured": "1",
        "Competition":[  
            {  
                "CompetitionID":"5",
                "CompetitionName":"UEFA Champions League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UCL.png"
            }
        ]
    },
    {  
        "TeamID":"455",
        "TeamFullName":"Shakhtar Donetsk",
        "TeamShortName":"Shakhtar",
        "TeamImageUrl":"/TeamIcons/455.png",
        "Stadium":"Metalist Stadium",
        "Country":"Ukraine",
        "ManagerName":"Paulo Fonseca",
        "SYMID":"SDK",
        "Competition":[  
            {  
                "CompetitionID":"5",
                "CompetitionName":"UEFA Champions League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UCL.png"
            }
        ]
    },
    {  
        "TeamID":"6",
        "TeamFullName":"Tottenham Hotspur",
        "TeamShortName":"Spurs",
        "TeamImageUrl":"/TeamIcons/6.png",
        "Stadium":"Tottenham Hotspur Stadium",
        "Country":"England",
        "ManagerName":"Mauricio Pochettino",
        "SYMID":"TOT",
        "Competition":[  
            {  
                "CompetitionID":"5",
                "CompetitionName":"UEFA Champions League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UCL.png"
            },
            {  
                "CompetitionID":"8",
                "CompetitionName":"English Barclays Premier League",
                "CompetitionImageUrl":"httpss://infinit.blob.core.windows.net/cms/AIS/Competition/competition8.jpg"
            }
        ]
    },
    {  
        "TeamID":"1902",
        "TeamFullName":"TSG 1899 Hoffenheim",
        "TeamShortName":"TSG Hoffenheim",
        "TeamImageUrl":"/TeamIcons/1902.png",
        "Stadium":"WIRSOL Rhein-Neckar-Arena",
        "Country":"Germany",
        "ManagerName":"Julian Nagelsmann",
        "SYMID":"TSG",
        "Competition":[  
            {  
                "CompetitionID":"5",
                "CompetitionName":"UEFA Champions League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UCL.png"
            }
        ]
    },
    {  
        "TeamID":"191",
        "TeamFullName":"Valencia CF",
        "TeamShortName":"Valencia",
        "TeamImageUrl":"/TeamIcons/191.png",
        "Stadium":"Mestalla",
        "Country":"Spain",
        "ManagerName":"Marcelino Garc\u00eda Toral",
        "SYMID":"VAL",
        "Competition":[  
            {  
                "CompetitionID":"5",
                "CompetitionName":"UEFA Champions League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UCL.png"
            }
        ]
    },
    {  
        "TeamID":"2703",
        "TeamFullName":"Viktoria Plzen",
        "TeamShortName":"Plzen",
        "TeamImageUrl":"/TeamIcons/2703.png",
        "Stadium":"Doosan Arena",
        "Country":"Czech Republic",
        "ManagerName":"Pavel Vrba",
        "SYMID":"PLZ",
        "Competition":[  
            {  
                "CompetitionID":"5",
                "CompetitionName":"UEFA Champions League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UCL.png"
            }
        ]
    },
    {  
        "TeamID":"1963",
        "TeamFullName":"Young Boys",
        "TeamShortName":"Young Boys",
        "TeamImageUrl":"/TeamIcons/1963.png",
        "Stadium":"Stade de Suisse",
        "Country":"Switzerland",
        "ManagerName":"Gerardo Seoane",
        "SYMID":"YB",
        "Competition":[  
            {  
                "CompetitionID":"5",
                "CompetitionName":"UEFA Champions League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UCL.png"
            }
        ]
    },
        {  
        "TeamID":"534",
        "TeamFullName":"Albania",
        "TeamShortName":"Albania",
        "TeamImageUrl":"/TeamIcons/534.png",
        "Stadium":"",
        "Country":"Albania",
        "ManagerName":"Christian Panucci",
        "SYMID":"ALB",
        "Competition":[  
            {  
                "CompetitionID":"88",
                "CompetitionName":"Friendlies",
                "CompetitionImageUrl":null
            },
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"499",
        "TeamFullName":"Andorra",
        "TeamShortName":"Andorra",
        "TeamImageUrl":"/TeamIcons/499.png",
        "Stadium":"",
        "Country":"Andorra",
        "ManagerName":"Koldo \u00c1lvarez",
        "SYMID":"AND",
        "Competition":[  
            {  
                "CompetitionID":"88",
                "CompetitionName":"Friendlies",
                "CompetitionImageUrl":null
            },
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"509",
        "TeamFullName":"Armenia",
        "TeamShortName":"Armenia",
        "TeamImageUrl":"/TeamIcons/509.png",
        "Stadium":"Vazgen Sargsyan Republican Stadium",
        "Country":"Armenia",
        "ManagerName":"Armen Gyulbudaghyants",
        "SYMID":"ARM",
        "Competition":[  
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"515",
        "TeamFullName":"Austria",
        "TeamShortName":"Austria",
        "TeamImageUrl":"/TeamIcons/515.png",
        "Stadium":"",
        "Country":"Austria",
        "ManagerName":"Franco Foda",
        "SYMID":"AUT",
        "Competition":[  
            {  
                "CompetitionID":"88",
                "CompetitionName":"Friendlies",
                "CompetitionImageUrl":null
            },
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"505",
        "TeamFullName":"Azerbaijan",
        "TeamShortName":"Azerbaijan",
        "TeamImageUrl":"/TeamIcons/505.png",
        "Stadium":"",
        "Country":"Azerbaijan",
        "ManagerName":"Gurban Gurbanov",
        "SYMID":"AZE",
        "Competition":[  
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"512",
        "TeamFullName":"Belarus",
        "TeamShortName":"Belarus",
        "TeamImageUrl":"/TeamIcons/512.png",
        "Stadium":"Borisov Arena",
        "Country":"Belarus",
        "ManagerName":"Igor Kriushenko",
        "SYMID":"BLR",
        "Competition":[  
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"360",
        "TeamFullName":"Belgium",
        "TeamShortName":"Belgium",
        "TeamImageUrl":"/TeamIcons/360.png",
        "Stadium":"King Baudouin Stadium",
        "Country":"Belgium",
        "ManagerName":"Roberto Mart\u00ednez",
        "SYMID":"BEL",
        "Competition":[  
            {  
                "CompetitionID":"88",
                "CompetitionName":"Friendlies",
                "CompetitionImageUrl":null
            },
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"537",
        "TeamFullName":"Bosnia and Herzegovina",
        "TeamShortName":"Bosnia",
        "TeamImageUrl":"/TeamIcons/537.png",
        "Stadium":"Asim Ferhatovic Hase Stadium",
        "Country":"Bosnia and Herzegovina",
        "ManagerName":"Robert Prosinecki",
        "SYMID":"BIH",
        "Competition":[  
            {  
                "CompetitionID":"88",
                "CompetitionName":"Friendlies",
                "CompetitionImageUrl":null
            },
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"502",
        "TeamFullName":"Bulgaria",
        "TeamShortName":"Bulgaria",
        "TeamImageUrl":"/TeamIcons/502.png",
        "Stadium":"Vasil Levski National Stadium",
        "Country":"Bulgaria",
        "ManagerName":"Petar Hubchev",
        "SYMID":"BGR",
        "Competition":[  
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"535",
        "TeamFullName":"Croatia",
        "TeamShortName":"Croatia",
        "TeamImageUrl":"/TeamIcons/535.png",
        "Stadium":"Maksimir",
        "Country":"Croatia",
        "ManagerName":"Zlatko Dalic",
        "SYMID":"CRO",
        "Competition":[  
            {  
                "CompetitionID":"88",
                "CompetitionName":"Friendlies",
                "CompetitionImageUrl":null
            },
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"501",
        "TeamFullName":"Cyprus",
        "TeamShortName":"Cyprus",
        "TeamImageUrl":"/TeamIcons/501.png",
        "Stadium":"GSP Stadium",
        "Country":"Cyprus",
        "ManagerName":"Ran Ben Shimon",
        "SYMID":"CYP",
        "Competition":[  
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"367",
        "TeamFullName":"Czech Republic",
        "TeamShortName":"Czech Rep",
        "TeamImageUrl":"/TeamIcons/367.png",
        "Stadium":"",
        "Country":"Czech Republic",
        "ManagerName":"Jaroslav Silhavy",
        "SYMID":"CZE",
        "Competition":[  
            {  
                "CompetitionID":"88",
                "CompetitionName":"Friendlies",
                "CompetitionImageUrl":null
            },
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"369",
        "TeamFullName":"Denmark",
        "TeamShortName":"Denmark",
        "TeamImageUrl":"/TeamIcons/369.png",
        "Stadium":"Telia Parken",
        "Country":"Denmark",
        "ManagerName":"\u00c5ge Hareide",
        "SYMID":"DEN",
        "Competition":[  
            {  
                "CompetitionID":"88",
                "CompetitionName":"Friendlies",
                "CompetitionImageUrl":null
            },
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"114",
        "TeamFullName":"England",
        "TeamShortName":"England",
        "TeamImageUrl":"/TeamIcons/114.png",
        "Stadium":"Wembley Stadium",
        "Country":"England",
        "ManagerName":"Gareth Southgate",
        "SYMID":"ENG",
        "Featured" : "1",
        "Competition":[  
            {  
                "CompetitionID":"88",
                "CompetitionName":"Friendlies",
                "CompetitionImageUrl":null
            },
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"500",
        "TeamFullName":"Estonia",
        "TeamShortName":"Estonia",
        "TeamImageUrl":"/TeamIcons/500.png",
        "Stadium":"A. Le Coq Arena",
        "Country":"Estonia",
        "ManagerName":"Martin Reim",
        "SYMID":"EST",
        "Competition":[  
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"498",
        "TeamFullName":"Faroe Islands",
        "TeamShortName":"Faroe Islands",
        "TeamImageUrl":"/TeamIcons/498.png",
        "Stadium":"T\u00f3rsv\u00f8llur",
        "Country":"Faroe Islands",
        "ManagerName":"Lars Olsen",
        "SYMID":"FRO",
        "Competition":[  
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"518",
        "TeamFullName":"Finland",
        "TeamShortName":"Finland",
        "TeamImageUrl":"/TeamIcons/518.png",
        "Stadium":"Helsinki Olympic Stadium",
        "Country":"Finland",
        "ManagerName":"Markku Kanerva",
        "SYMID":"FIN",
        "Competition":[  
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"368",
        "TeamFullName":"France",
        "TeamShortName":"France",
        "TeamImageUrl":"/TeamIcons/368.png",
        "Stadium":"Stade de France",
        "Country":"France",
        "ManagerName":"Didier Deschamps",
        "SYMID":"FRA",
        "Featured":"1",
        "Competition":[  
            {  
                "CompetitionID":"88",
                "CompetitionName":"Friendlies",
                "CompetitionImageUrl":null
            },
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"520",
        "TeamFullName":"Georgia",
        "TeamShortName":"Georgia",
        "TeamImageUrl":"/TeamIcons/520.png",
        "Stadium":"Boris Paichadze Stadium",
        "Country":"Georgia",
        "ManagerName":"Vladim\u00edr Weiss",
        "SYMID":"GEO",
        "Competition":[  
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"357",
        "TeamFullName":"Germany",
        "TeamShortName":"Germany",
        "TeamImageUrl":"/TeamIcons/357.png",
        "Stadium":"",
        "Country":"Germany",
        "ManagerName":"Joachim L\u00f6w",
        "SYMID":"GER",
        "Featured":"1",
        "Competition":[  
            {  
                "CompetitionID":"88",
                "CompetitionName":"Friendlies",
                "CompetitionImageUrl":null
            },
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"9493",
        "TeamFullName":"Gibraltar",
        "TeamShortName":"Gibraltar",
        "TeamImageUrl":"/TeamIcons/9493.png",
        "Stadium":"Est\u00e1dio Algarve",
        "Country":"Gibraltar",
        "ManagerName":"Julio C\u00e9sar Ribas",
        "SYMID":"GIB",
        "Competition":[  
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"517",
        "TeamFullName":"Greece",
        "TeamShortName":"Greece",
        "TeamImageUrl":"/TeamIcons/517.png",
        "Stadium":"Georgios Karaiskakis Stadium",
        "Country":"Greece",
        "ManagerName":"Angelos Anastasiadis",
        "SYMID":"GRC",
        "Competition":[  
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"538",
        "TeamFullName":"Hungary",
        "TeamShortName":"Hungary",
        "TeamImageUrl":"/TeamIcons/538.png",
        "Stadium":"Ferenc Puskas Stadion",
        "Country":"Hungary",
        "ManagerName":"Marco Rossi",
        "SYMID":"HUN",
        "Competition":[  
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"503",
        "TeamFullName":"Iceland",
        "TeamShortName":"Iceland",
        "TeamImageUrl":"/TeamIcons/503.png",
        "Stadium":"Laugardalsv\u00f6llur",
        "Country":"Iceland",
        "ManagerName":"Erik Hamr\u00e9n",
        "SYMID":"ISL",
        "Competition":[  
            {  
                "CompetitionID":"88",
                "CompetitionName":"Friendlies",
                "CompetitionImageUrl":null
            },
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"516",
        "TeamFullName":"Israel",
        "TeamShortName":"Israel",
        "TeamImageUrl":"/TeamIcons/516.png",
        "Stadium":"",
        "Country":"Israel",
        "ManagerName":"Andreas Herzog",
        "SYMID":"ISR",
        "Competition":[  
            {  
                "CompetitionID":"88",
                "CompetitionName":"Friendlies",
                "CompetitionImageUrl":null
            },
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"119",
        "TeamFullName":"Italy",
        "TeamShortName":"Italy",
        "TeamImageUrl":"/TeamIcons/119.png",
        "Stadium":"",
        "Country":"Italy",
        "ManagerName":"Roberto Mancini",
        "SYMID":"ITA",
        "Competition":[  
            {  
                "CompetitionID":"88",
                "CompetitionName":"Friendlies",
                "CompetitionImageUrl":null
            },
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"1846",
        "TeamFullName":"Kazakhstan",
        "TeamShortName":"Kazakhstan",
        "TeamImageUrl":"/TeamIcons/1846.png",
        "Stadium":"",
        "Country":"Kazakhstan",
        "ManagerName":"Stanimir Stoilov",
        "SYMID":"KAZ",
        "Competition":[  
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"11658",
        "TeamFullName":"Kosovo",
        "TeamShortName":"Kosovo",
        "TeamImageUrl":"/TeamIcons/11658.png",
        "Stadium":"Loro Borici",
        "Country":"Kosovo",
        "ManagerName":"Bernard Challandes",
        "SYMID":"KVX",
        "Competition":[  
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"513",
        "TeamFullName":"Latvia",
        "TeamShortName":"Latvia",
        "TeamImageUrl":"/TeamIcons/513.png",
        "Stadium":"",
        "Country":"Latvia",
        "ManagerName":"Mixu Paatelainen",
        "SYMID":"LAT",
        "Competition":[  
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"514",
        "TeamFullName":"Liechtenstein",
        "TeamShortName":"Liechtenstein",
        "TeamImageUrl":"/TeamIcons/514.png",
        "Stadium":"Rheinpark Stadion",
        "Country":"Liechtenstein",
        "ManagerName":"Rene Pauritsch",
        "SYMID":"LIE",
        "Competition":[  
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"519",
        "TeamFullName":"Lithuania",
        "TeamShortName":"Lithuania",
        "TeamImageUrl":"/TeamIcons/519.png",
        "Stadium":"LFF Stadionas",
        "Country":"Lithuania",
        "ManagerName":"Edgaras Jankauskas",
        "SYMID":"LTU",
        "Competition":[  
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"496",
        "TeamFullName":"Luxembourg",
        "TeamShortName":"Luxembourg",
        "TeamImageUrl":"/TeamIcons/496.png",
        "Stadium":"Stade Josy Barthel",
        "Country":"Luxembourg",
        "ManagerName":"Luc Holtz",
        "SYMID":"LUX",
        "Competition":[  
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"504",
        "TeamFullName":"Macedonia",
        "TeamShortName":"Macedonia",
        "TeamImageUrl":"/TeamIcons/504.png",
        "Stadium":"Filip II Arena",
        "Country":"Macedonia",
        "ManagerName":"Igor Angelovski",
        "SYMID":"FYROM",
        "Competition":[  
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"521",
        "TeamFullName":"Malta",
        "TeamShortName":"Malta",
        "TeamImageUrl":"/TeamIcons/521.png",
        "Stadium":"Ta' Qali National Stadium",
        "Country":"Malta",
        "ManagerName":"Raymond Farrugia",
        "SYMID":"MLT",
        "Competition":[  
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"506",
        "TeamFullName":"Moldova",
        "TeamShortName":"Moldova",
        "TeamImageUrl":"/TeamIcons/506.png",
        "Stadium":"Stadionul Zimbru",
        "Country":"Moldova",
        "ManagerName":"Alexandru Spiridon",
        "SYMID":"MDA",
        "Competition":[  
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"2734",
        "TeamFullName":"Montenegro",
        "TeamShortName":"Montenegro",
        "TeamImageUrl":"/TeamIcons/2734.png",
        "Stadium":"Podgorica City Stadium",
        "Country":"Montenegro",
        "ManagerName":"Ljubisa Tumbakovic",
        "SYMID":"MNE",
        "Competition":[  
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"366",
        "TeamFullName":"Netherlands",
        "TeamShortName":"Netherlands",
        "TeamImageUrl":"/TeamIcons/366.png",
        "Stadium":"Johan Cruijff Arena",
        "Country":"Netherlands",
        "ManagerName":"Ronald Koeman",
        "SYMID":"NLD",
        "Competition":[  
            {  
                "CompetitionID":"88",
                "CompetitionName":"Friendlies",
                "CompetitionImageUrl":null
            },
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"117",
        "TeamFullName":"Northern Ireland",
        "TeamShortName":"North Ireland",
        "TeamImageUrl":"/TeamIcons/117.png",
        "Stadium":"Windsor Park",
        "Country":"Northern Ireland",
        "ManagerName":"Michael O'Neill",
        "SYMID":"NIR",
        "Competition":[  
            {  
                "CompetitionID":"88",
                "CompetitionName":"Friendlies",
                "CompetitionImageUrl":null
            },
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"363",
        "TeamFullName":"Norway",
        "TeamShortName":"Norway",
        "TeamImageUrl":"/TeamIcons/363.png",
        "Stadium":"",
        "Country":"Norway",
        "ManagerName":"Lars Lagerb\u00e4ck",
        "SYMID":"NOR",
        "Competition":[  
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"511",
        "TeamFullName":"Poland",
        "TeamShortName":"Poland",
        "TeamImageUrl":"/TeamIcons/511.png",
        "Stadium":"PGE Narodowy",
        "Country":"Poland",
        "ManagerName":"Jerzy Brzeczek",
        "SYMID":"POL",
        "Competition":[  
            {  
                "CompetitionID":"88",
                "CompetitionName":"Friendlies",
                "CompetitionImageUrl":null
            },
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"359",
        "TeamFullName":"Portugal",
        "TeamShortName":"Portugal",
        "TeamImageUrl":"/TeamIcons/359.png",
        "Stadium":"",
        "Country":"Portugal",
        "ManagerName":"Fernando Santos",
        "SYMID":"POR",
        "Competition":[  
            {  
                "CompetitionID":"88",
                "CompetitionName":"Friendlies",
                "CompetitionImageUrl":null
            },
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"116",
        "TeamFullName":"Republic of Ireland",
        "TeamShortName":"Ireland",
        "TeamImageUrl":"/TeamIcons/116.png",
        "Stadium":"Aviva Stadium",
        "Country":"Republic of Ireland",
        "ManagerName":"Martin O'Neill",
        "SYMID":"IRL",
        "Competition":[  
            {  
                "CompetitionID":"88",
                "CompetitionName":"Friendlies",
                "CompetitionImageUrl":null
            },
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"358",
        "TeamFullName":"Romania",
        "TeamShortName":"Romania",
        "TeamImageUrl":"/TeamIcons/358.png",
        "Stadium":"National Arena",
        "Country":"Romania",
        "ManagerName":"Cosmin Contra",
        "SYMID":"ROM",
        "Competition":[  
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"536",
        "TeamFullName":"Russia",
        "TeamShortName":"Russia",
        "TeamImageUrl":"/TeamIcons/536.png",
        "Stadium":"",
        "Country":"Russia",
        "ManagerName":"Stanislav Cherchesov",
        "SYMID":"RUS",
        "Competition":[  
            {  
                "CompetitionID":"88",
                "CompetitionName":"Friendlies",
                "CompetitionImageUrl":null
            },
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"495",
        "TeamFullName":"San Marino",
        "TeamShortName":"",
        "TeamImageUrl":"/TeamIcons/495.png",
        "Stadium":"San Marino Stadium",
        "Country":"San Marino",
        "ManagerName":"Franco Varrella",
        "SYMID":"SMR",
        "Competition":[  
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"115",
        "TeamFullName":"Scotland",
        "TeamShortName":"Scotland",
        "TeamImageUrl":"/TeamIcons/115.png",
        "Stadium":"Hampden Park",
        "Country":"Scotland",
        "ManagerName":"Alex McLeish",
        "SYMID":"SCO",
        "Competition":[  
            {  
                "CompetitionID":"88",
                "CompetitionName":"Friendlies",
                "CompetitionImageUrl":null
            },
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"364",
        "TeamFullName":"Serbia",
        "TeamShortName":"Serbia",
        "TeamImageUrl":"/TeamIcons/364.png",
        "Stadium":"Rajko Mitic Stadium",
        "Country":"Serbia",
        "ManagerName":"Mladen Krstajic",
        "SYMID":"SRB",
        "Competition":[  
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"507",
        "TeamFullName":"Slovakia",
        "TeamShortName":"Slovakia",
        "TeamImageUrl":"/TeamIcons/507.png",
        "Stadium":"Tehelne Pole Stadium",
        "Country":"Slovakia",
        "ManagerName":"Pavel Hapal",
        "SYMID":"SVK",
        "Competition":[  
            {  
                "CompetitionID":"88",
                "CompetitionName":"Friendlies",
                "CompetitionImageUrl":null
            },
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"365",
        "TeamFullName":"Slovenia",
        "TeamShortName":"Slovenia",
        "TeamImageUrl":"/TeamIcons/365.png",
        "Stadium":"Arena Petrol",
        "Country":"Slovenia",
        "ManagerName":"Igor Benedejcic",
        "SYMID":"SVN",
        "Competition":[  
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"118",
        "TeamFullName":"Spain",
        "TeamShortName":"Spain",
        "TeamImageUrl":"/TeamIcons/118.png",
        "Stadium":"",
        "Country":"Spain",
        "ManagerName":"Luis Enrique Mart\u00ednez",
        "SYMID":"ESP",
        "Competition":[  
            {  
                "CompetitionID":"88",
                "CompetitionName":"Friendlies",
                "CompetitionImageUrl":null
            },
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"361",
        "TeamFullName":"Sweden",
        "TeamShortName":"Sweden",
        "TeamImageUrl":"/TeamIcons/361.png",
        "Stadium":"Friends Arena",
        "Country":"Sweden",
        "ManagerName":"Jan Olof Andersson",
        "SYMID":"SWE",
        "Competition":[  
            {  
                "CompetitionID":"88",
                "CompetitionName":"Friendlies",
                "CompetitionImageUrl":null
            },
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"497",
        "TeamFullName":"Switzerland",
        "TeamShortName":"Switzerland",
        "TeamImageUrl":"/TeamIcons/497.png",
        "Stadium":"",
        "Country":"Switzerland",
        "ManagerName":"Vladimir Petkovic",
        "SYMID":"SUI",
        "Featured":"1",
        "Competition":[  
            {  
                "CompetitionID":"88",
                "CompetitionName":"Friendlies",
                "CompetitionImageUrl":null
            },
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"362",
        "TeamFullName":"Turkey",
        "TeamShortName":"Turkey",
        "TeamImageUrl":"/TeamIcons/362.png",
        "Stadium":"Atat\u00fcrk Olympic Stadium",
        "Country":"Turkey",
        "ManagerName":"Mircea Lucescu",
        "SYMID":"TUR",
        "Competition":[  
            {  
                "CompetitionID":"88",
                "CompetitionName":"Friendlies",
                "CompetitionImageUrl":null
            },
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"510",
        "TeamFullName":"Ukraine",
        "TeamShortName":"Ukraine",
        "TeamImageUrl":"/TeamIcons/510.png",
        "Stadium":"Valeriy Lobanovsky Dynamo Stadium",
        "Country":"Ukraine",
        "ManagerName":"Andriy Shevchenko",
        "SYMID":"UKR",
        "Competition":[  
            {  
                "CompetitionID":"88",
                "CompetitionName":"Friendlies",
                "CompetitionImageUrl":null
            },
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    },
    {  
        "TeamID":"508",
        "TeamFullName":"Wales",
        "TeamShortName":"Wales",
        "TeamImageUrl":"/TeamIcons/508.png",
        "Stadium":"Cardiff City Stadium",
        "Country":"Wales",
        "ManagerName":"Ryan Giggs",
        "SYMID":"WAL",
        "Competition":[  
            {  
                "CompetitionID":"88",
                "CompetitionName":"Friendlies",
                "CompetitionImageUrl":null
            },
            {  
                "CompetitionID":"941",
                "CompetitionName":"UEFA Nations League",
                "CompetitionImageUrl":"https://d21xmla0sqnlu7.cloudfront.net/Sports/Soccer/Images/CompetitionIcon/UNL.png"
            }
        ]
    }
]