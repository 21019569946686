import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
import * as metaDescriptionAction from '../../actions/metaDescription';

class UpdateMetaDescription extends Component {
  getMetaDescriptionFromPath = (pathName) => {
    switch (pathName) {
      case '/':
        return `Get the latest news, highlights, live scores & fixtures for the Premier League, Women's Super League and more, from Australia's home of football OPTUS SPORT`;
      case '/login':
        return 'Log in to your Optus Sport Account here or reset your password.';
      case '/campaign':
        return `Sign up to Optus Sport here. Get access to the best in Football, with Premier League, Women's Super League and more. Live & On-Demand.`;
      case '/forgotpassword':
        return 'Change your Optus Sport password here.';
      case '/changepassword':
        return 'Change Password';
      case '/help':
        return 'Need a hand? Head to Optus Sport Help & Support for answers to common questions or to chat with other users on our forums.';
      case '/signup':
        return `Create a free Optus Sport Account | Subscribe to stream Premier League & Women's Super League matches live & on-demand in Australia on OPTUS SPORT`;
      case '/register':
        return 'Create your Optus Sport account.';
      case '/checkout':
        return 'Change your current Optus Sport subscription.';
      case '/usersettings':
        return 'Your Optus Sport account settings.';
      default:
        return this.props.metaDescription;
    }
  };

  componentDidMount() {
    const path = window.location.pathname;
    this.props.metaDescriptionAction &&
      this.props.metaDescriptionAction.setMetaDescription(
        this.getMetaDescriptionFromPath(path),
      );
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.location !== prevProps.location ||
      this.props.metaDescription !== prevProps.metaDescription
    ) {
      const path = _.get(this, 'props.location.pathname', null);
      this.props.metaDescriptionAction &&
        this.props.metaDescriptionAction.setMetaDescription(
          this.getMetaDescriptionFromPath(path),
        );
    }
  }

  render() {
    const { metaDescription = '' } = this.props;
    return (
      <Helmet>
        <meta name='description' content={metaDescription} />
      </Helmet>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    metaDescription: state.metaDescription,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    metaDescriptionAction: bindActionCreators(metaDescriptionAction, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UpdateMetaDescription),
);
