import React from 'react';
const Icon = (props) => {
  const { fill = '#fff' } = props;
  return (
    <g fill="none" fillRule="evenodd">
      <path d="m0 0h32v32h-32z" />
      <path
        d="m15.8473569 24c4.5024556 0 8.1526431-3.6512058 8.1526431-8.1554752 0-2.6859736-1.309357-5.1107217-3.3675763-6.60579821-.3759913 6.11924981-5.2782905 11.01426931-11.39997755 11.37677361 1.49353375 2.0678568 3.92287975 3.3844998 6.61491075 3.3844998zm-7.3443833-5.3629625c5.6071499 0 10.1526431-4.5467611 10.1526431-10.15547515 0-.85605679-.1058912-1.68737448-.3052862-2.48156235 4.3967469 1.11507777 7.6496695 5.0998149 7.6496695 9.8445248 0 5.6087141-4.5454931 10.1554752-10.1526431 10.1554752-4.7513318 0-8.74035558-3.2647395-9.8473569-7.6739128.80064067.2030539 1.63921036.3109503 2.5029736.3109503z"
        fill={fill}
      />
    </g>
  );
};

export default Icon;
