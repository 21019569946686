import React, { useRef, useEffect } from 'react';

// We should look into the list of the event path for determine
// if we should call the passed callback or not, not just checking
// the event.target for this purpose
export const useClickOutSide2 = ({ ref, callback, enabled = true }) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (typeof callback !== 'function') {
        throw Error('The provided callback is not a function');
      } else {
        if (ref.current) {
          if (!ref.current.id) {
            throw Error("The provided element ref must contain 'id' attribute");
          } else {
            const path = event.path || (event.composedPath && event.composedPath());
            if (path && path.find && !path.find((item) => item.id === ref.current.id)) {
              callback();
            }
          }
        } else {
          throw Error('useClickOutSide2 requires a ref to work with');
        }
      }
    };

    if (enabled && typeof callback === 'function') {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      if (enabled && typeof callback === 'function') {
        document.removeEventListener('mousedown', handleClickOutside);
      }
    };
  }, [ref, callback, enabled]);
};

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter({ ref, callback }) {
  /**
   * Alert if clicked on outside of element
   */
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      callback();
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
}

const useClickOutside = ({ children, style, ...props }) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter({ ref: wrapperRef, callback: props.callback });

  return (
    <div ref={wrapperRef} style={style}>
      {children}
    </div>
  );
};

export default useClickOutside;
