import _ from 'lodash';
import * as types from '../constants/actionTypes';
import jsCookie from 'js-cookie';
import getThemes, { themeNames } from '../utils/themes';

const THEME_COOKIE = 'OSTHEME';
const THEME_ALERT_COOKIE = 'OSTHEMEALERT';

export function getThemeCookie() {
  return jsCookie.get(THEME_COOKIE);
}

export function getThemeAlertCookie() {
  return jsCookie.get(THEME_ALERT_COOKIE);
}

export function setThemeCookie(theme, expire) {
  const expires = expire || 365;

  return jsCookie.set(
    THEME_COOKIE,
    theme,
    {
      expires
    }
  );
}

export function setThemeAlertCookie(themeAlert, expire) {
  const expires = expire || 365;

  return jsCookie.set(
    THEME_ALERT_COOKIE,
    themeAlert,
    {
      expires
    }
  );
}

/*export function removeThemeCookie() {
  return jsCookie.remove(THEME_COOKIE);
}

export function removeThemeAlertedCookie() {
  return jsCookie.remove(THEME_ALERTED_COOKIE);
}*/

export function initTheTheme() {
  return dispatch => {
    const currentThemeName = getThemeCookie();

    if (_.get(themeNames, currentThemeName)) {
      dispatch(toggleTheTheme(currentThemeName, true));
    }
  };
}

export function checkThemeAlert() {
  return dispatch => {
    const themeAlert = getThemeAlertCookie();

    if (themeAlert) {
      dispatch(setThemeAlert(true));
    }
  };
}

export function toggleTheTheme(theme, skipCookieSet = false) {
  return dispatch => {
    // Ensure we always have the theme object vs. theme name
    let themeObj = _.get(theme, 'name');
    themeObj = typeof themeObj === 'undefined' ? _.get(getThemes(), theme) : theme;

    if (themeObj && typeof themeObj === 'object') {
      if (!skipCookieSet) {
        // Update or set the cookie
        const themeName = _.get(themeObj, 'name');
        if (typeof themeName === 'string') {
          setThemeCookie(themeName);
        }
      }

      dispatch({
        type: types.TOGGLE_THE_THEME,
        'theme': themeObj
      });
    }
  };
}

export function setThemeAlert(skipCookieSet = false) {
  return dispatch => {
    if (!skipCookieSet) {
      setThemeAlertCookie(true);
    }

    dispatch({
      type: types.SET_THEME_ALERT,
      'themeAlert': true
    });
  };
}
