import React from 'react';
import PropTypes from 'prop-types';

const GoalIcon = ({ width, height, viewBox, ...props }) => (

  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox} {...props}>
    <g fill="#FFF" fillRule="evenodd">
        <path d="M12.863 10.68l-2.011.826L9.627 9.82l.015-.046.73-2.245.015-.046 1.99-.647 1.132 1.837a5.493 5.493 0 0 1-.646 2.006zm-.939 1.244a5.65 5.65 0 0 1-.859.705c-.37.246-.768.446-1.185.596l.872-1.027.163-.191.232-.096 1.238-.509a5.635 5.635 0 0 1-.46.522zm-4.993 1.524l-1.392-1.64 1.227-1.688h2.476l1.215 1.672-1.409 1.66a5.61 5.61 0 0 1-2.117-.004zm-1.991-.816a5.565 5.565 0 0 1-.865-.708 5.645 5.645 0 0 1-.44-.496l1.214.5.232.095.163.191.85 1.002a5.49 5.49 0 0 1-1.154-.584zM2.491 8.685L3.622 6.85l1.99.647.015.045.73 2.246.015.045-1.228 1.689-1.995-.82a5.493 5.493 0 0 1-.658-2.017zm.906-3.79l-.101 1.336-.019.25-.132.214-.692 1.123a5.565 5.565 0 0 1 .34-1.75 5.49 5.49 0 0 1 .604-1.172zm2.256-1.927l2.092.508v2.092l-.039.029-1.91 1.387-.056.041-1.967-.639.165-2.167a5.554 5.554 0 0 1 1.715-1.251zm2.092-.512a5.689 5.689 0 0 1 .488 0c.441.017.873.087 1.29.205l-1.29.313-.244.06-.244-.06-1.278-.31a5.56 5.56 0 0 1 1.278-.208zm.25 3.55l1.9 1.38-.726 2.232H6.822l-.725-2.233 1.898-1.379zm3.93-1.93l.138.144.163 2.153-1.988.645-.047-.034-1.91-1.387-.048-.036V3.476l2.106-.512c.582.27 1.118.644 1.585 1.112zm1.276 1.98a5.488 5.488 0 0 1 .345 1.747l-.692-1.122-.132-.213-.019-.25-.1-1.32c.246.362.446.75.598 1.158zm-.483-1.763a6.045 6.045 0 0 0-2.66-1.93 6.014 6.014 0 0 0-4.627.213 5.988 5.988 0 0 0-2.158 1.728A5.986 5.986 0 0 0 2.05 8.772a5.98 5.98 0 0 0 1.707 3.47A5.972 5.972 0 0 0 8 14a6.03 6.03 0 0 0 1.639-.228 5.972 5.972 0 0 0 2.603-1.53 6.03 6.03 0 0 0 1.02-1.356 5.981 5.981 0 0 0 .733-2.655 5.985 5.985 0 0 0-1.277-3.938z"/>
        <path d="M12.863 10.68l-2.011.826L9.627 9.82l.015-.046.73-2.245.015-.046 1.99-.647 1.132 1.837a5.493 5.493 0 0 1-.646 2.006zm-.939 1.244a5.65 5.65 0 0 1-.859.705c-.37.246-.768.446-1.185.596l.872-1.027.163-.191.232-.096 1.238-.509a5.635 5.635 0 0 1-.46.522zm-4.993 1.524l-1.392-1.64 1.227-1.688h2.476l1.215 1.672-1.409 1.66a5.61 5.61 0 0 1-2.117-.004zm-1.991-.816a5.565 5.565 0 0 1-.865-.708 5.645 5.645 0 0 1-.44-.496l1.214.5.232.095.163.191.85 1.002a5.49 5.49 0 0 1-1.154-.584zM2.491 8.685L3.622 6.85l1.99.647.015.045.73 2.246.015.045-1.228 1.689-1.995-.82a5.493 5.493 0 0 1-.658-2.017zm.906-3.79l-.101 1.336-.019.25-.132.214-.692 1.123a5.565 5.565 0 0 1 .34-1.75 5.49 5.49 0 0 1 .604-1.172zm2.256-1.927l2.092.508v2.092l-.039.029-1.91 1.387-.056.041-1.967-.639.165-2.167a5.554 5.554 0 0 1 1.715-1.251zm2.092-.512a5.689 5.689 0 0 1 .488 0c.441.017.873.087 1.29.205l-1.29.313-.244.06-.244-.06-1.278-.31a5.56 5.56 0 0 1 1.278-.208zm.25 3.55l1.9 1.38-.726 2.232H6.822l-.725-2.233 1.898-1.379zm3.93-1.93l.138.144.163 2.153-1.988.645-.047-.034-1.91-1.387-.048-.036V3.476l2.106-.512c.582.27 1.118.644 1.585 1.112zm1.276 1.98a5.488 5.488 0 0 1 .345 1.747l-.692-1.122-.132-.213-.019-.25-.1-1.32c.246.362.446.75.598 1.158zm-.483-1.763a6.045 6.045 0 0 0-2.66-1.93 6.014 6.014 0 0 0-4.627.213 5.988 5.988 0 0 0-2.158 1.728A5.986 5.986 0 0 0 2.05 8.772a5.98 5.98 0 0 0 1.707 3.47A5.972 5.972 0 0 0 8 14a6.03 6.03 0 0 0 1.639-.228 5.972 5.972 0 0 0 2.603-1.53 6.03 6.03 0 0 0 1.02-1.356 5.981 5.981 0 0 0 .733-2.655 5.985 5.985 0 0 0-1.277-3.938z"/>
    </g>
</svg>
);

GoalIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  viewBox: PropTypes.string
};

GoalIcon.defaultProps = {
  width: 32,
  height: 32,
  viewBox: '0 0 32 32'
};

export default GoalIcon;



