import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function lineup(state = initialState.lineup, action) {
  let currentLineup;

  switch (action.type) {

    case types.GET_LINEUP_INPROGRESS:
      currentLineup = {
        ...state.find(lineup => lineup.competitionId === action.results.competitionId &&  lineup.season === action.results.season && lineup.matchId === action.results.matchId),
        ...action.results,
        inProgress: true,
        isSuccess: false
      };

      return [
        ...state.filter(lineup => lineup.competitionId !== action.results.competitionId || lineup.season !== action.results.season),
        currentLineup
      ];

    case types.GET_LINEUP_SUCCESS:

      return [
        ...state.filter(lineup => lineup.competitionId !== action.results.competitionId || lineup.season !== action.results.season),
        {
          ...action.results,
          inProgress: false,
          isSuccess: true
        }
      ];

    case types.GET_LINEUP_FAILURE:
      currentLineup = {
        // for failure case, use whatever has been loaded before
        ...state.find(lineup => lineup.competitionId === action.results.competitionId &&  lineup.season === action.results.season && lineup.matchId === action.results.matchId),
        ...action.results,
        inProgress: false,
        isSuccess: false
      };

      return [
        ...state.filter(lineup => lineup.competitionId !== action.results.competitionId || lineup.season !== action.results.season),
        currentLineup
      ];

    default:
      return state;
  }
}
