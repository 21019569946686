import get from 'lodash/get';
import axios from 'axios';
import * as types from '../constants/actionTypes';
import defaultConfig from 'constants/defaultConfig';

export function getTeamStats(seasonId, competitionId, teamId, competitionCode) {
  return (dispatch) => {
    const results = { seasonId, competitionId, competitionCode, teamId };

    const {
      vcmsBaseUrl,
      VCMSConfig: { teamStatsEndpoint },
    } = defaultConfig;
    if (!seasonId || !competitionId || !competitionCode || !teamId) {
      return;
    }
    const url = `${vcmsBaseUrl}${teamStatsEndpoint}?season=${seasonId}&competitionCode=${competitionCode}&team=${teamId}`;
    dispatch({ type: types.GET_TEAMSTATS_INPROGRESS, results });

    axios
      .get(url)
      .then((res) => {
        const isSuccess = get(res, 'data.result[0].statuscode', 'N') === 'Y';

        if (isSuccess) {
          results.rows = get(res, 'data.APIinfo[0].StatInfo', []); // results return HighlightsInfo as well
          dispatch({ type: types.GET_TEAMSTATS_SUCCESS, results });
        } else {
          dispatch({ type: types.GET_TEAMSTATS_FAILURE, results });
        }
      })
      .catch((error) => {
        dispatch({ type: types.GET_TEAMSTATS_FAILURE, results });
      });
  };
}
