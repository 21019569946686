import React from 'react';
import theme from './theme';
import GlobalTheme from './GlobalTheme';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import './styles/tailwind-compiled.css';

const ThemeProvider = (props) => {
  const { children } = props;
  return (
    <>
      <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
      <GlobalTheme />
    </>
  );
};

export default ThemeProvider;
