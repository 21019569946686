import _ from 'lodash';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import defaultConfig from '../../../../constants/defaultConfig';
import VideoCard from './asset-card/AssetCard';
import selectors from './selectors';
import { selectors as storeSelector } from '../../../../store/createPortalStore';
import Header from './Header/';
import { assetFilter } from '../../PromotionalPlacements/utils';
import { ContentCardTypeName } from '../../PromotionalPlacements/constants';
import ContentCardAUI from '../../PromotionalPlacements/layouts/ContentCardAUI';
import ContentCardAsset from '../../PromotionalPlacements/components/ContentCardAsset';
import SectionLoader from '../../../ui/ui-kit/SectionLoader';

/**
 *
 * @param allAssets
 * @param noOfCols
 * @param noOfRows
 * @param categoryMapping
 * @param isArticle
 * @returns {*[]}
 */

const getVideoCardListsDom = (
  allAssets,
  noOfCols = 4,
  noOfRows,
  categoryMapping,
  isArticle = false,
  theme = 'dark',
  userTags,
  contentCardData,
  editorial,
) => {
  const {
    isReady: isContentCardsReady,
    data: contentCardsInObj,
  } = contentCardData;

  if (!userTags || !isContentCardsReady) {
    return <SectionLoader />;
  }

  const allAssetsCloned = assetFilter(allAssets, userTags, contentCardsInObj);

  let splittedAssets = [],
    rowIndex = 0;
  while (rowIndex < noOfRows && allAssetsCloned.length) {
    splittedAssets.push(allAssetsCloned.splice(0, noOfCols));
    rowIndex++;
  }
  return splittedAssets.map((assets, i) => (
    <div
      key={`${editorial?.id}-${i}`}
      className={`layout-d-card-list ${i < noOfRows - 1 ? 'pb-16' : ''}`}
    >
      {assets.map((asset, currIndex) => {
        const { assetTypeName } = asset;
        return (
          <div className='item' key={`${editorial?.id}-${asset?.id}`}>
            {assetTypeName === ContentCardTypeName ? (
              <ContentCardAsset
                key={asset?.id}
                assetId={asset?.id}
                uiComponent={ContentCardAUI}
                editorialId={editorial.id}
              />
            ) : (
              <VideoCard
                key={asset.id}
                asset={asset}
                categoryMapping={categoryMapping}
                isArticle={isArticle}
                theme={theme}
                themeType='d'
                className='rounded-t-xl'
                editorialId={editorial.id}
              />
            )}
          </div>
        );
      })}
    </div>
  ));
};

/**
 * Responsive Component to create row(s) of cards
 *
 * Desktop mode: 4 Normal cards in 1 row
 *
 * Tablet & Mobile: Smaller cards in 2 cards in 2 separate rows
 *
 * Number of rows to render can be controlled by `noOfRows` props
 */

class LayoutDVideoCardList extends Component {
  render() {
    const {
      editorial,
      categoryPath,
      videoCardsListDom,
      isArticle,
      carousel,
      viewAllUrl,
      showViewAllLink,
      theme,
    } = this.props;

    const cardList =
      videoCardsListDom && videoCardsListDom.length ? (
        <div className='w-full'>
          {!isArticle && (
            <Header
              title={_.get(carousel, 'title', _.get(editorial, 'title', ''))}
              categoryPath={categoryPath}
              viewAllUrl={viewAllUrl}
              showViewAllLink={showViewAllLink}
              theme={theme}
            />
          )}
          {videoCardsListDom}
        </div>
      ) : null;
    return <>{cardList}</>;
  }
}

function mapStateToProps(state, ownProps) {
  const noOfRows = ownProps.noOfRows || 1;
  const categoryMapping = _.get(state, 'systemConfig.categoryMapping', []);
  const noOfCols = ownProps.noOfCols || 4;
  const userTags = storeSelector.user.getUserTags(state);

  const contentCardsInObj = storeSelector.contentCards.getContentCardsInObj(
    state,
  );

  const editorial = state.editorials.find(
    (ed) => ed.id === ownProps.carousel.id,
  );

  if (ownProps.isArticle) {
    const { articles, isArticle, theme } = ownProps;
    return {
      videoCardsListDom: getVideoCardListsDom(
        articles,
        noOfCols,
        noOfRows,
        categoryMapping,
        isArticle,
        theme,
        userTags,
        contentCardsInObj,
        editorial,
      ),
    };
  }

  const imageBaseUrl =
    _.get(state, 'systemConfig.image.baseUrl', null) ||
    defaultConfig.image.baseUrl;
  const carouselImageSize =
    _.get(state, 'systemConfig.image.sizes.carouselImageSize', null) ||
    defaultConfig.image.sizes.carouselImageSize;
  const assetTypeNames = _.get(
    state,
    'systemConfig.hideCarouselModal.assetTypeNames',
    [],
  );
  const assets = ownProps.asset || _.get(editorial, 'assets', []);
  const { theme } = ownProps;
  return {
    imageBaseUrl,
    carouselImageSize,
    assetTypeNames,
    categoryMapping,
    editorial,
    categoryPath: selectors.getCategoryPath(ownProps, editorial),
    videoCardsListDom: getVideoCardListsDom(
      assets,
      noOfCols,
      noOfRows,
      categoryMapping,
      null,
      theme,
      userTags,
      contentCardsInObj,
      editorial,
    ),
  };
}

LayoutDVideoCardList.propTypes = {
  noOfRows: PropTypes.number,
  noOfCols: PropTypes.number,
  viewllUrl: PropTypes.string,
  showViewAllLink: PropTypes.bool,
};

LayoutDVideoCardList.defaultProps = {
  noOfRows: 1,
  noOfCols: 4,
  viewAllUrl: null,
  showViewAllLink: true,
};

export default connect(mapStateToProps)(LayoutDVideoCardList);
