import * as types from '../constants/actionTypes';
import API from '@aws-amplify/api';

export function getSAAGData() {
  const saagdata = {};
  return function(dispatch) {
    dispatch({ type: types.GET_SAAGDATA_INPROGRESS, saagdata });
    dispatch({
      type: types.SET_SAAG_STATUS_INPROGRESS,
    });

    return API.get('fe-api-dev-sportsdataGetSaagData', '', {
      queryStringParameters: {
        v: '1',
      },
    })
      .then((saagdata) => {
        if (!saagdata.error) {
          // set saag data
          dispatch({ type: types.GET_SAAGDATA_SUCCESS, saagdata });
          dispatch({
            type: types.SET_SAAG_STATUS_SUCCESS,
          });
        } else {
          // unset saag data
          dispatch({ type: types.GET_SAAGDATA_FAILURE, saagdata });
          dispatch({
            type: types.SET_SAAG_STATUS_FAILURE,
          });
        }
      })
      .catch((err) => {
        // Error so clear saag data
        dispatch({ type: types.GET_SAAGDATA_FAILURE, saagdata });
        dispatch({
          type: types.SET_SAAG_STATUS_FAILURE,
        });
      });
  };
}
