import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import defaultConfig from '../../constants/defaultConfig';
import {
  getPageTitleFromPath,
} from '../../utils';


class RouteWithGoogleAnalytics extends Component {

  componentWillUpdate({ location, history }) {
    const gtag = window.gtag;
    // Ignore identical links/hard refresh
    if (location.pathname === this.props.location.pathname) {
      return;
    }
    // If navigated back/forward
    if ((history.action === 'PUSH' || history.action === 'POP')  && typeof (gtag) === 'function') {
      const path = _.get(location, 'pathname', ' ').substring(1);
      gtag('config', defaultConfig.gaTrackingId, {
        'page_title': getPageTitleFromPath(path),
        'page_location': window.location.href,
        'page_path': location.pathname
      });
    }
  }

  render() {
    return null;
  }
}

export default withRouter(RouteWithGoogleAnalytics);
